import { RouteObject, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { InputWrapper } from '../../../components/input/InputWrapper/InputWrapper.tsx'
import { TextInput } from '../../../components/input/TextInput/TextInput.tsx'
import { Button } from '../../../components/component/Button/Button.tsx'
import { useFormSubmitMutation } from '../../../hooks/useFormSubmitMutation.ts'
import { graphql } from '../../../gql'
import { useFormDefaultValue } from '../../../hooks/useFormDefaultValue.ts'
import { fetchUser } from '../../../auth'
import {
  MultiSelectInput,
  SelectInput,
} from '../../../components/input/SelectInput/SelectInput.tsx'

const Page = () => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      role: 'developer',
      interests: [],
    },
  })

  useFormDefaultValue({
    control,
    queryKey: ['defaultProfileName'],
    request: graphql(`
      query defaultProfileName {
        me {
          suggestions {
            firstName
            lastName
          }
        }
      }
    `),
    mapDefaultValues: ({ me }) => ({
      firstName: me?.suggestions.firstName ?? '',
      lastName: me?.suggestions.lastName ?? '',
      role: 'developer',
      interests: [],
    }),
  })

  const navigate = useNavigate()

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation updatePersonalInformationOnboarding(
        $input: PersonalInformation!
      ) {
        updatePersonalInformation(personalInformation: $input) {
          id
        }
      }
    `),
    mapVariables: (data) => ({
      input: {
        firstName: data.firstName,
        lastName: data.lastName,
        role: data.role,
        interests: data.interests,
      },
    }),
    onSuccess: () => {
      fetchUser()
      navigate('/')
    },
  })

  return (
    <>
      <h1>About yourself</h1>
      <p style={{ marginBottom: 24 }}>
        This will help others in your team know who you are.
      </p>
      <form onSubmit={handleSubmit(submit)}>
        <InputWrapper
          component={TextInput}
          control={control}
          name="firstName"
          label="First name"
          autoFocus
        />
        <InputWrapper
          component={TextInput}
          control={control}
          name="lastName"
          label="Last name"
        />
        <InputWrapper
          //@ts-ignore
          component={SelectInput}
          control={control}
          name="role"
          label="Role"
          options={[
            { label: 'Engineering', value: 'developer' },
            { label: 'Product', value: 'product' },
            { label: 'Other', value: 'other' },
          ]}
        />
        <InputWrapper
          //@ts-ignore
          component={MultiSelectInput}
          control={control}
          name="interests"
          label="I am interested in"
          tooltip="This will help us give you an onboarding experience tailored to your needs"
          closeMenuOnSelect={false}
          options={[
            { label: 'Release management', value: 'releaseManagement' },
            { label: 'A/B testing', value: 'ABTesting' },
            { label: 'Remote Configuration', value: 'remoteConfiguration' },
            { label: 'Technical Migration', value: 'technicalMigration' },
            { label: 'Other', value: 'other' },
          ]}
        />
        <Button type="submit" color="primary" inline loading={isSubmitting}>
          Let's go!
        </Button>
      </form>
    </>
  )
}

export const profileRoute: RouteObject = {
  path: 'profile',
  element: <Page />,
}
