import { type TypedDocumentNode } from '@graphql-typed-document-node/core'
import { Control, FieldPath, FieldValues } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { gqlClient } from '../auth'
import { ClientError } from 'graphql-request'
import { GraphQLError } from 'graphql'
import { useIntl } from 'react-intl'
import { useModalContext } from '../components/component/Modal/Modal.tsx'

export type UseFormSubmitMutationOptions<
  TResponse,
  TVariables,
  TFieldValues extends FieldValues = FieldValues
> = {
  mutation: TypedDocumentNode<TResponse, TVariables>
  mapVariables?: (data: TFieldValues) => TVariables
  control?: Control<TFieldValues>
  resetOnSuccess?: boolean
  onStart?: (data: TFieldValues) => Promise<void> | void
  onSuccess?: (responseData: NonNullable<TResponse>, data: TFieldValues) => void
  onError?: Record<string, (error: GraphQLError, data: TFieldValues) => void>
  onUnknownError?: (error: GraphQLError, data: TFieldValues) => void
  onRootError?: (error: {
    type: string
    context?: Record<string, unknown>
  }) => void
}

export const useFormSubmitMutation = <
  TResponse,
  TVariables,
  TFieldValues extends FieldValues = FieldValues
>({
  mutation,
  control,
  resetOnSuccess = true,
  onError,
  onRootError,
  onStart,
  onSuccess,
  onUnknownError,
  mapVariables = (data) => data as unknown as TVariables,
}: UseFormSubmitMutationOptions<TResponse, TVariables, TFieldValues>) => {
  const onErrorWithDefault: Record<
    string,
    (error: GraphQLError, data: TFieldValues) => void
  > = {
    TRIAL_ENDED: () =>
      toast.error(
        'Your trial has ended, go to the billing tab and choose a plan'
      ),
    ...onError,
  }

  const intl = useIntl()
  const modal = useModalContext()

  const handleUnknownGraphQLError =
    onUnknownError ??
    ((error) => {
      toast.error('Something went wrong')
      console.error('GraphQL error', error)
    })

  const handleRootError =
    onRootError ??
    ((error) => {
      toast.error(error.type)
    })

  const handleGraphQLError = (error: GraphQLError, data: TFieldValues) => {
    const code = (error.extensions.code as string) ?? 'UNKNOWN'

    if (code === 'BAD_USER_INPUT' && control) {
      if (!error.extensions.fields) {
        handleUnknownGraphQLError(error, data)
      }

      for (const field of (error.extensions.fields ?? []) as {
        path: FieldPath<TFieldValues> | 'root'
        type: string
        context?: Record<string, string>
      }[]) {
        if (field.path === 'root' || !field.path) {
          handleRootError(field)
        } else {
          control.setError(field.path, {
            type: 'server',
            message: intl.formatMessage(
              {
                id: 'formError.' + field.type,
                defaultMessage: field.type,
              },
              field.context
            ),
          })
        }
      }
    } else {
      if (onErrorWithDefault?.[code]) {
        onErrorWithDefault[code](error, data)
      } else {
        handleUnknownGraphQLError(error, data)
      }
    }
  }

  return async (data: TFieldValues) => {
    try {
      await onStart?.(data)
    } catch (error) {
      return
    }

    try {
      const response = await gqlClient.request(
        mutation,
        mapVariables(data) as any
      )

      onSuccess?.(response as NonNullable<TResponse>, data)
      if (resetOnSuccess && control) {
        control._reset(data)
      }
      if (modal) {
        modal.close()
      }
    } catch (error) {
      if (error instanceof ClientError) {
        if (error.response.errors?.length) {
          handleGraphQLError(error.response.errors[0], data)
        } else {
          toast.error('Something went wrong')
          console.error('Client error', error)
        }
      }
    }
  }
}
