import { Outlet, RouteObject, useNavigate } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { DeepRequired, useForm } from 'react-hook-form'
import {
  IconLockOpen,
  IconPlayerPlay,
  IconPlus,
  IconSettings,
  IconTrash,
  IconWorld,
  IconX,
} from '@tabler/icons-react'
import { useCurrentOrgSafe } from '../../../../hooks/useCurrentOrgSafe.ts'
import { gqlClient } from '../../../../auth'
import { graphql } from '../../../../gql'
import { OrgWebhooksQuery, WebhooksRole } from '../../../../gql/graphql.ts'
import { Table } from '../../../../components/component/Table/Table.tsx'
import { Dropdown } from '../../../../components/component/DropdownMenu/DropdownMenu.tsx'
import { webhookRoute } from './Webhook.tsx'
import {
  useDeleteWebhook,
  useTestRunWebhook,
} from '../../../../queries/webhooks.tsx'
import { openModal } from '../../../../components/component/Modal/Modal.tsx'
import { useFormSubmitMutation } from '../../../../hooks/useFormSubmitMutation.ts'
import { toast } from 'react-hot-toast'
import { InputWrapper } from '../../../../components/input/InputWrapper/InputWrapper.tsx'
import { TextInput } from '../../../../components/input/TextInput/TextInput.tsx'
import { FormSubmitButtons } from '../../../../components/component/FormSubmitButtons/FormSubmitButtons.tsx'
import {
  AddRowsComponentProps,
  CellProps,
  DataSheetGrid,
  keyColumn,
  textColumn,
} from 'react-datasheet-grid'
import { IconButton } from '../../../../components/component/IconButton/IconButton.tsx'
import { Button } from '../../../../components/component/Button/Button.tsx'
import upgradePlan from '../../../../assets/empty-state/add-product.svg'
import { EmptyStateImage } from '../../../../components/layout/EmptyStateImage/EmptyStateImage.tsx'
import addHook from '../../../../assets/empty-state/add-hook.svg'

type Row = DeepRequired<OrgWebhooksQuery>['organization']['webhooks'][0]

const WebhookForm = () => {
  const { organization } = useCurrentOrgSafe()

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, isDirty },
  } = useForm({
    defaultValues: {
      url: '',
      headers: [
        { name: 'Content-Type', value: 'application/json' },
        { name: 'Authorization', value: 'Bearer ' },
      ],
    },
  })

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation createWebhook($id: ID!, $url: String!, $headers: JSON!) {
        organization(id: $id) {
          createWebhook(url: $url, headers: $headers) {
            id
          }
        }
      }
    `),
    mapVariables: (data) => ({
      id: organization.id,
      url: data.url ?? '',
      headers: data.headers.reduce((acc, cur) => {
        if (cur.name) {
          acc[cur.name] = cur.value
        }
        return acc
      }, {} as Record<string, string>),
    }),
    onSuccess: (data) => {
      toast.success('Webhook created')
      queryClient.invalidateQueries({ queryKey: ['webhooks'] })
      navigate(`/webhooks/${data.organization?.createWebhook.id}`)
    },
    onError: {
      PLAN_LIMIT: () => toast.error('You have reached your plan limit'),
    },
  })

  return (
    <form onSubmit={handleSubmit(submit)}>
      <InputWrapper
        control={control}
        name="url"
        component={TextInput}
        label="Url"
        prefix={<IconWorld size={16} />}
        rules={{ required: true }}
      />
      <InputWrapper
        control={control}
        name="headers"
        component={DataSheetGrid}
        // @ts-ignore
        columns={[
          { ...keyColumn('name', textColumn), title: 'Header' },
          { ...keyColumn('value', textColumn), title: 'Value' },
        ]}
        gutterColumn={false}
        disableContextMenu
        disableExpandSelection
        stickyRightColumn={{
          component: ({ deleteRow }: CellProps) => {
            return <IconButton icon={IconX} onClick={deleteRow} />
          },
        }}
        autoAddRow
        rowHeight={36}
        createRow={() => ({ name: '', value: '' })}
        addRowsComponent={({ addRows }: AddRowsComponentProps) => (
          <div style={{ marginTop: 10 }}>
            <Button
              variant="light"
              onClick={() => addRows(1)}
              inline
              icon={IconPlus}
            >
              Header
            </Button>
          </div>
        )}
      />
      <FormSubmitButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        reset={reset}
        labelSubmit="Create webhook"
      />
    </form>
  )
}

const createWebhook = () =>
  openModal({
    title: 'New Webhook',
    content: <WebhookForm />,
  })

const Page = () => {
  const { organization } = useCurrentOrgSafe()

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['webhooks'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query orgWebhooks($id: ID!) {
            organization(id: $id) {
              id
              webhooks {
                id
                url
              }
              webhooksLimit {
                limit
                current
              }
            }
          }
        `),
        {
          id: organization.id,
        }
      ),
  })

  const columns = useMemo<ColumnDef<Row>[]>(
    () => [
      {
        id: 'url',
        accessorFn: (row) => row.url,
        header: 'Url',
        sortingFn: 'alphanumeric',
        cell: ({ row }) => (
          <div>
            <div>
              <em>{row.original.url}</em>
            </div>
          </div>
        ),
        meta: {
          flex: '2 1 150px',
        },
      },
    ],
    []
  )

  const deleteWebhook = useDeleteWebhook()
  const testRunWebhook = useTestRunWebhook()

  if (data?.organization?.webhooksLimit.limit === 0) {
    return (
      <>
        <h1>Webhooks</h1>
        <EmptyStateImage
          imageSrc={upgradePlan}
          title="Want to get notified when someone changes a flag?"
          text={
            <p>
              Webhooks let you setup automated API calls from Tggl to your own
              backend when anyone changes a flag. This lets you update cache,
              display markers in Datadog, or trigger slack events. Read the{' '}
              <a href="https://tggl.io/developers/webhooks" target="_blank">
                webhooks documentation
              </a>
              .
            </p>
          }
          ctaTo="/settings/billing"
          ctaIcon={IconLockOpen}
          cta="Upgrade plan"
        />
      </>
    )
  }

  return (
    <>
      <h1>Webhooks</h1>
      <Table<Row>
        data={(data?.organization?.webhooks as Row[]) ?? []}
        loading={isLoading}
        fetching={isFetching}
        columns={columns}
        unit="webhooks"
        defaultSorting={[{ id: 'url', desc: false }]}
        onCreate={
          organization.myAggregatedRole.webhooks === WebhooksRole.Manage
            ? createWebhook
            : undefined
        }
        rowTo={({ id }) => `/webhooks/${id}`}
        moreActions={
          organization.myAggregatedRole.webhooks === WebhooksRole.Manage
            ? ({ row }) => (
                <>
                  <Dropdown.Item
                    icon={IconPlayerPlay}
                    label="Test run"
                    onClick={() => testRunWebhook(row.original.id)}
                  />
                  <Dropdown.Item
                    icon={IconSettings}
                    label="Settings"
                    to={`/webhooks/${row.original.id}/settings`}
                  />
                  <hr />
                  <Dropdown.Item
                    icon={IconTrash}
                    label="Delete"
                    danger
                    onClick={() => deleteWebhook(row.original.id)}
                  />
                </>
              )
            : undefined
        }
        emptyState={
          <EmptyStateImage
            imageSrc={addHook}
            title="You have no webhooks yet"
            text={
              <p>
                Webhooks are a way for you to get notified via an API call when
                anyone changes a flag or when a review is requested or updated.
                Read the{' '}
                <a href="https://tggl.io/developers/webhooks" target="_blank">
                  webhooks documentation
                </a>
              </p>
            }
            ctaOnClick={createWebhook}
            ctaIcon={IconPlus}
            cta={
              organization.myAggregatedRole.webhooks === WebhooksRole.Manage &&
              'New webhook'
            }
          />
        }
      />
    </>
  )
}

export const webhooksRoute: RouteObject = {
  path: 'webhooks',
  element: <Outlet />,
  children: [
    webhookRoute,
    {
      path: '',
      element: <Page />,
    },
  ],
}
