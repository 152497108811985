import { Navigate, RouteObject } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { InputWrapper } from '../../../components/input/InputWrapper/InputWrapper.tsx'
import { TextInput } from '../../../components/input/TextInput/TextInput.tsx'
import { Button } from '../../../components/component/Button/Button.tsx'
import { IconAlertTriangleFilled, IconArrowRight } from '@tabler/icons-react'
import style from './Onboarding.module.scss'
import defaultCompanyLogo from '../../../assets/logo/default-company-logo.svg'
import { useFormSubmitMutation } from '../../../hooks/useFormSubmitMutation.ts'
import { graphql } from '../../../gql'
import { useFormDefaultValue } from '../../../hooks/useFormDefaultValue.ts'
import {
  fetchUser,
  gqlClient,
  setToken,
  useCurrentOrg,
  useUser,
} from '../../../auth'
import { SwitchInput } from '../../../components/input/SwitchInput/SwitchInput.tsx'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-hot-toast'
import { AuthenticatedOnly } from '../../../components/layout/Auth/AuthenticatedOnly.tsx'

const Page = () => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      name: '',
      allowSelfSignup: true,
    },
  })

  const { data } = useFormDefaultValue({
    control,
    queryKey: ['defaultOrganizationName'],
    request: graphql(`
      query defaultOrganizationName {
        me {
          suggestions {
            organizationName
            organizationWebsite
          }
        }
      }
    `),
    mapDefaultValues: ({ me }) => ({
      name: me?.suggestions.organizationName ?? '',
      allowSelfSignup: Boolean(me?.suggestions.organizationWebsite),
    }),
  })

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation createOrganization($name: String!, $allowSelfSignup: Boolean) {
        createOrganization(name: $name, allowSelfSignup: $allowSelfSignup) {
          id
        }
      }
    `),
    onSuccess: () => {
      fetchUser()
    },
  })

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationFn: () =>
      gqlClient.request(
        graphql(`
          mutation requestAccessToJoinableOrganization {
            requestAccessToJoinableOrganization
          }
        `)
      ),
    onSuccess: () => {
      toast.success('Request sent!', { position: 'top-center' })
    },
  })

  const user = useUser()

  const name = watch('name')

  const { organization } = useCurrentOrg()

  if (organization) {
    return <Navigate to="/onboarding/profile" />
  }

  if (user?.joinableOrganization) {
    return (
      <>
        <h1>Join Organization</h1>
        <div className={style.companyLogo}>
          <img
            src={
              data?.me?.suggestions.organizationWebsite
                ? `https://img.logo.dev/${user.joinableOrganization.domain}?token=pk_PMblKfzbSO6DrsIAU8SfpA`
                : defaultCompanyLogo
            }
          />
          <span>{user.joinableOrganization.name}</span>
        </div>
        <p style={{ marginBottom: 16 }}>
          You do not have access to this organization yet, ask an admin to
          invite you.
        </p>
        {isError && (
          <p style={{ marginBottom: 16 }}>
            <IconAlertTriangleFilled size={16} /> An error occurred while
            requesting access, try requesting access yourself.
          </p>
        )}
        <Button
          color="success"
          loading={isPending}
          onClick={mutateAsync}
          disabled={isSuccess}
          style={{ marginBottom: 20 }}
        >
          {isSuccess ? 'Request sent!' : 'Request access'}
        </Button>
        <a href="#" onClick={() => setToken(null)}>
          Logout
        </a>
      </>
    )
  }

  return (
    <>
      <h1>Your Organization</h1>
      <div className={style.companyLogo}>
        <img
          src={
            data?.me?.suggestions.organizationWebsite
              ? `https://img.logo.dev/${data?.me?.suggestions.organizationWebsite}?token=pk_PMblKfzbSO6DrsIAU8SfpA`
              : defaultCompanyLogo
          }
        />
        <span>{name}</span>
      </div>
      <form onSubmit={handleSubmit(submit)}>
        <InputWrapper
          component={TextInput}
          control={control}
          name="name"
          label="Name"
          rules={{ required: true }}
          autoFocus
          helper={
            <p>
              Usually the name of your company. This will show up in menus and
              headings.
            </p>
          }
        />
        {data?.me?.suggestions.organizationWebsite && (
          <InputWrapper
            component={SwitchInput}
            control={control}
            name="allowSelfSignup"
            label={`@${data?.me?.suggestions.organizationWebsite} emails can join`}
          />
        )}
        <Button
          type="submit"
          color="primary"
          inline
          icon={IconArrowRight}
          disabled={!name}
          loading={isSubmitting}
        >
          Next
        </Button>
      </form>
    </>
  )
}

export const organizationRoute: RouteObject = {
  path: 'organization',
  element: (
    <AuthenticatedOnly>
      <Page />
    </AuthenticatedOnly>
  ),
}
