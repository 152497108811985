import * as React from 'react'
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
} from '@floating-ui/react'
import './Modal.scss'
import { createContext, ReactNode, useContext, useMemo } from 'react'
import { Singleton } from '../../../auth/Singleton.ts'
import { Toolbar } from '../Toolbar/Toolbar.tsx'
import { IconButton } from '../IconButton/IconButton.tsx'
import { IconX } from '@tabler/icons-react'
import { Button, ButtonProps } from '../Button/Button.tsx'
import cx from 'classnames'
import { Unit } from '../../../intl/types.ts'
import { toast } from 'react-hot-toast'
import { FormattedMessage } from 'react-intl'
import trashImage from '../../../assets/empty-state/trash.svg'
import { AnimatePresence, motion } from 'framer-motion'

interface DialogOptions {
  onClose?: () => void
  size?: 's' | 'm' | 'l'
}

export function useModal({ onClose }: DialogOptions = {}) {
  const data = useFloating({
    open: true,
    onOpenChange: (o) => !o && onClose?.(),
  })

  const context = data.context

  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' })
  const role = useRole(context)

  const interactions = useInteractions([dismiss, role])

  return React.useMemo(
    () => ({
      ...interactions,
      ...data,
    }),
    [interactions, data]
  )
}

const ModalContext = createContext<{ close: () => void } | null>(null)

export const useModalContext = () => useContext(ModalContext)

const MotionFloatingOverlay = motion(FloatingOverlay)

export const Modal = React.forwardRef<
  HTMLDivElement,
  Omit<React.HTMLProps<HTMLDivElement>, keyof DialogOptions> & DialogOptions
>(function DialogContent({ onClose, size = 's', ...props }, propRef) {
  const {
    context: floatingContext,
    refs,
    getFloatingProps,
  } = useModal({ onClose })
  const ref = useMergeRefs([refs.setFloating, propRef])
  const context = useMemo(() => ({ close: onClose ?? (() => null) }), [onClose])

  return (
    <FloatingPortal>
      <MotionFloatingOverlay
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.1 }}
        className="modal-overlay"
        lockScroll
      >
        <FloatingFocusManager context={floatingContext}>
          <ModalContext.Provider value={context}>
            <motion.div
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.1 }}
              ref={ref}
              className={cx('modal', size)}
              {...getFloatingProps(props)}
            >
              {props.children}
            </motion.div>
          </ModalContext.Provider>
        </FloatingFocusManager>
      </MotionFloatingOverlay>
    </FloatingPortal>
  )
})

export type ModalObject = {
  id: string
  title: ReactNode
  body: ReactNode
  onClose?: () => void
  size?: 's' | 'm' | 'l'
}

const modalsSingleton = new Singleton<ModalObject[]>([])
const useModals = modalsSingleton.hook()

export const closeModal = (id: string) => {
  modalsSingleton.setValue(modalsSingleton.value().filter((m) => m.id !== id))
}

export const confirm = ({
  title = 'Are you sure?',
  description,
  confirmProps,
  confirmText = 'Confirm',
  cancelProps,
  cancelText = 'Cancel',
  defaultButton = 'confirm',
}: {
  title?: ReactNode
  description?: ReactNode
  confirmText?: string
  confirmProps?: ButtonProps
  cancelText?: string
  cancelProps?: ButtonProps
  defaultButton?: 'confirm' | 'cancel'
} = {}) => {
  return new Promise((resolve, reject) => {
    const id = Math.random().toString()
    modalsSingleton.setValue([
      ...modalsSingleton.value(),
      {
        id,
        title,
        body: (
          <>
            {description}
            <Toolbar alignRight>
              <Button
                onClick={() => {
                  closeModal(id)
                  resolve(undefined)
                }}
                color="primary"
                autoFocus={defaultButton === 'confirm'}
                {...confirmProps}
              >
                {confirmText}
              </Button>
              <Button
                onClick={() => {
                  closeModal(id)
                  reject()
                }}
                variant="light"
                autoFocus={defaultButton === 'cancel'}
                {...cancelProps}
              >
                {cancelText}
              </Button>
            </Toolbar>
          </>
        ),
        onClose: () => reject(),
      },
    ])
  })
}

export const confirmDelete = ({
  unit = 'items',
  values,
  count = values?.length ?? 1,
  onDelete,
  onDeleted,
  description,
}: {
  unit?: Unit
  count?: number
  values?: string[]
  onDelete: (values: string[]) => Promise<unknown>
  onDeleted?: (values: string[]) => void
  description?: ReactNode
}) => {
  confirm({
    title: null,
    description: (
      <>
        <img
          src={trashImage}
          className="illustration"
          alt="trash-can"
          style={{ aspectRatio: '100/103' }}
        />

        <h2 style={{ textAlign: 'center' }}>
          Delete {count === 1 ? '' : `${count} `}
          <FormattedMessage id={`unit.${unit}`} values={{ count }} />?
        </h2>
        {description ??
          (count === 1 ? (
            values ? (
              <p style={{ textAlign: 'center' }}>
                You are about to delete this{' '}
                <FormattedMessage id={`unit.${unit}`} values={{ count }} />:{' '}
                {values[0]}
                <br />
                Are you sure you want to proceed?
              </p>
            ) : (
              <p style={{ textAlign: 'center' }}>
                You are about to delete this{' '}
                <FormattedMessage id={`unit.${unit}`} values={{ count }} />.
                <br />
                Are you sure you want to proceed?
              </p>
            )
          ) : values ? (
            <>
              <p>
                You are about to delete those{' '}
                <FormattedMessage id={`unit.${unit}`} values={{ count }} />:
              </p>
              <ul>
                {values.map((value) => (
                  <li>{value}</li>
                ))}
              </ul>
              <p>Are you sure you want to proceed?</p>
            </>
          ) : (
            <p style={{ textAlign: 'center' }}>
              You are about to delete those {count}{' '}
              <FormattedMessage id={`unit.${unit}`} values={{ count }} />.
              <br />
              Are you sure you want to proceed?
            </p>
          ))}
      </>
    ),
    confirmText: `Delete${count > 1 ? ` (${count})` : ''}`,
    confirmProps: {
      color: 'error',
    },
  }).then(() =>
    toast.promise(
      onDelete(values ?? []).then(() => onDeleted?.(values ?? [])),
      {
        loading: (
          <>
            Deleting {count === 1 ? '' : `${count} `}
            <FormattedMessage id={`unit.${unit}`} values={{ count }} />
            ...
          </>
        ),
        success: (
          <>
            {count} <FormattedMessage id={`unit.${unit}`} values={{ count }} />{' '}
            deleted
          </>
        ),
        error: (
          <>
            Failed to delete {count === 1 ? '' : `${count} `}
            <FormattedMessage id={`unit.${unit}`} values={{ count }} />
          </>
        ),
      }
    )
  )
}

export const openModal = ({
  title,
  content,
  size,
  onClose,
}: {
  title: ReactNode
  content: ReactNode
  size?: 's' | 'm' | 'l'
  onClose?: () => void
}) => {
  const id = Math.random().toString()
  modalsSingleton.setValue([
    ...modalsSingleton.value(),
    {
      id,
      title,
      body: content,
      size,
      onClose,
    },
  ])
}

export const ModalRenderer = () => {
  const modals = useModals()

  return (
    <AnimatePresence>
      {modals.map((modal) => (
        <Modal
          key={modal.id}
          onClose={() => {
            closeModal(modal.id)
            modal.onClose?.()
          }}
          size={modal.size}
        >
          {modal.title && (
            <Toolbar>
              <h2>{modal.title}</h2>
              <IconButton
                icon={IconX}
                onClick={() => {
                  closeModal(modal.id)
                  modal.onClose?.()
                }}
              />
            </Toolbar>
          )}
          {modal.body}
        </Modal>
      ))}
    </AnimatePresence>
  )
}
