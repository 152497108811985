import { FC } from 'react'
import { FormattedDate } from 'react-intl'
import { Tooltip } from '../Tooltip/Tooltip.tsx'

const agoFormnatter = (ts: number): string => {
  let diff = (Date.now() - ts) / 1000

  if (diff < 60) {
    return 'Just now'
  }

  diff /= 60

  if (diff < 60) {
    return `${Math.round(diff)} min ago`
  }

  diff /= 60

  if (Math.round(diff) === 1) {
    return '1 hour ago'
  }

  if (diff < 6) {
    return `${Math.round(diff)} hours ago`
  }

  const today = new Date()
  today.setHours(0)
  today.setMinutes(0)
  const target = new Date(ts)
  target.setHours(0)
  target.setMinutes(0)

  const days = Math.round(
    (today.getTime() - target.getTime()) / (1000 * 60 * 60 * 24)
  )

  if (days === 0) {
    return 'Today'
  }

  if (days === 1) {
    return 'Yesterday'
  }

  if (days < 7) {
    return `${days} days ago`
  }

  if (days < 6 * 30) {
    return Intl.DateTimeFormat('en-GB', {
      month: 'short',
      day: 'numeric',
    }).format(target)
  }

  return Intl.DateTimeFormat('en-GB', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(target)
}

export const ShortDate: FC<{
  value: string | number
  noTime?: boolean
  ago?: boolean
}> = ({ value, noTime, ago }) => {
  if (!value) {
    return null
  }

  if (ago) {
    return (
      <Tooltip>
        <Tooltip.Trigger
          wrap
          component="span"
          children={agoFormnatter(
            typeof value === 'string' ? new Date(value).getTime() : value
          )}
        />
        <Tooltip.Content>
          <FormattedDate value={value} dateStyle="full" timeStyle="short" />
        </Tooltip.Content>
      </Tooltip>
    )
  }

  return (
    <Tooltip>
      <Tooltip.Trigger
        wrap
        component={FormattedDate}
        value={value}
        day="numeric"
        month="long"
        hour={noTime ? undefined : 'numeric'}
        minute={noTime ? undefined : 'numeric'}
      />
      <Tooltip.Content>
        <FormattedDate value={value} dateStyle="full" timeStyle="short" />
      </Tooltip.Content>
    </Tooltip>
  )
}
