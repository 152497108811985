import { FC, ReactNode, useId } from 'react'
import './Tabs.scss'
import { motion } from 'framer-motion'
import {
  NavLink,
  NavLinkProps,
  useLocation,
  useResolvedPath,
} from 'react-router-dom'
import cx from 'classnames'

type TabProps = { label: ReactNode; layoutId: string; counter?: number } & Omit<
  NavLinkProps,
  'onClick'
>

const Tab: FC<TabProps & { active?: boolean; onClick?: () => void }> = ({
  label,
  layoutId,
  active,
  onClick,
  counter,
  ...rest
}) => {
  const path = useResolvedPath(rest.to, { relative: rest.relative })
  const location = useLocation()

  const isActive =
    active ??
    (location.pathname === path.pathname ||
      (!rest.end &&
        location.pathname.startsWith(path.pathname) &&
        location.pathname.charAt(path.pathname.length) === '/'))

  if (onClick) {
    return (
      <button
        className={cx('tab', isActive && 'active')}
        onClick={() => onClick()}
        type="button"
      >
        {label}
        {counter ? <div className="tab-counter">{counter}</div> : null}
        {isActive && (
          <motion.div
            layoutId={layoutId}
            transition={{ duration: 0.2 }}
            className="tab-indicator"
          />
        )}
      </button>
    )
  }

  return (
    <NavLink
      className={({ isActive }) => cx('tab', isActive && 'active')}
      {...rest}
    >
      {label}
      {counter ? <div className="tab-counter">{counter}</div> : null}
      {isActive && (
        <motion.div
          layoutId={layoutId}
          transition={{ duration: 0.2 }}
          className="tab-indicator"
        />
      )}
    </NavLink>
  )
}

export const Tabs: FC<{
  tabs: Omit<TabProps, 'layoutId'>[]
  current?: string
  onTabChange?: (tab: string) => void
}> = ({ tabs, current, onTabChange }) => {
  const layoutId = useId()

  return (
    <nav className="tabs">
      {tabs.map(({ label, ...rest }, i) => {
        return (
          <Tab
            key={i}
            label={label}
            layoutId={layoutId}
            active={current ? current === rest.to : undefined}
            onClick={
              onTabChange ? () => onTabChange(rest.to as string) : undefined
            }
            {...rest}
          />
        )
      })}
    </nav>
  )
}
