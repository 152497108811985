import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { safeJwtDecode } from '../utils/jwt.ts'

export enum TokenStatus {
  INVALID,
  EXPIRED,
  OK,
}

export const useQueryParamToken = <T = { email: string }>() => {
  const [searchParams] = useSearchParams()
  const [, forceUpdate] = useState(0)
  const t = searchParams.get('t')

  const decoded = useMemo(() => safeJwtDecode<T & { exp: number }>(t), [t])

  let status = TokenStatus.INVALID

  if (decoded === null) {
    status = TokenStatus.INVALID
  } else if (decoded.exp <= Date.now() / 1000) {
    status = TokenStatus.EXPIRED
  } else {
    status = TokenStatus.OK
  }

  useEffect(() => {
    if (status === TokenStatus.OK && decoded) {
      const timer = setTimeout(
        () => forceUpdate((n) => n + 1),
        decoded.exp * 1000 - Date.now()
      )

      return () => clearTimeout(timer)
    }
  }, [status, decoded])

  return { decoded, status, token: t ?? null } as
    | {
        status: TokenStatus.INVALID
        decoded: null
        token: null
      }
    | {
        status: TokenStatus.EXPIRED | TokenStatus.OK
        decoded: T
        token: string
      }
}
