import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchUser, gqlClient, setCurrentOrg, useUser } from '../../../auth'
import { graphql } from '../../../gql'
import emptyInbox from '../../../assets/empty-state/empty-inbox.svg'
import './Notifications.scss'
import { NotificationsQuery } from '../../../gql/graphql.ts'
import { DeepRequired } from 'react-hook-form'
import { ShortDate } from '../../component/ShortDate/ShortDate.tsx'
import { Dropdown } from '../../component/DropdownMenu/DropdownMenu.tsx'
import { MoreActions } from '../../component/MoreActions/MoreActions.tsx'
import { IconEyeCheck, IconEyeX, IconTrash } from '@tabler/icons-react'
import { Button } from '../../component/Button/Button.tsx'
import React from 'react'
import { openModal } from '../../component/Modal/Modal.tsx'
import { InviteMembers } from '../../../pages/app/settings/Team/Team.tsx'
import { Link } from 'react-router-dom'

const Notification = ({
  notification,
  deleteNotification,
  refresh,
}: {
  notification: DeepRequired<NotificationsQuery>['me']['notifications'][number]
  deleteNotification: () => Promise<void>
  refresh: () => Promise<void>
}) => {
  if (notification.slug === 'REQUEST_ACCESS_TO_ORGANIZATION') {
    return (
      <div className="notification-content">
        <div className="notification-title">
          <b>{notification.data.email}</b> asked to join{' '}
          <b>{notification.organization.name}</b>
        </div>
        <div className="notification-meta">
          <ShortDate value={notification.createdAt} ago />
        </div>
        <div className="notification-actions">
          <Button
            color="success"
            onClick={async () => {
              setCurrentOrg(notification.organization.id, null)
              openModal({
                title: 'Invite members',
                content: (
                  <InviteMembers defaultEmails={[notification.data.email]} />
                ),
                onClose: refresh,
              })
            }}
          >
            Accept
          </Button>
          <Button variant="light" onClick={deleteNotification}>
            Deny
          </Button>
        </div>
      </div>
    )
  }

  if (
    [
      'NEW_CHANGE_REQUEST',
      'CHANGE_REQUEST_MERGED',
      'CHANGE_REQUEST_OPENED',
      'CHANGE_REQUEST_CLOSED',
      'CHANGE_REQUEST_APPROVED',
      'CHANGE_REQUEST_REJECTED',
    ].includes(notification.slug)
  ) {
    return (
      <Link
        className="notification-link"
        to={`/project/${notification.changeRequest.flag.project.slug}/feature-flags/${notification.changeRequest.flag.id}/reviews/${notification.changeRequest.id}`}
      >
        <div className="notification-title">
          <b>{notification.changeRequest.flag.name}</b>:{' '}
          {
            {
              NEW_CHANGE_REQUEST: (
                <>New review that requires your approval by</>
              ),
              CHANGE_REQUEST_MERGED: (
                <>
                  Your review was <b>merged</b> by
                </>
              ),
              CHANGE_REQUEST_OPENED: (
                <>
                  Your review was <b>re-opened</b> by
                </>
              ),
              CHANGE_REQUEST_CLOSED: (
                <>
                  Your review was <b>closed</b> by
                </>
              ),
              CHANGE_REQUEST_APPROVED: (
                <>
                  Your review was <b>approved</b> by
                </>
              ),
              CHANGE_REQUEST_REJECTED: (
                <>
                  Your review was <b>rejected</b> by
                </>
              ),
            }[notification.slug]
          }{' '}
          {notification.changeRequest?.createdBy.name ??
            notification.changeRequest?.createdBy.email}
        </div>
        <div className="notification-meta">
          <ShortDate value={notification.createdAt} ago />
        </div>
      </Link>
    )
  }

  if (notification.slug === 'CHANGE_REQUEST_APPROVAL_REMOVED') {
    return (
      <Link
        className="notification-link"
        to={`/project/${notification.changeRequest.flag.project.slug}/feature-flags/${notification.changeRequest.flag.id}/reviews/${notification.changeRequest.id}`}
      >
        <div className="notification-title">
          <b>{notification.changeRequest.flag.name}</b>:{' '}
          {notification.changeRequest?.createdBy.name ??
            notification.changeRequest?.createdBy.email}{' '}
          removed its approval from your review
        </div>
        <div className="notification-meta">
          <ShortDate value={notification.createdAt} ago />
        </div>
      </Link>
    )
  }

  return null
}

export const Notifications = () => {
  const user = useUser()

  const { data, refetch } = useQuery({
    queryKey: [user?.id, 'notifications'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query notifications {
            me {
              id
              unreadNotificationCount
              notifications {
                id
                slug
                read
                createdAt
                organization {
                  id
                  name
                }
                project {
                  id
                  name
                }
                contextProperty {
                  id
                  name
                }
                changeRequest {
                  id
                  createdBy {
                    id
                    name
                    email
                  }
                  flag {
                    id
                    name
                    project {
                      id
                      slug
                    }
                  }
                }
                flag {
                  id
                  name
                }
                data
              }
            }
          }
        `)
      ),
  })

  const { mutateAsync: markAsRead } = useMutation({
    mutationFn: (id: string) =>
      gqlClient.request(
        graphql(`
          mutation markNotificationAsRead($id: ID!) {
            notifications(ids: [$id]) {
              markAsRead {
                id
              }
            }
          }
        `),
        { id }
      ),
  })

  const { mutateAsync: markAsUnread } = useMutation({
    mutationFn: (id: string) =>
      gqlClient.request(
        graphql(`
          mutation markNotificationAsUnread($id: ID!) {
            notifications(ids: [$id]) {
              markAsUnread {
                id
              }
            }
          }
        `),
        { id }
      ),
  })

  const { mutateAsync: del } = useMutation({
    mutationFn: (id: string) =>
      gqlClient.request(
        graphql(`
          mutation deleteNotification($id: ID!) {
            notifications(ids: [$id]) {
              delete
            }
          }
        `),
        { id }
      ),
  })

  return (
    <div className="notifications-panel">
      <div className="tabs" style={{ marginBottom: 16 }}>
        <div className="tab">
          Inbox
          {Boolean(data?.me?.unreadNotificationCount) && (
            <div className="notifications-badge" style={{ marginLeft: 4 }}>
              {data?.me?.unreadNotificationCount}
            </div>
          )}
          <div className="tab-indicator" />
        </div>
      </div>
      {!data?.me?.notifications.length ? (
        <div className="no-notifications">
          <img src={emptyInbox} />
          <h4>All good!</h4>
          <p>You don't have any notifications.</p>
        </div>
      ) : (
        data.me.notifications.map((notification) => (
          <React.Fragment key={notification.id}>
            <div className="notification">
              <div className="notification-corner">
                <Dropdown component={MoreActions}>
                  {!notification.read && (
                    <Dropdown.Item
                      label="Mark as read"
                      icon={IconEyeCheck}
                      onClick={() =>
                        markAsRead(notification.id).then(fetchUser)
                      }
                    />
                  )}
                  {notification.read && (
                    <Dropdown.Item
                      label="Mark as un-read"
                      icon={IconEyeX}
                      onClick={() =>
                        markAsUnread(notification.id).then(fetchUser)
                      }
                    />
                  )}
                  <hr />
                  <Dropdown.Item
                    label="Delete"
                    icon={IconTrash}
                    danger
                    onClick={() => del(notification.id).then(fetchUser)}
                  />
                </Dropdown>
                {!notification.read && <div className="notifications-badge" />}
              </div>
              <Notification
                notification={notification as any}
                deleteNotification={() =>
                  del(notification.id)
                    .then(fetchUser)
                    .then(() => refetch()) as any
                }
                refresh={() => fetchUser().then(() => refetch()) as any}
              />
            </div>
            <hr />
          </React.Fragment>
        ))
      )}
    </div>
  )
}
