import { CSSProperties, FC, ReactNode } from 'react'
import './Card.scss'
import cx from 'classnames'
import { ErrorBoundary } from 'react-error-boundary'

export const Card: FC<{
  children: ReactNode
  color?: 'primary' | 'yellow'
  variant?: 'filled' | 'outlined' | 'outlined-thick'
  style?: CSSProperties
}> = ({ children, color, variant = 'filled', style }) => {
  return (
    <article className={cx('card', color, variant)} style={style}>
      <ErrorBoundary
        fallback={
          <>
            <h2>Something went wrong</h2>
            <p>
              Something unexpected happened, we are unable to display this
              information at the moment.
            </p>
          </>
        }
      >
        {children}
      </ErrorBoundary>
    </article>
  )
}
