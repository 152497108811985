import { Tabs } from '../../../components/component/Tabs/Tabs'
import { Navigate, Outlet, RouteObject } from 'react-router-dom'
import { billingRoute } from './Billing/Billing.tsx'
import { organizationRoute } from './Organization/Organization.tsx'
import { profileRoute } from './Profile/Profile.tsx'
import { teamRoute } from './Team/Team.tsx'
import { usageRoute } from './Usage/Usage.tsx'
import { BillingRole } from '../../../gql/graphql.ts'
import { useCurrentOrgSafe } from '../../../hooks/useCurrentOrgSafe.ts'
import { integrationsRoute } from './Integrations/Integrations.tsx'

const Settings = () => {
  const { organization } = useCurrentOrgSafe()

  return (
    <>
      <h1>Settings</h1>
      <Tabs
        tabs={[
          {
            label: 'Organization',
            to: 'organization',
          },
          {
            label: 'Team',
            to: 'team',
          },
          {
            label: 'Usage',
            to: 'usage',
          },
          ...(organization.myAggregatedRole.billing === BillingRole.Manage
            ? [
                {
                  label: 'Billing',
                  to: 'billing',
                },
              ]
            : []),
          {
            label: 'Profile',
            to: 'profile',
          },
          {
            label: 'Integrations',
            to: 'integrations',
          },
        ]}
      />

      <Outlet />
    </>
  )
}

export const settingsRoute: RouteObject = {
  path: '/settings',
  element: <Settings />,
  children: [
    {
      index: true,
      element: <Navigate to="/settings/profile" />,
    },
    billingRoute,
    organizationRoute,
    profileRoute,
    teamRoute,
    usageRoute,
    integrationsRoute,
  ],
}
