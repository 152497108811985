import { graphql } from '../gql'

export const verifyEmailRequestQuery = graphql(`
  mutation verifyEmailRequest($email: String!) {
    requestVerifyEmail(email: $email)
  }
`)

export const resetPasswordRequestQuery = graphql(`
  mutation resetPasswordRequest($email: String!) {
    requestResetPassword(email: $email)
  }
`)
