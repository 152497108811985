import { FC } from 'react'
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { diffLines } from 'diff'
import yaml from 'yaml'

export const Diff: FC<{
  before: any
  after: any
  stringify?: (value: any) => string
}> = ({ before, after, stringify = yaml.stringify }) => {
  return (
    <SyntaxHighlighter
      language="diff"
      style={dracula}
      children={diffLines(stringify(before), stringify(after))
        .map(({ value, added, removed }) =>
          value
            .replace(/\n$/, '')
            .split('\n')
            .map((v) => `${added ? '+ ' : removed ? '- ' : '  '}${v}`)
            .join('\n')
        )
        .join('\n')}
    />
  )
}
