import { CSSProperties, forwardRef } from 'react'
import { IconCheck, IconMinus } from '@tabler/icons-react'
import './Checkbox.scss'
import { motion } from 'framer-motion'

export type CheckboxProps = {
  value: boolean
  onChange: (value: boolean) => void
  indeterminate?: boolean
  style?: CSSProperties
}

const MotionIconCheck = motion(IconCheck)

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ value, onChange, indeterminate, style }, ref) => {
    return (
      <div className="checkbox-container" style={style}>
        <label className="checkbox">
          <input
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
            type="checkbox"
            ref={ref}
          />
          {!indeterminate && (
            <MotionIconCheck
              animate={{ scale: value ? 1 : 0 }}
              initial={{ scale: value ? 1 : 0 }}
              transition={{ duration: value ? 0.15 : 0 }}
              size={14}
              stroke={2.6}
            />
          )}
          {indeterminate && <IconMinus size={14} stroke={2.6} />}
        </label>
      </div>
    )
  }
)
