import {
  Control,
  FieldArrayPath,
  FieldValues,
  useController,
  useFieldArray,
  UseFieldArrayReturn,
  useWatch,
} from 'react-hook-form'
import { ContextPropertyType, Operator } from '../../../gql/graphql.ts'
import { IconGripVertical, IconPlus, IconTrash } from '@tabler/icons-react'
import { Button } from '../../component/Button/Button.tsx'
import { FC, ReactNode, useMemo, useRef, useState } from 'react'
import { IconButton } from '../../component/IconButton/IconButton.tsx'
import './RulesInput.scss'
import { InputWrapper } from '../InputWrapper/InputWrapper.tsx'
import {
  OperatorInput,
  options as operatorsOptions,
} from '../OperatorInput/OperatorInput.tsx'
import {
  ContextPropertyInput,
  useProjectContextProperties,
} from '../ContextPropertyInput/ContextPropertyInput.tsx'
import { ParamsInput } from '../ParamsInput/ParamsInput.tsx'
import { DragControls, Reorder, useDragControls } from 'framer-motion'
import { getMovedItem } from '../../../utils/reorder.ts'

export type Rule = {
  id: string | null
  operator: Operator | null
  params: Record<string, any>
  contextPropertyId: string | null
}

export type ConditionsInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>
> = {
  control: Control<TFieldValues>
  name: TFieldArrayName
}

const OperatorInputWrapper: FC<{
  control: Control<any>
  name: string
  contextProperties: ContextPropertiesRecord
}> = ({ control, name, contextProperties }) => {
  const contextPropertyId = useWatch({
    control,
    name: `${name}.contextPropertyId`,
  })

  if (!contextPropertyId) {
    return null
  }

  return (
    <InputWrapper
      control={control}
      name={`${name}.operator`}
      component={OperatorInput}
      typeFilter={contextProperties[contextPropertyId]?.type}
    />
  )
}

const ParamsInputWrapper: FC<{
  control: Control<any>
  name: string
  contextProperties: ContextPropertiesRecord
}> = ({ control, name, contextProperties }) => {
  const operator = useWatch({
    control,
    name: `${name}.operator`,
  })
  const contextPropertyId = useWatch({
    control,
    name: `${name}.contextPropertyId`,
  })

  return (
    <ParamsInput
      control={control}
      name={`${name}.params`}
      contextPropertyId={contextPropertyId}
      operator={operator}
      meta={contextProperties[contextPropertyId]?.meta}
    />
  )
}

const defaultOperatorForType: Record<ContextPropertyType, Operator> = {
  [ContextPropertyType.Boolean]: Operator.IsTrue,
  [ContextPropertyType.Date]: Operator.DateAfter,
  [ContextPropertyType.Number]: Operator.NumEquals,
  [ContextPropertyType.Select]: Operator.SelectEquals,
  [ContextPropertyType.String]: Operator.StrEquals,
  [ContextPropertyType.StringArray]: Operator.ArrOverlap,
  [ContextPropertyType.Version]: Operator.SemverGte,
}

const ContextPropertyInputWrapper: FC<{
  control: Control<any>
  name: string
  contextProperties: ContextPropertiesRecord
}> = ({ control, name, contextProperties }) => {
  const operator = useWatch({
    control,
    name: `${name}.operator`,
  })

  const {
    field: { onChange },
  } = useController({
    control,
    name: `${name}.operator`,
  })

  return (
    <InputWrapper
      control={control}
      name={`${name}.contextPropertyId`}
      component={ContextPropertyInput as any}
      rules={{ required: true }}
      onChangeSpy={(id) => {
        if (
          defaultOperatorForType[contextProperties[id]?.type] &&
          !operatorsOptions.some(
            ({ type, options }) =>
              type === contextProperties[id]?.type &&
              options.some(({ value }) => value === operator)
          )
        ) {
          setTimeout(
            () => onChange(defaultOperatorForType[contextProperties[id]?.type]),
            0
          )
        }
      }}
    />
  )
}

type ContextPropertiesRecord = Record<
  string,
  { type: ContextPropertyType; meta: any }
>

const Item: FC<{
  render: (controls: DragControls) => ReactNode
}> = ({ render }) => {
  const controls = useDragControls()

  return render(controls)
}

const transition = {
  type: 'spring',
  stiffness: 700,
  damping: 30,
  mass: 0.8,
}

export const RulesInput = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>
>({
  control,
  name,
}: ConditionsInputProps<TFieldValues, TFieldArrayName>) => {
  const { fields, append, remove, move, update } = useFieldArray({
    control,
    name,
  }) as unknown as UseFieldArrayReturn<{ rules: Rule[] }, 'rules'>

  const { data } = useProjectContextProperties()
  const contextProperties = useMemo(() => {
    return (
      data?.project?.contextProperties.reduce((acc, curr) => {
        acc[curr.id] = { type: curr.type, meta: curr.meta }
        return acc
      }, {} as ContextPropertiesRecord) ?? {}
    )
  }, [data])
  const [deletingRuleId, setDeletingRuleId] = useState<string[]>([])
  const lastRuleAddedAtRef = useRef(0)

  return (
    <Reorder.Group
      axis="y"
      values={fields}
      className="rules-container"
      onReorder={(newValue) => {
        const moved = getMovedItem(fields as any, newValue as any)

        if (moved) {
          move(moved.from, moved.to)
        }
      }}
      as="div"
    >
      {fields.map((rule, index) => (
        <Item
          key={rule.id}
          render={(controls) => {
            return (
              <Reorder.Item
                value={rule}
                layout="position"
                transition={{
                  ...transition,
                  height: { ease: 'linear', duration: 0.1 },
                  opacity: { ease: 'linear', duration: 0.1 },
                }}
                className="rule"
                dragListener={false}
                dragControls={controls}
                initial={
                  Date.now() - lastRuleAddedAtRef.current < 100
                    ? { height: 0, opacity: 0 }
                    : { height: 'auto', opacity: 1 }
                }
                animate={{
                  height: deletingRuleId.includes(rule.id) ? 0 : 'auto',
                  opacity: deletingRuleId.includes(rule.id) ? 0 : 1,
                }}
                onAnimationComplete={() => {
                  if (deletingRuleId.includes(rule.id)) {
                    remove(index)
                    setDeletingRuleId((prev) =>
                      prev.filter((id) => id !== rule.id)
                    )
                  }
                }}
                as="div"
              >
                <div>
                  <IconGripVertical
                    size={16}
                    className="handler"
                    onPointerDown={(e) => {
                      controls.start(e)
                      e.preventDefault()
                    }}
                  />
                  <div className="rule-container">
                    <ContextPropertyInputWrapper
                      control={control}
                      name={`${name}.${index}`}
                      contextProperties={contextProperties}
                    />
                    <OperatorInputWrapper
                      control={control}
                      name={`${name}.${index}`}
                      contextProperties={contextProperties}
                    />
                    <ParamsInputWrapper
                      control={control}
                      name={`${name}.${index}`}
                      contextProperties={contextProperties}
                    />
                  </div>
                  {fields.length > 1 && (
                    <IconButton
                      icon={IconTrash}
                      onClick={() => {
                        setDeletingRuleId((prev) => [...prev, rule.id])
                      }}
                    />
                  )}
                  {fields.length <= 1 && rule.contextPropertyId !== null && (
                    <IconButton
                      icon={IconTrash}
                      onClick={() => {
                        update(index, {
                          id: null,
                          contextPropertyId: null,
                          operator: null,
                          params: {},
                        })
                      }}
                    />
                  )}
                </div>
              </Reorder.Item>
            )
          }}
        />
      ))}
      <Button
        inline
        icon={IconPlus}
        onClick={() => {
          lastRuleAddedAtRef.current = Date.now()
          append({
            id: null,
            contextPropertyId: null,
            operator: null,
            params: {},
          })
        }}
      >
        And
      </Button>
    </Reorder.Group>
  )
}
