import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  ArchiveFlagMutationVariables,
  DeleteeFlagMutationVariables,
} from '../gql/graphql.ts'
import { gqlClient } from '../auth'
import { graphql } from '../gql'
import { undoableLoadingToast } from '../utils/toast.tsx'
import { confirmDelete } from '../components/component/Modal/Modal.tsx'

const useArchiveFlagMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (variables: ArchiveFlagMutationVariables) =>
      gqlClient.request(
        graphql(`
          mutation archiveFlag($id: ID!, $archive: Boolean!) {
            flag(id: $id) {
              toggleArchive(archive: $archive) {
                id
                archived
              }
            }
          }
        `),
        variables
      ),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['flags'] })
      queryClient.invalidateQueries({ queryKey: ['flag', variables.id] })
    },
  })
}

export const useArchiveFlag = () => {
  const archiveFlag = useArchiveFlagMutation()

  return (id: string) =>
    undoableLoadingToast({
      id,
      do: {
        promise: () =>
          archiveFlag.mutateAsync({
            id,
            archive: true,
          }),
        loading: 'Archiving flag...',
        success: 'Flag archived',
        error: 'Failed to archive flag',
      },
      undo: {
        promise: () =>
          archiveFlag.mutateAsync({
            id,
            archive: false,
          }),
        loading: 'Un-archiving flag...',
        success: 'Flag un-archived',
        error: 'Failed to un-archive flag',
      },
    })
}

export const useDeleteFlag = () => {
  const queryClient = useQueryClient()

  const deleteFlag = useMutation({
    mutationFn: (variables: DeleteeFlagMutationVariables) =>
      gqlClient.request(
        graphql(`
          mutation deleteeFlag($id: ID!) {
            flag(id: $id) {
              delete
            }
          }
        `),
        variables
      ),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['flags'] })
      queryClient.invalidateQueries({ queryKey: ['flag', variables.id] })
    },
  })

  return (id: string) =>
    confirmDelete({
      unit: 'flags',
      onDelete: () => deleteFlag.mutateAsync({ id }),
      description: (
        <>
          <p style={{ textAlign: 'center' }}>
            You are about to permanently delete this flag, you will loose all of
            its configuration and history forever.
          </p>
          <p style={{ textAlign: 'center' }}>This action cannot be undone.</p>
        </>
      ),
    })
}

export const useUnArchiveFlag = () => {
  const archiveFlag = useArchiveFlagMutation()

  return (id: string) =>
    undoableLoadingToast({
      id,
      do: {
        promise: () =>
          archiveFlag.mutateAsync({
            id,
            archive: false,
          }),
        loading: 'Un-archiving flag...',
        success: 'Flag un-archived',
        error: 'Failed to un-archive flag',
      },
      undo: {
        promise: () =>
          archiveFlag.mutateAsync({
            id,
            archive: true,
          }),
        loading: 'Archiving flag...',
        success: 'Flag archived',
        error: 'Failed to archive flag',
      },
    })
}
