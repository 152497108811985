import { GraphQLClient } from 'graphql-request'
import { toast } from 'react-hot-toast'
import { Singleton } from './Singleton'
import { MeQuery } from '../gql/graphql'
import { graphql } from '../gql'
import LogRocket from 'logrocket'

setTimeout(() => {
  if (import.meta.env.PROD) {
    LogRocket.init('tggl/tggl-app')
  }
}, 500)

let navigate = (to: string) => Promise.resolve()

export const registerNavigate = (navigateFn: (to: string) => Promise<void>) => {
  navigate = navigateFn
}

const jwt = new Singleton(localStorage.getItem('token'))
const user = new Singleton<Exclude<MeQuery['me'], undefined>>(null)
const currentOrg = new Singleton<{
  organization:
    | Exclude<MeQuery['me'], undefined | null>['organizations'][0]
    | null
  project:
    | Exclude<
        MeQuery['me'],
        undefined | null
      >['organizations'][0]['projects'][0]
    | null
}>({
  organization: null,
  project: null,
})

export const setCurrentOrg = (
  organizationId: string | null,
  projectIdOrSlug: string | null
) => {
  const u = user.value()

  if (u) {
    const organization =
      u.organizations.find(
        (organization) => organization.id === organizationId
      ) ??
      u.organizations[0] ??
      null
    const project =
      organization?.projects.find(
        (project) =>
          project.id === projectIdOrSlug || project.slug === projectIdOrSlug
      ) ??
      organization?.projects[0] ??
      null
    if (organization?.id) {
      localStorage.setItem('organizationId', organization.id)
    }
    if (project?.id) {
      localStorage.setItem('projectId', project.id)
    }

    const projectSlugFromUrl = window.location.pathname.match(
      /^\/project\/([^/]+)\//
    )?.[1]

    if (projectSlugFromUrl && project && projectSlugFromUrl !== project?.slug) {
      navigate(
        window.location.pathname.replace(
          /^\/project\/([^/]+)\//,
          `/project/${project.slug}/`
        )
      )
    }

    currentOrg.setValue({
      organization,
      project,
    })
  } else {
    currentOrg.setValue({
      organization: null,
      project: null,
    })
  }
}

export const useCurrentOrg = currentOrg.hook()

user.onChange(() => {
  const projectSlugFromUrl = window.location.pathname.match(
    /^\/project\/([^/]+)\//
  )?.[1]

  setCurrentOrg(
    localStorage.getItem('organizationId'),
    projectSlugFromUrl ?? localStorage.getItem('projectId')
  )
})

user.onChange(() => {
  const u = user.value()

  // @ts-ignore
  window.pendo?.initialize({
    visitor: {
      id: u?.id,
      email: u?.email,
      full_name: u?.name,
    },

    account: {
      id: u?.id,
      // name:         // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
    },
  })

  if (u && import.meta.env.PROD) {
    LogRocket.identify(u.id, {
      name: u.name ?? 'Unknown',
      email: u.email,
    })

    try {
      // @ts-ignore
      const enc = new TextEncoder('utf-8')
      window.crypto.subtle
        .importKey(
          'raw',
          enc.encode('683a011e9f4635a064dbbabdf160268104afdeea'),
          {
            name: 'HMAC',
            hash: { name: 'SHA-256' },
          },
          false,
          ['sign', 'verify']
        )
        .then((key) => {
          window.crypto.subtle
            .sign('HMAC', key, enc.encode(u.id))
            .then((signature) => {
              const b = new Uint8Array(signature)
              const hash = Array.prototype.map
                .call(b, (x) => x.toString(16).padStart(2, '0'))
                .join('')
              // @ts-ignore
              window.Tawk_API?.login?.({
                hash: hash,
                userId: u.id,
                name: u.name ?? 'Unknown',
                email: u.email,
              })
            })
        })
        .catch(() => null)
    } catch (error) {
      // Do nothing
    }
  }
})

export const gqlClient = new GraphQLClient(
  import.meta.env.PROD
    ? 'https://api.tggl.io/graphql'
    : 'http://localhost:3008/graphql',
  {
    responseMiddleware: (response) => {
      if (
        response instanceof TypeError &&
        response.message === 'Network request failed'
      ) {
        toast.error('Network error, check your connection')
      }
    },
    headers: {
      Authorization: jwt.value() ? `Bearer ${jwt.value()}` : '',
    },
  }
)

export const setToken = (token: string | null) => {
  if (token === null) {
    localStorage.removeItem('token')
  } else {
    localStorage.setItem('token', token)
  }

  gqlClient.setHeader('Authorization', token ? `Bearer ${token}` : '')

  jwt.setValue(token)
}

export const fetchUser = () => {
  return gqlClient
    .request(
      graphql(`
        query me {
          me {
            id
            name
            firstName
            lastName
            email
            hasTotp
            unreadNotificationCount
            role
            joinableOrganization {
              name
              domain
            }
            organizations {
              id
              name
              domain
              allowSelfSignup
              canMakeReviews
              canEnforceReview
              monitoringDaysLimit
              auditLogDaysLimit
              projects {
                id
                name
                slug
                description
                tags {
                  id
                  name
                  color
                }
                environmentContextProperty {
                  contextProperty {
                    id
                  }
                  value
                }
                changeRequestMinApprovals
                changeRequestRoleApprovals {
                  id
                }
                requireChangeRequest
              }
              roles {
                id
                name
                default
              }
              myAggregatedRole {
                webhooks
                projects
                apiKeys
                billing
                context
                team
                viewFlags
                manageFlags
                reviews
                technicalExpertise
              }
              createdAt
              trialEnd
              trialGracePeriodEndsAt
              hideTrial
              status
            }
          }
        }
      `)
    )
    .then((data) => user.setValue(data.me ?? null))
    .catch(() => setToken(null))
}

fetchUser()

jwt.onChange(fetchUser)

document.addEventListener('visibilitychange', () => {
  if (!document.hidden) {
    fetchUser()
  }
})

export const useToken = jwt.hook()
export const useUser = user.hook()
