import { forwardRef } from 'react'
import { ContextPropertyType, Operator } from '../../../gql/graphql.ts'
import { SelectInput } from '../SelectInput/SelectInput.tsx'

export const options = [
  {
    label: 'Text',
    type: ContextPropertyType.String,
    options: [
      {
        label: 'Is empty',
        value: Operator.IsEmpty,
        tooltip:
          'Property is missing form the context, is null, or is an empty string',
      },
      {
        label: 'Not empty',
        value: Operator.IsNotEmpty,
        tooltip:
          'Property is present in the context and is neither null nor an empty string',
      },
      {
        label: 'Is one of',
        value: Operator.StrEquals,
        tooltip: 'Property matches one of the specified values',
      },
      {
        label: 'Not one of',
        value: Operator.StrNotEquals,
        tooltip: 'Property does not match any of the specified values',
      },
      {
        label: 'Starts with',
        value: Operator.StrStartsWith,
        tooltip: 'Property starts with at least one of the specified values',
      },
      {
        label: 'Does not start with',
        value: Operator.StrNotStartsWith,
        tooltip: 'Property does not start with any of the specified values',
      },
      {
        label: 'Ends with',
        value: Operator.StrEndsWith,
        tooltip: 'Property ends with at least one of the specified values',
      },
      {
        label: 'Does not end with',
        value: Operator.StrNotEndsWith,
        tooltip: 'Property does not end with any of the specified values',
      },
      {
        label: 'Contains one of',
        value: Operator.StrContains,
        tooltip: 'Property contains at least one of the specified values',
      },
      {
        label: 'Does not contain',
        value: Operator.StrNotContains,
        tooltip: 'Property does not contain any of the specified values',
      },
      {
        label: 'Matches regexp',
        value: Operator.StrRegexp,
        tooltip: 'Property matches the specified regular expression pattern',
      },
      {
        label: 'Does not match regexp',
        value: Operator.StrNotRegexp,
        tooltip:
          'Property does not match the specified regular expression pattern',
      },
      {
        label: 'Random sample',
        value: Operator.Percentage,
        tooltip: 'Randomly select X% of users',
      },
    ],
  },
  {
    label: 'Number',
    type: ContextPropertyType.Number,
    options: [
      {
        label: 'Is empty',
        value: Operator.IsEmpty,
        tooltip:
          'Property is missing form the context, has a null value, or is an empty string',
      },
      {
        label: 'Not empty',
        value: Operator.IsNotEmpty,
        tooltip:
          'Property is present in the context and is neither null nor an empty string',
      },
      {
        label: '=',
        value: Operator.NumEquals,
        tooltip: 'Property is equal to the specified value',
      },
      {
        label: '≠',
        value: Operator.NumNotEquals,
        tooltip: 'Property is not equal to the specified value',
      },
      {
        label: '>',
        value: Operator.NumGt,
        tooltip: 'Property is greater than the specified value',
      },
      {
        label: '<',
        value: Operator.NumLt,
        tooltip: 'Property is less than the specified values',
      },
      {
        label: '≥',
        value: Operator.NumGte,
        tooltip: 'Property is greater than or equal to the specified value',
      },
      {
        label: '≤',
        value: Operator.NumLte,
        tooltip: 'Property is less than or equal to the specified value',
      },
      {
        label: 'Random sample',
        value: Operator.Percentage,
        tooltip: 'Randomly select X% of users',
      },
    ],
  },
  {
    label: 'Date',
    type: ContextPropertyType.Date,
    options: [
      {
        label: 'Is empty',
        value: Operator.IsEmpty,
        tooltip:
          'Property is missing form the context, has a null value, or is an empty string',
      },
      {
        label: 'Not empty',
        value: Operator.IsNotEmpty,
        tooltip:
          'Property is present in the context and is neither null nor an empty string',
      },
      { label: 'Is date', value: Operator.DateIs },
      { label: 'Is after or equal', value: Operator.DateAfter },
      { label: 'Is before or equal', value: Operator.DateBefore },
    ],
  },
  {
    label: 'Boolean',
    type: ContextPropertyType.Boolean,
    options: [
      {
        label: 'Is empty',
        value: Operator.IsEmpty,
        tooltip:
          'Property is missing form the context, has a null value, or is an empty string',
      },
      {
        label: 'Not empty',
        value: Operator.IsNotEmpty,
        tooltip:
          'Property is present in the context and is neither null nor an empty string',
      },
      { label: 'Is true', value: Operator.IsTrue },
      { label: 'Is false', value: Operator.IsFalse },
    ],
  },
  {
    label: 'Select',
    type: ContextPropertyType.Select,
    options: [
      {
        label: 'Is empty',
        value: Operator.IsEmpty,
        tooltip:
          'Property is missing form the context, has a null value, or is an empty string',
      },
      {
        label: 'Not empty',
        value: Operator.IsNotEmpty,
        tooltip:
          'Property is present in the context and is neither null nor an empty string',
      },
      { label: 'Is one of', value: Operator.SelectEquals },
      { label: 'Not one of', value: Operator.SelectNotEquals },
    ],
  },
  {
    label: 'Array',
    type: ContextPropertyType.StringArray,
    options: [
      {
        label: 'Is empty',
        value: Operator.IsEmpty,
        tooltip:
          'Property is missing form the context, has a null value, or is an empty string',
      },
      {
        label: 'Not empty',
        value: Operator.IsNotEmpty,
        tooltip:
          'Property is present in the context and is neither null nor an empty string',
      },
      { label: 'Contains one of', value: Operator.ArrOverlap },
      { label: 'Does not contain', value: Operator.ArrNotOverlap },
    ],
  },
  {
    label: 'Version',
    type: ContextPropertyType.Version,
    options: [
      {
        label: 'Is empty',
        value: Operator.IsEmpty,
        tooltip:
          'Property is missing form the context, has a null value, or is an empty string',
      },
      {
        label: 'Not empty',
        value: Operator.IsNotEmpty,
        tooltip:
          'Property is present in the context and is neither null nor an empty string',
      },
      { label: 'Exact version', value: Operator.SemverIs },
      { label: 'Min version', value: Operator.SemverGte },
      { label: 'Max version', value: Operator.SemverLte },
    ],
  },
]

export const OperatorInput = forwardRef<
  unknown,
  {
    value: Operator | null
    onChange: (value: Operator | null) => void
    typeFilter?: ContextPropertyType
  }
>(({ value, onChange, typeFilter }, ref) => {
  return (
    <SelectInput
      value={value}
      ref={ref}
      onChange={onChange}
      placeholder="Operator"
      options={
        typeFilter ? options.filter(({ type }) => type === typeFilter) : options
      }
      allOptions={options}
    />
  )
})
