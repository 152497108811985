import { Navigate, Outlet, RouteObject } from 'react-router-dom'
import { organizationRoute } from './Organization.tsx'
import { AuthenticatedOnly } from '../../../components/layout/Auth/AuthenticatedOnly.tsx'
import { CenteredVerticalLayout } from '../../../components/layout/CenteredVerticalLayout/CenteredVerticalLayout.tsx'
import { profileRoute } from './Profile.tsx'

const Page = () => {
  return (
    <CenteredVerticalLayout>
      <Outlet />
    </CenteredVerticalLayout>
  )
}

export const onboardingRoute: RouteObject = {
  path: 'onboarding',
  element: (
    <AuthenticatedOnly>
      <Page />
    </AuthenticatedOnly>
  ),
  children: [
    organizationRoute,
    profileRoute,
    {
      index: true,
      element: <Navigate to="./organization" />,
    },
  ],
}
