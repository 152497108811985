import {
  Link,
  Navigate,
  Outlet,
  RouteObject,
  useParams,
} from 'react-router-dom'
import { IconArrowLeft } from '@tabler/icons-react'
import { Tabs } from '../../../../components/component/Tabs/Tabs.tsx'
import { deliveriesRoute } from './Deliveries.tsx'
import { settingsRoute } from './Settings.tsx'
import { useQuery } from '@tanstack/react-query'
import { gqlClient } from '../../../../auth'
import { graphql } from '../../../../gql'
import fantom from '../../../../assets/empty-state/nothing-here.svg'

import { EmptyStateImage } from '../../../../components/layout/EmptyStateImage/EmptyStateImage.tsx'
import { WebhooksRole } from '../../../../gql/graphql.ts'
import { useCurrentOrgSafe } from '../../../../hooks/useCurrentOrgSafe.ts'

const Page = () => {
  const { webhookId } = useParams()
  const { organization } = useCurrentOrgSafe()

  const { data, isFetching } = useQuery({
    queryKey: ['webhook', webhookId, 'exists'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query WebhookExists($id: ID!) {
            webhook(id: $id) {
              id
            }
          }
        `),
        { id: webhookId as string }
      ),
  })

  return (
    <>
      <h1>
        <Link to={`/webhooks`}>
          <IconArrowLeft size={24} stroke={2.5} /> Webhooks
        </Link>
        <hr />#{webhookId}
      </h1>
      {!isFetching && !data?.webhook?.id ? (
        <EmptyStateImage
          imageSrc={fantom}
          title="This webhook does not exist"
          text="Maybe it was deleted?"
          cta="See all"
          ctaIcon={IconArrowLeft}
          ctaTo="/webhooks"
        />
      ) : (
        <>
          {organization.myAggregatedRole.webhooks === WebhooksRole.Manage && (
            <Tabs
              tabs={[
                { label: 'Recent deliveries', to: './deliveries' },
                { label: 'Settings', to: './settings' },
              ]}
            />
          )}
          <Outlet />
        </>
      )}
    </>
  )
}

export const webhookRoute: RouteObject = {
  path: ':webhookId',
  element: <Page />,
  children: [
    deliveriesRoute,
    settingsRoute,
    {
      path: '',
      element: <Navigate to="deliveries" />,
    },
  ],
}
