import { useCurrentOrg } from '../auth'

export const useCurrentOrgSafe = () => {
  const current = useCurrentOrg()

  if (!current.organization || !current.project) {
    throw new Error('No current organization or project')
  }

  return current as {
    organization: Exclude<(typeof current)['organization'], null>
    project: Exclude<(typeof current)['project'], null>
  }
}
