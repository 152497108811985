import { RouteObject } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useCurrentOrgSafe } from '../../../hooks/useCurrentOrgSafe.ts'
import { gqlClient, setCurrentOrg } from '../../../auth'
import { graphql } from '../../../gql'
import { Code } from '../../../components/component/Code/Code.tsx'
import { Sections } from '../../../components/component/Sections/Sections.tsx'
import { Badge } from '../../../components/component/Badge/Badge.tsx'
import { IconBook, IconCheck, IconChevronDown } from '@tabler/icons-react'
import { Dropdown } from '../../../components/component/DropdownMenu/DropdownMenu.tsx'
import { forwardRef, useState } from 'react'
import { Tabs } from '../../../components/component/Tabs/Tabs.tsx'
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import { coldarkDark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { Button } from '../../../components/component/Button/Button.tsx'

const reactCode = (apiKey: string) => `
import { TgglClient, TgglProvider, useFlag } from 'react-tggl-client'

const client = new TgglClient('${apiKey}')

const App = () => {
  return (
    <TgglProvider client={client}>
      {/*...*/}
    </TgglProvider>
  )
}

const MyComponent = () => {
  const { active, value } = useFlag('myFlag')
  
  //...
}
`

const nodeCode = (apiKey: string) => `
import { TgglClient } from 'tggl-client'

const client = new TgglClient('${apiKey}')

await client.setContext({
  userId: 'foo',
  email: 'foo@gmail.com',
  country: 'FR',
  // ...
})

if (client.isActive('my-feature')) {
  // ...
}
`

const curlCode = (apiKey: string) => `
curl 'https://api.tggl.io/flags' \\
  -H 'Content-Type: application/json' \\
  -H 'x-tggl-api-key: ${apiKey}' \\
  --data-raw '{"userId":"foo","email":"foo@gmail.com"}'
`

const goCode = (apiKey: string) => `
type Payload struct {
  UserID string \`json:"userId"\`
  Email  string \`json:"email"\`
}

data := Payload{
// fill struct
}
payloadBytes, err := json.Marshal(data)
if err != nil {
  // handle err
}
body := bytes.NewReader(payloadBytes)

req, err := http.NewRequest("POST", "https://api.tggl.io/flags", body)
if err != nil {
  // handle err
}
req.Header.Set("Content-Type", "application/json")
req.Header.Set("X-Tggl-Api-Key", "${apiKey}")

resp, err := http.DefaultClient.Do(req)
if err != nil {
  // handle err
}
defer resp.Body.Close()
`

const pythonCode = (apiKey: string) => `
from tggl import TgglClient

client = TgglClient('${apiKey}')

flags = client.eval_context({
    'user_id': 'foo',
    'email': 'foo@gmail.com',
    'country': 'FR'
})

if (flags.is_active('my_feature')):
    print('Feature 1 is active')
`

const rustCode = (apiKey: string) => `
extern crate reqwest;
use reqwest::header;

fn main() -> Result<(), Box<dyn std::error::Error>> {
    let mut headers = header::HeaderMap::new();
    headers.insert("Content-Type", "application/json".parse().unwrap());
    headers.insert("x-tggl-api-key", "${apiKey}".parse().unwrap());

    let res = reqwest::Client::new()
        .post("https://api.tggl.io/flags")
        .headers(headers)
        .body("{\\"userId\\":\\"foo\\",\\"email\\":\\"foo@gmail.com\\"}")
        .send()?
        .text()?;
    println!("{}", res);

    Ok(())
}
`

const phpCode = (apiKey: string) => `
use Tggl\\Client\\TgglClient;

$client = new TgglClient('${apiKey}');

$flags = $client->evalContext(new Context());

if ($flags->isActive('my-feature')) {
  // ...
}
`

const codes = [
  {
    name: 'Node.js',
    code: nodeCode,
    language: 'typescript',
    link: 'https://tggl.io/developers/sdks/node',
  },
  {
    name: 'React',
    code: reactCode,
    language: 'jsx',
    link: 'https://tggl.io/developers/sdks/react',
  },
  {
    name: 'PHP',
    code: phpCode,
    language: 'php',
    link: 'https://tggl.io/developers/sdks/php',
  },
  {
    name: 'Python',
    code: pythonCode,
    language: 'python',
    link: 'https://tggl.io/developers/sdks/python',
  },
  {
    name: 'cURL',
    code: curlCode,
    language: 'bash',
    link: 'https://tggl.io/developers/api-reference',
  },
  {
    name: 'Go',
    code: goCode,
    language: 'go',
    link: null,
  },
  {
    name: 'Rust',
    code: rustCode,
    language: 'rust',
    link: null,
  },
]

const ProjectSelector = forwardRef<HTMLSpanElement>((props, ref) => {
  const { project } = useCurrentOrgSafe()

  return (
    <Badge ref={ref} color="primary" {...props}>
      Project: {project.name} <IconChevronDown size={16} />
    </Badge>
  )
})

const Page = () => {
  const { organization, project } = useCurrentOrgSafe()

  const { data } = useQuery({
    queryKey: ['project', project.id, 'api keys'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query projectApiKeys($id: ID!) {
            project(id: $id) {
              id
              clientApiKey {
                id
                key
              }
              serverApiKey {
                id
                key
              }
            }
          }
        `),
        {
          id: project.id,
        }
      ),
  })

  const [currentTab, setCurrentTab] = useState(codes[0])

  return (
    <>
      <h1 style={{ marginBottom: 50 }}>
        API Keys
        <Dropdown component={ProjectSelector}>
          {organization.projects.map((p) => (
            <Dropdown.Item
              key={p.id}
              label={p.name}
              selected={p.id === project.id}
              icon={IconCheck}
              onClick={() => setCurrentOrg(organization.id, p.id)}
            />
          ))}
        </Dropdown>
      </h1>
      <Sections>
        <Sections.Section title="Client key">
          <Code value={data?.project?.clientApiKey.key ?? ''} large />
          <p>
            Used by the client to evaluate flags via the API only. Use this key
            on all client-side apps to avoid exposing your segmentation rules.{' '}
            <a
              href="https://tggl.io/developers/api-reference#client-vs-server-key"
              target="_blank"
            >
              Learn more
            </a>
          </p>
        </Sections.Section>
        <hr />
        <Sections.Section title="Server key">
          <Code value={data?.project?.serverApiKey.key ?? ''} large secret />
          <p>
            Used by server-side apps to evaluate flags via the API or locally.
            Do not publish this key to your clients or you risk exposing
            sensitive information.{' '}
            <a
              href="https://tggl.io/developers/api-reference#client-vs-server-key"
              target="_blank"
            >
              Learn more
            </a>
          </p>
        </Sections.Section>
        <hr />
        <Sections.Section title="SDK">
          <Tabs
            tabs={codes.map(({ name }) => ({ label: name, to: name }))}
            current={currentTab.name}
            onTabChange={(name) =>
              setCurrentTab(codes.find((c) => c.name === name) ?? codes[0])
            }
          />
          <SyntaxHighlighter
            language={currentTab.language}
            style={coldarkDark}
            children={currentTab
              .code(data?.project?.clientApiKey.key ?? '<API_KEY>')
              .trim()}
          />
          {currentTab.link && (
            <Button
              to={currentTab.link}
              inline
              color="primary"
              icon={IconBook}
              target="_blank"
            >
              {currentTab.name} doc
            </Button>
          )}
        </Sections.Section>
      </Sections>
    </>
  )
}

export const apiKeysRoute: RouteObject = {
  path: 'api-keys',
  element: <Page />,
}
