import {
  Bar,
  BarChart,
  BarProps,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { FC, useMemo } from 'react'
import { MonitoringTooltip } from './MonitoringTooltip.tsx'
import { addDays, startOfDay } from 'date-fns'

const timeFormatter = Intl.DateTimeFormat('en-GB', {
  hour: '2-digit',
  minute: '2-digit',
})

const dateFormatter = Intl.DateTimeFormat('en-GB', {
  day: 'numeric',
  month: 'short',
})

const monthFormatter = Intl.DateTimeFormat('en-GB', {
  month: 'short',
})

const weekdayFormatter = Intl.DateTimeFormat('en-GB', {
  weekday: 'long',
})

const numberFormatter = Intl.NumberFormat('en-GB')

const RoundedBar = ({
  fill,
  x,
  y,
  width,
  height,
  opacity,
  series,
  payload = {},
  ...rest
}: Partial<BarProps> & {
  series: { key: string }[]
  payload?: any
}) => {
  if (!height || !width) {
    return null
  }

  const index = series.findIndex((s) => s.key === rest.dataKey)

  const radius = Math.min(
    series.slice(index + 1).some(({ key }) => payload[key])
      ? 0
      : Number(width) < 5
      ? 1
      : Number(width) < 10
      ? 2
      : 3,
    width / 2,
    height
  )
  const pathData = `
    M${Number(x) + radius},${y} 
    h${Number(width) - 2 * radius} 
    a${radius},${radius} 0 0 1 ${radius},${radius} 
    v${Number(height) - radius} 
    h-${width} 
    v-${Number(height) - radius} 
    a${radius},${radius} 0 0 1 ${radius},-${radius} 
    Z
  `

  return <path d={pathData} fill={fill} opacity={opacity} />
}

export const getDataConfig = (data: { date: number; next: number }[]) => {
  const domainSizeHours = data.length
    ? (data[data.length - 1].next - data[0].date) / 60 / 60
    : 0

  const config: {
    maxSizeHours: number
    roundToNextMinutes?: number
    startOf?: 'day'
    moduloInMonth?: number
    formatter: Intl.DateTimeFormat
  }[] = [
    {
      maxSizeHours: 6,
      roundToNextMinutes: 10,
      formatter: timeFormatter,
    },
    {
      maxSizeHours: 48,
      roundToNextMinutes: 60 * 2,
      formatter: timeFormatter,
    },
    {
      maxSizeHours: 24 * 7 * 2,
      startOf: 'day',
      formatter: weekdayFormatter,
    },
    {
      maxSizeHours: 24 * 30 * 2,
      moduloInMonth: 5,
      formatter: dateFormatter,
    },
    {
      maxSizeHours: 24 * 30 * 12,
      moduloInMonth: 31,
      formatter: monthFormatter,
    },
  ]

  return (
    config.find((c) => domainSizeHours <= c.maxSizeHours) ??
    config[config.length - 1]
  )
}

export const MonitoringChart: FC<{
  data: { date: number; next: number }[]
  series: { name: string; key: string; color: string }[]
}> = ({ data, series }) => {
  const { ticks, tickFormatter } = useMemo(() => {
    const ticks = []
    let tickFormatter = timeFormatter

    if (data.length) {
      const currentConfig = getDataConfig(data)
      tickFormatter = currentConfig.formatter

      if (currentConfig.roundToNextMinutes) {
        const step = currentConfig.roundToNextMinutes * 60
        let current = Math.ceil(data[0].date / step) * step

        while (current < data[data.length - 1].next) {
          ticks.push(current)
          current += step
        }
      } else if (currentConfig.startOf === 'day') {
        let current = startOfDay(new Date(data[0].date * 1000))

        while (current.getTime() < data[data.length - 1].next * 1000) {
          ticks.push(current.getTime() / 1000)
          current = addDays(current, 1)
        }
      } else if (currentConfig.moduloInMonth) {
        ticks.push(
          ...data
            .map((d) => new Date(d.date * 1000))
            .filter(
              (d) =>
                (d.getDate() - 1) % (currentConfig.moduloInMonth ?? 2) === 0
            )
            .map((d) => d.getTime() / 1000)
        )
      }
    }

    return {
      ticks,
      tickFormatter,
    }
  }, [data])

  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart
        data={data}
        barGap={0}
        barCategoryGap={
          (data?.length ?? 0) >= 300 ? 0 : (data?.length ?? 0) >= 100 ? 1 : 3
        }
      >
        <defs>
          <linearGradient id="gradientFill" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#e7e7e7" stopOpacity={0.2} />
            <stop offset="95%" stopColor="#e7e7e7" stopOpacity={1} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" stroke={'#e7e7e7'} />
        <XAxis
          dataKey="date"
          tickFormatter={(value) =>
            tickFormatter.format(new Date(value * 1000))
          }
          ticks={ticks}
          axisLine={{ stroke: '#cecece' }}
          tickLine={{ stroke: '#cecece' }}
        />
        <YAxis
          tickFormatter={numberFormatter.format}
          axisLine={false}
          tickLine={false}
        />
        <Tooltip
          content={<MonitoringTooltip series={series} />}
          cursor={{ fill: 'url(#gradientFill)' }}
        />
        {series?.map((s, i) => (
          <Bar
            key={s.name}
            type="step"
            stackId="1"
            dataKey={s.key}
            strokeWidth={0}
            fill={s.color}
            isAnimationActive={false}
            shape={<RoundedBar series={series ?? []} />}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  )
}
