import { RouteObject } from 'react-router-dom'
import { FC, useEffect, useMemo, useState } from 'react'
import { Sections } from '../../../../components/component/Sections/Sections.tsx'
import { useForm } from 'react-hook-form'
import { InputWrapper } from '../../../../components/input/InputWrapper/InputWrapper.tsx'
import { TextInput } from '../../../../components/input/TextInput/TextInput.tsx'
import { FormSubmitButtons } from '../../../../components/component/FormSubmitButtons/FormSubmitButtons.tsx'
import {
  ContextPropertyInput,
  useProjectContextProperties,
} from '../../../../components/input/ContextPropertyInput/ContextPropertyInput.tsx'
import style from './Settings.module.scss'
import { ContextPropertyType, ContextRole } from '../../../../gql/graphql.ts'
import { SelectInput } from '../../../../components/input/SelectInput/SelectInput.tsx'
import { useCurrentOrgSafe } from '../../../../hooks/useCurrentOrgSafe.ts'
import { useFormSubmitMutation } from '../../../../hooks/useFormSubmitMutation.ts'
import { graphql } from '../../../../gql'
import { fetchUser } from '../../../../auth'

type ContextPropertiesRecord = Record<
  string,
  { type: ContextPropertyType; meta: any }
>

const ProdEnv: FC = () => {
  const { project, organization } = useCurrentOrgSafe()
  const [initialProjectId, setInitialProjectId] = useState(project.id)

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting },
    watch,
  } = useForm({
    defaultValues: {
      contextPropertyId:
        project.environmentContextProperty?.contextProperty.id ?? null,
      value: project.environmentContextProperty?.value ?? null,
    },
  })

  useEffect(() => {
    if (initialProjectId !== project.id) {
      reset({
        contextPropertyId:
          project.environmentContextProperty?.contextProperty.id ?? null,
        value: project.environmentContextProperty?.value ?? null,
      })
      setInitialProjectId(project.id)
    }
  }, [initialProjectId, project.id])

  const contextPropertyId = watch('contextPropertyId')

  const { data } = useProjectContextProperties()
  const contextProperties = useMemo(() => {
    return (
      data?.project?.contextProperties.reduce((acc, curr) => {
        acc[curr.id] = { type: curr.type, meta: curr.meta }
        return acc
      }, {} as ContextPropertiesRecord) ?? {}
    )
  }, [data])

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation setEnvironmentContextProperty(
        $projectId: ID!
        $contextPropertyId: ID!
        $value: String!
      ) {
        project(id: $projectId) {
          setEnvironmentContextProperty(
            contextPropertyId: $contextPropertyId
            value: $value
          ) {
            value
            contextProperty {
              id
            }
          }
        }
      }
    `),
    mapVariables: (values) => ({
      projectId: project.id,
      contextPropertyId: values.contextPropertyId ?? '',
      value: values.value ?? '',
    }),
    onSuccess: async () => {
      await fetchUser()
    },
  })

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className={style.alignedInputs}>
        <InputWrapper
          component={ContextPropertyInput}
          control={control}
          name="contextPropertyId"
          label="Property"
          disabled={
            organization.myAggregatedRole.context !== ContextRole.Manage
          }
          rules={{ required: true }}
        />
        {contextProperties[contextPropertyId ?? '']?.type ===
        ContextPropertyType.Select ? (
          <InputWrapper
            // @ts-ignore
            component={SelectInput}
            control={control}
            name="value"
            label="Value"
            disabled={
              organization.myAggregatedRole.context !== ContextRole.Manage
            }
            rules={{ required: true }}
            style={{ flexGrow: 1 }}
            options={
              contextProperties[contextPropertyId ?? ''].meta.options.map(
                ({ value }: { value: string }) => ({
                  value,
                  label: value,
                })
              ) ?? []
            }
          />
        ) : (
          <InputWrapper
            // @ts-ignore
            component={TextInput}
            control={control}
            name="value"
            label="Value"
            disabled={
              organization.myAggregatedRole.context !== ContextRole.Manage
            }
            rules={{ required: true }}
            style={{ flexGrow: 1 }}
          />
        )}
      </div>
      <FormSubmitButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        reset={reset}
        labelSubmit="Update"
      />
    </form>
  )
}

const Settings: FC = () => {
  return (
    <Sections>
      <Sections.Section
        title="Production environement"
        subTitle="Define what the production environement is to limit changes made to it"
      >
        <ProdEnv />
      </Sections.Section>
    </Sections>
  )
}

export const settingsRoute: RouteObject = {
  path: 'settings',
  element: <Settings />,
}
