import { FC, useEffect, useState } from 'react'
import { TooltipProps } from 'recharts/types/component/Tooltip'
import style from './MonitoringChart.module.scss'
import cx from 'classnames'
import { isSameDay } from 'date-fns'
import {
  flip,
  FloatingPortal,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react'

const dateFormatter = Intl.DateTimeFormat('en-GB', {
  day: 'numeric',
  month: 'short',
  weekday: 'short',
})

const numberFormatter = Intl.NumberFormat('en-GB')
const percentFormatter = Intl.NumberFormat('en-GB', { style: 'percent' })

const timeFormatter = Intl.DateTimeFormat('en-GB', {
  hour: '2-digit',
  minute: '2-digit',
})

export const MonitoringTooltip: FC<
  TooltipProps<any, any> & {
    series: { name: string; key: string; color: string }[]
    percent?: boolean
  }
> = ({ active, payload, series, percent = false }) => {
  const [reference, setReference] = useState<Element>()
  const { refs, floatingStyles, update } = useFloating({
    elements: { reference },
    placement: 'bottom-start',
    middleware: [
      offset({ mainAxis: 8, crossAxis: 8 }),
      shift({ padding: 8 }),
      flip({ padding: 8 }),
    ],
  })

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setReference({
        getBoundingClientRect() {
          return {
            width: 0,
            height: 0,
            x: event.clientX,
            y: event.clientY,
            left: event.clientX,
            right: event.clientX,
            top: event.clientY,
            bottom: event.clientY,
          }
        },
      } as Element)
      update()
    }

    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [update])

  if (active && payload && payload?.length) {
    const dataPoint = payload[0].payload
    const date = new Date(dataPoint.date * 1000)
    const next = dataPoint.next ? new Date(dataPoint.next * 1000) : null
    const total = series.reduce((acc, cur) => acc + dataPoint[cur.key], 0)

    return (
      <FloatingPortal>
        <div
          className={style.graphTooltip}
          ref={refs.setFloating}
          style={floatingStyles}
        >
          {!next || isSameDay(date, next) ? (
            <div className={style.date}>{dateFormatter.format(date)}</div>
          ) : (
            <div className={style.date}>
              {dateFormatter.format(date)} - {dateFormatter.format(next)}
            </div>
          )}
          {!next ? (
            <div className={style.time}>{timeFormatter.format(date)}</div>
          ) : (
            <div className={style.time}>
              {timeFormatter.format(date)} - {timeFormatter.format(next)}
            </div>
          )}
          {series.map((s, i) => (
            <div
              className={cx(style.series, !dataPoint[s.key] && style.zero)}
              key={i}
            >
              <div className={style.color} style={{ background: s.color }} />
              <div className={style.name}>{s.name}</div>
              <div className={style.value}>
                {percent
                  ? percentFormatter.format(dataPoint[s.key] / total)
                  : numberFormatter.format(dataPoint[s.key])}
              </div>
            </div>
          ))}
        </div>
      </FloatingPortal>
    )
  }

  return null
}

export const PieChartTooltip: FC<
  TooltipProps<any, any> & {
    series: { value: number }[]
  }
> = ({ active, payload, series }) => {
  const [reference, setReference] = useState<Element>()
  const { refs, floatingStyles, update } = useFloating({
    elements: { reference },
    placement: 'bottom-start',
    middleware: [
      offset({ mainAxis: 8, crossAxis: 8 }),
      shift({ padding: 8 }),
      flip({ padding: 8 }),
    ],
  })

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setReference({
        getBoundingClientRect() {
          return {
            width: 0,
            height: 0,
            x: event.clientX,
            y: event.clientY,
            left: event.clientX,
            right: event.clientX,
            top: event.clientY,
            bottom: event.clientY,
          }
        },
      } as Element)
      update()
    }

    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [update])

  if (active && payload && payload?.length) {
    const s = payload[0].payload.payload

    return (
      <FloatingPortal>
        <div
          className={style.graphTooltip}
          ref={refs.setFloating}
          style={floatingStyles}
        >
          <div className={style.series}>
            <div className={style.color} style={{ background: s.color }} />
            <div className={style.name}>{s.name}</div>
            <div className={style.value}>
              {percentFormatter.format(
                s.value / series.reduce((acc, cur) => acc + cur.value, 0)
              )}
            </div>
          </div>
        </div>
      </FloatingPortal>
    )
  }

  return null
}

export const SimpleTooltip: FC<TooltipProps<any, any>> = ({
  active,
  payload,
  label,
  labelFormatter = (label) => label,
}) => {
  const [reference, setReference] = useState<Element>()
  const { refs, floatingStyles, update } = useFloating({
    elements: { reference },
    placement: 'bottom-start',
    middleware: [
      offset({ mainAxis: 8, crossAxis: 8 }),
      shift({ padding: 8 }),
      flip({ padding: 8 }),
    ],
  })

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setReference({
        getBoundingClientRect() {
          return {
            width: 0,
            height: 0,
            x: event.clientX,
            y: event.clientY,
            left: event.clientX,
            right: event.clientX,
            top: event.clientY,
            bottom: event.clientY,
          }
        },
      } as Element)
      update()
    }

    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [update])

  if (active && payload && payload?.length) {
    return (
      <FloatingPortal>
        <div
          className={style.graphTooltip}
          ref={refs.setFloating}
          style={floatingStyles}
        >
          <div className={style.date}>
            {label ? labelFormatter(label, payload) : null}
          </div>
          {payload.map((p, i) => (
            <div className={cx(style.series, !p.value && style.zero)} key={i}>
              <div className={style.name}>{p.name}</div>
              <div className={style.value}>
                {numberFormatter.format(p.value)}
              </div>
            </div>
          ))}
        </div>
      </FloatingPortal>
    )
  }

  return null
}
