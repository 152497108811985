// @ts-ignore
import { ReactComponent as VectorLogo } from './logo.svg'
import style from './Logo.module.scss'
import {CSSProperties, FC, useState} from 'react'
import { motion } from 'framer-motion'

export const Logo: FC<{ height?: number, style?: CSSProperties }> = ({ height = 40, style: s }) => {
  const [hover, setHover] = useState(false)

  return (
    <div
      className={style.container}
      style={{ height, ...s }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <VectorLogo />
      <motion.div
        layout
        className={style.marker}
        animate={{ x: hover ? '-215%' : 0 }}
        transition={{
          type: 'spring',
          stiffness: 700,
          damping: 30,
        }}
      />
    </div>
  )
}
