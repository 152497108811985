import { RouteObject } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useCurrentOrgSafe } from '../../../../hooks/useCurrentOrgSafe.ts'
import { gqlClient } from '../../../../auth'
import { graphql } from '../../../../gql'
import { useForm } from 'react-hook-form'
import { InputWrapper } from '../../../../components/input/InputWrapper/InputWrapper.tsx'
import { BigRadioInput } from '../../../../components/input/BigRadioInput/BigRadioInput.tsx'
import style from './Billing.module.scss'
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl'
import {
  IconCheck,
  IconCreditCard,
  IconExternalLink,
  IconPhone,
  IconPlus,
  IconX,
} from '@tabler/icons-react'
import { IntInput } from '../../../../components/input/TextInput/TextInput.tsx'
import { useEffect } from 'react'
import { Card } from '../../../../components/component/Card/Card.tsx'
import { Columns } from '../../../../components/component/Columns/Columns.tsx'
import { Button } from '../../../../components/component/Button/Button.tsx'
import { OrganizationStatus } from '../../../../gql/graphql.ts'
import { Toolbar } from '../../../../components/component/Toolbar/Toolbar.tsx'
import { useFormSubmitMutation } from '../../../../hooks/useFormSubmitMutation.ts'
import { ActionsList } from '../../../../components/component/ActionsList/ActionsList.tsx'
import { Sections } from '../../../../components/component/Sections/Sections.tsx'
import { openModal } from '../../../../components/component/Modal/Modal.tsx'
import { FormSubmitButtons } from '../../../../components/component/FormSubmitButtons/FormSubmitButtons.tsx'
import addSeatsImage from '../../../../assets/empty-state/add-users.svg'
import { Meter } from '../../../../components/component/Meter/Meter.tsx'
import { toast } from 'react-hot-toast'
import { CenteredVerticalLayout } from '../../../../components/layout/CenteredVerticalLayout/CenteredVerticalLayout.tsx'

const FirstSubscription = () => {
  const { organization } = useCurrentOrgSafe()
  const { data, isLoading } = useQuery({
    queryKey: ['organization', organization.id, 'availablePlans'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query availablePlans($id: ID!) {
            organization(id: $id) {
              id
              availablePlans {
                id
                name
                frequencies {
                  id
                  frequency
                  price
                  memberPrice
                }
                membersLimit
                includedMembers
                includedMonthlyRequests
                monthlyRequestsPackageSize
                monthlyRequestsPackagePrice
                projectsLimit
                makeReviews
                enforceReview
                monitoringDaysLimit
                auditLogDaysLimit
                localFlagEvaluation
                webhooks
              }
            }
          }
        `),
        { id: organization.id }
      ),
    retry: false,
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      planId: '',
      frequency: 12,
      members: 5,
    },
  })

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation updateOrganizationBilling(
        $id: ID!
        $data: FirstSubscriptionData!
      ) {
        organization(id: $id) {
          firstSubscription(data: $data)
        }
      }
    `),
    mapVariables: (data) => ({ id: organization.id, data }),
    onSuccess: (data) => {
      if (data.organization?.firstSubscription) {
        window.location.href = data.organization?.firstSubscription
      }
    },
  })

  const members = watch('members')
  const planId = watch('planId')
  const frequency = watch('frequency')

  useEffect(() => {
    if (
      planId &&
      data?.organization?.availablePlans.find(
        (plan) => plan.id === planId && plan.membersLimit < members
      )
    ) {
      setValue(
        'planId',
        data?.organization?.availablePlans.find(
          (plan) => plan.membersLimit >= members
        )?.id ?? 'enterprise'
      )

      if (members > 50) {
        setValue('members', 5)
      }
    }
  }, [data?.organization?.availablePlans, members, planId, setValue])

  const plan = data?.organization?.availablePlans.find(
    (plan) => plan.id === planId
  )

  if (isLoading) {
    return null
  }

  return (
    <>
      {organization.status === OrganizationStatus.Trial && (
        <Card variant="outlined-thick" color="yellow">
          <h2>Trial</h2>
          <Columns gap="m">
            <div>
              <h3>What happens at the end of the trial?</h3>
              <p>
                On{' '}
                <FormattedDate
                  value={organization.trialEnd ?? 0}
                  month="long"
                  day="numeric"
                  hour="numeric"
                  minute="numeric"
                />{' '}
                you will no longer be able to update your flags until you choose
                a plan. Your flags will be kept as-is for 7 days, after which
                they will be de-activated.
              </p>
            </div>
            <div>
              <h3>Can I change plan later?</h3>
              <p>
                Yes. You can upgrade plan at anytime, or downgrade at the start
                of your next billing period.
              </p>
            </div>
          </Columns>
        </Card>
      )}
      {organization.status === OrganizationStatus.TrialGracePeriod && (
        <Card variant="outlined-thick" color="yellow">
          <h2>Grace period</h2>
          <Columns gap="m">
            <div>
              <h3>About your flags</h3>
              <p>
                You can no longer create or update your existing flags, but your
                application can still read them during the grace period.
              </p>
            </div>
            <div>
              <h3>End of the grace period</h3>
              <p>
                On{' '}
                <FormattedDate
                  value={organization.trialGracePeriodEndsAt ?? 0}
                  month="long"
                  day="numeric"
                  hour="numeric"
                  minute="numeric"
                />{' '}
                all your flags will be de-activated, you will not loose your
                current configuration, but they will all appear as "off" in your
                application.
              </p>
            </div>
            <div>
              <h3>Can I change plan later?</h3>
              <p>
                Yes. You can upgrade plan at anytime, or downgrade at the start
                of your next billing period.
              </p>
            </div>
          </Columns>
        </Card>
      )}
      {organization.status === OrganizationStatus.TrialEnded && (
        <Card variant="outlined-thick" color="yellow">
          <h2>Trial period ended</h2>
          <Columns gap="m">
            <div>
              <h3>About your flags</h3>
              <p>
                All your flags are de-activated and will appear as "off" in your
                app. Subscribing now will not change your settings, you can
                safely re-activate them one at a time if needed.
              </p>
            </div>
            <div>
              <h3>Can I change plan later?</h3>
              <p>
                Yes. You can upgrade plan at anytime, or downgrade at the start
                of your next billing period.
              </p>
            </div>
          </Columns>
        </Card>
      )}
      <form onSubmit={handleSubmit(submit)} style={{ marginTop: 24 }}>
        <InputWrapper
          control={control}
          name="planId"
          label="Plan"
          // @ts-ignore
          component={BigRadioInput}
          className={style.planList}
          options={[
            ...(data?.organization?.availablePlans.map((plan) => ({
              label: plan.name,
              value: plan.id,
              disabled: plan.membersLimit < members,
              description: (
                <>
                  <ul>
                    <li>
                      Included seats <span>{plan.includedMembers}</span>
                    </li>
                    <li>
                      Extra seat
                      <span>
                        <FormattedNumber
                          value={plan.frequencies[0].memberPrice / 100}
                          maximumFractionDigits={0}
                          style="currency"
                          currency="EUR"
                        />
                        /{plan.frequencies[0].frequency === 1 ? 'm' : 'y'}
                      </span>
                    </li>
                    <li>
                      Max seats <span>{plan.membersLimit}</span>
                    </li>
                    <li>
                      Included requests
                      <span>
                        <FormattedNumber
                          value={plan.includedMonthlyRequests}
                          notation="compact"
                        />
                        /m
                      </span>
                    </li>
                    <li>
                      Extra{' '}
                      <FormattedNumber
                        value={plan.monthlyRequestsPackageSize}
                        notation="compact"
                      />{' '}
                      requests
                      <span>
                        <FormattedNumber
                          value={plan.monthlyRequestsPackagePrice / 100}
                          maximumFractionDigits={0}
                          style="currency"
                          currency="EUR"
                        />
                      </span>
                    </li>
                    <li>
                      Server-side flags
                      <span>
                        {plan.localFlagEvaluation ? (
                          <IconCheck size={14} />
                        ) : (
                          <IconX size={14} />
                        )}
                      </span>
                    </li>
                    <li>
                      Reviews
                      <span>
                        {plan.makeReviews ? (
                          <IconCheck size={14} />
                        ) : (
                          <IconX size={14} />
                        )}
                      </span>
                    </li>
                    <li>
                      Required approvals
                      <span>
                        {plan.enforceReview ? (
                          <IconCheck size={14} />
                        ) : (
                          <IconX size={14} />
                        )}
                      </span>
                    </li>
                    <li>
                      Webhooks
                      <span>
                        {plan.webhooks ? plan.webhooks : <IconX size={14} />}
                      </span>
                    </li>
                    <li>
                      Projects
                      <span>
                        {plan.projectsLimit ? plan.projectsLimit : '∞'}
                      </span>
                    </li>
                    <li>
                      Audit log
                      <span>
                        {plan.auditLogDaysLimit
                          ? plan.auditLogDaysLimit + ' days'
                          : '∞'}
                      </span>
                    </li>
                    <li>
                      Monitoring
                      <span>
                        {plan.monitoringDaysLimit
                          ? plan.monitoringDaysLimit === 1
                            ? '24h'
                            : plan.monitoringDaysLimit + ' days'
                          : '1 year'}
                      </span>
                    </li>
                  </ul>
                  <div className={style.planPrice}>
                    <FormattedNumber
                      value={plan.frequencies[0].price / 100}
                      maximumFractionDigits={0}
                      style="currency"
                      currency="EUR"
                    />
                    <span className={style.frequency}>
                      / {plan.frequencies[0].frequency === 1 ? 'month' : 'year'}
                    </span>
                  </div>
                </>
              ),
            })) ?? []),
            {
              label: 'Enterprise',
              value: 'enterprise',
              description: (
                <>
                  <ul>
                    <li>
                      Seats <span>∞</span>
                    </li>
                    <li>
                      Requests <span>custom</span>
                    </li>
                    <li>
                      Features <span>all</span>
                    </li>
                    <li>
                      Required approvals{' '}
                      <span>
                        <IconCheck size={14} />
                      </span>
                    </li>
                    <li>
                      Webhooks <span>∞</span>
                    </li>
                    <li>
                      Audit log <span>∞</span>
                    </li>
                    <li>
                      Monitoring <span>1 year</span>
                    </li>
                  </ul>
                  <div className={style.planPrice}>Custom pricing</div>
                </>
              ),
            },
          ]}
        />
        {planId === 'enterprise' && (
          <Button
            color="primary"
            inline
            icon={IconPhone}
            to="https://tidycal.com/nicolaskeller/30-min"
            target="_blank"
          >
            Book a call with our sales team
          </Button>
        )}
        {planId && planId !== 'enterprise' && (
          <>
            <InputWrapper
              control={control}
              name="members"
              label="Seats"
              // @ts-ignore
              component={IntInput}
              style={{ maxWidth: 100 }}
              tooltip="Number of employees in your organization who need to access Tggl"
              min={5}
            />
            <InputWrapper
              control={control}
              name="frequency"
              label="Billing period"
              // @ts-ignore
              component={BigRadioInput}
              className={style.planList}
              options={
                plan?.frequencies.map((frequency) => ({
                  label:
                    frequency.frequency === 1 ? (
                      'Monthly'
                    ) : (
                      <>
                        Yearly <small>2 months free</small>
                      </>
                    ),
                  value: frequency.frequency,
                  description: (
                    <>
                      <ul>
                        <li>
                          Flat fee
                          <span>
                            <FormattedNumber
                              value={frequency.price / 100}
                              maximumFractionDigits={0}
                              style="currency"
                              currency="EUR"
                            />
                            /{frequency.frequency === 1 ? 'm' : 'y'}
                          </span>
                        </li>
                        <li>
                          Extra seat (x
                          {Math.max(0, members - plan.includedMembers)})
                          <span>
                            <FormattedNumber
                              value={frequency.memberPrice / 100}
                              maximumFractionDigits={0}
                              style="currency"
                              currency="EUR"
                            />
                            /{frequency.frequency === 1 ? 'm' : 'y'}
                          </span>
                        </li>
                      </ul>
                      <div className={style.planPrice}>
                        <FormattedNumber
                          value={
                            (frequency.price +
                              Math.max(0, members - plan.includedMembers) *
                                frequency.memberPrice) /
                            100
                          }
                          maximumFractionDigits={0}
                          style="currency"
                          currency="EUR"
                        />
                        <span className={style.frequency}>
                          / {frequency.frequency === 1 ? 'month' : 'year'}{' '}
                          {frequency.frequency === 1 && (
                            <>
                              •{' '}
                              <FormattedNumber
                                value={
                                  ((frequency.price +
                                    Math.max(
                                      0,
                                      members - plan.includedMembers
                                    ) *
                                      frequency.memberPrice) *
                                    12) /
                                  100
                                }
                                maximumFractionDigits={0}
                                style="currency"
                                currency="EUR"
                              />{' '}
                              / year
                            </>
                          )}
                        </span>
                      </div>
                    </>
                  ),
                })) ?? []
              }
            />
            <Toolbar>
              <Button
                color="primary"
                inline
                loading={isSubmitting}
                type="submit"
                icon={IconCreditCard}
              >
                Choose {plan?.name} plan,{' '}
                {frequency === 1 ? 'monthly' : 'yearly'} payments
              </Button>
              <span>Your subscription will start immediately</span>
            </Toolbar>
          </>
        )}
      </form>
    </>
  )
}

const AddSeats = () => {
  const { organization } = useCurrentOrgSafe()

  const { data } = useQuery({
    queryKey: ['billing', organization.id, 'OrgSeatsSituation'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query OrgSeatsSituation($id: ID!) {
            organization(id: $id) {
              id
              membersLimit {
                limit
                current
                left
              }
              currentPlan {
                name
                frequency
                canAddUpToNSeats
                extraSeatsUnitPrice
                maxSeats
              }
            }
          }
        `),
        {
          id: organization.id,
        }
      ),
  })

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isDirty, isSubmitting },
  } = useForm({
    defaultValues: {
      extraSeats: null as number | null,
    },
  })

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation AddSeats($organizationId: ID!, $extraSeats: Int!) {
        organization(id: $organizationId) {
          addSeats(seats: $extraSeats)
        }
      }
    `),
    mapVariables: (data) => ({
      organizationId: organization.id,
      extraSeats: data.extraSeats ?? 0,
    }),
    onSuccess: () => {
      toast.success('Seats added')
    },
  })

  const extraSeats = watch('extraSeats')

  return (
    <form onSubmit={handleSubmit(submit)}>
      <img className="illustration" src={addSeatsImage} />
      <h2>Add seats</h2>
      <div style={{ marginBottom: 16, marginTop: 16 }}>
        <Meter
          current={data?.organization?.membersLimit.current}
          limit={data?.organization?.membersLimit.limit}
          title={
            <>
              <b>{data?.organization?.membersLimit.left}</b>{' '}
              <FormattedMessage
                id={`unit.seats`}
                values={{
                  count: data?.organization?.membersLimit.left,
                }}
              />{' '}
              left.
            </>
          }
        />
      </div>
      <InputWrapper
        control={control}
        name="extraSeats"
        label="Number of seats to add"
        component={IntInput}
        continuousUpdates
        min={0}
      />

      {(extraSeats ?? 0) >
      (data?.organization?.currentPlan?.canAddUpToNSeats ?? 0) ? (
        <>
          <p style={{ marginTop: 16 }}>
            The {data?.organization?.currentPlan?.name} plan supports{' '}
            {data?.organization?.currentPlan?.maxSeats} seats maximum.
            Currently, you can add up to{' '}
            {data?.organization?.currentPlan?.canAddUpToNSeats} seats or switch
            to a higher plan.
          </p>
          <Toolbar alignRight>
            <Button
              color="primary"
              inline
              icon={IconExternalLink}
              to="https://tidycal.com/nicolaskeller/30-min"
              target="_blank"
            >
              Switch plan
            </Button>
          </Toolbar>
        </>
      ) : (
        <>
          <p style={{ marginTop: 16 }}>
            You are about to add {extraSeats ?? 0}{' '}
            <FormattedMessage
              id={`unit.seats`}
              values={{
                count: extraSeats,
              }}
            />{' '}
            for an additional{' '}
            <FormattedNumber
              value={
                ((extraSeats ?? 0) *
                  (data?.organization?.currentPlan?.extraSeatsUnitPrice ?? 0)) /
                100
              }
              currency="EUR"
              style="currency"
            />{' '}
            a{' '}
            {data?.organization?.currentPlan?.frequency === 1
              ? 'month'
              : 'year'}
            . This amount will be pro-ratted for your current billing period.
          </p>
          <FormSubmitButtons
            isDirty={isDirty}
            isSubmitting={isSubmitting}
            reset={reset}
            labelSubmit="Add  seats"
            disabled={!extraSeats}
          />
        </>
      )}
    </form>
  )
}

const Page = () => {
  const { organization } = useCurrentOrgSafe()

  const { data, isLoading } = useQuery({
    queryKey: ['billing', organization.id, 'OrgBillingPortal'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query OrgBillingPortal($id: ID!) {
            organization(id: $id) {
              id
              billingPortalUrl
              currentPlan {
                id
                name
                frequency
                price
                canAddSeatsSelfServed
              }
              membersLimit {
                limit
              }
            }
          }
        `),
        {
          id: organization.id,
        }
      ),
  })

  if (
    [
      OrganizationStatus.Trial,
      OrganizationStatus.TrialEnded,
      OrganizationStatus.TrialGracePeriod,
    ].includes(organization.status)
  ) {
    return <FirstSubscription />
  }

  const currentPlan = data?.organization?.currentPlan

  if (isLoading) {
    return (
      <CenteredVerticalLayout>
        <CenteredVerticalLayout.Loader />
      </CenteredVerticalLayout>
    )
  }

  if (!currentPlan) {
    return null
  }

  return (
    <>
      <Sections>
        <Sections.Section title="Current">
          <h2>{currentPlan.name} plan</h2>
          <p style={{ marginTop: 8 }}>
            <FormattedNumber
              value={currentPlan.price / 100}
              style="currency"
              currency="EUR"
            />{' '}
            / {currentPlan.frequency === 1 ? 'month' : 'year'} + taxes
          </p>
        </Sections.Section>
        <Sections.Section title="Actions">
          <ActionsList>
            {currentPlan.canAddSeatsSelfServed && (
              <ActionsList.Action
                title="Seats"
                subTitle="Add seats to your current plan"
                action={
                  <Button
                    color="primary"
                    icon={IconPlus}
                    onClick={() =>
                      openModal({
                        title: null,
                        content: <AddSeats />,
                      })
                    }
                  >
                    Add seats
                  </Button>
                }
              />
            )}
            <ActionsList.Action
              title="Invoices"
              subTitle="View your invoices and manage your payment methods"
              action={
                <Button
                  to={data?.organization?.billingPortalUrl ?? undefined}
                  icon={IconExternalLink}
                  target="_blank"
                >
                  Billing portal
                </Button>
              }
            />
            <ActionsList.Action
              title="Change plan"
              subTitle="Change your current plan or payment frequency by emailing us at hello@tggl.io or book a call with our sales team"
              action={
                <Button
                  to="https://tidycal.com/nicolaskeller/30-min"
                  icon={IconPhone}
                  target="_blank"
                >
                  Book a call
                </Button>
              }
            />
          </ActionsList>
        </Sections.Section>
      </Sections>
    </>
  )
}

export const billingRoute: RouteObject = {
  path: 'billing',
  element: <Page />,
}
