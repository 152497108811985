import * as React from 'react'
import {
  ComponentPropsWithoutRef,
  FC,
  forwardRef,
  ForwardRefExoticComponent,
  ReactNode,
  Ref,
  RefAttributes,
  useContext,
  useRef,
  useState,
} from 'react'
import type { Placement } from '@floating-ui/react'
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingPortal,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
} from '@floating-ui/react'
import './Tooltip.scss'
import Balancer from 'react-wrap-balancer'

interface TooltipOptions {
  placement?: Placement
  delay?: number
  forceShow?: boolean
  showArrow?: boolean
}

const useTooltip = ({
  placement = 'top',
  showArrow = true,
  delay = 200,
  forceShow = false,
}: TooltipOptions = {}) => {
  const [open, setOpen] = useState(false)
  const arrowRef = useRef(null)

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(8),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'start',
        padding: 5,
      }),
      shift({ padding: 5 }),
      arrow({
        element: arrowRef,
        padding: 8,
      }),
    ],
  })

  const context = data.context

  const hover = useHover(context, { move: false, delay })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  const interactions = useInteractions([hover, focus, dismiss, role])

  return React.useMemo(
    () => ({
      open: open || forceShow,
      setOpen,
      arrowRef,
      showArrow,
      ...interactions,
      ...data,
    }),
    [open, forceShow, setOpen, interactions, data, showArrow]
  )
}

type ContextType = ReturnType<typeof useTooltip> | null

const TooltipContext = React.createContext<ContextType>(null)

const useTooltipContext = () => {
  const context = useContext(TooltipContext)

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />')
  }

  return context
}

export const Tooltip: FC<{ children: ReactNode } & TooltipOptions> & {
  Trigger: typeof TooltipTrigger
  Content: typeof TooltipContent
} = ({ children, ...options }) => {
  const tooltip = useTooltip(options)
  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  )
}

const TooltipTrigger = forwardRef<unknown>(
  <
    TComponent extends
      | ForwardRefExoticComponent<any>
      | keyof JSX.IntrinsicElements
  >(
    {
      component,
      wrap,
      ...props
    }: {
      component: TComponent
      wrap?: boolean
    } & (TComponent extends FC<infer Props>
      ? Props
      : TComponent extends keyof JSX.IntrinsicElements
      ? ComponentPropsWithoutRef<TComponent>
      : never),
    ref: Ref<unknown>
  ) => {
    const context = useTooltipContext()
    const Component = component as FC<any>
    const mergedRed = useMergeRefs([ref, context.refs.setReference])

    if (wrap) {
      return (
        <span ref={mergedRed} {...context.getReferenceProps()}>
          <Component {...props} />
        </span>
      )
    }

    return <Component ref={mergedRed} {...context.getReferenceProps(props)} />
  }
) as <
  TComponent extends
    | ForwardRefExoticComponent<any>
    | keyof JSX.IntrinsicElements
>(
  props: {
    component: TComponent
    wrap?: boolean
  } & (TComponent extends FC<infer Props>
    ? Props
    : TComponent extends keyof JSX.IntrinsicElements
    ? ComponentPropsWithoutRef<TComponent>
    : never) &
    RefAttributes<unknown>
) => ReactNode

Tooltip.Trigger = TooltipTrigger

const TooltipContent: FC<{ children: ReactNode }> = ({ children }) => {
  const context = useTooltipContext()

  if (!context.open || !children) return null

  return (
    <FloatingPortal>
      <div
        className="tooltip-content"
        ref={context.refs.setFloating}
        style={context.floatingStyles}
        {...context.getFloatingProps()}
      >
        <Balancer>{children}</Balancer>
        {context.showArrow && (
          <FloatingArrow
            ref={context.arrowRef}
            context={context.context}
            tipRadius={1}
            fill="#3f3f3f"
            height={6}
            width={13}
          />
        )}
      </div>
    </FloatingPortal>
  )
}

Tooltip.Content = TooltipContent
