import {
  forwardRef,
  ReactNode,
  RefAttributes,
  useEffect,
  useRef,
  useState,
} from 'react'
import Select, {
  ClearIndicatorProps,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  GroupProps,
  IndicatorsContainerProps,
  MenuProps,
  MultiValueProps,
  OptionProps,
  OptionsOrGroups,
} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import AsyncCreatableSelect from 'react-select/async-creatable'
import {
  IconArrowsDiagonal,
  IconCheck,
  IconCopy,
  IconExclamationCircle,
  IconPencil,
  IconPlus,
  IconSelector,
  IconSortAscendingLetters,
  IconX,
} from '@tabler/icons-react'
import './SelectInput.scss'
import cx from 'classnames'
import { motion } from 'framer-motion'
import { FieldError } from 'react-hook-form'
import { Tooltip } from '../../component/Tooltip/Tooltip.tsx'
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  size,
  useFloating,
  useMergeRefs,
} from '@floating-ui/react'
import { useTempState } from '../../../hooks/useTempState.tsx'
import { rankItem } from '@tanstack/match-sorter-utils'
import { openModal, useModalContext } from '../../component/Modal/Modal.tsx'
import {
  DataSheetGrid,
  DataSheetGridRef,
  textColumn,
} from 'react-datasheet-grid'
import { Toolbar } from '../../component/Toolbar/Toolbar.tsx'
import { Button } from '../../component/Button/Button.tsx'
import {
  Badge,
  BadgeProps,
  nameToColors,
} from '../../component/Badge/Badge.tsx'
import { IconButton } from '../../component/IconButton/IconButton.tsx'

const MotionIconCheck = motion(IconCheck)

export type SelectInputProps<T> = {
  id?: string
  value: T
  onChange: (value: T) => void
  options: OptionsOrGroups<SelectOption<T>, GroupBase<SelectOption<T>>>
  allOptions?: OptionsOrGroups<SelectOption<T>, GroupBase<SelectOption<T>>>
  placeholder?: ReactNode
  autoWidth?: boolean
  disabled?: boolean
  showSelectedIcon?: boolean
  onCreateOption?: (inputValue: string) => Promise<T | undefined>
  moreActions?: (option: SelectOption<T>, deleteOption: () => void) => void
}

export type MultiSelectInputProps<T> = Omit<
  SelectInputProps<T>,
  'value' | 'onChange' | 'autoWidth' | 'showSelectedIcon'
> & {
  value: T[]
  onChange: (value: T[]) => void
  hideSelectedOptions?: boolean
  closeMenuOnSelect?: boolean
}

export type AutoCompleteArrayTextInputProps = Omit<
  SelectInputProps<string>,
  'value' | 'onChange' | 'autoWidth' | 'showSelectedIcon' | 'options'
> & {
  value: string[]
  onChange: (value: string[]) => void
  getOptions: (inputValue: string) => Promise<SelectOption<string>[]>
  getLabels: (values: string[]) => Promise<(string | null)[]>
}

export type ArrayTextInputProps = Omit<
  SelectInputProps<string>,
  'value' | 'onChange' | 'options' | 'autoWidth' | 'showSelectedIcon'
> & {
  value: string[]
  onChange: (value: string[]) => void
  error?: FieldError[]
}

export type SelectOption<T> = {
  value: T
  label: string
  error?: string
  tooltip?: ReactNode
  color?: BadgeProps['color']
  icon?: (props: {
    size?: string | number
    stroke?: string | number
    selected: boolean
  }) => ReactNode
}

const Control = <T,>({
  children,
  innerRef,
  innerProps,
  isFocused,
  selectProps,
}: ControlProps<SelectOption<T>>) => {
  return (
    <div
      className={cx(
        'input-container',
        (selectProps as any).autoWidth && 'auto-width'
      )}
      ref={useMergeRefs([innerRef, (selectProps as any).anchorRef])}
      {...innerProps}
    >
      <div className={`input ${isFocused ? 'focused' : ''}`}>{children}</div>
    </div>
  )
}

const IndicatorsContainer = <T,>({
  children,
}: IndicatorsContainerProps<SelectOption<T>>) => {
  return children
}

const DropdownIndicator = <T,>({
  innerProps,
}: DropdownIndicatorProps<SelectOption<T>>) => {
  return (
    <IconSelector
      size={18}
      stroke={2}
      className="caret"
      {...(innerProps as JSX.IntrinsicElements['svg'])}
    />
  )
}

// @ts-ignore
const CopyArray = ({
  innerProps,
  hasValue,
  getValue,
}: DropdownIndicatorProps<SelectOption<string>, true>) => {
  const [copied, setCopied] = useTempState()

  if (!hasValue) return null

  return (
    <Tooltip>
      <Tooltip.Trigger
        component={IconCopy}
        size={18}
        stroke={2}
        className="select-copy-button"
        {...(innerProps as JSX.IntrinsicElements['svg'])}
        onClick={() => {
          setCopied()
          navigator.clipboard.writeText(
            getValue()
              .map((option) => option.value)
              .join(',')
          )
        }}
      />
      <Tooltip.Content>
        {copied ? 'Copied!' : 'Copy values as a comma separated list'}
      </Tooltip.Content>
    </Tooltip>
  )
}

const ExpandedModal = ({
  initialValue,
  setValue,
}: {
  initialValue: readonly SelectOption<string>[]
  setValue: DropdownIndicatorProps<SelectOption<string>, true>['setValue']
}) => {
  const [internalValue, setInternalValue] = useState<(string | null)[]>(() => [
    ...initialValue.map((v) => v.value),
    '',
  ])
  const internalValueRef = useRef(internalValue)
  internalValueRef.current = internalValue
  const ref = useRef<DataSheetGridRef>(null)
  const context = useModalContext()

  return (
    <>
      <div style={{ marginBottom: 8 }}>
        <b>{internalValue.length}</b> values
      </div>
      <DataSheetGrid<string | null>
        value={internalValue}
        ref={ref}
        rowHeight={34}
        onChange={setInternalValue}
        disableContextMenu
        disableExpandSelection
        addRowsComponent={({ addRows }) => (
          <Toolbar style={{ marginTop: 16 }}>
            <Button icon={IconPlus} onClick={() => addRows()}>
              Add
            </Button>
            <Button
              icon={IconSortAscendingLetters}
              onClick={() =>
                setInternalValue((iv) => [...iv].filter(Boolean).sort())
              }
            >
              Sort
            </Button>
            <div style={{ flex: 1 }} />
            <Button
              color="primary"
              onClick={() => {
                const newValue = [
                  ...new Set(internalValueRef.current.filter(Boolean)),
                ].map((v) => ({
                  value: v ?? '',
                  label: v ?? '',
                }))
                setValue(
                  newValue,
                  newValue.length ? 'select-option' : 'deselect-option'
                )
                context?.close()
              }}
            >
              Done
            </Button>
          </Toolbar>
        )}
        columns={[{ ...textColumn, title: 'Values' }]}
        autoAddRow
        duplicateRow={({ rowData }) => rowData}
        createRow={() => null}
      />
    </>
  )
}

const ExpandModal = ({
  innerProps,
  getValue,
  setValue,
}: DropdownIndicatorProps<SelectOption<string>, true>) => {
  return (
    <Tooltip>
      <Tooltip.Trigger
        component={IconArrowsDiagonal}
        size={18}
        stroke={2}
        className="select-copy-button"
        {...(innerProps as JSX.IntrinsicElements['svg'])}
        onClick={() =>
          openModal({
            title: null,
            content: (
              <ExpandedModal initialValue={getValue()} setValue={setValue} />
            ),
            size: 'm',
          })
        }
      />
      <Tooltip.Content>Expand</Tooltip.Content>
    </Tooltip>
  )
}

const ClearIndicator = <T,>({
  innerProps,
}: ClearIndicatorProps<SelectOption<T>>) => {
  return (
    <IconX
      size={16}
      stroke={2}
      {...(innerProps as JSX.IntrinsicElements['svg'])}
    />
  )
}

const Option = <T,>({
  innerRef,
  innerProps,
  isSelected,
  isFocused,
  label,
  selectProps,
  data,
  setValue,
  getValue,
}: OptionProps<SelectOption<T>>) => {
  const Icon = data.icon
  const allProps = {
    ...innerProps,
    ref: innerRef,
    className: cx('select-item', {
      selected: isSelected,
      focused: isFocused,
    }),
    children: (
      <>
        {Icon ? (
          <Icon selected={isSelected} size={16} stroke={2} />
        ) : (
          <MotionIconCheck
            initial={{ scale: isSelected ? 1 : 0 }}
            animate={{ scale: isSelected ? 1 : 0 }}
            size={16}
            stroke={2.5}
          />
        )}
        {data.color ? (
          <Badge color={data.color} style={{ fontSize: '0.9em' }}>
            {label}
          </Badge>
        ) : (
          label
        )}
        <div style={{ flex: 1 }} />
        {/* @ts-ignore */}
        {selectProps.showOptionsValue && data.value !== label && (
          <span className="option-value">#{String(data.value)}</span>
        )}
        {/* @ts-ignore */}
        {selectProps.moreActions && (
          <>
            <IconButton
              icon={IconPencil}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                // @ts-ignore
                selectProps.moreActions(data, () =>
                  setValue(
                    getValue().filter(({ value }) => value !== data.value),
                    'deselect-option'
                  )
                )
              }}
            />
          </>
        )}
      </>
    ),
  }

  if (data.tooltip) {
    return (
      <Tooltip placement="right" delay={0} showArrow={false}>
        <Tooltip.Trigger component="div" {...allProps} />
        <Tooltip.Content>{data.tooltip}</Tooltip.Content>
      </Tooltip>
    )
  }

  return <div {...allProps} />
}

const Group = <T,>({ children, label }: GroupProps<SelectOption<T>>) => {
  return (
    <div className="select-group">
      <div>{label}</div>
      {children}
    </div>
  )
}

const MultiValue = <T,>({
  children,
  isFocused,
  selectOption,
  data,
}: MultiValueProps<SelectOption<T>>) => {
  return (
    <Tooltip>
      <Tooltip.Trigger
        component="div"
        className={cx(
          'select-pill',
          isFocused && 'focused',
          data.error && 'error'
        )}
        style={data.color ? nameToColors(data.color) : undefined}
      >
        <span>{children}</span>
        <IconX size={12} onClick={() => selectOption(data)} />
      </Tooltip.Trigger>
      <Tooltip.Content>{data.error}</Tooltip.Content>
    </Tooltip>
  )
}

const Menu = <T,>({
  children,
  innerProps,
  innerRef,
  selectProps,
}: MenuProps<SelectOption<T>>) => {
  const { floatingStyles, refs } = useFloating<HTMLElement>({
    elements: {
      reference: (selectProps as any).anchorRef.current,
    },
    placement: 'bottom-start',
    middleware: [
      size({
        apply: ({ rects, elements }) => {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          })
        },
      }),
      offset({
        mainAxis: 3,
        alignmentAxis: -3,
      }),
      flip({ padding: 10 }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  })

  return (
    <FloatingPortal>
      <div
        className="select__menu"
        ref={useMergeRefs([refs.setFloating, innerRef])}
        style={floatingStyles}
        {...innerProps}
      >
        {children}
      </div>
    </FloatingPortal>
  )
}

const LoadingIndicator = () => null

export const SelectInput = forwardRef<unknown, SelectInputProps<string>>(
  <T = string,>(
    {
      id,
      value,
      onChange,
      options,
      allOptions = options,
      autoWidth,
      disabled,
      showSelectedIcon,
      onCreateOption,
      ...props
    }: SelectInputProps<T>,
    ref: unknown
  ) => {
    const anchorRef = useRef<HTMLElement>()

    const currentOption = allOptions
      .flatMap((option) => ('options' in option ? option.options : option))
      .find((option) => option.value === value)

    const CurrentIcon = currentOption?.icon
    const Component = onCreateOption ? CreatableSelect : Select

    return (
      <Component<SelectOption<T>>
        inputId={id}
        value={
          currentOption
            ? {
                value: currentOption.value,
                label:
                  showSelectedIcon && CurrentIcon
                    ? ((
                        <>
                          <CurrentIcon selected size={16} />
                          {currentOption.label}
                        </>
                      ) as unknown as string)
                    : currentOption.label,
              }
            : value === null
            ? null
            : {
                value,
                label: (
                  <>
                    <IconExclamationCircle size={16} style={{ opacity: 0.5 }} />
                    Invalid
                  </>
                ) as unknown as string,
              }
        }
        isDisabled={disabled}
        onChange={(option) => onChange(option?.value ?? (null as T))}
        options={options}
        classNamePrefix="select"
        // @ts-ignore
        autoWidth={autoWidth}
        anchorRef={anchorRef}
        filterOption={({ label }, input) => rankItem(label, input).passed}
        components={{
          Control,
          IndicatorsContainer,
          IndicatorSeparator: null,
          DropdownIndicator,
          ClearIndicator,
          Option,
          Group,
          Menu,
          LoadingIndicator,
        }}
        ref={ref as any}
        onCreateOption={
          onCreateOption
            ? (inputValue) => {
                onCreateOption(inputValue).then((newValue) => {
                  if (newValue !== undefined) {
                    onChange(newValue)
                  }
                })
              }
            : undefined
        }
        {...props}
      />
    )
  }
) as <T = string>(
  props: SelectInputProps<T> & RefAttributes<unknown>
) => ReactNode

export const MultiSelectInput = forwardRef<
  unknown,
  MultiSelectInputProps<string>
>(
  <T = string,>(
    {
      id,
      value = [],
      onChange,
      options,
      disabled,
      onCreateOption,
      ...props
    }: MultiSelectInputProps<T>,
    ref: unknown
  ) => {
    const anchorRef = useRef<HTMLElement>()
    const [loading, setLoading] = useState<string | boolean>(false)

    const flatOptions = options.flatMap((option) =>
      'options' in option ? option.options : option
    )

    const Component = onCreateOption ? CreatableSelect : Select

    const displayValue = value.map(
      (val) =>
        flatOptions.find((option) => option.value === val) ?? {
          value: val,
          label: String(val),
        }
    )
    return (
      <Component<SelectOption<T>, true>
        inputId={id}
        value={
          typeof loading === 'string'
            ? [...displayValue, { value: '' as T, label: loading }]
            : displayValue
        }
        onChange={(options) => onChange(options.map((option) => option.value))}
        options={options}
        isDisabled={disabled}
        classNamePrefix="select"
        filterOption={({ label }, input) => rankItem(label, input).passed}
        components={{
          Control,
          IndicatorsContainer,
          IndicatorSeparator: null,
          DropdownIndicator,
          ClearIndicator,
          Option,
          Group,
          MultiValue,
          Menu,
          LoadingIndicator,
        }}
        // @ts-ignore
        anchorRef={anchorRef}
        isClearable={false}
        isMulti
        ref={ref as any}
        onCreateOption={
          onCreateOption
            ? (inputValue) => {
                setLoading(inputValue)
                onCreateOption(inputValue)
                  .then((newValue) => {
                    if (newValue !== undefined) {
                      onChange([...value, newValue])
                    }
                  })
                  .finally(() => setLoading(false))
              }
            : undefined
        }
        {...props}
      />
    )
  }
) as <T = string>(
  props: MultiSelectInputProps<T> & RefAttributes<unknown>
) => ReactNode

export const AutoCompleteArrayTextInput = forwardRef<
  unknown,
  AutoCompleteArrayTextInputProps
>(
  (
    {
      id,
      value = [],
      onChange,
      disabled,
      getOptions,
      getLabels,
      ...props
    }: AutoCompleteArrayTextInputProps,
    ref: unknown
  ) => {
    const anchorRef = useRef<HTMLElement>()
    const [labels, setLabels] = useState<Record<string, string | null>>({})

    useEffect(() => {
      const valuesMissingLabels = value.filter(
        (val) => !(String(val) in labels)
      )

      if (valuesMissingLabels.length) {
        getLabels(valuesMissingLabels).then((newLabels) => {
          setLabels((oldLabels) =>
            newLabels.reduce(
              (acc, label, i) => ({
                ...acc,
                [String(valuesMissingLabels[i])]: label,
              }),
              oldLabels
            )
          )
        })
      }
    }, [getLabels, labels, value])

    const displayValue = value.map((val) => ({
      value: val,
      label: labels[String(val)] ?? String(val),
    }))

    const [inputValue, setInputValue] = useState('')

    return (
      <AsyncCreatableSelect<SelectOption<string>, true>
        inputId={id}
        value={displayValue}
        onChange={(options) => onChange(options.map((option) => option.value))}
        isDisabled={disabled}
        classNamePrefix="select"
        components={{
          Control,
          IndicatorsContainer,
          IndicatorSeparator: null,
          DropdownIndicator: ExpandModal,
          ClearIndicator,
          Option,
          Group,
          MultiValue,
          Menu,
          LoadingIndicator,
        }}
        // @ts-ignore
        anchorRef={anchorRef}
        showOptionsValue
        isClearable={false}
        isMulti
        ref={ref as any}
        defaultOptions
        loadOptions={getOptions}
        inputValue={inputValue}
        onInputChange={(text) => {
          if (text.includes(',')) {
            onChange([
              ...new Set([
                ...value,
                ...text
                  .split(',')
                  .map((val) => val.trim())
                  .filter(Boolean),
              ]),
            ])
            setInputValue('')
          } else {
            setInputValue(text)
          }
        }}
        {...props}
      />
    )
  }
) as (
  props: AutoCompleteArrayTextInputProps & RefAttributes<unknown>
) => ReactNode

export const ArrayTextInput = forwardRef(
  (
    {
      id,
      value = [],
      onChange,
      error,
      placeholder,
      disabled,
      ...props
    }: ArrayTextInputProps,
    ref
  ) => {
    const [inputValue, setInputValue] = useState('')

    return (
      <CreatableSelect<SelectOption<string>, true>
        inputId={id}
        value={value.map((val, i) => ({
          value: val,
          label: val,
          error: error?.[i]?.message,
        }))}
        onChange={(options) => onChange(options.map((option) => option.value))}
        classNamePrefix="select"
        inputValue={inputValue}
        isDisabled={disabled}
        placeholder={placeholder ?? ''}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === 'Tab') {
            const trimmed = inputValue.trim()

            if (trimmed) {
              event.preventDefault()
              if (!value.includes(trimmed)) {
                onChange([...value, trimmed])
              }
              setInputValue('')
            }
          }
        }}
        onInputChange={(text, actionMeta) => {
          if (actionMeta.action === 'input-blur') {
            const trimmed = inputValue.trim()

            if (trimmed && !value.includes(trimmed)) {
              onChange([...value, trimmed])
            }

            setInputValue('')
          } else if (text.includes(',')) {
            onChange([
              ...new Set([
                ...value,
                ...text
                  .split(',')
                  .map((val) => val.trim())
                  .filter(Boolean),
              ]),
            ])
            setInputValue('')
          } else {
            setInputValue(text)
          }
        }}
        filterOption={({ label }, input) => rankItem(label, input).passed}
        components={{
          Control,
          IndicatorsContainer,
          IndicatorSeparator: null,
          DropdownIndicator: ExpandModal,
          ClearIndicator,
          Option,
          Group,
          MultiValue,
          LoadingIndicator,
        }}
        isClearable={false}
        isMulti
        menuIsOpen={false}
        {...props}
      />
    )
  }
)
