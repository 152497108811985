import { useQueryClient } from '@tanstack/react-query'
import { confirmDelete } from '../components/component/Modal/Modal.tsx'
import { toast } from 'react-hot-toast'
import { gqlClient } from '../auth'
import { graphql } from '../gql'

export const useDeleteWebhook = () => {
  const queryClient = useQueryClient()

  return (id: string) =>
    confirmDelete({
      unit: 'webhooks',
      onDelete: () =>
        gqlClient
          .request(
            graphql(`
              mutation DeleteWebhook($id: ID!) {
                webhook(id: $id) {
                  delete
                }
              }
            `),
            { id }
          )
          .then(() => {
            queryClient.invalidateQueries({ queryKey: ['webhook', id] })
            queryClient.invalidateQueries({ queryKey: ['webhooks'] })
          }),
      description: (
        <p style={{ textAlign: 'center' }}>
          You are about to permanently delete this webhook and all its related
          data, including the history of deliveries.
        </p>
      ),
    })
}

export const useTestRunWebhook = () => {
  const queryClient = useQueryClient()

  return (id: string) =>
    toast.promise(
      gqlClient
        .request(
          graphql(`
            mutation TestRunWebhook($id: ID!) {
              webhook(id: $id) {
                run {
                  id
                }
              }
            }
          `),
          { id }
        )
        .then(() => new Promise((resolve) => setTimeout(resolve, 1500)))
        .then(() => {
          queryClient.invalidateQueries({ queryKey: ['webhook', id] })
        }),
      {
        loading: 'Running webhook...',
        success: 'Test run queued',
        error: 'Failed to trigger test run',
      }
    )
}
