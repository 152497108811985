import { FC, ReactNode } from 'react'
import './Columns.scss'
import cx from 'classnames'

export const Columns: FC<{ children: ReactNode; gap?: 's' | 'm' }> = ({
  children,
  gap = 's',
}) => {
  return <div className={cx('columns', gap)}>{children}</div>
}
