import { FC, ReactNode } from 'react'
import './ActionsList.scss'

export const ActionsList: FC<{ children?: ReactNode }> & {
  Action: typeof Action
} = ({ children }) => {
  return <div className="actions">{children}</div>
}

const Action: FC<{
  title: ReactNode
  subTitle?: ReactNode
  action: ReactNode
}> = ({ title, subTitle, action }) => {
  return (
    <>
      <article>
        <main>
          <div className="title">{title}</div>
          {subTitle}
        </main>
        <aside>{action}</aside>
      </article>
    </>
  )
}

ActionsList.Action = Action
