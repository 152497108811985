import { CSSProperties, FC, ReactNode } from 'react'
import cx from 'classnames'
import './Toolbar.scss'

export const Toolbar: FC<{
  children: ReactNode
  alignRight?: boolean
  style?: CSSProperties
}> = ({ children, alignRight = false, style }) => {
  return (
    <div className={cx('toolbar', alignRight && 'right')} style={style}>
      {children}
    </div>
  )
}
