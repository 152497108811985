import { FC, ReactNode } from 'react'
import style from './CheckList.module.scss'
import { Card } from '../Card/Card.tsx'
import cx from 'classnames'
import { IconCheck, TablerIconsProps } from '@tabler/icons-react'
import { FormattedNumber } from 'react-intl'
import { Link } from 'react-router-dom'

export const CheckList: FC<{
  items: {
    completed: boolean
    title: ReactNode
    to?: string
    icon?: (props: TablerIconsProps) => ReactNode
    action?: string
  }[]
}> = ({ items }) => {
  if (items.length === 0) {
    return null
  }

  const completion =
    items.reduce((acc, cur) => acc + Number(cur.completed), 0) / items.length

  return (
    <>
      <div className={style.loadingBar}>
        <div
          style={{
            width: completion * 100 + '%',
          }}
        />
      </div>
      <div className={style.loadingStatus}>
        <FormattedNumber value={completion} style="percent" /> completed
      </div>
      <Card variant="outlined">
        <div className={style.checkList}>
          {items.map(({ icon: Icon = () => null, ...item }, index) =>
            item.to && !item.completed ? (
              <Link key={index} to={item.to} className={style.checkListItem}>
                <div className={style.checkListItemIcon} />
                <div className={style.checkListItemText}>{item.title}</div>
                <div className={style.checkListItemAction}>
                  <Icon size={14} stroke={2} /> {item.action}
                </div>
              </Link>
            ) : (
              <div
                key={index}
                className={cx(style.checkListItem, {
                  [style.checkListItemChecked]: item.completed,
                })}
              >
                <div className={style.checkListItemIcon}>
                  {item.completed ? <IconCheck size={12} stroke={4} /> : null}
                </div>
                <div className={style.checkListItemText}>{item.title}</div>
                <div className={style.checkListItemAction}>
                  <Icon size={14} stroke={2} /> {item.action}
                </div>
              </div>
            )
          )}
        </div>
      </Card>
    </>
  )
}
