import { Outlet, RouteObject, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { gqlClient } from '../../../../auth'
import { graphql } from '../../../../gql'
import { Table } from '../../../../components/component/Table/Table.tsx'
import { DeepRequired } from 'react-hook-form'
import {
  WebhookDeliveriesQuery,
  WebhooksRole,
} from '../../../../gql/graphql.ts'
import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { ShortDate } from '../../../../components/component/ShortDate/ShortDate.tsx'
import style from './Deliveries.module.scss'
import { deliveryRoute, StatusBadge } from './Delivery.tsx'
import emptyInbox from '../../../../assets/empty-state/empty-inbox.svg'
import { IconPlayerPlay } from '@tabler/icons-react'
import { useTestRunWebhook } from '../../../../queries/webhooks.tsx'
import { EmptyStateImage } from '../../../../components/layout/EmptyStateImage/EmptyStateImage.tsx'
import { useCurrentOrgSafe } from '../../../../hooks/useCurrentOrgSafe.ts'

type Row = DeepRequired<WebhookDeliveriesQuery>['webhook']['runs'][0]

const WebhookUrl = ({ row }: { row: Row }) => {
  const { runId } = useParams()

  return (
    <span aria-expanded={row.id === runId ? 'true' : undefined}>
      <em style={{ marginRight: 10 }}>{row.method}</em> {row.url}
    </span>
  )
}

const Page = () => {
  const { webhookId } = useParams()

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['webhook', webhookId, 'deliveries'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query WebhookDeliveries($id: ID!) {
            webhook(id: $id) {
              id
              runs {
                id
                url
                createdAt
                method
                responseStatus
              }
            }
          }
        `),
        { id: webhookId as string }
      ),
  })

  const columns = useMemo<ColumnDef<Row>[]>(
    () => [
      {
        id: 'status',
        accessorFn: (row) => row.responseStatus,
        header: 'Status',
        enableSorting: false,
        cell: ({ row }) => <StatusBadge status={row.original.responseStatus} />,
        meta: {
          flex: '0 0 100px',
        },
        minSize: 100,
      },
      {
        id: 'url',
        accessorFn: (row) => row.url,
        header: 'Url',
        enableSorting: false,
        cell: ({ row }) => <WebhookUrl row={row.original} />,
        meta: {
          flex: '2 1 150px',
        },
      },
      {
        id: 'createdAt',
        accessorFn: (row) => row.createdAt,
        header: 'Date',
        cell: ({ row }) => <ShortDate value={row.original.createdAt} ago />,
        meta: {
          flex: '0 0 180px',
        },
        minSize: 180,
      },
    ],
    []
  )

  const testRunWebhook = useTestRunWebhook()
  const { organization } = useCurrentOrgSafe()

  return (
    <>
      <div className={style.container}>
        <div>
          <Table<Row>
            data={(data?.webhook?.runs as Row[]) ?? []}
            columns={columns}
            loading={isLoading}
            fetching={isFetching}
            rowTo={({ id }) => `./${id}`}
            defaultSorting={[{ id: 'createdAt', desc: true }]}
            noToolbar
            emptyState={
              <EmptyStateImage
                imageSrc={emptyInbox}
                title="No deliveries yet"
                text="No call to this webhook have been made yet, either modify a flag in any of your projects, or trigger a test run."
                ctaIcon={IconPlayerPlay}
                cta={
                  organization.myAggregatedRole.webhooks ===
                    WebhooksRole.Manage && 'Test run'
                }
                ctaOnClick={() => testRunWebhook(webhookId as string)}
              />
            }
          />
        </div>
        <div>
          <Outlet />
        </div>
      </div>
    </>
  )
}

export const deliveriesRoute: RouteObject = {
  path: 'deliveries',
  element: <Page />,
  children: [deliveryRoute],
}
