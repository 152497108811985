import { Link, RouteObject } from 'react-router-dom'
import { CenteredVerticalLayout } from '../../../components/layout/CenteredVerticalLayout/CenteredVerticalLayout.tsx'
import { Button } from '../../../components/component/Button/Button.tsx'
import { IconArrowRight, IconDownload, IconLoader2 } from '@tabler/icons-react'
import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { gqlClient } from '../../../auth'
import { graphql } from '../../../gql'
import { useCurrentOrgSafe } from '../../../hooks/useCurrentOrgSafe.ts'
import { useSilentLoader } from '../../../hooks/useSilentLoader.tsx'
import { useForm } from 'react-hook-form'
import { useFormSubmitMutation } from '../../../hooks/useFormSubmitMutation.ts'
import { InputWrapper } from '../../../components/input/InputWrapper/InputWrapper.tsx'
import { TextInput } from '../../../components/input/TextInput/TextInput.tsx'
import ldToTggl from './launchdarkly-to-tggl.svg'
import { Checkbox } from '../../../components/input/Checkbox/Checkbox.tsx'
import style from './ld.module.scss'
import { Table } from '../../../components/component/Table/Table.tsx'
import { ColumnDef } from '@tanstack/react-table'
import { Badge } from '../../../components/component/Badge/Badge.tsx'
import { LaunchDarklyImportedFlagStatus } from '../../../gql/graphql.ts'
import cx from 'classnames'

type StateProps = {
  setState: (state: {
    current: keyof typeof states
    state: Record<string, any>
  }) => void
  state: Record<string, any>
}

const InitState = ({ setState }: StateProps) => {
  const { organization } = useCurrentOrgSafe()

  const { data, isPending } = useQuery({
    queryKey: ['launchDarkly', organization.id, 'importingFlags'],
    refetchInterval: 2000,
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query getLaunchDarklyImportingFlags($id: ID!) {
            organization(id: $id) {
              launchDarklyIntegration {
                importedFlags {
                  projectName
                  flagName
                  status
                  flag {
                    id
                  }
                  project {
                    id
                    slug
                    name
                  }
                }
              }
            }
          }
        `),
        {
          id: organization.id,
        }
      ),
  })

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'flagName',
        accessorFn: (row) => row.flagName,
        header: 'Flag',
        sortingFn: 'alphanumeric',
        cell: ({ getValue, row }) => (
          <div>
            <div>
              <em>{getValue() as string}</em>
            </div>
            <small>
              {row.original.projectName}{' '}
              <IconArrowRight size={12} style={{ verticalAlign: 'middle' }} />{' '}
              {row.original.project.name}
            </small>
          </div>
        ),
      },
      {
        id: 'status',
        accessorFn: (row) => row.status,
        header: 'Status',
        meta: {
          flex: '0 0 100px',
        },
        cell: ({ getValue }) => {
          if (
            getValue() === LaunchDarklyImportedFlagStatus.Pending ||
            getValue() === LaunchDarklyImportedFlagStatus.Importing
          ) {
            return (
              <IconLoader2 className={cx('spinner')} size={16} stroke={1.5} />
            )
          }
          if (getValue() === LaunchDarklyImportedFlagStatus.Success) {
            return <Badge color="success">Imported</Badge>
          }
          if (getValue() === LaunchDarklyImportedFlagStatus.Failed) {
            return <Badge color="error">Error</Badge>
          }
          return null
        },
      },
    ],
    []
  )

  if (isPending) {
    return null
  }

  if (data?.organization?.launchDarklyIntegration.importedFlags.length) {
    return (
      <CenteredVerticalLayout size="m">
        <h1>Your imported flags</h1>
        <Table
          unit="flags"
          data={data?.organization?.launchDarklyIntegration.importedFlags ?? []}
          columns={columns}
          onCreate={() => setState({ current: 'selectProject', state: {} })}
          createButtonText={'Import more flags'}
          rowTo={(row) =>
            row.flag
              ? `/project/${row.project.slug}/feature-flags/${row.flag.id}`
              : null
          }
        />
      </CenteredVerticalLayout>
    )
  }

  return (
    <CenteredVerticalLayout>
      <h1>Import flags</h1>
      <p>
        Import your flags from LaunchDarkly in one click and get started with
        Tggl
      </p>
      <img
        src={ldToTggl}
        style={{
          width: '70%',
          margin: '24px auto 32px auto',
          display: 'block',
        }}
      />
      <Button
        color="primary"
        icon={IconDownload}
        onClick={() => setState({ current: 'selectProject', state: {} })}
      >
        Import
      </Button>
    </CenteredVerticalLayout>
  )
}

const InputTokenState = ({ setState }: StateProps) => {
  const { organization } = useCurrentOrgSafe()

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      token: '',
    },
  })

  const submit = useFormSubmitMutation({
    mutation: graphql(`
      mutation setLaunchDarklyToken($id: ID!, $token: String!) {
        organization(id: $id) {
          setLaunchDarklyToken(token: $token)
        }
      }
    `),
    control,
    mapVariables: (data) => ({
      id: organization.id,
      token: data.token,
    }),
    onSuccess: () => {
      setState({ current: 'selectProject', state: {} })
    },
  })

  return (
    <CenteredVerticalLayout>
      <form onSubmit={handleSubmit(submit)}>
        <h1>Connect your account</h1>
        <p style={{ marginBottom: 8 }}>
          <Link
            to="https://app.launchdarkly.com/settings/authorization"
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            Go to LaunchDarkly
          </Link>{' '}
          and create a new token to connect your account.
        </p>
        <p style={{ marginBottom: 24 }}>
          <Link
            to="https://tggl.io/help/integrations/launchdarkly#2-get-your-token"
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            Need help?
          </Link>
        </p>
        <InputWrapper
          component={TextInput}
          control={control}
          name="token"
          label="Token"
          rules={{ required: true }}
          className="monospace"
          autoFocus
        />
        <Button color="primary" type="submit" loading={isSubmitting}>
          Connect account
        </Button>
      </form>
    </CenteredVerticalLayout>
  )
}

const SelectProjectState = ({ setState }: StateProps) => {
  const { organization } = useCurrentOrgSafe()

  const { isLoading, data, error } = useQuery({
    queryKey: ['launchDarkly', organization.id, 'projects'],
    retry: false,
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query getLaunchDarklyProjects($id: ID!) {
            organization(id: $id) {
              launchDarklyIntegration {
                projects {
                  key
                  name
                }
              }
            }
          }
        `),
        {
          id: organization.id,
        }
      ),
  })

  const projects = data?.organization?.launchDarklyIntegration?.projects

  useEffect(() => {
    if (error) {
      setState({ current: 'inputToken', state: {} })
    } else {
      if (projects?.length === 1) {
        setState({
          current: 'selectFlags',
          state: {
            projectKey: projects?.[0].key,
            projectName: projects?.[0].name,
          },
        })
      }
    }
  }, [error, setState, projects])

  const silentLoader = useSilentLoader(isLoading)

  if (silentLoader) {
    return null
  }

  if (isLoading) {
    return <CenteredVerticalLayout.Loader />
  }

  if (error) {
    return null
  }

  return (
    <CenteredVerticalLayout>
      <h1>From which project?</h1>

      <p style={{ marginBottom: 24 }}>
        Select the project from which you wish to import your flags
      </p>
      <div className={style.buttonsList}>
        {projects?.map(({ name, key }) => (
          <Button
            key={key}
            icon={IconArrowRight}
            onClick={() =>
              setState({
                current: 'selectFlags',
                state: { projectKey: key, projectName: name },
              })
            }
          >
            {name}
          </Button>
        ))}
      </div>
    </CenteredVerticalLayout>
  )
}
const SelectProjectTgglState = ({ setState, state }: StateProps) => {
  const { organization } = useCurrentOrgSafe()

  useEffect(() => {
    if (organization.projects.length === 1) {
      setState({
        current: 'importFlags',
        state: {
          ...state,
          toProjectId: organization.projects[0].id,
        },
      })
    }
  }, [setState, organization, state])

  return (
    <CenteredVerticalLayout>
      <h1>Where to import flags?</h1>

      <p style={{ marginBottom: 24 }}>
        Select the project you wish to import your flags to
      </p>
      <div className={style.buttonsList}>
        {organization.projects.map(({ name, id }) => (
          <Button
            key={id}
            icon={IconArrowRight}
            onClick={() =>
              setState({
                current: 'importFlags',
                state: {
                  ...state,
                  toProjectId: id,
                },
              })
            }
          >
            {name}
          </Button>
        ))}
      </div>
    </CenteredVerticalLayout>
  )
}

const SelectFlagsState = ({ setState, state }: StateProps) => {
  const { organization } = useCurrentOrgSafe()

  const { isLoading, data, error } = useQuery({
    queryKey: ['launchDarkly', organization.id, 'flags', state.project],
    retry: false,
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query getLaunchDarklyFlags($id: ID!, $projectKey: String!) {
            organization(id: $id) {
              launchDarklyIntegration {
                flags(projectKey: $projectKey) {
                  name
                  key
                }
              }
            }
          }
        `),
        {
          id: organization.id,
          projectKey: state.projectKey,
        }
      ),
  })

  const [excludedFlags, setExcludedFlags] = useState<Record<string, boolean>>(
    {}
  )

  useEffect(() => {
    if (error) {
      setState({ current: 'inputToken', state: {} })
    }
  }, [error, setState])

  const silentLoader = useSilentLoader(isLoading)

  if (silentLoader) {
    return null
  }

  if (isLoading) {
    return <CenteredVerticalLayout.Loader />
  }

  if (error) {
    return null
  }

  const flags = data?.organization?.launchDarklyIntegration?.flags

  const selectedFlags = flags?.filter(({ key }) => !excludedFlags[key]) ?? []

  return (
    <CenteredVerticalLayout>
      <h1>Which flags to import?</h1>
      <p style={{ marginBottom: 24 }}>
        Choose the flags you wish to import into Tggl
      </p>
      <div className={style.checkboxList}>
        <div
          className={style.checkboxItem}
          onClick={() =>
            setExcludedFlags(
              selectedFlags.length === flags?.length
                ? flags?.reduce((acc, { key }) => ({ ...acc, [key]: true }), {})
                : {}
            )
          }
        >
          <Checkbox
            value={selectedFlags.length === flags?.length}
            indeterminate={
              selectedFlags.length !== flags?.length && selectedFlags.length > 0
            }
            onChange={() => null}
            style={{ pointerEvents: 'none' }}
          />
          <b>All flags</b>
        </div>
        {flags?.map(({ name, key }) => (
          <div
            className={style.checkboxItem}
            onClick={() =>
              setExcludedFlags((prev) => {
                if (prev[key]) {
                  const { [key]: _, ...rest } = prev
                  return rest
                }
                return { ...prev, [key]: true }
              })
            }
          >
            <Checkbox
              value={!excludedFlags[key]}
              onChange={() => null}
              style={{ pointerEvents: 'none' }}
            />
            {name}
          </div>
        ))}
      </div>
      <Button
        color="primary"
        type="submit"
        disabled={selectedFlags.length === 0}
        onClick={() =>
          setState({
            current: 'selectTgglProject',
            state: {
              ...state,
              flags: selectedFlags,
            },
          })
        }
      >
        Import flags ({selectedFlags.length})
      </Button>
    </CenteredVerticalLayout>
  )
}

const ImportFlagsState = ({ setState, state }: StateProps) => {
  const { organization } = useCurrentOrgSafe()

  const { mutateAsync } = useMutation({
    mutationFn: () =>
      gqlClient.request(
        graphql(`
          mutation importLaunchDarklyFlags(
            $id: ID!
            $projectKey: String!
            $flags: [LDFlagInput!]!
            $toProjectId: ID!
            $projectName: String!
          ) {
            organization(id: $id) {
              importLaunchDarklyFlags(
                projectKey: $projectKey
                flags: $flags
                projectId: $toProjectId
                projectName: $projectName
              )
            }
          }
        `),
        {
          id: organization.id,
          projectKey: state.projectKey,
          flags: state.flags,
          toProjectId: state.toProjectId,
          projectName: state.projectName,
        }
      ),
  })

  useEffect(() => {
    mutateAsync().then(() => setState({ current: 'init', state: {} }))
  }, [mutateAsync, setState])

  return <CenteredVerticalLayout.Loader />
}

const states = {
  init: InitState,
  selectProject: SelectProjectState,
  selectTgglProject: SelectProjectTgglState,
  inputToken: InputTokenState,
  selectFlags: SelectFlagsState,
  importFlags: ImportFlagsState,
}

const Page = () => {
  const [state, setState] = useState<{
    current: keyof typeof states
    state: Record<string, any>
  }>({ current: 'init', state: {} })

  const State = states[state.current]

  return <State setState={setState} state={state.state} />
}

export const importFlagsRoute: RouteObject = {
  path: '/import-flags',
  element: <Page />,
}
