import { FC, ReactNode } from 'react'
import './CheckablePill.scss'
import { IconCheck } from '@tabler/icons-react'
import { motion } from 'framer-motion'
import cx from 'classnames'

const MotionIconCheck = motion(IconCheck)

export const CheckablePill: FC<{ label: string; checked?: boolean }> & {
  List: typeof List
} = ({ checked, label }) => {
  return (
    <div className={cx('checkable-pill', checked && 'checked')}>
      <MotionIconCheck
        animate={{ scale: checked ? 1 : 0 }}
        initial={{ scale: checked ? 1 : 0 }}
        size={16}
        stroke={2.5}
      />
      <motion.div
        animate={{ x: checked ? 0 : -7 }}
        initial={{ x: checked ? 0 : -7 }}
      >
        {label}
      </motion.div>
    </div>
  )
}

const List: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="checkable-pill-list">{children}</div>
}

CheckablePill.List = List
