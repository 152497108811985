import { forwardRef, useEffect, useState } from 'react'
import { MultiSelectInput } from '../SelectInput/SelectInput.tsx'
import { useCurrentOrgSafe } from '../../../hooks/useCurrentOrgSafe.ts'
import { IconLockOpen } from '@tabler/icons-react'
import './SoftRolesSelector.scss'

export const SoftRolesSelector = forwardRef<
  HTMLInputElement,
  {
    value: string[]
    onChange: (value: string[]) => void
    flagExists?: boolean
  }
>(({ value, onChange, flagExists, ...rest }, ref) => {
  const { organization } = useCurrentOrgSafe()
  const [showWidget, setShowWidget] = useState(value.length !== 0)

  useEffect(() => {
    if (value.length && !showWidget) {
      setShowWidget(true)
    }
  }, [showWidget, value.length])

  if (!showWidget) {
    return (
      <div className="soft-roles-selector-container">
        <IconLockOpen size={16} />
        {flagExists ? 'Shared with everyone' : 'Will be shared with everyone'}
        <button
          onClick={() => setShowWidget(true)}
          className="restrict-access-button"
          type="button"
        >
          Restrict access
        </button>
      </div>
    )
  }

  return (
    <MultiSelectInput
      ref={ref}
      {...rest}
      value={value}
      onChange={onChange}
      options={organization.roles.map((role) => ({
        label: role.name,
        value: role.id,
      }))}
    />
  )
})
