import React, {
  CSSProperties,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FormattedTextInput } from '../TextInput/TextInput.tsx'
import './PercentageRangeInput.scss'
import cx from 'classnames'
import { Tooltip } from '../../component/Tooltip/Tooltip.tsx'
import { FormattedNumber } from 'react-intl'
import { IconInfoSquareRoundedFilled } from '@tabler/icons-react'

const TEN_TO_THE_12 = 1000000000000
const TEN_TO_THE_10 = 10000000000

type Range = { start: number; end: number }

const clamp = (value: number, min = 0, max = 1) =>
  Math.min(Math.max(value, min), max)

const roundTo = (value: number, closest = 0.01) =>
  Math.round(value / closest) * closest

export const PercentageRangeInput = forwardRef<
  unknown,
  { value: Range; onChange: (value: Range) => void }
>(({ value, onChange }, ref) => {
  const dragRef = useRef({
    clickedAt: 0,
    left: 0,
    right: 0,
    dragging: false,
    mode: 'move' as 'move' | 'start' | 'end',
    start: 0,
    end: 0,
  })
  const trackRef = useRef<HTMLDivElement>(null)
  const [grabbing, setGrabbing] = useState(false)

  const startGrabbing = (event: React.MouseEvent) => {
    if (!trackRef.current) return
    event.preventDefault()
    // @ts-ignore
    document.activeElement?.blur()

    const rect = trackRef.current.getBoundingClientRect()

    const clickedAt = clamp(
      (event.pageX - rect.left) / (rect.right - rect.left)
    )
    const data = {
      start: value.start,
      end: value.end,
      clickedAt,
      left: rect.left,
      right: rect.right,
      dragging: true,
      mode: 'move',
    }

    if (event.currentTarget.classList.contains('drag-handle')) {
      dragRef.current = {
        ...data,
        mode: 'move',
      }
    } else {
      const dragStart = clickedAt < (value.start + value.end) / 2
      dragRef.current = {
        ...data,
        mode: dragStart ? 'start' : 'end',
      }
      onChange({
        start: roundTo(dragStart ? clickedAt : value.start),
        end: roundTo(dragStart ? value.end : clickedAt),
      })
    }

    setGrabbing(true)
  }

  useEffect(() => {
    const handleEvent = (event: MouseEvent) => {
      if (dragRef.current.dragging) {
        const { clickedAt, left, right, mode, start, end } = dragRef.current
        const { pageX } = event
        const percentage = clamp((pageX - left) / (right - left))
        const r = event.shiftKey ? 0.05 : 0.01

        if (mode === 'move') {
          let delta = percentage - clickedAt
          if (delta > 0) {
            delta = Math.min(delta, 1 - end)
          } else {
            delta = Math.max(delta, -start)
          }
          onChange({
            start: roundTo(start + delta, r),
            end: roundTo(end + delta, r),
          })
        } else if (mode === 'start') {
          onChange({
            start: roundTo(percentage, r),
            end: roundTo(Math.max(percentage, end), r),
          })
        } else if (mode === 'end') {
          onChange({
            start: roundTo(Math.min(percentage, start), r),
            end: roundTo(percentage, r),
          })
        }
      }
    }

    const handleMouseUp = () => {
      dragRef.current.dragging = false
      setGrabbing(false)
    }

    document.addEventListener('mousemove', handleEvent)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousemove', handleEvent)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [onChange])

  return (
    <div className="percentage-container">
      <div className="range">
        <div className="track-container">
          <div className="track" ref={trackRef}>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
          <div
            className={cx(
              'track main',
              grabbing && dragRef.current.mode === 'move' && 'grabbing'
            )}
            style={
              { ['--from']: value.start, ['--to']: value.end } as CSSProperties
            }
          >
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
          <div className="click-handle" onMouseDown={startGrabbing} />
          <div
            className="drag-handle"
            style={
              { ['--from']: value.start, ['--to']: value.end } as CSSProperties
            }
            onMouseDown={startGrabbing}
          />
          <Tooltip
            forceShow={grabbing && dragRef.current.mode !== 'end'}
            showArrow={false}
          >
            <Tooltip.Trigger
              component="div"
              className={cx(
                'indicator',
                grabbing && dragRef.current.mode === 'start' && 'grabbing'
              )}
              style={{ ['--at']: value.start } as CSSProperties}
              onMouseDown={startGrabbing}
            />
            <Tooltip.Content>
              <FormattedNumber value={value.start} style="percent" />
            </Tooltip.Content>
          </Tooltip>
          <Tooltip
            forceShow={grabbing && dragRef.current.mode !== 'start'}
            showArrow={false}
          >
            <Tooltip.Trigger
              component="div"
              className={cx(
                'indicator',
                grabbing && dragRef.current.mode === 'end' && 'grabbing',
                grabbing && dragRef.current.mode === 'start' && 'pass-through'
              )}
              style={{ ['--at']: value.end } as CSSProperties}
              onMouseDown={startGrabbing}
            />
            <Tooltip.Content>
              <FormattedNumber value={value.end} style="percent" />
            </Tooltip.Content>
          </Tooltip>
        </div>
        <div className="tooltip">
          <IconInfoSquareRoundedFilled size={18} />
          <span>
            Hold <code>Shift</code> to snap
          </span>
        </div>
      </div>
      <FormattedTextInput
        value={value.end - value.start}
        onChange={(diff) => {
          const newEnd = Math.min(1, value.start + diff)
          const newStart = newEnd - diff
          onChange({
            start: roundTo(newStart),
            end: roundTo(newEnd),
          })
        }}
        prefix={<span style={{ transform: 'translateX(8px)' }}>%</span>}
        parseUserInput={(val) => {
          const number = parseFloat(val)
          return !isNaN(number)
            ? Math.max(0, Math.min(100, Math.round(number))) / 100
            : value.end - value.start
        }}
        formatBlurredInput={(value) =>
          typeof value === 'number' && !isNaN(value)
            ? String(Math.round(value * TEN_TO_THE_12) / TEN_TO_THE_10)
            : ''
        }
        formatInputOnFocus={(value) =>
          typeof value === 'number' && !isNaN(value)
            ? String(Math.round(value * TEN_TO_THE_12) / TEN_TO_THE_10)
            : ''
        }
        // @ts-ignore
        ref={ref}
        style={{ width: 30 }}
      />
    </div>
  )
})
