import { RouteObject, useNavigate } from 'react-router-dom'
import { TokenStatus, useQueryParamToken } from '../hooks/useQueryParamToken.ts'
import { useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import { gqlClient, setToken } from '../auth'
import { graphql } from '../gql'
import { toast } from 'react-hot-toast'
import { useSilentLoader } from '../hooks/useSilentLoader.tsx'
import { CenteredVerticalLayout } from '../components/layout/CenteredVerticalLayout/CenteredVerticalLayout.tsx'
import { SendEmailButton } from '../components/component/SendEmailButton/SendEmailButton.tsx'
import { EmptyStateImage } from '../components/layout/EmptyStateImage/EmptyStateImage.tsx'
import somethingWentWrong from '../assets/empty-state/something-went-wrong.svg'
import { IconArrowLeft } from '@tabler/icons-react'
import { verifyEmailRequestQuery } from '../queries/auth.ts'

const Page = () => {
  const navigate = useNavigate()
  const token = useQueryParamToken()
  const { mutateAsync, isPending, isError } = useMutation({
    mutationFn: (t: string) =>
      gqlClient.request(
        graphql(`
          mutation verifyEmail($token: String!) {
            verifyEmail(token: $token)
          }
        `),
        { token: t }
      ),
  })

  useEffect(() => {
    if (token.status === TokenStatus.OK) {
      mutateAsync(token.token)
        .then((data) => {
          if (data) {
            setToken(data.verifyEmail)
            toast.success('Email verified')
            navigate('/')
          }
        })
        .catch(() => null)
    }
  }, [navigate, token.status, token.token, mutateAsync])

  const silentLoader = useSilentLoader(isPending)

  if (silentLoader) {
    return null
  }

  if (isPending) {
    return (
      <CenteredVerticalLayout>
        <CenteredVerticalLayout.Loader />
      </CenteredVerticalLayout>
    )
  }

  if (token.status === TokenStatus.EXPIRED) {
    return (
      <CenteredVerticalLayout center>
        <h1>Link expired</h1>
        <p>
          Email verification links have a limited lifespan for security
          purposes. You can send a new link to <b>{token.decoded.email}</b>.
        </p>
        <SendEmailButton
          mutation={verifyEmailRequestQuery}
          variables={{ email: token.decoded.email }}
        >
          Send new link
        </SendEmailButton>
      </CenteredVerticalLayout>
    )
  }

  if (isError) {
    return (
      <EmptyStateImage
        imageSrc={somethingWentWrong}
        title="Could not verify email"
        text="Link is probably invalid. Did you copy the link correctly?"
        cta="Back"
        ctaIcon={IconArrowLeft}
        ctaTo="/login"
      />
    )
  }

  return null
}

export const verifyEmailRoute: RouteObject = {
  path: '/verify-email',
  element: <Page />,
}
