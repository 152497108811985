import { RouteObject, useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useFormDefaultValue } from '../../../../../hooks/useFormDefaultValue.ts'
import { graphql } from '../../../../../gql'
import { Sections } from '../../../../../components/component/Sections/Sections.tsx'
import { InputWrapper } from '../../../../../components/input/InputWrapper/InputWrapper.tsx'
import { TextInput } from '../../../../../components/input/TextInput/TextInput.tsx'
import { FormSubmitButtons } from '../../../../../components/component/FormSubmitButtons/FormSubmitButtons.tsx'
import { useFormSubmitMutation } from '../../../../../hooks/useFormSubmitMutation.ts'
import { toast } from 'react-hot-toast'
import { LongTextInput } from '../../../../../components/input/LongTextInput/LongTextInput.tsx'
import { Columns } from '../../../../../components/component/Columns/Columns.tsx'
import {
  confirm,
  openModal,
} from '../../../../../components/component/Modal/Modal.tsx'
import { Button } from '../../../../../components/component/Button/Button.tsx'
import {
  IconArchive,
  IconCopy,
  IconRestore,
  IconTrash,
} from '@tabler/icons-react'
import { ActionsList } from '../../../../../components/component/ActionsList/ActionsList.tsx'
import {
  useArchiveFlag,
  useDeleteFlag,
  useUnArchiveFlag,
} from '../../../../../queries/flags'
import { SelectInput } from '../../../../../components/input/SelectInput/SelectInput.tsx'
import { useCurrentOrgSafe } from '../../../../../hooks/useCurrentOrgSafe.ts'
import { useQueryClient } from '@tanstack/react-query'
import { setCurrentOrg } from '../../../../../auth'
import { SoftRolesSelector } from '../../../../../components/input/SoftRolesSelector/SoftRolesSelector.tsx'
import { TagsInput } from '../../../../../components/input/TagsInput/TagsInput.tsx'
import {
  ManageFlagsRole,
  TechnicalExpertiseRole,
} from '../../../../../gql/graphql.ts'
import { SwitchInput } from '../../../../../components/input/SwitchInput/SwitchInput.tsx'

const DuplicateFlagForm = ({ id }: { id: string }) => {
  const { organization, project } = useCurrentOrgSafe()

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, isDirty },
  } = useForm({
    defaultValues: {
      projectId: project.id,
      slug: '',
      name: '',
    },
  })

  const { isLoading } = useFormDefaultValue({
    control,
    queryKey: ['flag', id, 'copy flag default value'],
    request: graphql(`
      query copyFlagData($id: ID!) {
        flag(id: $id) {
          id
          name
          slug
          project {
            id
          }
        }
      }
    `),
    variables: { id: id },
    skip: !id,
    mapDefaultValues: (data) => ({
      name: data.flag?.name ?? '',
      slug: data.flag?.slug ?? '',
      projectId: data.flag?.project.id ?? '',
    }),
  })

  const queryclient = useQueryClient()
  const navigate = useNavigate()

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation duplicateFlag($id: ID!, $data: DuplicateFlagData!) {
        flag(id: $id) {
          duplicate(data: $data) {
            id
            project {
              id
              slug
            }
          }
        }
      }
    `),
    mapVariables: (data) => ({
      id,
      data: {
        slug: data.slug,
        name: data.name,
        projectId: data.projectId,
      },
    }),
    onSuccess: (data, { projectId }) => {
      queryclient.invalidateQueries({ queryKey: ['flags', projectId] })
      navigate(
        `/project/${data.flag?.duplicate.project.slug}/feature-flags/${data.flag?.duplicate.id}`
      )
      setCurrentOrg(organization.id, data.flag?.duplicate.project.id ?? '')
      toast.success('Flag duplicated')
    },
  })

  return (
    <form
      onSubmit={handleSubmit(submit)}
      style={{ opacity: isLoading ? 0.5 : 1 }}
    >
      <InputWrapper
        control={control}
        name="projectId"
        // @ts-ignore
        component={SelectInput}
        label="Project"
        options={organization.projects.map((project) => ({
          label: project.name,
          value: project.id,
        }))}
      />
      <InputWrapper
        control={control}
        name="name"
        component={TextInput}
        label="Name"
        rules={{ required: true }}
      />
      <InputWrapper
        control={control}
        name="slug"
        tooltip="A developer friendly name to use inside your code"
        className="monospace"
        component={TextInput}
        label="Key"
        rules={{ required: true }}
      />
      <FormSubmitButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        reset={reset}
        labelSubmit="Duplicate"
      />
    </form>
  )
}

export const duplicateFlag = (id: string) => {
  openModal({
    title: 'Duplicate Flag',
    content: <DuplicateFlagForm id={id} />,
  })
}

const Page = () => {
  const { flagId } = useParams()

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, dirtyFields },
  } = useForm({
    defaultValues: {
      name: '',
      slug: '',
      roleIds: [] as string[],
      tagIds: [] as string[],
      description: '',
    },
  })

  const { data } = useFormDefaultValue({
    control,
    queryKey: ['flag', flagId, 'information'],
    request: graphql(`
      query flagInformationForUpdate($id: ID!) {
        flag(id: $id) {
          id
          name
          slug
          description
          archived
          roles {
            id
          }
          tags {
            id
          }
        }
      }
    `),
    variables: { id: flagId as string },
    mapDefaultValues: (data) => ({
      name: data.flag?.name ?? '',
      slug: data.flag?.slug ?? '',
      roleIds: data.flag?.roles.map(({ id }) => id) ?? [],
      tagIds: data.flag?.tags.map(({ id }) => id) ?? [],
      description: data.flag?.description ?? '',
    }),
  })

  const archiveFlag = useArchiveFlag()
  const unArchiveFlag = useUnArchiveFlag()
  const deleteFlag = useDeleteFlag()
  const queryClient = useQueryClient()

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation updateFlagInformation($id: ID!, $data: UpdateFlagData!) {
        flag(id: $id) {
          updateInformation(data: $data) {
            id
            name
            description
            roles {
              id
            }
            tags {
              id
            }
          }
        }
      }
    `),
    mapVariables: (data) => ({
      id: flagId as string,
      data,
    }),
    onSuccess: (_, information) => {
      toast.success('Flag updated')
      queryClient.invalidateQueries({ queryKey: ['flag', flagId] })
      reset({ ...information })
    },
    onStart: async () => {
      if (dirtyFields.slug) {
        await confirm({
          description: (
            <>
              <p>
                You are about to change the key of this flag. The key is used
                inside the code to reference it. This change takes effect
                immediately and will break any code that references the old key.
              </p>
              <p>
                A safer way might be to duplicate this flag, change and deploy
                your code to use the new key, and only then delete the old flag.
              </p>
            </>
          ),
          confirmText: 'Update key now',
          confirmProps: {
            color: 'error',
          },
          defaultButton: 'cancel',
        })
      }
    },
  })

  const { organization } = useCurrentOrgSafe()

  return (
    <Sections>
      <Sections.Section title="Information">
        <form onSubmit={handleSubmit(submit)}>
          <Columns>
            <InputWrapper
              label="Name"
              name="name"
              component={TextInput}
              control={control}
            />
            <InputWrapper
              label="Key"
              name="slug"
              tooltip="The key is used inside the code to reference this flag. Changing it is usually not a good idea."
              component={TextInput}
              control={control}
              disabled={
                organization.myAggregatedRole.technicalExpertise ===
                TechnicalExpertiseRole.NonTechnical
              }
              className="monospace"
            />
          </Columns>
          <Columns>
            <InputWrapper
              control={control}
              name="roleIds"
              label="Restricted to"
              // @ts-ignore
              component={SoftRolesSelector}
              rules={{ validate: () => true }}
              flagExists
            />
            <InputWrapper
              control={control}
              name="tagIds"
              label="Tags"
              component={TagsInput}
            />
          </Columns>
          <InputWrapper
            label="Description"
            name="description"
            component={LongTextInput}
            control={control}
          />
          <FormSubmitButtons
            isDirty={isDirty}
            isSubmitting={isSubmitting}
            reset={reset}
            labelSubmit="Save"
          />
        </form>
      </Sections.Section>
      <hr />
      <Sections.Section title="Lifecycle">
        <LifecycleSection />
      </Sections.Section>
      {organization.myAggregatedRole.manageFlags !== ManageFlagsRole.None && (
        <>
          <hr />
          <Sections.Section
            title={data?.flag?.archived === true ? 'Danger zone' : 'Actions'}
          >
            <ActionsList>
              {data?.flag?.archived === false && (
                <ActionsList.Action
                  title="Make a copy"
                  subTitle={
                    <p>Copy this flag with all its settings to any project.</p>
                  }
                  action={
                    <Button
                      inline
                      icon={IconCopy}
                      onClick={() => duplicateFlag(flagId as string)}
                    >
                      Duplicate
                    </Button>
                  }
                />
              )}
              {data?.flag?.archived === false && (
                <ActionsList.Action
                  title="Archive flag"
                  subTitle={
                    <p>
                      The flag will immediately be considered in-active by your
                      app. Can be un-archived or permanently deleted later.
                    </p>
                  }
                  action={
                    <Button
                      color="error"
                      inline
                      icon={IconArchive}
                      onClick={() => archiveFlag(flagId as string)}
                    >
                      Archive
                    </Button>
                  }
                />
              )}
              {data?.flag?.archived === true && (
                <>
                  <ActionsList.Action
                    title="Un-archive flag"
                    subTitle={
                      <p>
                        Your app will immediately see your flag as active again.
                      </p>
                    }
                    action={
                      <Button
                        color="error"
                        inline
                        icon={IconRestore}
                        onClick={() => unArchiveFlag(flagId as string)}
                      >
                        Un-archive
                      </Button>
                    }
                  />
                  <ActionsList.Action
                    title="Permanently delete flag"
                    subTitle={
                      <p>
                        You will not be able to recover this flag. Since your
                        app already does not see it, nothing will change.
                      </p>
                    }
                    action={
                      <Button
                        color="error"
                        inline
                        icon={IconTrash}
                        onClick={() => deleteFlag(flagId as string)}
                      >
                        Delete
                      </Button>
                    }
                  />
                </>
              )}
            </ActionsList>
          </Sections.Section>
        </>
      )}
    </Sections>
  )
}

const LifecycleSection = () => {
  const { flagId } = useParams()

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm({
    defaultValues: {
      ownerId: null as string | null,
      permanent: false,
    },
  })

  const { organization } = useCurrentOrgSafe()

  const { data } = useFormDefaultValue({
    control,
    queryKey: ['flag', flagId, 'lifecycleDefaultValue'],
    request: graphql(`
      query flagLifecycleForUpdate($id: ID!, $organizationId: ID!) {
        flag(id: $id) {
          id
          owner {
            id
          }
          permanent
        }
        organization(id: $organizationId) {
          members {
            __typename
            ... on OrganizationMember {
              user {
                id
                name
                email
              }
            }
          }
        }
      }
    `),
    variables: { id: flagId as string, organizationId: organization.id },
    mapDefaultValues: (data) => ({
      ownerId: data.flag?.owner?.id ?? null,
      permanent: data.flag?.permanent ?? false,
    }),
  })

  const queryClient = useQueryClient()

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation updateFlagLifecycle($id: ID!, $data: UpdateFlagLifecycle!) {
        flag(id: $id) {
          updateLifecycle(data: $data) {
            id
          }
        }
      }
    `),
    mapVariables: (data) => ({
      id: flagId as string,
      data,
    }),
    onSuccess: (_, information) => {
      toast.success('Flag updated')
      queryClient.invalidateQueries({ queryKey: ['flag', flagId] })
      reset({ ...information })
    },
  })

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Columns>
        <InputWrapper
          label="Owner"
          name="ownerId"
          tooltip="The person you should refer to regarding this flag"
          //@ts-ignore
          component={SelectInput}
          options={
            data?.organization?.members
              .map((member) =>
                member.__typename === 'OrganizationMember'
                  ? {
                      value: member.user.id,
                      label: member.user.name ?? member.user.email,
                    }
                  : null
              )
              .filter(Boolean) ?? []
          }
          control={control}
        />
        <InputWrapper
          label="Permanent"
          name="permanent"
          tooltip="A flag marked as permanent will not trigger any alert if it serves the same variation for all users. You should enable this option for long lasting flags such as kill switches or remote config."
          component={SwitchInput}
          control={control}
        />
      </Columns>
      <FormSubmitButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        reset={reset}
        labelSubmit="Save"
      />
    </form>
  )
}

export const settingsRoute: RouteObject = {
  path: 'settings',
  element: <Page />,
}
