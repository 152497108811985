import { Outlet, RouteObject } from 'react-router-dom'
import { flagsRoute, NewFlagForm } from './flags/Flags.tsx'
import { contextRoute } from './context/Context.tsx'
import { apiKeysRoute } from './ApiKeys.tsx'
import style from './Overview.module.scss'
import { Card } from '../../../components/component/Card/Card.tsx'
import { DailyRequests } from '../settings/Usage/Usage.tsx'
import { useCurrentOrgSafe } from '../../../hooks/useCurrentOrgSafe.ts'
import { useQuery } from '@tanstack/react-query'
import { gqlClient, useUser } from '../../../auth'
import { graphql } from '../../../gql'
import { Table } from '../../../components/component/Table/Table.tsx'
import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import {
  LastFlagsUpdateQuery,
  OrganizationStatus,
  SlackIntegrationStatus,
} from '../../../gql/graphql.ts'
import { DeepRequired } from 'react-hook-form'
import { ShortDate } from '../../../components/component/ShortDate/ShortDate.tsx'
import Balancer from 'react-wrap-balancer'
import { Button } from '../../../components/component/Button/Button.tsx'
import requestsStyle from '../settings/Usage/Usage.module.scss'
import launchImage from '../../../assets/empty-state/launch.svg'
import { openModal } from '../../../components/component/Modal/Modal.tsx'
import { IconArrowRight } from '@tabler/icons-react'
import { CheckList } from '../../../components/component/CheckList/CheckList.tsx'

const Project = () => {
  return <Outlet />
}

type Row = DeepRequired<LastFlagsUpdateQuery>['project']['lastUpdatedFlags'][0]

const Overview = () => {
  const { project, organization } = useCurrentOrgSafe()
  const user = useUser()

  const { data, isLoading } = useQuery({
    queryKey: ['project', project.id, 'last flags update'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query lastFlagsUpdate($projectId: ID!) {
            project(id: $projectId) {
              lastUpdatedFlags {
                id
                createdBy {
                  id
                  name
                }
                createdAt
                flag {
                  id
                  name
                }
              }
            }
          }
        `),
        { projectId: project.id }
      ),
  })

  const { data: organizationData } = useQuery({
    queryKey: ['organization', organization.id, 'checklist'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query orgChecklist($organizationId: ID!) {
            organization(id: $organizationId) {
              slackIntegration {
                status
              }
              membersLimit {
                current
              }
              flagsLimit {
                current
              }
              requestsLimit {
                current
              }
            }
          }
        `),
        { organizationId: organization.id }
      ),
    enabled:
      organization.status === OrganizationStatus.Trial &&
      !organization.hideTrial,
  })

  const columns = useMemo<ColumnDef<Row>[]>(
    () => [
      {
        id: 'name',
        accessorFn: (row) => row.flag.name,
        header: 'Flag changed',
        sortingFn: 'alphanumeric',
        cell: ({ getValue }) => <em>{getValue() as string}</em>,
      },
      {
        id: 'author',
        accessorFn: (row) => row.createdBy.name,
        header: 'Author',
        sortingFn: 'alphanumeric',
      },
      {
        id: 'date',
        accessorFn: (row) => row.createdAt,
        header: 'Date',
        cell: ({ getValue }) => <ShortDate value={getValue() as number} ago />,
        sortingFn: 'alphanumeric',
      },
    ],
    []
  )

  if (
    organization.status === OrganizationStatus.Trial &&
    !organization.hideTrial
  ) {
    return (
      <div className={style.onboardingContainer}>
        <h1>Get started with Tggl</h1>
        <h2>Hello {user?.firstName} 👋</h2>
        <p>
          We want you to get the most out of your Tggl trial, here is a quick
          onboarding checklist!
        </p>
        <CheckList
          items={
            !user?.role || user.role === 'developer'
              ? [
                  { completed: true, title: 'Create organization' },
                  {
                    completed:
                      (organizationData?.organization?.requestsLimit.current ??
                        1) > 0,
                    title: 'Install the SDK',
                    to: `/project/${project.slug}/api-keys`,
                    icon: IconArrowRight,
                    action: 'Install',
                  },
                  {
                    completed:
                      (organizationData?.organization?.flagsLimit.current ??
                        1) > 0,
                    title: 'Create your first flag',
                    to: `/project/${project.slug}/feature-flags`,
                    icon: IconArrowRight,
                    action: 'Create',
                  },
                  {
                    completed:
                      (organizationData?.organization?.membersLimit.current ??
                        1) > 1,
                    title: 'Invite your team mates',
                    to: '/settings/team',
                    icon: IconArrowRight,
                    action: 'Invite',
                  },
                  {
                    completed:
                      organizationData?.organization?.slackIntegration
                        .status === SlackIntegrationStatus.Enabled,
                    title: 'Link your Slack account',
                    to: '/settings/integrations',
                    icon: IconArrowRight,
                    action: 'Add to Slack',
                  },
                  {
                    completed: false,
                    title: 'Choose a plan',
                    to: '/settings/billing',
                    icon: IconArrowRight,
                    action: 'Billing',
                  },
                ]
              : [
                  { completed: true, title: 'Create organization' },
                  {
                    completed:
                      (organizationData?.organization?.flagsLimit.current ??
                        1) > 0,
                    title: 'Create your first flag',
                    to: `/project/${project.slug}/feature-flags`,
                    icon: IconArrowRight,
                    action: 'Create',
                  },
                  {
                    completed:
                      (organizationData?.organization?.membersLimit.current ??
                        1) > 1,
                    title: 'Invite someone from your tech team',
                    to: '/settings/team',
                    icon: IconArrowRight,
                    action: 'Invite',
                  },
                  {
                    completed:
                      organizationData?.organization?.slackIntegration
                        .status === SlackIntegrationStatus.Enabled,
                    title: 'Link your Slack account',
                    to: '/settings/integrations',
                    icon: IconArrowRight,
                    action: 'Add to Slack',
                  },
                  {
                    completed: false,
                    title: 'Choose a plan',
                    to: '/settings/billing',
                    icon: IconArrowRight,
                    action: 'Billing',
                  },
                ]
          }
        />
      </div>
    )
  }

  return (
    <>
      <h1>Overview</h1>
      <div className={style.container}>
        <div>
          <Table<Row>
            unit="flags"
            noToolbar
            loading={isLoading}
            rowTo={({ flag, id }) =>
              `/project/${project.slug}/feature-flags/${flag.id}/history/${id}`
            }
            data={(data?.project?.lastUpdatedFlags as Row[]) ?? []}
            columns={columns}
            emptyState={
              <Card variant="outlined">
                <h2>Feature flags</h2>
                <div className={requestsStyle.requestsEmptyState}>
                  <img src={launchImage} />
                  <p>
                    <Balancer>
                      You have no flags yet. You can create your first flag and
                      start experimenting with it!
                    </Balancer>
                  </p>
                  <Button
                    color="primary"
                    inline
                    onClick={() =>
                      openModal({
                        title: 'New flag',
                        content: <NewFlagForm />,
                      })
                    }
                  >
                    Create my first flag
                  </Button>
                </div>
              </Card>
            }
          />
        </div>
        <DailyRequests projectId={project.id} />
      </div>
    </>
  )
}

export const projectRoute: RouteObject = {
  path: 'project/:projectSlug',
  element: <Project />,
  children: [
    flagsRoute,
    contextRoute,
    apiKeysRoute,
    {
      index: true,
      element: <Overview />,
    },
  ],
}
