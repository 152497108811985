import {
  Link,
  Navigate,
  Outlet,
  RouteObject,
  useParams,
} from 'react-router-dom'
import { useCurrentOrgSafe } from '../../../../../hooks/useCurrentOrgSafe.ts'
import { Tabs } from '../../../../../components/component/Tabs/Tabs.tsx'
import { settingsRoute } from './Settings.tsx'
import { conditionsRoute } from './Conditions.tsx'
import { variationsRoute } from './Variations.tsx'
import { historyRoute } from './History.tsx'
import { IconArrowLeft } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
import { gqlClient } from '../../../../../auth'
import { graphql } from '../../../../../gql'
import { Badge } from '../../../../../components/component/Badge/Badge.tsx'
import { Tooltip } from '../../../../../components/component/Tooltip/Tooltip.tsx'
import { EmptyStateImage } from '../../../../../components/layout/EmptyStateImage/EmptyStateImage.tsx'
import fantom from '../../../../../assets/empty-state/nothing-here.svg'
import { changeRequestsRoute } from './ChangeRequests.tsx'
import { ManageFlagsRole } from '../../../../../gql/graphql.ts'
import { monitoringRoute } from './Monitoring.tsx'

const Flag = () => {
  const { project, organization } = useCurrentOrgSafe()
  const { flagId } = useParams()

  const { data, isFetching } = useQuery({
    queryKey: ['flag', flagId, 'header'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query flagHeader($id: ID!) {
            flag(id: $id) {
              id
              name
              archived
              openChangeRequests
              hasChangeRequests
            }
          }
        `),
        { id: flagId as string }
      ),
  })

  if (!isFetching && !data?.flag?.id) {
    return (
      <>
        <h1>
          <Link to={`/project/${project.slug}/feature-flags`}>
            <IconArrowLeft size={24} stroke={2.5} /> Feature flags
          </Link>
        </h1>
        <EmptyStateImage
          imageSrc={fantom}
          title="This flag does not exist"
          text="Maybe it was deleted?"
          cta="See all"
          ctaIcon={IconArrowLeft}
          ctaTo={`/project/${project.slug}/feature-flags`}
        />
      </>
    )
  }

  return (
    <>
      <h1>
        <Link to={`/project/${project.slug}/feature-flags`}>
          <IconArrowLeft size={24} stroke={2.5} /> Feature flags
        </Link>
        <hr />
        {data?.flag?.name}{' '}
        {data?.flag?.archived && (
          <Tooltip>
            <Tooltip.Trigger
              component={Badge}
              color="error"
              children="Archived"
            />
            <Tooltip.Content>
              Go to settings to un-archive or permanently delete this flag
            </Tooltip.Content>
          </Tooltip>
        )}
      </h1>
      <Tabs
        tabs={[
          { label: 'Conditions', to: './conditions' },
          { label: 'Variations', to: './variations' },
          ...(data?.flag?.hasChangeRequests
            ? [
                {
                  label: 'Reviews',
                  to: './reviews',
                  counter: data?.flag?.openChangeRequests,
                },
              ]
            : []),
          { label: 'History', to: './history' },
          { label: 'Monitoring', to: './monitoring' },
          ...(organization.myAggregatedRole.manageFlags !== ManageFlagsRole.None
            ? [{ label: 'Settings', to: './settings' }]
            : []),
        ]}
      />
      <Outlet />
    </>
  )
}

export const flagRoute: RouteObject = {
  path: ':flagId',
  element: <Flag />,
  children: [
    conditionsRoute,
    variationsRoute,
    historyRoute,
    settingsRoute,
    changeRequestsRoute,
    monitoringRoute,
    {
      path: '',
      element: <Navigate to="conditions" />,
    },
  ],
}
