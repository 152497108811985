import { FC } from 'react'
import { Button, ButtonProps } from '../Button/Button.tsx'
import { Toolbar } from '../Toolbar/Toolbar.tsx'
import { useModalContext } from '../Modal/Modal.tsx'

export const FormSubmitButtons: FC<{
  labelSubmit?: string
  colorSubmit?: ButtonProps['color']
  isDirty: boolean
  isSubmitting: boolean
  reset: () => void
  disabled?: boolean
}> = ({
  labelSubmit = 'Save',
  isDirty,
  isSubmitting,
  reset,
  colorSubmit = 'primary',
  disabled,
}) => {
  const context = useModalContext()

  if (!isDirty && !context) {
    return null
  }

  return (
    <Toolbar alignRight={context !== null}>
      <Button
        type="submit"
        color={colorSubmit}
        loading={isSubmitting}
        disabled={disabled}
      >
        {labelSubmit}
      </Button>
      <Button
        variant="light"
        onClick={context?.close ?? reset}
        disabled={isSubmitting}
      >
        Cancel
      </Button>
    </Toolbar>
  )
}
