import {
  createBrowserRouter,
  Navigate,
  Outlet,
  useRouteError,
} from 'react-router-dom'
import { loginRoute } from './pages/Login.tsx'
import { logoutRoute } from './pages/Logout.tsx'
import { appRoute } from './pages/app/App.tsx'
import { ModalRenderer } from './components/component/Modal/Modal.tsx'
import notFound from './assets/empty-state/page-not-found-ufo.svg'
import errorImage from './assets/empty-state/something-went-wrong.svg'
import { IconArrowLeft, IconRefresh } from '@tabler/icons-react'
import { registerNavigate } from './auth'
import { EmptyStateImage } from './components/layout/EmptyStateImage/EmptyStateImage.tsx'
import { forgotPasswordRoute } from './pages/ForgotPassword.tsx'
import { signUpRoute } from './pages/SignUp.tsx'
import { verifyEmailRoute } from './pages/VerifyEmail.tsx'
import { resetPasswordRoute } from './pages/ResetPassword.tsx'
import { onboardingRoute } from './pages/app/onboarding/Onboarding.tsx'
import { invitationRoute } from './pages/Invitatin.tsx'
import {unsubscribeRoute} from "./pages/Unsubscribe.tsx";

const ErrorPage = () => {
  const error = useRouteError() as any

  if (error.statusText === 'Not Found') {
    return (
      <EmptyStateImage
        imageSrc={notFound}
        title="Page Not Found"
        text="Sorry, we do not know how you got there but this page does not exist."
        cta="Back to safety"
        ctaIcon={IconArrowLeft}
        ctaTo="/"
      />
    )
  }

  return (
    <EmptyStateImage
      imageSrc={errorImage}
      title="Something went wrong"
      text="Something unexpected happened. Try reloading the page and if the problem persists, please contact support."
      cta="Refresh"
      ctaIcon={IconRefresh}
      ctaOnClick={() => window.location.reload()}
    />
  )
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <Outlet />
        <ModalRenderer />
      </>
    ),
    children: [
      loginRoute,
      signUpRoute,
      {
        path: '/signup',
        element: <Navigate to="/sign-up" />,
      },
      invitationRoute,
      resetPasswordRoute,
      verifyEmailRoute,
      unsubscribeRoute,
      forgotPasswordRoute,
      logoutRoute,
      appRoute,
      onboardingRoute,
    ],
    errorElement: <ErrorPage />,
  },
])

registerNavigate(router.navigate)
