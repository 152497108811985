import {
  CellContext,
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  RowSelectionState,
  SortingState,
  TableState,
  Updater,
  useReactTable,
} from '@tanstack/react-table'
import cx from 'classnames'
import {
  IconArrowUp,
  IconCheck,
  IconChevronDown,
  IconLoader2,
  IconPlus,
  IconX,
} from '@tabler/icons-react'
import { FC, forwardRef, ReactNode, useMemo } from 'react'
import { TextInput } from '../../input/TextInput/TextInput.tsx'
import { Toolbar } from '../Toolbar/Toolbar.tsx'
import { Checkbox } from '../../input/Checkbox/Checkbox.tsx'
import { Button } from '../Button/Button.tsx'
import { FormattedMessage } from 'react-intl'
import { Dropdown, MenuItemProps } from '../DropdownMenu/DropdownMenu.tsx'
import { MoreActions } from '../MoreActions/MoreActions.tsx'
import searchImage from '../../../assets/empty-state/file-not-found.svg'
import { Link } from 'react-router-dom'
import { Skeleton } from '../Skeleton/Skeleton.tsx'
import { useSilentLoader } from '../../../hooks/useSilentLoader.tsx'
import searchUser from '../../../assets/empty-state/search-user.svg'
import { EmptyStateImage } from '../../layout/EmptyStateImage/EmptyStateImage.tsx'
import { Unit } from '../../../intl/types.ts'
import { Tooltip } from '../Tooltip/Tooltip.tsx'
import { sanitize } from '../../../utils/string.ts'

type FilterOption<TRow extends { id: string }> = {
  name: string
  filter: (row: TRow, value: any) => boolean
  options: (Omit<MenuItemProps, 'selected' | 'onClick' | 'to'> & {
    value: any
  })[]
}

export type TableProps<TRow extends { id: string }> = {
  data: TRow[]
  loading?: boolean
  fetching?: boolean
  loadingRows?: number
  columns: ColumnDef<TRow>[]
  defaultSorting?: SortingState
  defaultPageSize?: number
  rowSelection?: RowSelectionState
  onRowSelectionChange?: OnChangeFn<RowSelectionState>
  unit?: Unit
  moreActions?: (data: CellContext<TRow, unknown>) => ReactNode
  createButtonText?: string
  onCreate?: () => void
  rowTo?: (row: TRow) => string | null
  noToolbar?: boolean
  emptyState?: ReactNode
  selectionToolbar?: ReactNode
  noSelectionToolbar?: ReactNode
  filters?: Record<string, FilterOption<TRow>>
  defaultFilters?: Record<string, any>
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value) => {
  const columnValue = sanitize(row.getValue(columnId))

  const pass = value.search.trim()
    ? (value.search as string)
        .split(' ')
        .map((s) => s.trim())
        .filter(Boolean)
        .every((s) => columnValue.includes(sanitize(s)))
    : true

  return (
    pass &&
    Object.entries(value.activeFilters).every(([filter, filterValue]) =>
      value.filters[filter].filter(row.original, filterValue)
    )
  )
}

const Filter: FC<{
  setState: (updater: Updater<TableState>) => void
  filter: FilterOption<any>
  filterKey: string
  filterValue: any
}> = ({ setState, filter, filterKey, filterValue }) => {
  return (
    <Dropdown
      component={forwardRef<HTMLDivElement>((props, ref) => {
        const selectedOption = filter.options.find(
          ({ value }) => value === filterValue
        )

        return (
          <div {...props} className="filter" ref={ref}>
            <Tooltip>
              <Tooltip.Trigger
                component={IconX}
                size={14}
                onClick={() => {
                  setState((state) => {
                    delete state.globalFilter.activeFilters[filterKey]
                    return {
                      ...state,
                      globalFilter: { ...state.globalFilter },
                    }
                  })
                }}
              />
              <Tooltip.Content>Remove filter</Tooltip.Content>
            </Tooltip>
            {filter.name}
            <hr />
            <span className="value">{selectedOption?.label}</span>
            <IconChevronDown size={14} />
          </div>
        )
      })}
    >
      {filter.options.map((option) => (
        <Dropdown.Item
          icon={IconCheck}
          {...option}
          key={option.label}
          onClick={() =>
            setState((state) => {
              return {
                ...state,
                globalFilter: {
                  ...state.globalFilter,
                  activeFilters: {
                    ...state.globalFilter.activeFilters,
                    [filterKey]: option.value,
                  },
                },
              }
            })
          }
          selected={
            option.value === filterValue || (option.icon ? undefined : false)
          }
        />
      ))}
    </Dropdown>
  )
}

export const Table = <TRow extends { id: string }>({
  columns,
  data,
  loading = false,
  fetching = false,
  loadingRows = 4,
  defaultSorting,
  defaultPageSize = 25,
  rowSelection,
  onRowSelectionChange,
  unit = 'items',
  moreActions,
  onCreate,
  rowTo,
  noToolbar = false,
  emptyState,
  createButtonText = 'New',
  selectionToolbar,
  filters = {},
  defaultFilters = {},
  noSelectionToolbar,
}: TableProps<TRow>) => {
  const rowSelectionEnabled = Boolean(rowSelection)
  const silentLoader = useSilentLoader(loading)

  const enhancedColumns = useMemo<ColumnDef<TRow>[]>(() => {
    const output: ColumnDef<TRow>[] = []

    if (rowSelectionEnabled) {
      output.push({
        id: 'selection',
        header: ({ table }) => {
          return table.getPrePaginationRowModel().rows.length === 0 ? null : (
            <Checkbox
              value={table.getIsAllPageRowsSelected()}
              indeterminate={table.getIsSomePageRowsSelected()}
              onChange={(checked) =>
                table.getToggleAllPageRowsSelectedHandler()({
                  target: { checked },
                })
              }
            />
          )
        },
        cell: ({ row }) => {
          return (
            <Checkbox
              value={row.getIsSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          )
        },
        minSize: 36,
        meta: {
          flex: '0 0 36px',
          compact: true,
          noLink: true,
          loadingCell: () => null,
        },
      })
    }

    output.push(...columns)

    if (moreActions) {
      output.push({
        id: 'actions',
        meta: {
          flex: '0 0 44px',
          compact: true,
          noLink: true,
          loadingCell: () => null,
        },
        cell: (data) => (
          <Dropdown component={MoreActions}>{moreActions(data)}</Dropdown>
        ),
        minSize: 44,
      })
    }
    return output
  }, [columns, moreActions, rowSelectionEnabled])

  const table = useReactTable({
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: fuzzyFilter,
    columns: enhancedColumns,
    enableSortingRemoval: false,
    initialState: {
      sorting: defaultSorting,
      pagination: {
        pageSize: defaultPageSize,
      },
      globalFilter: { search: '', filters, activeFilters: defaultFilters },
    },
    state: {
      rowSelection,
    },
    getRowId: (row) => row.id,
    enableRowSelection: rowSelectionEnabled,
    onRowSelectionChange,
    defaultColumn: {
      minSize: 150,
      maxSize: 0,
    },
  })

  const numberRowsSelected = Object.keys(rowSelection ?? {}).length

  if (silentLoader) {
    return null
  }

  if (!loading && table.getPreFilteredRowModel().rows.length === 0) {
    return (
      emptyState ?? (
        <EmptyStateImage
          title={
            <>
              You have no{' '}
              <FormattedMessage
                id={`unit.${unit}`}
                values={{
                  count: 0,
                }}
              />{' '}
              yet
            </>
          }
          ctaOnClick={onCreate}
          ctaIcon={IconPlus}
          cta="Create"
        />
      )
    )
  }

  const tableState = table.getState()

  return (
    <>
      {!noToolbar && (
        <Toolbar>
          <div className="filters">
            {Object.entries(tableState.globalFilter.activeFilters).map(
              ([filterKey, filterValue]) => (
                <Filter
                  key={filterKey}
                  filter={filters[filterKey]}
                  filterKey={filterKey}
                  filterValue={filterValue}
                  setState={table.setState}
                />
              )
            )}
            {Object.keys(tableState.globalFilter.activeFilters).length <
              Object.keys(tableState.globalFilter.filters).length && (
              <Dropdown
                component={forwardRef<HTMLDivElement>((props, ref) => {
                  return (
                    <div {...props} className="filter add-filters" ref={ref}>
                      <IconPlus size={14} /> Add filter
                    </div>
                  )
                })}
              >
                {Object.entries(
                  tableState.globalFilter.filters as Record<
                    string,
                    FilterOption<any>
                  >
                )
                  .filter(
                    ([filterKey]) =>
                      !(filterKey in tableState.globalFilter.activeFilters)
                  )
                  .map(([filterKey, filter]) => (
                    <Dropdown label={filter.name} key={filterKey}>
                      {filter.options.map((option) => (
                        <Dropdown.Item
                          key={option.value}
                          {...option}
                          onClick={() =>
                            table.setState((state) => {
                              return {
                                ...state,
                                globalFilter: {
                                  ...state.globalFilter,
                                  activeFilters: {
                                    ...state.globalFilter.activeFilters,
                                    [filterKey]: option.value,
                                  },
                                },
                              }
                            })
                          }
                        />
                      ))}
                    </Dropdown>
                  ))}
              </Dropdown>
            )}
          </div>
          {numberRowsSelected > 0 && (
            <>
              <span>
                <b>{numberRowsSelected}</b>{' '}
                <FormattedMessage
                  id={`unit.${unit}`}
                  values={{ count: numberRowsSelected }}
                />{' '}
                selected
              </span>
              {table.getIsAllPageRowsSelected() &&
              !table.getIsAllRowsSelected() ? (
                <Button
                  variant="light"
                  onClick={() => table.toggleAllRowsSelected(true)}
                >
                  select all {data.length}{' '}
                  <FormattedMessage
                    id={`unit.${unit}`}
                    values={{ count: data.length }}
                  />
                </Button>
              ) : (
                <Button
                  variant="light"
                  onClick={() => table.setRowSelection({})}
                >
                  clear
                </Button>
              )}
            </>
          )}
          <div style={{ flex: 1 }} />
          {!loading && fetching && (
            <IconLoader2
              className="spinner"
              size={20}
              style={{ color: '#969696' }}
            />
          )}
          <TextInput
            search
            value={tableState.globalFilter.search}
            onChange={(search) =>
              table.setState((state) => ({
                ...state,
                globalFilter: { ...tableState.globalFilter, search },
              }))
            }
          />
          {(!rowSelection || Object.keys(rowSelection).length === 0) &&
            noSelectionToolbar}
          {onCreate &&
            (!rowSelection || Object.keys(rowSelection).length === 0) && (
              <Button color="primary" onClick={onCreate} icon={IconPlus}>
                {createButtonText}
              </Button>
            )}
          {rowSelection &&
            Object.keys(rowSelection).length > 0 &&
            selectionToolbar}
        </Toolbar>
      )}
      <div className="table-container">
        <table className="table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={cx(
                      header.column.getCanSort() && 'sortable',
                      header.column.columnDef.meta?.compact && 'compact'
                    )}
                    style={{
                      flex: header.column.columnDef.meta?.flex ?? '1 1 150px',
                      minWidth: header.column.columnDef.minSize || undefined,
                      maxWidth: header.column.columnDef.maxSize || undefined,
                    }}
                    onClick={() => {
                      if (header.column.getCanSort()) {
                        header.column.toggleSorting()
                      }
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    {header.column.getCanSort() && (
                      <IconArrowUp
                        size={16}
                        className={cx(
                          'sorting-indicator',
                          header.column.getIsSorted() || 'not-sorted',
                          'next-' +
                            (header.column.getNextSortingOrder() ||
                              'not-sorted')
                        )}
                      />
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {loading &&
              !data.length &&
              new Array(loadingRows).fill(0).map((_, i) => (
                <tr key={i}>
                  {table.getAllColumns().map((column) => (
                    <td
                      key={column.id}
                      className={cx(
                        column.columnDef.meta?.compact && 'compact'
                      )}
                      style={{
                        flex: column.columnDef.meta?.flex ?? '1 1 150px',
                        minWidth: column.columnDef.minSize || undefined,
                        maxWidth: column.columnDef.maxSize || undefined,
                      }}
                    >
                      {(
                        column.columnDef.meta?.loadingCell ??
                        (() => <Skeleton />)
                      )()}
                    </td>
                  ))}
                </tr>
              ))}
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={cx(
                      cell.column.columnDef.meta?.compact && 'compact'
                    )}
                    style={{
                      flex: cell.column.columnDef.meta?.flex ?? '1 1 150px',
                      minWidth: cell.column.columnDef.minSize || undefined,
                      maxWidth: cell.column.columnDef.maxSize || undefined,
                    }}
                  >
                    {rowTo &&
                    !cell.column.columnDef.meta?.noLink &&
                    rowTo(cell.row.original) ? (
                      <Link
                        to={rowTo(cell.row.original) as string}
                        className="cell-link"
                        tabIndex={-1}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Link>
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </td>
                ))}
              </tr>
            ))}
            {tableState.globalFilter.search &&
              table.getPrePaginationRowModel().rows.length === 0 &&
              table.getPreFilteredRowModel().rows.length > 0 && (
                <tr className="table-empty-state">
                  <td>
                    <img
                      className="illustration"
                      src={unit === 'members' ? searchUser : searchImage}
                      alt="no results"
                    />
                    No{' '}
                    <FormattedMessage
                      id={`unit.${unit}`}
                      values={{
                        count: 0,
                      }}
                    />{' '}
                    matching your search
                    <Button
                      onClick={() => table.resetGlobalFilter()}
                      color="primary"
                    >
                      Clear
                    </Button>
                  </td>
                </tr>
              )}
            {!tableState.globalFilter.search &&
              table.getPrePaginationRowModel().rows.length === 0 &&
              table.getPreFilteredRowModel().rows.length > 0 && (
                <tr className="table-empty-state">
                  <td>
                    <img
                      className="illustration"
                      src={unit === 'members' ? searchUser : searchImage}
                      alt="no results"
                    />
                    No{' '}
                    <FormattedMessage
                      id={`unit.${unit}`}
                      values={{
                        count: 0,
                      }}
                    />{' '}
                    matching your filters, try adjusting them
                  </td>
                </tr>
              )}
          </tbody>
          <tfoot>
            <tr>
              <td style={{ flex: 1 }}>
                <span>
                  <b>{table.getPrePaginationRowModel().rows.length}</b>{' '}
                  <FormattedMessage
                    id={`unit.${unit}`}
                    values={{
                      count: table.getPrePaginationRowModel().rows.length,
                    }}
                  />
                </span>
              </td>
              {table.getPageCount() > 1 && (
                <td>
                  <Toolbar>
                    <Button
                      disabled={!table.getCanPreviousPage()}
                      onClick={table.previousPage}
                    >
                      Previous
                    </Button>
                    <Button
                      disabled={!table.getCanNextPage()}
                      onClick={table.nextPage}
                    >
                      Next
                    </Button>
                  </Toolbar>
                </td>
              )}
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  )
}
