import { useForm } from 'react-hook-form'
import { Button } from '../components/component/Button/Button'
import { InputWrapper } from '../components/input/InputWrapper/InputWrapper'
import { PasswordInput } from '../components/input/PasswordInput/PasswordInput'
import { TextInput } from '../components/input/TextInput/TextInput'
import { CenteredVerticalLayout } from '../components/layout/CenteredVerticalLayout/CenteredVerticalLayout'
import { graphql } from '../gql'
import { useFormSubmitMutation } from '../hooks/useFormSubmitMutation'
import { AnonymousOnly } from '../components/layout/Auth/AnonymousOnly'
import { Link, RouteObject, useSearchParams } from 'react-router-dom'
import { PasswordStrength } from '../components/component/PasswordStrength/PasswordStrength.tsx'
import style from './SignUp.module.scss'
import checkYourInbox from '../assets/empty-state/check-your-inbox.svg'
import { toast } from 'react-hot-toast'
import { gqlClient, setToken } from '../auth'
import { useGoogleLogin } from '@react-oauth/google'
import { QuotesLayout } from '../components/layout/QuotesLayout/QuotesLayout.tsx'
import { IconCheck } from '@tabler/icons-react'
import google from '../assets/logo/google.svg'

const Page = () => {
  const [searchParams] = useSearchParams()
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isSubmitSuccessful },
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      email: searchParams.get('email') ?? '',
      password: '',
    },
  })

  const submit = useFormSubmitMutation({
    mutation: graphql(`
      mutation signUp($email: String!, $password: String!) {
        signUp(email: $email, password: $password)
      }
    `),
    control,
  })

  const login = useGoogleLogin({
    onSuccess: ({ access_token }) =>
      toast.promise(
        gqlClient
          .request(
            graphql(`
              mutation googleLogin($token: String!) {
                googleLogin(token: $token)
              }
            `),
            { token: access_token ?? '' }
          )
          .then(({ googleLogin }) => setToken(googleLogin)),
        {
          loading: 'Signing up using Google',
          success: 'Welcome to Tggl!',
          error: 'Could not sign up using Google',
        }
      ),
    onError: () => toast.error('Could not sign in with Google'),
  })

  const password = watch('password')

  if (isSubmitSuccessful) {
    return (
      <CenteredVerticalLayout center>
        <img src={checkYourInbox} />
        <h1>Almost there!</h1>
        <p>
          We just sent you an email at <b>{getValues().email}</b>. Check your
          inbox and click the link to finalize your account!
        </p>
      </CenteredVerticalLayout>
    )
  }

  return (
    <QuotesLayout>
      <CenteredVerticalLayout>
        <form onSubmit={handleSubmit(submit)} className={style.form}>
          <h1>Create your account</h1>
          <ul>
            <li>
              <div>
                <IconCheck size={12} stroke={3} />
              </div>
              Free 30 days trial
            </li>
            <li>
              <div>
                <IconCheck size={12} stroke={3} />
              </div>
              <s>No credit card required</s>
            </li>
          </ul>
          <Button onClick={() => login()}>
            <img src={google} alt="Google logo" height={18} />
            Google
          </Button>

          <div className="text-separator">or</div>
          <InputWrapper
            component={TextInput}
            control={control}
            name="email"
            label="Email"
            type="email"
            rules={{ required: true, pattern: /^\S+@\S+$/i }}
            autoComplete="email"
            autoFocus
          />
          <InputWrapper
            component={PasswordInput}
            control={control}
            name="password"
            label="Password"
            rules={{ required: true }}
            autoComplete="new-password"
            helper={<PasswordStrength password={password} hidden />}
          />
          <Button color="primary" type="submit" loading={isSubmitting}>
            Start trial
          </Button>
          <Link to="/login">Already have an account? Login</Link>
        </form>
      </CenteredVerticalLayout>
    </QuotesLayout>
  )
}

export const signUpRoute: RouteObject = {
  path: '/sign-up',
  element: (
    <AnonymousOnly>
      <Page />
    </AnonymousOnly>
  ),
}
