import { useLayoutEffect } from 'react'
import { setToken } from '../auth'
import { AuthenticatedOnly } from '../components/layout/Auth/AuthenticatedOnly'
import { RouteObject } from 'react-router-dom'

const Logout = () => {
  useLayoutEffect(() => {
    setToken(null)
  }, [])
  return null
}

export const logoutRoute: RouteObject = {
  path: '/logout',
  element: (
    <AuthenticatedOnly>
      <Logout />
    </AuthenticatedOnly>
  ),
}
