import { RouteObject } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { InputWrapper } from '../../../../components/input/InputWrapper/InputWrapper.tsx'
import { TextInput } from '../../../../components/input/TextInput/TextInput.tsx'
import { Button } from '../../../../components/component/Button/Button.tsx'
import { graphql } from '../../../../gql'
import { Columns } from '../../../../components/component/Columns/Columns.tsx'
import { Sections } from '../../../../components/component/Sections/Sections.tsx'
import { useFormSubmitMutation } from '../../../../hooks/useFormSubmitMutation.ts'
import { toast } from 'react-hot-toast'
import { useFormDefaultValue } from '../../../../hooks/useFormDefaultValue.ts'
import { PasswordInput } from '../../../../components/input/PasswordInput/PasswordInput.tsx'
import { gqlClient, setToken, useUser } from '../../../../auth'
import { PasswordStrength } from '../../../../components/component/PasswordStrength/PasswordStrength.tsx'
import { FormSubmitButtons } from '../../../../components/component/FormSubmitButtons/FormSubmitButtons.tsx'
import { Toolbar } from '../../../../components/component/Toolbar/Toolbar.tsx'
import { ActionsList } from '../../../../components/component/ActionsList/ActionsList.tsx'
import {
  openModal,
  useModalContext,
} from '../../../../components/component/Modal/Modal.tsx'
import lockProfile from '../../../../assets/empty-state/lock-profile.svg'
import { useQuery } from '@tanstack/react-query'
import QRCode from 'react-qr-code'
import { Code } from '../../../../components/component/Code/Code.tsx'
import style from './Profile.module.scss'
import Balancer from 'react-wrap-balancer'
import { IconArrowLeft, IconArrowRight, IconCheck } from '@tabler/icons-react'
import { useState } from 'react'
import { PinInput } from '../../../../components/input/PinInput/PinInput.tsx'
import mfaImage from '../../../../assets/empty-state/2FA.svg'

const PersonalInformationForm = () => {
  const user = useUser()
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty },
    reset,
  } = useForm({
    defaultValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      email: user?.email ?? '',
    },
  })

  useFormDefaultValue({
    control,
    queryKey: ['me', 'personalInformationDefaultValues'],
    request: graphql(`
      query personalInformationDefaultValues {
        me {
          firstName
          lastName
          email
        }
      }
    `),
    mapDefaultValues: (data) => ({
      firstName: data.me?.firstName ?? '',
      lastName: data.me?.lastName ?? '',
      email: data.me?.email ?? '',
    }),
  })

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation updatePersonalInformation($input: PersonalInformation!) {
        updatePersonalInformation(personalInformation: $input) {
          id
        }
      }
    `),
    mapVariables: (data) => ({
      input: { firstName: data.firstName, lastName: data.lastName },
    }),
    onSuccess: () => toast.success('Personal information updated'),
  })

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Columns>
        <InputWrapper
          component={TextInput}
          control={control}
          name="firstName"
          label="First name"
          autoComplete="given-name"
        />
        <InputWrapper
          component={TextInput}
          control={control}
          name="lastName"
          label="Last name"
          autoComplete="family-name"
        />
        <InputWrapper
          component={TextInput}
          control={control}
          name="email"
          label="Email"
          disabled
        />
      </Columns>
      <FormSubmitButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        reset={reset}
        labelSubmit="Update"
      />
    </form>
  )
}

const ChangePassword = () => {
  const user = useUser()
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      totpToken: '',
    },
  })

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation changePassword(
        $currentPassword: String!
        $newPassword: String!
        $totpToken: String
      ) {
        changePassword(
          currentPassword: $currentPassword
          newPassword: $newPassword
          totpToken: $totpToken
        )
      }
    `),
    onSuccess: (data) => {
      setToken(data.changePassword)
      toast.success('Password changed')
    },
  })

  const password = watch('newPassword')

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Columns>
        {user?.hasTotp && (
          <InputWrapper
            component={PinInput}
            control={control}
            name="totpToken"
            autoFocus
            label="Security code"
            tooltip="From your authenticator app"
            rules={{ required: true }}
            length={6}
          />
        )}
        <InputWrapper
          component={PasswordInput}
          control={control}
          name="currentPassword"
          label="Current password"
          autoComplete="current-password"
          rules={{ required: true }}
        />
        <InputWrapper
          component={PasswordInput}
          control={control}
          name="newPassword"
          label="New password"
          autoComplete="new-password"
          rules={{ required: true }}
          helper={<PasswordStrength password={password} />}
        />
      </Columns>
      <FormSubmitButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        reset={reset}
        labelSubmit="Change password"
      />
    </form>
  )
}

const TerminateOtherSessions = () => {
  const user = useUser()
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      currentPassword: '',
      totpToken: '',
    },
  })

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation changePassword(
        $currentPassword: String!
        $newPassword: String!
        $totpToken: String
      ) {
        changePassword(
          currentPassword: $currentPassword
          newPassword: $newPassword
          totpToken: $totpToken
        )
      }
    `),
    mapVariables: (data) => ({
      currentPassword: data.currentPassword,
      newPassword: data.currentPassword,
      totpToken: data.totpToken,
    }),
    onSuccess: (data) => {
      setToken(data.changePassword)
      toast.success('All other devices have been logged out')
    },
  })

  const context = useModalContext()

  return (
    <form onSubmit={handleSubmit(submit)}>
      <img src={lockProfile} alt="Lock profile" className="illustration" />
      <h2 style={{ textAlign: 'center', marginBottom: 20 }}>
        Terminate all other sessions
      </h2>
      {user?.hasTotp && (
        <InputWrapper
          component={PinInput}
          control={control}
          name="totpToken"
          label="Security code"
          autoFocus
          tooltip="From your authenticator app"
          rules={{ required: true }}
          length={6}
        />
      )}
      <InputWrapper
        component={PasswordInput}
        control={control}
        name="currentPassword"
        label="Password"
        autoComplete="current-password"
        rules={{ required: true }}
      />
      <Toolbar alignRight>
        <Button type="submit" color="primary" loading={isSubmitting}>
          Terminate
        </Button>
        <Button
          variant="light"
          onClick={() => {
            context?.close()
          }}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
      </Toolbar>
    </form>
  )
}

const Disable2FA = () => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, isDirty },
  } = useForm({
    defaultValues: {
      currentPassword: '',
    },
  })

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation disableTotp($currentPassword: String!) {
        disableTotp(currentPassword: $currentPassword)
      }
    `),
    mapVariables: (data) => ({
      currentPassword: data.currentPassword,
    }),
    onSuccess: (data) => {
      setToken(data.disableTotp)
      toast.success('Two-factor authentication has been disabled')
    },
  })

  return (
    <form onSubmit={handleSubmit(submit)}>
      <h2>Disable 2FA</h2>
      <p style={{ marginBottom: 24, opacity: 0.6 }}>
        You are about to disable two-factor authentication and expose your
        account. This will lower the level of security.
      </p>
      <InputWrapper
        component={PasswordInput}
        control={control}
        name="currentPassword"
        label="Password"
        autoFocus
        autoComplete="current-password"
        rules={{ required: true }}
      />
      <FormSubmitButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        reset={reset}
        colorSubmit="error"
        labelSubmit="Disable 2FA"
      />
    </form>
  )
}

const Enable2FA = () => {
  const [showQrCode, setShowQrCode] = useState(true)
  const { data: mfaData, isFetching } = useQuery({
    queryKey: ['me', 'twoFactorsAuthentication'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          mutation generateTotpSecret {
            generateTotpSecret {
              otpauthUri
              secret
              secretToken
            }
          }
        `)
      ),
  })

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      currentPassword: '',
      totpToken: '',
    },
  })

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation enableTwoFactorsAuthentication(
        $currentPassword: String!
        $totpToken: String!
        $secretToken: String!
      ) {
        enableTotp(
          currentPassword: $currentPassword
          totpToken: $totpToken
          secretToken: $secretToken
        )
      }
    `),
    mapVariables: (data) => ({
      currentPassword: data.currentPassword,
      totpToken: data.totpToken,
      secretToken: mfaData?.generateTotpSecret.secretToken ?? '',
    }),
    onSuccess: (data) => {
      setToken(data.enableTotp)
      toast.success('Two-factor authentication enabled')
    },
    onError: {
      TOTP_ALREADY_ENABLED: () =>
        toast.error('Two-factor authentication already enabled'),
      EXPIRED_TOKEN: () => toast.error('The QR code expired, please try again'),
    },
  })

  if (isFetching) {
    return null
  }

  if (showQrCode) {
    return (
      <div className={style.enable2FA}>
        <h2>Scan QR code</h2>
        <p>
          <Balancer>
            Scan this QR code using a third party app like{' '}
            <b>Google Authenticator</b>
          </Balancer>
        </p>
        <QRCode value={mfaData?.generateTotpSecret.otpauthUri ?? ''} />
        <div className="text-separator">or manually enter this code</div>
        <Code
          value={mfaData?.generateTotpSecret.secret ?? ''}
          large
          shorten={11}
        />
        <Button
          color="primary"
          icon={IconArrowRight}
          onClick={() => setShowQrCode(false)}
        >
          Next
        </Button>
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <img src={mfaImage} className="illustration" />
      <p style={{ marginBottom: 24, opacity: 0.8 }}>
        Input your current password and the 6 digits code from your
        authenticator app.
      </p>
      <InputWrapper
        control={control}
        name="currentPassword"
        label="Current password"
        autoFocus
        autoComplete="current-password"
        rules={{ required: true }}
        component={PasswordInput}
      />
      <InputWrapper
        control={control}
        name="totpToken"
        label="Code"
        rules={{ required: true }}
        component={PinInput}
        length={6}
      />
      <Toolbar alignRight>
        <Button color="primary" type="submit" loading={isSubmitting}>
          Enable 2FA
        </Button>
        <Button
          icon={IconArrowLeft}
          onClick={() => setShowQrCode(true)}
          disabled={isSubmitting}
        >
          Previous
        </Button>
      </Toolbar>
    </form>
  )
}

const Page = () => {
  const user = useUser()

  return (
    <Sections>
      <Sections.Section title="Personal information">
        <PersonalInformationForm />
      </Sections.Section>
      <hr />
      <Sections.Section title="Security">
        <ActionsList>
          <ActionsList.Action
            title="Change password"
            subTitle="You will no longer be able to login with the old password"
            action={
              <Button
                onClick={() =>
                  openModal({
                    title: 'Change password',
                    content: <ChangePassword />,
                  })
                }
              >
                Change password
              </Button>
            }
          />
          {!user?.hasTotp && (
            <ActionsList.Action
              title="Enable 2FA"
              subTitle="Greatly improve the security of your account with two-factor authentication"
              action={
                <Button
                  color="primary"
                  onClick={() =>
                    openModal({
                      title: null,
                      content: <Enable2FA />,
                    })
                  }
                >
                  Enable 2FA
                </Button>
              }
            />
          )}
          {user?.hasTotp && (
            <ActionsList.Action
              title={
                <>
                  2FA enabled <IconCheck size={16} />
                </>
              }
              subTitle="Your account is protected with two-factor authentication"
              action={
                <Button
                  onClick={() =>
                    openModal({
                      title: null,
                      content: <Disable2FA />,
                    })
                  }
                >
                  Disable 2FA
                </Button>
              }
            />
          )}
        </ActionsList>
      </Sections.Section>
      <hr />
      <Sections.Section title="Danger zone">
        <ActionsList>
          <ActionsList.Action
            title="Terminate all other sessions"
            subTitle="All other devices will be logged out"
            action={
              <Button
                color="error"
                onClick={() =>
                  openModal({
                    title: null,
                    content: <TerminateOtherSessions />,
                  })
                }
              >
                Terminate
              </Button>
            }
          />
          <ActionsList.Action
            title="Log out"
            subTitle="Only this device will be logged out"
            action={<Button color="error">Log out</Button>}
          />
        </ActionsList>
      </Sections.Section>
    </Sections>
  )
}

export const profileRoute: RouteObject = {
  path: 'profile',
  element: <Page />,
}
