import { useForm } from 'react-hook-form'
import { Button } from '../components/component/Button/Button'
import { InputWrapper } from '../components/input/InputWrapper/InputWrapper'
import { TextInput } from '../components/input/TextInput/TextInput'
import { CenteredVerticalLayout } from '../components/layout/CenteredVerticalLayout/CenteredVerticalLayout'
import { useFormSubmitMutation } from '../hooks/useFormSubmitMutation'
import { AnonymousOnly } from '../components/layout/Auth/AnonymousOnly'
import { RouteObject, useSearchParams } from 'react-router-dom'
import { IconArrowLeft } from '@tabler/icons-react'
import checkYourInbox from '../assets/empty-state/check-your-inbox.svg'
import { resetPasswordRequestQuery } from '../queries/auth.ts'

const Page = () => {
  const [searchParams] = useSearchParams()

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isSubmitSuccessful },
    getValues,
  } = useForm({
    defaultValues: {
      email: searchParams.get('email') ?? '',
    },
  })

  const submit = useFormSubmitMutation({
    mutation: resetPasswordRequestQuery,
    control,
  })

  if (isSubmitSuccessful) {
    return (
      <CenteredVerticalLayout center>
        <img src={checkYourInbox} />
        <h1>Check your inbox!</h1>
        <p>
          If your email <b>{getValues().email}</b> exists in our database,
          you'll receive a reset link shortly.
        </p>
        <Button variant="light" icon={IconArrowLeft} to="/login">
          Login
        </Button>
      </CenteredVerticalLayout>
    )
  }

  return (
    <CenteredVerticalLayout>
      <form onSubmit={handleSubmit(submit)}>
        <h1>Forgot your password?</h1>
        <p style={{ marginBottom: 24 }}>
          Don’t worry!
          <br />
          Just enter the email address associated with this account and we will
          send you a link to reset your password.
        </p>
        <InputWrapper
          component={TextInput}
          control={control}
          name="email"
          label="Email"
          type="email"
          rules={{ required: true, pattern: /^\S+@\S+$/i }}
          autoComplete="username"
          autoFocus
        />
        <Button color="primary" type="submit" loading={isSubmitting}>
          Reset password
        </Button>
        <Button
          variant="light"
          icon={IconArrowLeft}
          to="/login"
          style={{ marginTop: 8 }}
        >
          Login
        </Button>
      </form>
    </CenteredVerticalLayout>
  )
}

export const forgotPasswordRoute: RouteObject = {
  path: '/forgot-password',
  element: (
    <AnonymousOnly>
      <Page />
    </AnonymousOnly>
  ),
}
