import { FC } from 'react'
import { CheckablePill } from '../CheckablePill/CheckablePill.tsx'
import { motion } from 'framer-motion'

export const PasswordStrength: FC<{ password?: string; hidden?: boolean }> = ({
  password = '',
  hidden,
}) => {
  return (
    <motion.div
      animate={{
        height: hidden && !password ? 0 : 'auto',
        opacity: hidden && !password ? 0 : 1,
      }}
      initial={false}
      style={{ overflow: 'hidden' }}
    >
      <CheckablePill.List>
        <CheckablePill checked={/[A-Z]/.test(password)} label="1 Upper case" />
        <CheckablePill checked={/[a-z]/.test(password)} label="1 Lower case" />
        <CheckablePill checked={/[0-9]/.test(password)} label="1 Number" />
        <CheckablePill checked={/\W/.test(password)} label="1 Special char" />
        <CheckablePill checked={password.length >= 8} label="8 chars min" />
      </CheckablePill.List>
    </motion.div>
  )
}
