import { RouteObject, useParams } from 'react-router-dom'
import style from './Deliveries.module.scss'
import { useQuery } from '@tanstack/react-query'
import { gqlClient } from '../../../../auth'
import { graphql } from '../../../../gql'
import { Card } from '../../../../components/component/Card/Card.tsx'
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { Badge } from '../../../../components/component/Badge/Badge.tsx'
import { ShortDate } from '../../../../components/component/ShortDate/ShortDate.tsx'
import { Skeleton } from '../../../../components/component/Skeleton/Skeleton.tsx'

export const StatusBadge = ({ status }: { status: number | null }) => {
  return (
    <Badge color={!status || status > 299 ? 'error' : 'success'}>
      {status ?? 'FAILED'}
    </Badge>
  )
}

const Page = () => {
  const { runId } = useParams()

  const { data, isLoading } = useQuery({
    queryKey: ['webhook run', runId],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query WebhookRun($id: ID!) {
            webhookRun(id: $id) {
              id
              responseStatus
              method
              createdAt
              url
              body
              headers
              responseBody
              responseHeaders
            }
          }
        `),
        { id: runId as string }
      ),
  })

  if (isLoading) {
    return (
      <Card>
        <Skeleton />
      </Card>
    )
  }

  return (
    <div className={style.sidePanel}>
      <Card>
        <h2>
          Request
          <span style={{ float: 'right' }}>
            <ShortDate value={data?.webhookRun?.createdAt ?? 0} ago />
          </span>
        </h2>
        <span>
          <b>{data?.webhookRun?.method}</b> {data?.webhookRun?.url}
        </span>
        <h3>Headers</h3>
        <SyntaxHighlighter
          language="json"
          style={dracula}
          children={JSON.stringify(data?.webhookRun?.headers, null, 2)}
        />
        <h3>Body</h3>
        <SyntaxHighlighter
          language="json"
          style={dracula}
          children={JSON.stringify(data?.webhookRun?.body, null, 2)}
        />
      </Card>
      <Card>
        <h2>
          Response
          <StatusBadge status={data?.webhookRun?.responseStatus ?? null} />
        </h2>
        <h3>Headers</h3>
        <SyntaxHighlighter
          language="json"
          style={dracula}
          children={JSON.stringify(data?.webhookRun?.responseHeaders, null, 2)}
        />
        <h3>Body</h3>
        <SyntaxHighlighter
          language="json"
          style={dracula}
          children={JSON.stringify(data?.webhookRun?.responseBody, null, 2)}
        />
      </Card>
    </div>
  )
}

export const deliveryRoute: RouteObject = {
  path: ':runId',
  element: <Page />,
}
