import { toast } from 'react-hot-toast'
import { Button } from '../components/component/Button/Button.tsx'

export const undoableLoadingToast = ({
  id,
  do: d,
  undo,
}: {
  id: string
  do: {
    promise: () => Promise<any>
    loading: string
    success: string
    error: string
  }
  undo: {
    promise: () => Promise<any>
    loading: string
    success: string
    error: string
  }
}) => {
  toast.promise(
    d.promise(),
    {
      loading: d.loading,
      success: (
        <>
          {d.success}
          <Button
            variant="light"
            onClick={() =>
              toast.promise(
                undo.promise(),
                {
                  loading: undo.loading,
                  success: undo.success,
                  error: undo.error,
                },
                { id }
              )
            }
          >
            Undo
          </Button>
        </>
      ),
      error: d.error,
    },
    { id }
  )
}
