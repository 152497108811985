import { useEffect, useState } from 'react'

export const useSilentLoader = (loading: boolean, duration = 250) => {
  const [elapsed, setElapsed] = useState(false)

  useEffect(() => {
    setTimeout(() => setElapsed(true), duration)
  }, [duration])

  return loading && !elapsed
}
