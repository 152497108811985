import { Outlet, RouteObject, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { gqlClient, useUser } from '../../../../../auth'
import { graphql } from '../../../../../gql'
import { useMemo, useState } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { ShortDate } from '../../../../../components/component/ShortDate/ShortDate.tsx'
import { DeepRequired } from 'react-hook-form'
import {
  ChangeRequestLogType,
  ChangeRequestsQuery,
  ChangeRequestStatus,
  ReviewsRole,
} from '../../../../../gql/graphql.ts'
import style from './History.module.scss'
import { Table } from '../../../../../components/component/Table/Table.tsx'
import { Badge } from '../../../../../components/component/Badge/Badge.tsx'
import { Card } from '../../../../../components/component/Card/Card.tsx'
import { FlagChanges } from './History.tsx'
import { Button } from '../../../../../components/component/Button/Button.tsx'
import { Toolbar } from '../../../../../components/component/Toolbar/Toolbar.tsx'
import {
  IconAlertCircleFilled,
  IconCheck,
  IconCircleMinus,
  IconGitMerge,
  IconPlus,
  IconRefreshDot,
  IconTrash,
  IconX,
} from '@tabler/icons-react'
import { toast } from 'react-hot-toast'
import changeRequestStyle from './ChangeRequests.module.scss'
import { useCurrentOrgSafe } from '../../../../../hooks/useCurrentOrgSafe.ts'
import cx from 'classnames'
import { FormattedList, FormattedMessage } from 'react-intl'
import React from 'react'
import Markdown from 'react-markdown'

type Row = DeepRequired<ChangeRequestsQuery>['flag']['changeRequests'][0]

const CreatedBy = ({
  author,
  id,
}: {
  author: Row['createdBy']
  id: string
}) => {
  const { changeRequestId } = useParams()

  return (
    <span aria-expanded={id === changeRequestId ? 'true' : undefined}>
      {author.name ?? author.email}
    </span>
  )
}

const ChageRequestStatus = ({ value }: { value: Row['status'] }) => {
  return (
    <span>
      {value === ChangeRequestStatus.Closed && (
        <Badge color="error">Closed</Badge>
      )}
      {value === ChangeRequestStatus.Conflict && (
        <Badge color="warning">Conflicts</Badge>
      )}
      {value === ChangeRequestStatus.Created && (
        <Badge color="primary">Requested</Badge>
      )}
      {value === ChangeRequestStatus.Merged && (
        <Badge color="success">Applied</Badge>
      )}
    </span>
  )
}

const Page = () => {
  const { flagId } = useParams()

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['flag', flagId, 'changeRequests'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query changeRequests($id: ID!) {
            flag(id: $id) {
              id
              changeRequests {
                id
                status
                createdBy {
                  id
                  name
                  email
                }
                createdAt
              }
            }
          }
        `),
        { id: flagId as string }
      ),
    staleTime: 10000,
  })

  const columns = useMemo<ColumnDef<Row>[]>(
    () => [
      {
        id: 'name',
        accessorFn: (row) => row.createdBy.name + row.createdBy.email,
        header: 'Author',
        cell: ({ row }) => (
          <CreatedBy author={row.original.createdBy} id={row.id} />
        ),
        enableSorting: false,
      },
      {
        id: 'date',
        accessorFn: (row) => row.createdAt,
        sortingFn: 'datetime',
        header: 'Date',
        cell: ({ row }) => <ShortDate value={row.original.createdAt} ago />,
      },
      {
        id: 'status',
        accessorFn: (row) => row.status,
        enableSorting: false,
        header: 'Status',
        cell: ({ row }) => <ChageRequestStatus value={row.original.status} />,
      },
    ],
    []
  )

  return (
    <div className={style.container}>
      <aside>
        <Table<Row>
          data={(data?.flag?.changeRequests as Row[]) ?? []}
          columns={columns}
          loading={isLoading}
          fetching={isFetching}
          defaultSorting={[{ id: 'date', desc: true }]}
          rowTo={({ id }) => id}
          filters={{
            status: {
              name: 'Status',
              options: [
                {
                  label: 'Pending',
                  value: [
                    ChangeRequestStatus.Created,
                    ChangeRequestStatus.Conflict,
                  ].join(','),
                },
                {
                  label: 'Done',
                  value: [
                    ChangeRequestStatus.Merged,
                    ChangeRequestStatus.Closed,
                  ].join(','),
                },
              ],
              filter: (row, value) => value.split(',').includes(row.status),
            },
          }}
          defaultFilters={{
            status: [
              ChangeRequestStatus.Created,
              ChangeRequestStatus.Conflict,
            ].join(','),
          }}
        />
      </aside>
      <main>
        <Outlet />
      </main>
    </div>
  )
}

const ChangeRequest = () => {
  const { changeRequestId, flagId } = useParams()
  const { organization } = useCurrentOrgSafe()
  const user = useUser()

  const { data } = useQuery({
    queryKey: ['changeRequest', changeRequestId],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query changeRequestChanges($id: ID!) {
            changeRequest(id: $id) {
              id
              createdAt
              status
              createdBy {
                id
                name
                email
              }
              conflicts {
                message
              }
              updatedAt
              updatedBy {
                id
                name
                email
              }
              logs {
                id
                createdAt
                createdBy {
                  id
                  name
                  email
                }
                logType
                outdated
                message
              }
              approvals {
                id
                logType
                createdBy {
                  id
                  name
                  email
                }
              }
              roleApprovals {
                role {
                  id
                  name
                }
                approved
                approvals {
                  id
                  logType
                  createdBy {
                    id
                    name
                    email
                  }
                }
              }
              changes {
                __typename
                ... on FlagCreated {
                  created
                }
                ... on FlagActivated {
                  newActive
                  oldActive
                }
                ... on FlagArchived {
                  newArchived
                  oldArchived
                }
                ... on FlagDefaultVariationChanged {
                  oldVariation {
                    name
                    color
                    active
                    value
                  }
                  newVariation {
                    name
                    color
                    active
                    value
                  }
                }
                ... on FlagNameChanged {
                  newName
                  oldName
                }
                ... on FlagDescriptionChanged {
                  newDescription
                  oldDescription
                }
                ... on FlagSlugChanged {
                  newSlug
                  oldSlug
                }
                ... on FlagSharingChanged {
                  newShareWithEveryone
                  oldShareWithEveryone
                  oldRoles
                  newRoles
                }
                ... on FlagVariationCreated {
                  variation {
                    name
                    value
                  }
                }
                ... on FlagVariationDeleted {
                  variation {
                    name
                  }
                }
                ... on FlagVariationUpdated {
                  oldVariation {
                    name
                    color
                    active
                    value
                    description
                    order
                  }
                  newVariation {
                    name
                    color
                    active
                    value
                    description
                    order
                  }
                }
                ... on FlagConditionCreated {
                  condition {
                    name
                    codeLike
                  }
                }
                ... on FlagConditionDeleted {
                  condition {
                    name
                    codeLike
                  }
                }
                ... on FlagTagsChanged {
                  newTags {
                    id
                    name
                    color
                  }
                  oldTags {
                    id
                    name
                    color
                  }
                  addedTags {
                    id
                    name
                    color
                  }
                  removedTags {
                    id
                    name
                    color
                  }
                }
                ... on FlagConditionUpdated {
                  oldCondition {
                    name
                    order
                    codeLike
                  }
                  newCondition {
                    name
                    order
                    codeLike
                  }
                }
              }
              flag {
                project {
                  changeRequestMinApprovals
                }
              }
            }
          }
        `),
        { id: changeRequestId as string }
      ),
    staleTime: 5000,
  })

  const queryClient = useQueryClient()

  const mergeChangeRequest = useMutation({
    mutationFn: (id: string) =>
      gqlClient.request(
        graphql(`
          mutation mergeChangeRequest($id: ID!) {
            changeRequest(id: $id) {
              merge {
                id
                status
              }
            }
          }
        `),
        { id }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['changeRequest', changeRequestId],
      })
      queryClient.invalidateQueries({
        queryKey: ['flag', flagId],
      })
    },
  })

  const closeChangeRequest = useMutation({
    mutationFn: (id: string) =>
      gqlClient.request(
        graphql(`
          mutation closeChangeRequest($id: ID!) {
            changeRequest(id: $id) {
              close {
                id
                status
              }
            }
          }
        `),
        { id }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['changeRequest', changeRequestId],
      })
      queryClient.invalidateQueries({
        queryKey: ['flag', flagId],
      })
    },
  })

  const openChangeRequest = useMutation({
    mutationFn: (id: string) =>
      gqlClient.request(
        graphql(`
          mutation openChangeRequest($id: ID!) {
            changeRequest(id: $id) {
              open {
                id
                status
              }
            }
          }
        `),
        { id }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['changeRequest', changeRequestId],
      })
      queryClient.invalidateQueries({
        queryKey: ['flag', flagId],
      })
    },
  })

  const approveChangeRequest = useMutation({
    mutationFn: (id: string) =>
      gqlClient.request(
        graphql(`
          mutation approveChangeRequest($id: ID!) {
            changeRequest(id: $id) {
              approve {
                id
              }
            }
          }
        `),
        { id }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['changeRequest', changeRequestId],
      })
      queryClient.invalidateQueries({
        queryKey: ['flag', flagId],
      })
    },
  })

  const rejectChangeRequest = useMutation({
    mutationFn: (id: string) =>
      gqlClient.request(
        graphql(`
          mutation rejectChangeRequest($id: ID!) {
            changeRequest(id: $id) {
              reject {
                id
              }
            }
          }
        `),
        { id }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['changeRequest', changeRequestId],
      })
      queryClient.invalidateQueries({
        queryKey: ['flag', flagId],
      })
    },
  })

  const removeApproval = useMutation({
    mutationFn: (id: string) =>
      gqlClient.request(
        graphql(`
          mutation removeApproval($id: ID!) {
            changeRequest(id: $id) {
              removeApproval {
                id
              }
            }
          }
        `),
        { id }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['changeRequest', changeRequestId],
      })
      queryClient.invalidateQueries({
        queryKey: ['flag', flagId],
      })
    },
  })

  const [changeReview, setChangeReview] = useState(false)

  const changeRequest = data?.changeRequest
  const rejectedBy =
    changeRequest?.approvals
      .filter((approval) => approval.logType === ChangeRequestLogType.Rejected)
      .map((approval) => approval.createdBy.name ?? approval.createdBy.email) ??
    []
  const approvedBy =
    changeRequest?.approvals
      .filter((approval) => approval.logType === ChangeRequestLogType.Approved)
      .map((approval) => approval.createdBy.name ?? approval.createdBy.email) ??
    []

  if (!changeRequest) {
    return null
  }

  return (
    <>
      {/* @ts-ignore */}
      <FlagChanges changes={changeRequest.changes} />

      <div className={changeRequestStyle.timeline}>
        {changeRequest.logs.map((log) => (
          <React.Fragment key={log.id}>
            <div className={cx(log.outdated && changeRequestStyle.outdated)}>
              {log.logType === ChangeRequestLogType.Created && (
                <>
                  <div className={changeRequestStyle.timelineIcon}>
                    <IconPlus size={16} stroke={2.5} />
                  </div>
                  <span>
                    Review requested by{' '}
                    <b>{log.createdBy.name ?? log.createdBy.email}</b>
                  </span>
                </>
              )}
              {log.logType === ChangeRequestLogType.Approved && (
                <>
                  <div
                    className={cx(
                      changeRequestStyle.timelineIcon,
                      changeRequestStyle.success
                    )}
                  >
                    <IconCheck size={16} stroke={2.5} />
                  </div>
                  <span>
                    Approved by{' '}
                    <b>{log.createdBy.name ?? log.createdBy.email}</b>
                  </span>
                </>
              )}
              {log.logType === ChangeRequestLogType.Rejected && (
                <>
                  <div
                    className={cx(
                      changeRequestStyle.timelineIcon,
                      changeRequestStyle.error
                    )}
                  >
                    <IconX size={16} stroke={2.5} />
                  </div>
                  <span>
                    Rejected by{' '}
                    <b>{log.createdBy.name ?? log.createdBy.email}</b>
                  </span>
                </>
              )}
              {log.logType === ChangeRequestLogType.Closed && (
                <>
                  <div className={changeRequestStyle.timelineIcon}>
                    <IconCircleMinus size={16} stroke={2} />
                  </div>
                  <span>
                    Closed by <b>{log.createdBy.name ?? log.createdBy.email}</b>
                  </span>
                </>
              )}
              {log.logType === ChangeRequestLogType.Opened && (
                <>
                  <div className={changeRequestStyle.timelineIcon}>
                    <IconRefreshDot size={16} stroke={2} />
                  </div>
                  <span>
                    Re-opened by{' '}
                    <b>{log.createdBy.name ?? log.createdBy.email}</b>
                  </span>
                </>
              )}
              {log.logType === ChangeRequestLogType.Merged && (
                <>
                  <div className={changeRequestStyle.timelineIcon}>
                    <IconGitMerge size={16} stroke={2} />
                  </div>
                  <span>
                    Applied by{' '}
                    <b>{log.createdBy.name ?? log.createdBy.email}</b>
                  </span>
                </>
              )}
              <div style={{ flex: 1 }} />
              <ShortDate value={log.createdAt as number} ago />
            </div>
            {log.message && (
              <div className={changeRequestStyle.logMessage}>
                <Markdown>{log.message}</Markdown>
              </div>
            )}
          </React.Fragment>
        ))}
        {(changeRequest.status === ChangeRequestStatus.Created ||
          changeRequest.status === ChangeRequestStatus.Conflict) &&
          (changeRequest.approvals.every(
            (approval) => approval.createdBy.id !== user?.id
          ) ||
            changeReview) &&
          organization.myAggregatedRole.reviews !== ReviewsRole.Create &&
          changeRequest.createdBy.id !== user?.id && (
            <div>
              <div className={changeRequestStyle.timelineIcon}></div>
              <Button
                inline
                icon={IconCheck}
                color="success"
                disabled={
                  approveChangeRequest.isPending ||
                  rejectChangeRequest.isPending
                }
                onClick={() =>
                  toast
                    .promise(
                      approveChangeRequest.mutateAsync(
                        changeRequestId as string
                      ),
                      {
                        loading: 'Approving review...',
                        success: 'Review approved',
                        error: 'Could not approve review',
                      }
                    )
                    .then(() => setChangeReview(false))
                }
              >
                Approve
              </Button>
              <Button
                inline
                color="error"
                disabled={
                  approveChangeRequest.isPending ||
                  rejectChangeRequest.isPending
                }
                icon={IconX}
                onClick={() =>
                  toast
                    .promise(
                      rejectChangeRequest.mutateAsync(
                        changeRequestId as string
                      ),
                      {
                        loading: 'Rejecting review...',
                        success: 'Review rejected',
                        error: 'Could not reject review',
                      }
                    )
                    .then(() => setChangeReview(false))
                }
              >
                Reject
              </Button>
              {changeReview && (
                <Button
                  onClick={() => setChangeReview(false)}
                  variant="light"
                  inline
                >
                  Cancel
                </Button>
              )}
            </div>
          )}
        {(changeRequest.status === ChangeRequestStatus.Created ||
          changeRequest.status === ChangeRequestStatus.Conflict) &&
          changeRequest.approvals.some(
            (approval) => approval.createdBy.id === user?.id
          ) &&
          !changeReview && (
            <div>
              <div className={changeRequestStyle.timelineIcon}></div>
              {organization.myAggregatedRole.reviews !== ReviewsRole.Create && (
                <Button onClick={() => setChangeReview(true)} inline>
                  Change review
                </Button>
              )}
              <Button
                onClick={() => removeApproval.mutate(changeRequestId as string)}
                loading={removeApproval.isPending}
                icon={IconTrash}
                variant="light"
                inline
              >
                Delete review
              </Button>
            </div>
          )}
      </div>

      {changeRequest.status === ChangeRequestStatus.Created && (
        <Card variant="outlined">
          <h2>
            Review
            {changeRequest.flag.project.changeRequestMinApprovals > 0 && (
              <div className={changeRequestStyle.approvalsCounter}>
                <div className={changeRequestStyle.approvalsLoader}>
                  <div
                    className={changeRequestStyle.approvalBar}
                    style={{
                      width:
                        (approvedBy.length /
                          Math.max(
                            rejectedBy.length + approvedBy.length,
                            changeRequest.flag.project.changeRequestMinApprovals
                          )) *
                          100 +
                        '%',
                    }}
                  />
                  <div
                    className={cx(
                      changeRequestStyle.approvalBar,
                      changeRequestStyle.rejected
                    )}
                    style={{
                      width:
                        (rejectedBy.length /
                          Math.max(
                            rejectedBy.length + approvedBy.length,
                            changeRequest.flag.project.changeRequestMinApprovals
                          )) *
                          100 +
                        '%',
                    }}
                  />
                </div>
                {rejectedBy.length > 0 ? (
                  <>
                    {rejectedBy.length}{' '}
                    <FormattedMessage
                      id="unit.rejections"
                      values={{ count: rejectedBy.length }}
                    />
                  </>
                ) : (
                  <>
                    {approvedBy.length} /{' '}
                    {changeRequest.flag.project.changeRequestMinApprovals}{' '}
                    <FormattedMessage
                      id="unit.approvals"
                      values={{
                        count:
                          changeRequest.flag.project.changeRequestMinApprovals,
                      }}
                    />
                  </>
                )}
              </div>
            )}
            <span style={{ float: 'right' }}>
              <Badge color="primary">Requested</Badge>
            </span>
          </h2>
          {changeRequest.roleApprovals.length > 0 && (
            <div className={changeRequestStyle.roleApprovals}>
              {changeRequest.roleApprovals.map((roleApproval) => (
                <div
                  key={roleApproval.role.id}
                  className={cx(changeRequestStyle.role)}
                >
                  <div
                    className={cx(
                      changeRequestStyle.roleApprovalIcon,
                      roleApproval.approved === true &&
                        changeRequestStyle.success,
                      roleApproval.approved === false &&
                        changeRequestStyle.error
                    )}
                  >
                    {roleApproval.approved === true ? (
                      <IconCheck size={12} stroke={2.5} />
                    ) : roleApproval.approved === false ? (
                      <IconX size={12} stroke={2.5} />
                    ) : null}
                  </div>
                  <div>
                    {roleApproval.role.name}{' '}
                    {roleApproval.approvals.length === 0 ? (
                      <Badge>Required</Badge>
                    ) : (
                      <small>
                        <FormattedList
                          value={roleApproval.approvals.map(
                            (approval) =>
                              approval.createdBy.name ??
                              approval.createdBy.email
                          )}
                        />
                      </small>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}

          {rejectedBy.length ? (
            <p style={{ marginBottom: 8 }}>
              Changes <b>cannot be applied</b> because they were <b>rejected</b>{' '}
              by <FormattedList value={rejectedBy} />.
            </p>
          ) : approvedBy.length <
            changeRequest.flag.project.changeRequestMinApprovals ? (
            <p style={{ marginBottom: 8 }}>
              {changeRequest.flag.project.changeRequestMinApprovals -
                approvedBy.length}{' '}
              <FormattedMessage
                id="unit.approvals"
                values={{
                  count:
                    changeRequest.flag.project.changeRequestMinApprovals -
                    approvedBy.length,
                }}
              />{' '}
              missing to apply changes.
            </p>
          ) : changeRequest.roleApprovals.some(
              (roleApproval) => roleApproval.approved !== true
            ) ? (
            <p style={{ marginBottom: 8 }}>
              An approval from a user with the role{' '}
              <FormattedList
                value={changeRequest.roleApprovals
                  .filter((roleApproval) => roleApproval.approved !== true)
                  .map((approval) => approval.role.name)}
              />{' '}
              is missing to apply changes.
            </p>
          ) : (
            <p style={{ marginBottom: 8 }}>Apply changes to the flag.</p>
          )}
          {[ReviewsRole.Create, ReviewsRole.Approve].includes(
            organization.myAggregatedRole.reviews
          ) ? (
            <Toolbar>
              <Button inline disabled icon={IconGitMerge}>
                Apply
              </Button>
              <Button inline disabled icon={IconCircleMinus}>
                Close
              </Button>
            </Toolbar>
          ) : (
            <Toolbar>
              <Button
                color="success"
                inline
                disabled={
                  mergeChangeRequest.isPending ||
                  closeChangeRequest.isPending ||
                  ((rejectedBy.length > 0 ||
                    approvedBy.length <
                      changeRequest.flag.project.changeRequestMinApprovals ||
                    changeRequest.roleApprovals.some(
                      (roleApproval) => roleApproval.approved !== true
                    )) &&
                    organization.myAggregatedRole.reviews !==
                      ReviewsRole.Bypass)
                }
                onClick={() =>
                  toast.promise(
                    mergeChangeRequest.mutateAsync(changeRequestId as string),
                    {
                      loading: 'Applying changes...',
                      success: 'Changes applied',
                      error: (error) => {
                        if (
                          error?.response?.errors?.[0]?.extensions?.code ===
                          'TRIAL_ENDED'
                        ) {
                          return 'Your trial has ended, go to the billing tab and choose a plan'
                        }

                        return 'Could not apply changes'
                      },
                    }
                  )
                }
                icon={IconGitMerge}
              >
                Apply
              </Button>
              <Button
                color="error"
                variant="light"
                inline
                icon={IconCircleMinus}
                onClick={() =>
                  toast.promise(
                    closeChangeRequest.mutateAsync(changeRequestId as string),
                    {
                      loading: 'Closing review...',
                      success: 'Review closed',
                      error: 'Could not close review',
                    }
                  )
                }
              >
                Close
              </Button>
            </Toolbar>
          )}
        </Card>
      )}

      {changeRequest.status === ChangeRequestStatus.Conflict && (
        <Card variant="outlined">
          <h2>
            Review
            <span style={{ float: 'right' }}>
              <Badge color="warning">Conflicts</Badge>
            </span>
          </h2>
          <ul className={changeRequestStyle.conflicts}>
            {changeRequest.conflicts.map((conflict, index) => (
              <li key={index}>
                <IconAlertCircleFilled size={16} />
                {conflict.message}
              </li>
            ))}
          </ul>
          <p style={{ marginBottom: 8 }}>
            Changes cannot be applied. Resolve conflicts or close this review.
          </p>
          {[ReviewsRole.Create, ReviewsRole.Approve].includes(
            organization.myAggregatedRole.reviews
          ) ? (
            <Toolbar>
              <Button inline disabled icon={IconGitMerge}>
                Apply
              </Button>
              <Button inline disabled icon={IconCircleMinus}>
                Close
              </Button>
            </Toolbar>
          ) : (
            <Toolbar>
              <Button color="success" inline disabled icon={IconGitMerge}>
                Apply
              </Button>
              <Button
                color="error"
                variant="light"
                inline
                icon={IconCircleMinus}
                onClick={() =>
                  toast.promise(
                    closeChangeRequest.mutateAsync(changeRequestId as string),
                    {
                      loading: 'Closing review...',
                      success: 'Review closed',
                      error: 'Could not close review',
                    }
                  )
                }
              >
                Close
              </Button>
            </Toolbar>
          )}
        </Card>
      )}

      {changeRequest.status === ChangeRequestStatus.Merged && (
        <Card variant="outlined">
          <h2>
            Review
            <span style={{ float: 'right' }}>
              <Badge color="success">Applied</Badge>
            </span>
          </h2>
          <p style={{ marginBottom: 8 }}>
            Review was applied by{' '}
            <b>
              {changeRequest.updatedBy?.name ?? changeRequest.updatedBy?.email}
            </b>{' '}
            on <ShortDate value={changeRequest.updatedAt as number} />
          </p>
        </Card>
      )}

      {changeRequest.status === ChangeRequestStatus.Closed && (
        <Card variant="outlined">
          <h2>
            Review
            <span style={{ float: 'right' }}>
              <Badge color="error">Closed</Badge>
            </span>
          </h2>
          <p style={{ marginBottom: 8 }}>
            Review was closed by{' '}
            <b>
              {changeRequest.updatedBy?.name ?? changeRequest.updatedBy?.email}
            </b>{' '}
            on <ShortDate value={changeRequest.updatedAt as number} />
          </p>
          {[ReviewsRole.Create, ReviewsRole.Approve].includes(
            organization.myAggregatedRole.reviews
          ) ? (
            <Toolbar>
              <Button inline icon={IconRefreshDot} disabled>
                Re-open review
              </Button>
            </Toolbar>
          ) : (
            <Toolbar>
              <Button
                inline
                icon={IconRefreshDot}
                onClick={() =>
                  toast.promise(
                    openChangeRequest.mutateAsync(changeRequestId as string),
                    {
                      loading: 'Re-opening review...',
                      success: 'Review re-opened',
                      error: 'Could not re-open review',
                    }
                  )
                }
              >
                Re-open review
              </Button>
            </Toolbar>
          )}
        </Card>
      )}
    </>
  )
}

export const changeRequestsRoute: RouteObject = {
  path: 'reviews',
  element: <Page />,
  children: [
    {
      path: ':changeRequestId',
      element: <ChangeRequest />,
    },
  ],
}
