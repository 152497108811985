import { FC, forwardRef } from 'react'
import { Tooltip } from '../Tooltip/Tooltip.tsx'
import { ColorIndicator } from '../../input/VariationInput/VariationInput.tsx'
import './Variation.scss'

export const Variation: FC<{
  variation: {
    name: string
    color: string
    description?: string
  }
}> = ({ variation }) => {
  return (
    <Tooltip>
      <Tooltip.Trigger
        component={forwardRef<HTMLSpanElement>((_, ref) => (
          <span className="variation-name" ref={ref}>
            <ColorIndicator color={variation.color} selected={false} />{' '}
            {variation.name}
          </span>
        ))}
      />
      <Tooltip.Content>{variation.description}</Tooltip.Content>
    </Tooltip>
  )
}
