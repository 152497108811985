import { CSSProperties, FC, forwardRef } from 'react'
import { SelectInput } from '../SelectInput/SelectInput.tsx'
import { useQuery } from '@tanstack/react-query'
import { gqlClient } from '../../../auth'
import { graphql } from '../../../gql'
import cx from 'classnames'
import './VariationInput.scss'

export const ColorIndicator: FC<{ color: string; selected: boolean }> = ({
  color,
  selected,
}) => {
  return (
    <div
      className={cx('variation-color-indicator', { selected })}
      style={{ ['--color']: color } as CSSProperties}
    />
  )
}

export const VariationInput = forwardRef<
  unknown,
  {
    value: string | null
    onChange: (value: string | null) => void
    flagId: string
  }
>(({ value, onChange, flagId }, ref) => {
  const { data } = useQuery({
    queryKey: ['flag', flagId],
    staleTime: 60000,
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query flagVariations($id: ID!) {
            flag(id: $id) {
              id
              variations {
                id
                name
                color
                description
                value
              }
            }
          }
        `),
        {
          id: flagId,
        }
      ),
  })
  return (
    <SelectInput
      value={value}
      ref={ref}
      onChange={onChange}
      autoWidth
      placeholder="Variation"
      showSelectedIcon
      options={
        data?.flag?.variations.map((variation) => ({
          value: variation.id,
          label: variation.name,
          tooltip: variation.description,
          icon: ({ selected }) => (
            <ColorIndicator selected={selected} color={variation.color} />
          ),
        })) ?? []
      }
    />
  )
})
