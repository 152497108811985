import { RouteObject, useNavigate } from 'react-router-dom'
import { TokenStatus, useQueryParamToken } from '../hooks/useQueryParamToken.ts'
import { graphql } from '../gql'
import { CenteredVerticalLayout } from '../components/layout/CenteredVerticalLayout/CenteredVerticalLayout.tsx'
import { SendEmailButton } from '../components/component/SendEmailButton/SendEmailButton.tsx'
import { EmptyStateImage } from '../components/layout/EmptyStateImage/EmptyStateImage.tsx'
import somethingWentWrong from '../assets/empty-state/something-went-wrong.svg'
import { IconArrowLeft } from '@tabler/icons-react'
import { resetPasswordRequestQuery } from '../queries/auth.ts'
import { InputWrapper } from '../components/input/InputWrapper/InputWrapper.tsx'
import { Button } from '../components/component/Button/Button.tsx'
import { useForm } from 'react-hook-form'
import { useFormSubmitMutation } from '../hooks/useFormSubmitMutation.ts'
import { PasswordInput } from '../components/input/PasswordInput/PasswordInput.tsx'
import { PasswordStrength } from '../components/component/PasswordStrength/PasswordStrength.tsx'
import { toast } from 'react-hot-toast'
import { setToken } from '../auth'
import { useState } from 'react'
import { Modal } from '../components/component/Modal/Modal.tsx'
import { PinInput } from '../components/input/PinInput/PinInput.tsx'
import { Toolbar } from '../components/component/Toolbar/Toolbar.tsx'

const Page = () => {
  const navigate = useNavigate()
  const token = useQueryParamToken()
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    watch,
  } = useForm({
    defaultValues: {
      newPassword: '',
      totpToken: '',
    },
  })
  const [tokenExpired, setTokenExpired] = useState(false)
  const [needTotp, setNeedTotp] = useState(false)

  const password = watch('newPassword')

  const submit = useFormSubmitMutation({
    mutation: graphql(`
      mutation resetPassword(
        $token: String!
        $newPassword: String!
        $totpToken: String
      ) {
        resetPassword(
          token: $token
          newPassword: $newPassword
          totpToken: $totpToken
        )
      }
    `),
    control,
    mapVariables: (values) => ({
      token: token.token ?? '',
      newPassword: values.newPassword,
      totpToken: values.totpToken,
    }),
    onSuccess: (data) => {
      toast.success('Password changed')
      navigate('/')
      setToken(data.resetPassword)
    },
    onError: {
      EXPIRED_TOKEN: () => setTokenExpired(true),
      MISSING_TOTP_TOKEN: () => setNeedTotp(true),
    },
  })

  if (token.status === TokenStatus.EXPIRED || tokenExpired) {
    return (
      <CenteredVerticalLayout center>
        <h1>Link expired</h1>
        <p>
          Password reset links have a limited lifespan for security purposes.
          You can send a new link to <b>{token.decoded?.email}</b>.
        </p>
        <SendEmailButton
          mutation={resetPasswordRequestQuery}
          variables={{ email: token.decoded?.email ?? '' }}
        >
          Send new link
        </SendEmailButton>
      </CenteredVerticalLayout>
    )
  }

  if (token.status === TokenStatus.INVALID) {
    return (
      <EmptyStateImage
        imageSrc={somethingWentWrong}
        title="Cannot reset password"
        text="Link is probably invalid. Did you copy the link correctly?"
        cta="Back"
        ctaIcon={IconArrowLeft}
        ctaTo="/login"
      />
    )
  }

  return (
    <CenteredVerticalLayout>
      {needTotp && (
        <Modal>
          <form onSubmit={handleSubmit(submit)}>
            <h2 style={{ marginBottom: 24 }}>Two-factor authentication</h2>
            <InputWrapper
              component={PinInput}
              control={control}
              name="totpToken"
              label="Security code"
              tooltip="From your authenticator app"
              rules={{ required: true }}
              length={6}
            />
            <Toolbar alignRight>
              <Button color="primary" type="submit" loading={isSubmitting}>
                Login
              </Button>
              <Button onClick={() => setNeedTotp(false)}>Cancel</Button>
            </Toolbar>
          </form>
        </Modal>
      )}
      <h1>Change your password</h1>
      <form onSubmit={handleSubmit(submit)}>
        <input
          type="text"
          hidden
          name="email"
          defaultValue={token.decoded.email}
          autoComplete="username"
        />
        <InputWrapper
          component={PasswordInput}
          control={control}
          name="newPassword"
          label="Password"
          rules={{ required: true }}
          autoComplete="new-password"
          autoFocus
          helper={<PasswordStrength password={password} />}
        />
        <Button color="primary" type="submit" loading={isSubmitting}>
          Reset password
        </Button>
        <Button
          variant="light"
          icon={IconArrowLeft}
          to="/login"
          style={{ marginTop: 8 }}
        >
          Cancel
        </Button>
      </form>
    </CenteredVerticalLayout>
  )
}

export const resetPasswordRoute: RouteObject = {
  path: '/reset-password',
  element: <Page />,
}
