import { forwardRef } from 'react'
import { TextInput, TextInputProps } from '../TextInput/TextInput.tsx'
import {
  IconCalendar,
  IconChevronLeft,
  IconChevronRight,
} from '@tabler/icons-react'
import { Dropdown } from '../../component/DropdownMenu/DropdownMenu.tsx'
import { Day, useLilius } from 'use-lilius'
import { IconButton } from '../../component/IconButton/IconButton.tsx'
import './DateInput.scss'
import { FormattedDate } from 'react-intl'
import { format, isToday } from 'date-fns'
import cx from 'classnames'

export const DateInput = forwardRef<
  HTMLInputElement,
  {
    value: string | null
    onChange: (value: string | null) => void
    time?: boolean
  } & Pick<TextInputProps, 'placeholder' | 'autoFocus' | 'disabled'>
>(({ value, onChange, time, ...props }, ref) => {
  const { viewPreviousMonth, viewNextMonth, viewing, calendar, setViewing } =
    useLilius({
      weekStartsOn: Day.MONDAY,
    })

  return (
    <TextInput
      value={value ?? ''}
      ref={ref}
      onChange={onChange}
      type={time ? 'datetime-local' : 'date'}
      {...props}
      suffix={
        <Dropdown
          placement="bottom-end"
          alignmentAxisOffset={-9}
          mainAxisOffset={14}
          onOpenChange={(open) => {
            if (open && value) {
              setViewing(new Date(value))
            }
          }}
          component={forwardRef(({ onClick, ...props }, ref) => (
            <IconCalendar
              size={16}
              {...props}
              onClick={(e) => {
                e.preventDefault()
                onClick?.(e)
              }}
              ref={ref}
            />
          ))}
        >
          <div className="calendar">
            <IconButton icon={IconChevronLeft} onClick={viewPreviousMonth} />
            <span className="current-month">
              <FormattedDate value={viewing} month="long" year="numeric" />
            </span>
            <IconButton icon={IconChevronRight} onClick={viewNextMonth} />
            {calendar[0][0].map((day) => (
              <div key={`${day}`} className="weekday">
                <FormattedDate value={day} weekday="narrow" />
              </div>
            ))}
            {calendar[0].map((week) =>
              week.map((day) => (
                <div
                  className={cx('day', {
                    'day-outside-month': day.getMonth() !== viewing.getMonth(),
                    today: isToday(day),
                    selected: format(day, 'yyyy-MM-dd') === value?.slice(0, 10),
                  })}
                  key={`${day}`}
                  onClick={() => {
                    onChange(
                      format(day, 'yyyy-MM-dd') +
                        (time ? value?.slice(10) || 'T00:00' : '')
                    )
                  }}
                >
                  <FormattedDate value={day} day="numeric" />
                </div>
              ))
            )}
          </div>
        </Dropdown>
      }
    />
  )
})
