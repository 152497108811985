import { FC, MouseEvent } from 'react'
import './Code.scss'
import { Tooltip } from '../Tooltip/Tooltip.tsx'
import { useTempState } from '../../../hooks/useTempState.tsx'
import { IconButton } from '../IconButton/IconButton.tsx'
import { IconCopy } from '@tabler/icons-react'

export const Code: FC<{
  value: string
  secret?: boolean
  large?: boolean
  shorten?: number
}> = ({ value, secret, large, shorten }) => {
  const [copied, setCopied] = useTempState()
  const shortened =
    shorten && value.length > 13
      ? value.slice(0, shorten) + '...' + value.slice(-shorten)
      : value

  if (large) {
    return (
      <code className="code large">
        {secret
          ? value
              ?.split('')
              .map(() => '•')
              .join('')
          : shortened}

        <Tooltip>
          <Tooltip.Trigger
            component={IconButton}
            icon={IconCopy}
            onClick={(event: MouseEvent) => {
              navigator.clipboard.writeText(value)
              setCopied()
              event.preventDefault()
            }}
          />
          <Tooltip.Content>
            {copied ? 'Copied!' : 'Click to copy value'}
          </Tooltip.Content>
        </Tooltip>
      </code>
    )
  }

  return (
    <Tooltip>
      <Tooltip.Trigger
        component="code"
        className="code"
        onClick={(event: MouseEvent) => {
          navigator.clipboard.writeText(value)
          setCopied()
          event.preventDefault()
        }}
        children={
          secret
            ? value
                ?.split('')
                .map(() => '●')
                .join('')
            : shortened
        }
      />
      <Tooltip.Content>
        {copied ? 'Copied!' : 'Click to copy value'}
      </Tooltip.Content>
    </Tooltip>
  )
}
