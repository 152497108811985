import {
  RouteObject,
  unstable_usePrompt as usePrompt,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { Control, useFieldArray, useForm } from 'react-hook-form'
import { Sections } from '../../../../../components/component/Sections/Sections.tsx'
import { Card } from '../../../../../components/component/Card/Card.tsx'
import { InputWrapper } from '../../../../../components/input/InputWrapper/InputWrapper.tsx'
import {
  FloatInput,
  TextInput,
} from '../../../../../components/input/TextInput/TextInput.tsx'
import { Button } from '../../../../../components/component/Button/Button.tsx'
import {
  IconAbc,
  IconAlertTriangle,
  IconAlignLeft,
  IconBraces,
  IconCheck,
  IconCircleDotted,
  IconEyeglass,
  IconGripVertical,
  IconHash,
  IconHelpCircleFilled,
  IconPlus,
  IconToggleRight,
  IconTrash,
} from '@tabler/icons-react'
import style from './Variations.module.scss'
import { IconButton } from '../../../../../components/component/IconButton/IconButton.tsx'
import { CSSProperties, FC, forwardRef, ReactNode } from 'react'
import { useFormDefaultValue } from '../../../../../hooks/useFormDefaultValue.ts'
import { graphql } from '../../../../../gql'
import { Tooltip } from '../../../../../components/component/Tooltip/Tooltip.tsx'
import { SelectInput } from '../../../../../components/input/SelectInput/SelectInput.tsx'
import { Dropdown } from '../../../../../components/component/DropdownMenu/DropdownMenu.tsx'
import cx from 'classnames'
import { Toolbar } from '../../../../../components/component/Toolbar/Toolbar.tsx'
import styleConditions from './Conditions.module.scss'
import { useFormSubmitMutation } from '../../../../../hooks/useFormSubmitMutation.ts'
import { toast } from 'react-hot-toast'
import { JsonInput } from '../../../../../components/input/JsonInput/JsonInput.tsx'
import { useCurrentOrgSafe } from '../../../../../hooks/useCurrentOrgSafe.ts'
import { useQueryClient } from '@tanstack/react-query'
import {
  ChangeRequestRequirement,
  ManageFlagsRole,
  ReviewsRole,
} from '../../../../../gql/graphql.ts'
import { getMovedItem } from '../../../../../utils/reorder.ts'
import { DragControls, motion, Reorder, useDragControls } from 'framer-motion'

enum ValuesType {
  NULL = 'NULL',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
  JSON = 'JSON',
}

const colors = [
  '#8f8f8f',
  '#f44336',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#009688',
  '#4caf50',
  '#ffc107',
  '#ff9800',
]

const safeJSONParse = (value: string | null) => {
  try {
    return JSON.parse(value ?? '')
  } catch (e) {
    return null
  }
}

export const VariationColorInput = forwardRef<
  any,
  {
    value: string
    onChange: (value: string) => void
  }
>(({ value, onChange }, ref) => {
  return (
    <Dropdown
      placement="top-start"
      mainAxisOffset={13}
      alignmentAxisOffset={-9}
      className={style.colorSwatchDropdown}
      component={forwardRef(({ onClick, ...props }, ref) => (
        <div
          {...props}
          ref={ref}
          onClick={(e) => {
            e.preventDefault()
            onClick(e)
          }}
          className={style.colorIndicator}
          style={{ ['--color']: value } as CSSProperties}
        />
      ))}
      ref={ref}
    >
      <div className={style.colorSwatch}>
        {colors.map((color, i) => (
          <div key={i}>
            <motion.div
              key={color}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                delay: i * 0.02,
                type: 'spring',
                stiffness: 700,
                damping: 20,
              }}
              className={cx(
                style.colorIndicator,
                color === value && style.active
              )}
              style={{ ['--color']: color } as CSSProperties}
              onClick={() => onChange(color)}
            />
          </div>
        ))}
      </div>
    </Dropdown>
  )
})

const Item: FC<{
  render: (controls: DragControls) => ReactNode
}> = ({ render }) => {
  const controls = useDragControls()

  return render(controls)
}

const Page = () => {
  const { organization, project } = useCurrentOrgSafe()
  const { flagId } = useParams()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      valuesType: ValuesType.NULL,
      inactive: {
        id: null as string | null,
        name: '',
        description: '',
        color: colors[0],
      },
      active: [
        {
          id: null as string | null,
          name: '',
          description: '',
          color: colors[1],
          used: false,
          value: null as any,
        },
      ],
    },
  })

  const valuesType = watch('valuesType')

  const { data: flagData } = useFormDefaultValue({
    control,
    queryKey: ['flag', flagId, 'variations default values'],
    request: graphql(`
      query updateFlagVariationsDefaultData($id: ID!) {
        flag(id: $id) {
          id
          sharedWithMe
          variations {
            id
            name
            description
            active
            used
            value
            color
          }
        }
      }
    `),
    variables: { id: flagId as string },
    mapDefaultValues: (data) => {
      let valuesType = ValuesType.NULL

      data.flag?.variations.forEach(({ active, value }) => {
        if (!active || value === null) {
          return
        }
        if (typeof value === 'number') {
          valuesType =
            valuesType === ValuesType.NUMBER || valuesType === ValuesType.NULL
              ? ValuesType.NUMBER
              : ValuesType.JSON
        }
        if (typeof value === 'string') {
          valuesType =
            valuesType === ValuesType.STRING || valuesType === ValuesType.NULL
              ? ValuesType.STRING
              : ValuesType.JSON
        }
        if (typeof value === 'boolean') {
          valuesType =
            valuesType === ValuesType.BOOLEAN || valuesType === ValuesType.NULL
              ? ValuesType.BOOLEAN
              : ValuesType.JSON
        }
        if (typeof value === 'object') {
          valuesType = ValuesType.JSON
        }
      })

      return {
        valuesType,
        inactive: data.flag?.variations.find(({ active }) => !active) ?? {
          id: null,
          name: '',
          description: '',
          color: colors[0],
        },
        active:
          data.flag?.variations
            .filter(({ active }) => active)
            .map((variation) => ({
              ...variation,
              value:
                valuesType === ValuesType.JSON
                  ? JSON.stringify(variation.value, null, 2)
                  : variation.value,
            })) ?? [],
      }
    },
  })

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'active',
  })

  usePrompt({
    when: isDirty,
    message: 'You have unsaved changes. Are you sure you want to leave?',
  })

  const queryClient = useQueryClient()

  const mapVariables = (
    variations: typeof control extends Control<infer D> ? D : never
  ) => ({
    id: flagId as string,
    variations: [
      {
        id: variations.inactive.id,
        name: variations.inactive.name,
        description: variations.inactive.description,
        active: false,
        value: null,
        color: variations.inactive.color,
      },
      ...variations.active.map(({ id, name, description, value, color }) => ({
        id,
        name,
        description,
        active: true,
        value:
          variations.valuesType === ValuesType.JSON
            ? safeJSONParse(value)
            : value,
        color,
      })),
    ],
  })
  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation updateFlagVariations(
        $id: ID!
        $variations: [UpdateFlagVariation!]!
      ) {
        flag(id: $id) {
          updateVariations(variations: $variations) {
            id
            variations {
              id
              name
              description
              active
              value
              color
            }
          }
        }
      }
    `),
    mapVariables,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['flag', flagId] })
      toast.success('Variations updated')
    },
  })

  const navigate = useNavigate()

  const saveChangeRequest = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation updateFlagChangeRequestVariations(
        $id: ID!
        $variations: [UpdateFlagVariation!]!
      ) {
        flag(id: $id) {
          createChangeRequest(
            conditions: null
            information: null
            variations: $variations
          ) {
            id
          }
        }
      }
    `),
    mapVariables,
    onSuccess: (data) => {
      toast.success('Review created')
      queryClient.invalidateQueries({
        queryKey: ['flag', flagId],
      })
      setTimeout(
        () =>
          navigate(
            `/project/${project.slug}/feature-flags/${flagId}/reviews/${data.flag?.createChangeRequest.id}`
          ),
        100
      )
    },
  })

  usePrompt({
    when: isDirty,
    message: 'You have unsaved changes. Are you sure you want to leave?',
  })

  return (
    <form
      onSubmit={handleSubmit(
        organization.myAggregatedRole.manageFlags !== ManageFlagsRole.None
          ? submit
          : () => null
      )}
      style={{ marginTop: 24 }}
    >
      <Sections titleColumnWidthFr={0.4}>
        <Sections.Section
          title="Fallback"
          subTitle={
            <p>
              This variation is used when specific conditions are met, or when
              something goes wrong.{' '}
              <a
                href="https://tggl.io/help/concepts/variations"
                target="_blank"
              >
                Learn more
              </a>
            </p>
          }
        >
          <Card>
            <div className={style.cardHeader}>
              <InputWrapper
                placeholder="Name"
                name={`inactive.name`}
                component={TextInput}
                control={control}
                rules={{ required: true }}
                prefix={
                  <InputWrapper
                    name={`inactive.color`}
                    component={VariationColorInput}
                    control={control}
                  />
                }
              />
              {valuesType !== ValuesType.NULL && (
                <span className={style.hardCodedValue}>
                  Hard-codded value{' '}
                  <Tooltip>
                    <Tooltip.Trigger
                      component={IconHelpCircleFilled}
                      size={14}
                    />
                    <Tooltip.Content>
                      This value must be edited in the code directly and will be
                      used if anything goes wrong with Tggl
                    </Tooltip.Content>
                  </Tooltip>
                </span>
              )}
            </div>
            <InputWrapper
              placeholder="Description"
              name={`inactive.description`}
              component={TextInput}
              control={control}
              prefix={
                <Tooltip>
                  <Tooltip.Trigger component={IconAlignLeft} size={16} />
                  <Tooltip.Content>
                    A short description for your team
                  </Tooltip.Content>
                </Tooltip>
              }
            />
          </Card>
        </Sections.Section>
        <hr />
        <Sections.Section
          title="Active"
          subTitle={
            <>
              {fields.length === 1 ? (
                <p style={{ marginBottom: 16 }}>
                  This variation is used when specific conditions are met.
                </p>
              ) : (
                <p style={{ marginBottom: 16 }}>
                  Those variations are used when specific conditions are met.
                </p>
              )}

              <SelectInput
                value={valuesType}
                onChange={(newType) => {
                  setValue('valuesType', newType)

                  if (newType === ValuesType.NULL) {
                    fields.forEach((_, index) =>
                      setValue(`active.${index}.value`, null, {
                        shouldDirty: true,
                        shouldTouch: true,
                      })
                    )
                  }

                  if (newType === ValuesType.NUMBER) {
                    fields.forEach((_, index) => {
                      const newValue = parseFloat(
                        getValues(`active.${index}.value`)
                      )
                      setValue(
                        `active.${index}.value`,
                        isNaN(newValue) ? 0 : newValue,
                        {
                          shouldDirty: true,
                          shouldTouch: true,
                        }
                      )
                    })
                  }

                  if (newType === ValuesType.STRING) {
                    fields.forEach((_, index) => {
                      const newValue = getValues(`active.${index}.value`)
                      setValue(
                        `active.${index}.value`,
                        newValue !== null ? String(newValue) : '',
                        {
                          shouldDirty: true,
                          shouldTouch: true,
                        }
                      )
                    })
                  }

                  if (newType === ValuesType.JSON) {
                    fields.forEach((_, index) => {
                      const newValue = getValues(`active.${index}.value`)
                      setValue(
                        `active.${index}.value`,
                        JSON.stringify(newValue),
                        {
                          shouldDirty: true,
                          shouldTouch: true,
                        }
                      )
                    })
                  }

                  if (newType === ValuesType.BOOLEAN) {
                    fields.forEach((_, index) => {
                      setValue(
                        `active.${index}.value`,
                        Boolean(getValues(`active.${index}.value`)),
                        {
                          shouldDirty: true,
                          shouldTouch: true,
                        }
                      )
                    })
                  }
                }}
                showSelectedIcon
                options={[
                  {
                    value: ValuesType.NULL,
                    label: 'No value',
                    icon: IconCircleDotted,
                  },
                  { value: ValuesType.STRING, label: 'String', icon: IconAbc },
                  { value: ValuesType.NUMBER, label: 'Number', icon: IconHash },
                  {
                    value: ValuesType.BOOLEAN,
                    label: 'Boolean',
                    icon: IconToggleRight,
                  },
                  { value: ValuesType.JSON, label: 'JSON', icon: IconBraces },
                ]}
              />
            </>
          }
        >
          <Reorder.Group
            axis="y"
            values={fields}
            className={style.variationsList}
            onReorder={(newValue) => {
              const moved = getMovedItem(fields as any, newValue as any)

              if (moved) {
                move(moved.from, moved.to)
              }
            }}
            as="div"
          >
            {fields.map((field, index) => (
              <Item
                key={field.id}
                render={(controls) => (
                  <Reorder.Item
                    key={field.id}
                    value={field}
                    layout="position"
                    transition={{
                      type: 'spring',
                      stiffness: 700,
                      damping: 30,
                      mass: 0.8,
                    }}
                    dragListener={false}
                    dragControls={controls}
                    as="div"
                  >
                    <Card key={field.id}>
                      <div className={style.cardHeader}>
                        <InputWrapper
                          placeholder="Name"
                          name={`active.${index}.name`}
                          component={TextInput}
                          control={control}
                          rules={{ required: true }}
                          prefix={
                            <InputWrapper
                              name={`active.${index}.color`}
                              component={VariationColorInput}
                              control={control}
                            />
                          }
                        />
                        {valuesType === ValuesType.BOOLEAN && (
                          <InputWrapper
                            name={`active.${index}.value`}
                            component={SelectInput}
                            control={control}
                            options={[
                              { label: 'True', value: true },
                              { label: 'False', value: false },
                            ]}
                            rules={{
                              required: false,
                              validate: (value) => typeof value === 'boolean',
                            }}
                            placeholder="Value"
                          />
                        )}
                        {valuesType === ValuesType.NUMBER && (
                          <InputWrapper
                            name={`active.${index}.value`}
                            component={FloatInput}
                            control={control}
                            rules={{ required: true, validate: () => true }}
                            placeholder="Value"
                            prefix={
                              <Tooltip>
                                <Tooltip.Trigger
                                  component={IconHash}
                                  size={16}
                                />
                                <Tooltip.Content>
                                  This value will be used inside the code
                                </Tooltip.Content>
                              </Tooltip>
                            }
                          />
                        )}
                        {valuesType === ValuesType.STRING && (
                          <InputWrapper
                            name={`active.${index}.value`}
                            component={TextInput}
                            control={control}
                            rules={{ required: true, validate: () => true }}
                            placeholder="Value"
                            prefix={
                              <Tooltip>
                                <Tooltip.Trigger
                                  component={IconAbc}
                                  size={16}
                                />
                                <Tooltip.Content>
                                  This value will be used inside the code
                                </Tooltip.Content>
                              </Tooltip>
                            }
                          />
                        )}

                        <div
                          className="handler"
                          onPointerDown={(e) => {
                            controls.start(e)
                            e.preventDefault()
                          }}
                        >
                          <IconGripVertical size={18} />
                        </div>

                        <div style={{ flex: 1 }} />

                        <Tooltip>
                          <Tooltip.Trigger
                            component={IconButton}
                            icon={IconTrash}
                            onClick={() => remove(index)}
                            disabled={field.used}
                          />
                          <Tooltip.Content>
                            {field.used
                              ? 'This variation cannot be deleted because it is used in one of the conditions'
                              : 'Delete'}
                          </Tooltip.Content>
                        </Tooltip>
                      </div>
                      <InputWrapper
                        placeholder="Description"
                        name={`active.${index}.description`}
                        component={TextInput}
                        control={control}
                        prefix={
                          <Tooltip>
                            <Tooltip.Trigger
                              component={IconAlignLeft}
                              size={16}
                            />
                            <Tooltip.Content>
                              A short description for your team
                            </Tooltip.Content>
                          </Tooltip>
                        }
                      />
                      {valuesType === ValuesType.JSON && (
                        <InputWrapper
                          name={`active.${index}.value`}
                          component={JsonInput}
                          control={control}
                          rules={{
                            required: false,
                            validate: () => true,
                          }}
                        />
                      )}
                    </Card>
                  </Reorder.Item>
                )}
              />
            ))}
            <Button
              icon={IconPlus}
              onClick={() =>
                append({
                  id: null,
                  name: '',
                  description: '',
                  color: colors[1],
                  used: false,
                  value: null,
                })
              }
            >
              Add variation
            </Button>
          </Reorder.Group>
        </Sections.Section>
      </Sections>
      <motion.div
        className={styleConditions.toolbar}
        initial={{ y: '100%' }}
        animate={{ y: isDirty ? 0 : '100%' }}
      >
        <Toolbar>
          {organization.myAggregatedRole.manageFlags === ManageFlagsRole.All ||
          (organization.myAggregatedRole.manageFlags ===
            ManageFlagsRole.Shared &&
            flagData?.flag?.sharedWithMe) ? (
            <>
              {(project.requireChangeRequest !==
                ChangeRequestRequirement.Always ||
                organization.myAggregatedRole.reviews === ReviewsRole.Bypass ||
                !organization.canMakeReviews) && (
                <Tooltip>
                  <Tooltip.Trigger
                    component="div"
                    children={
                      <Button
                        color="primary"
                        loading={isSubmitting}
                        type="submit"
                        icon={IconCheck}
                        disabled={
                          project.requireChangeRequest !==
                            ChangeRequestRequirement.Never &&
                          organization.myAggregatedRole.reviews !==
                            ReviewsRole.Bypass &&
                          organization.canMakeReviews
                        }
                      >
                        Save changes
                      </Button>
                    }
                  />
                  <Tooltip.Content>
                    {project.requireChangeRequest !==
                      ChangeRequestRequirement.Never &&
                    organization.myAggregatedRole.reviews !==
                      ReviewsRole.Bypass &&
                    organization.canMakeReviews
                      ? 'Changing variations may impact production'
                      : 'Apply changes immediately'}
                  </Tooltip.Content>
                </Tooltip>
              )}
              {organization.canMakeReviews && (
                <Tooltip>
                  <Tooltip.Trigger
                    component={Button}
                    color="success"
                    variant="light"
                    loading={isSubmitting}
                    icon={IconEyeglass}
                    children="Request review"
                    onClick={handleSubmit(saveChangeRequest)}
                  />
                  <Tooltip.Content>
                    Someone else will need to approve these changes before
                    applying them
                  </Tooltip.Content>
                </Tooltip>
              )}
            </>
          ) : (
            <>
              <IconAlertTriangle
                size={16}
                style={{ color: 'var(--color-error)' }}
              />{' '}
              You are only a viewer, all changes will be lost
            </>
          )}
        </Toolbar>
      </motion.div>
    </form>
  )
}

export const variationsRoute: RouteObject = {
  path: 'variations',
  element: <Page />,
}
