import { CSSProperties, FC, ReactNode, useId } from 'react'
import {
  ControllerRenderProps,
  FieldPath,
  FieldPathValue,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form'
import './InputWrapper.scss'
import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { Tooltip } from '../../component/Tooltip/Tooltip.tsx'
import { IconHelpCircleFilled } from '@tabler/icons-react'

export type InputWrapperProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TProps extends {
    value: FieldPathValue<TFieldValues, TName>
    onChange: (value: FieldPathValue<TFieldValues, TName>) => void
  } = {
    value: FieldPathValue<TFieldValues, TName>
    onChange: (value: FieldPathValue<TFieldValues, TName>) => void
  }
> = UseControllerProps<TFieldValues, TName> & {
  label?: string
  component: FC<TProps>
  helper?: ReactNode
  tooltip?: string
  style?: CSSProperties
  onChangeSpy?: (value: FieldPathValue<TFieldValues, TName>) => void
} & Omit<TProps, keyof UseControllerProps | keyof ControllerRenderProps>

export const InputWrapper = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TProps extends {
    value: FieldPathValue<TFieldValues, TName>
    onChange: (value: FieldPathValue<TFieldValues, TName>) => void
  } = {
    value: FieldPathValue<TFieldValues, TName>
    onChange: (value: FieldPathValue<TFieldValues, TName>) => void
  }
>({
  label,
  component: Component,
  helper,
  tooltip,
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  style,
  onChangeSpy,
  ...props
}: InputWrapperProps<TFieldValues, TName, TProps>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  })

  const id = useId()

  return (
    <div className={cx('input-wrapper', error && 'error')} style={style}>
      {label && (
        <label htmlFor={id}>
          {label}{' '}
          {tooltip && (
            <Tooltip>
              <Tooltip.Trigger
                component={IconHelpCircleFilled}
                size={16}
                style={{ color: 'var(--color-primary-light)' }}
              />
              <Tooltip.Content>{tooltip}</Tooltip.Content>
            </Tooltip>
          )}
        </label>
      )}
      {/* @ts-ignore */}
      <Component
        id={id}
        error={error}
        {...field}
        onChange={(arg) => {
          field.onChange(arg)
          onChangeSpy?.(arg)
        }}
        {...props}
      />
      <AnimatePresence>
        {error?.message && (
          <motion.div
            key="error-message"
            className="error-message"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
          >
            <span>{error?.message}</span>
          </motion.div>
        )}
        {helper}
      </AnimatePresence>
    </div>
  )
}
