import { FC } from 'react'
import { Tooltip } from '../Tooltip/Tooltip.tsx'

export const Truncate: FC<{ text?: string | null; length: number }> = ({
  text,
  length,
}) => {
  if (!text) {
    return null
  }

  if (text.length <= length) {
    return text
  }

  return (
    <>
      {text.slice(0, text.indexOf(' ', length))}
      <Tooltip>
        <Tooltip.Trigger component={() => '...'} wrap />
        <Tooltip.Content>{text}</Tooltip.Content>
      </Tooltip>
    </>
  )
}
