import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { QueryKey, useQuery } from '@tanstack/react-query'
import { gqlClient } from '../auth'
import { useEffect, useRef } from 'react'
import { Control, FieldValues } from 'react-hook-form'

export const useFormDefaultValue = <
  TResult,
  TVariables,
  TFieldValues extends FieldValues = FieldValues
>({
  request,
  variables,
  control,
  mapDefaultValues,
  queryKey,
  skip = false,
}: TVariables extends Record<string, never>
  ? {
      request: TypedDocumentNode<TResult, TVariables>
      queryKey: QueryKey
      variables?: undefined
      control: Control<TFieldValues>
      skip?: boolean
      mapDefaultValues: (result: TResult) => TFieldValues
    }
  : {
      request: TypedDocumentNode<TResult, TVariables>
      queryKey: QueryKey
      variables: TVariables
      control: Control<TFieldValues>
      skip?: boolean
      mapDefaultValues: (result: TResult) => TFieldValues
    }) => {
  const query = useQuery({
    queryKey,
    queryFn: async () => gqlClient.request(request, variables ?? undefined),
    staleTime: 0,
    enabled: !skip,
  })
  const ready = useRef(false)

  useEffect(() => {
    const data = query.data
    if (data) {
      setTimeout(() => control._reset(mapDefaultValues(data)), 0)
      ready.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data])

  return { ...query, ready }
}
