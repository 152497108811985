import { forwardRef, ReactNode, RefAttributes } from 'react'
import './BigRadioInput.scss'
import cx from 'classnames'

export type Option<T = string> = {
  label: ReactNode
  value: T
  imageSrc?: string
  description?: ReactNode
  disabled?: boolean
}

export type BigRadioInputProps<T = string> = {
  value: T
  onChange: (value: T) => void
  options: Option<T>[]
  className?: string
  id?: string
  name?: string
}

export const BigRadioInput = forwardRef<
  HTMLFieldSetElement,
  BigRadioInputProps
>(({ options, value, className, onChange, ...props }, ref) => {
  return (
    <fieldset
      ref={ref}
      className={cx('big-radio-input', className)}
      id={props.id}
    >
      {options.map((option) => (
        <label key={String(option.value)}>
          <input
            type="radio"
            name={props.name}
            value={String(option.value)}
            checked={value === option.value}
            onChange={() => onChange(option.value)}
            disabled={option.disabled}
          />
          {option.imageSrc && <img src={option.imageSrc} />}
          <div className="description-wrapper">
            <div className="label">{option.label}</div>
            <div className="description">{option.description}</div>
          </div>
          <div className="radio" />
        </label>
      ))}
    </fieldset>
  )
}) as <T = string>(
  props: BigRadioInputProps<T> & RefAttributes<HTMLFieldSetElement>
) => ReactNode
