import {
  Link,
  Navigate,
  RouteObject,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { gqlClient, setToken } from '../auth'
import { graphql } from '../gql'
import { toast } from 'react-hot-toast'
import { useSilentLoader } from '../hooks/useSilentLoader.tsx'
import { CenteredVerticalLayout } from '../components/layout/CenteredVerticalLayout/CenteredVerticalLayout.tsx'
import { useForm } from 'react-hook-form'
import style from './Invitation.module.scss'
import { InputWrapper } from '../components/input/InputWrapper/InputWrapper.tsx'
import { PasswordInput } from '../components/input/PasswordInput/PasswordInput.tsx'
import { PasswordStrength } from '../components/component/PasswordStrength/PasswordStrength.tsx'
import { TextInput } from '../components/input/TextInput/TextInput.tsx'
import { Button } from '../components/component/Button/Button.tsx'
import { useFormSubmitMutation } from '../hooks/useFormSubmitMutation.ts'

const Page = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { data, isLoading } = useQuery({
    queryKey: ['invitation', searchParams.get('i')],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query invitation($key: String!) {
            invitation(key: $key) {
              id
              email
              invitedBy {
                id
                firstName
              }
              organization {
                id
                name
                domain
              }
            }
          }
        `),
        {
          key: searchParams.get('i') as string,
        }
      ),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
    control,
    setValue,
  } = useForm({ defaultValues: { password: '', email: '' } })

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation acceptInvitation($key: String!, $password: String!) {
        acceptInvitation(invitationKey: $key, password: $password)
      }
    `),
    mapVariables: (data) => ({
      key: searchParams.get('i') as string,
      password: data.password,
    }),
    onSuccess: (data) => {
      toast.success('Welcome to Tggl!')
      setToken(data.acceptInvitation)
      navigate('/onboarding/profile')
    },
  })

  const password = watch('password')

  const silentLoader = useSilentLoader(isLoading)

  useEffect(() => {
    if (!isLoading && !data?.invitation) {
      toast.error('Invitation has expired')
    }

    if (data?.invitation) {
      setValue('email', data.invitation.email)
    }
  }, [data?.invitation, isLoading, setValue])

  if (silentLoader) {
    return null
  }

  if (isLoading) {
    return <CenteredVerticalLayout.Loader />
  }

  if (!data?.invitation) {
    return <Navigate to="/login" />
  }

  return (
    <>
      {data.invitation.organization.domain && (
        <img
          src={`https://img.logo.dev/${data.invitation.organization.domain}?token=pk_PMblKfzbSO6DrsIAU8SfpA`}
          className={style.companyLogo}
        />
      )}
      <h1>Join {data.invitation.organization.name}</h1>

      <p style={{ marginBottom: 16 }}>
        {data.invitation.invitedBy.firstName} has invited you to join{' '}
        {data.invitation.organization.name} on Tggl. Just choose a password and
        you are good to go!
      </p>

      <form onSubmit={handleSubmit(submit)}>
        <InputWrapper
          component={TextInput}
          control={control}
          name="email"
          label="Email"
          autoComplete="username"
          disabled
        />
        <InputWrapper
          component={PasswordInput}
          control={control}
          name="password"
          label="Password"
          rules={{ required: true }}
          autoComplete="new-password"
          helper={<PasswordStrength password={password} />}
        />
        <Button color="primary" type="submit" loading={isSubmitting}>
          Let's go!
        </Button>
      </form>

      <Link to="/login" style={{ marginTop: 16, display: 'block' }}>
        Already have an account? Login
      </Link>
    </>
  )
}

export const invitationRoute: RouteObject = {
  path: '/invitation',
  element: (
    <CenteredVerticalLayout>
      <Page />
    </CenteredVerticalLayout>
  ),
}
