import { forwardRef, useCallback } from 'react'
import { InputWrapper } from '../InputWrapper/InputWrapper.tsx'
import { MultiSelectInput, SelectOption } from '../SelectInput/SelectInput.tsx'
import { fetchUser, gqlClient } from '../../../auth'
import { graphql } from '../../../gql'
import {
  confirmDelete,
  openModal,
  useModalContext,
} from '../../component/Modal/Modal.tsx'
import { useCurrentOrgSafe } from '../../../hooks/useCurrentOrgSafe.ts'
import { useForm } from 'react-hook-form'
import { useFormSubmitMutation } from '../../../hooks/useFormSubmitMutation.ts'
import { toast } from 'react-hot-toast'
import { TextInput } from '../TextInput/TextInput.tsx'
import { VariationColorInput } from '../../../pages/app/project/flags/flag/Variations.tsx'
import { Toolbar } from '../../component/Toolbar/Toolbar.tsx'
import { Button } from '../../component/Button/Button.tsx'

const TagForm = ({
  id,
  name,
  color,
  onDelete,
}: {
  id: string
  name: string
  color: string
  onDelete?: () => void
}) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      name,
      color,
    },
  })

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation editTag($id: ID!, $name: String!, $color: String!) {
        tag(id: $id) {
          update(name: $name, color: $color) {
            id
            name
            color
          }
        }
      }
    `),
    mapVariables: (data) => ({ id, ...data }),
    onSuccess: async () => {
      await fetchUser()
      toast.success('Tag updated')
    },
  })
  const modalContext = useModalContext()

  return (
    <form onSubmit={handleSubmit(submit)}>
      <InputWrapper
        control={control}
        name="name"
        component={TextInput}
        label="Name"
        autoFocus
        rules={{ required: true }}
        prefix={
          <InputWrapper
            name="color"
            component={VariationColorInput}
            control={control}
          />
        }
      />
      <Toolbar alignRight>
        <Button type="submit" color="primary" loading={isSubmitting}>
          Save
        </Button>
        <Button
          variant="light"
          disabled={isSubmitting}
          onClick={() =>
            confirmDelete({
              unit: 'tags',
              onDelete: async () => {
                onDelete?.()
                await gqlClient.request(
                  graphql(`
                    mutation deleteTag($id: ID!) {
                      tag(id: $id) {
                        delete
                      }
                    }
                  `),
                  { id }
                )
                await fetchUser()
                modalContext?.close()
              },
            })
          }
        >
          Delete
        </Button>
      </Toolbar>
    </form>
  )
}

export const useCreateTag = () => {
  const { project } = useCurrentOrgSafe()

  return useCallback(
    async (name: string) => {
      const result = await gqlClient.request(
        graphql(`
          mutation createTag($projectId: ID!, $name: String!) {
            project(id: $projectId) {
              createTag(name: $name) {
                id
                name
                color
              }
            }
          }
        `),
        {
          projectId: project.id,
          name,
        }
      )

      await fetchUser()

      return result.project?.createTag.id as string
    },
    [project.id]
  )
}

export const TagsInput = forwardRef<
  unknown,
  {
    value: string[]
    onChange: (value: string[]) => void
  }
>((props, ref) => {
  const { project } = useCurrentOrgSafe()
  const createTag = useCreateTag()

  return (
    <MultiSelectInput
      {...props}
      placeholder="Select or create tag"
      ref={ref}
      options={project.tags.map((tag) => ({
        value: tag.id,
        label: tag.name,
        color: tag.color,
      }))}
      hideSelectedOptions={false}
      onCreateOption={createTag}
      // @ts-ignore
      moreActions={(option: SelectOption<string>, deleteOption) => {
        openModal({
          title: 'Edit tag',
          content: (
            <TagForm
              id={option.value}
              name={option.label}
              color={option.color ?? ''}
              onDelete={deleteOption}
            />
          ),
        })
      }}
    />
  )
})
