import {
  CSSProperties,
  FC,
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  ReactNode,
  RefAttributes,
} from 'react'
import cx from 'classnames'
import './Badge.scss'
import Color from 'color'

export type BadgeProps = {
  color?:
    | 'default'
    | 'error'
    | 'primary'
    | 'success'
    | 'warning'
    | (string & unknown)
  children: ReactNode
  style?: CSSProperties
}

export const nameToColors = (
  name: BadgeProps['color']
): Pick<CSSProperties, 'background' | 'color'> => {
  switch (name) {
    case 'error':
      return {
        background: '#fde7e9',
        color: 'var(--color-error)',
      }
    case 'warning':
      return {
        background: '#fff3e2',
        color: '#f5a623',
      }
    case 'primary':
      return {
        background: '#ebeff8',
        color: 'var(--color-primary)',
      }
    case 'success':
      return {
        background: '#dcf6e4',
        color: 'var(--color-success-dark)',
      }
  }

  if (typeof name === 'string') {
    return {
      background: Color(name).mix(Color('white'), 0.85).hex(),
      color: Color(name).darken(0.7).hex(),
    }
  }

  return {
    background: '#e9e9ec',
  }
}

export const Badge = forwardRef<HTMLSpanElement, BadgeProps>(
  ({ children, color, style, ...props }, ref) => {
    return (
      <span
        {...props}
        className={cx('badge')}
        style={{ ...nameToColors(color), ...style }}
        ref={ref}
      >
        {children}
      </span>
    )
  }
) as ForwardRefExoticComponent<
  PropsWithoutRef<BadgeProps> & RefAttributes<HTMLSpanElement>
> & { List: FC<{ children: ReactNode }> }

const List: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="badge-list">{children}</div>
}

Badge.List = List
