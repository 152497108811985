export const getMovedItem = (
  before: { id: string }[],
  after: { id: string }[]
): { from: number; to: number } | null => {
  const newOrder = before.map(
    (item, index) => after.findIndex((i) => i.id === item.id) - index
  )

  let firstPositive = null
  let firstNegative = null
  let countPositives = 0
  let countNegatives = 0

  for (let i = 0; i < newOrder.length; i++) {
    if (newOrder[i] > 0) {
      countPositives++
      firstPositive ??= i
    } else if (newOrder[i] < 0) {
      countNegatives++
      firstNegative ??= i
    }
  }

  if (countPositives === 1) {
    return {
      from: firstPositive!,
      to: firstPositive! + newOrder[firstPositive!],
    }
  }

  if (countNegatives === 1) {
    return {
      from: firstNegative!,
      to: firstNegative! + newOrder[firstNegative!],
    }
  }

  return null
}
