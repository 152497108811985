import { forwardRef } from 'react'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-monokai'
import './JsonInput.scss'

export const JsonInput = forwardRef<
  AceEditor,
  { value: string; onChange: (value: string) => void }
>(({ value, onChange, ...props }, ref) => {
  return (
    <AceEditor
      mode="json"
      theme="monokai"
      value={value}
      onChange={onChange}
      fontSize={14}
      width="100%"
      showPrintMargin={false}
      showGutter={true}
      highlightActiveLine={true}
      setOptions={{
        enableBasicAutocompletion: false,
        enableLiveAutocompletion: false,
        enableSnippets: false,
        showLineNumbers: true,
        tabSize: 2,
        minLines: 3,
        maxLines: 10,
        useWorker: false,
      }}
      ref={ref}
      {...props}
    />
  )
})
