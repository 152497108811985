export function sanitize(str: string) {
  if (typeof str !== 'string') {
    return ''
  }

  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .replace(/[^a-z0-9]+/g, '')
}
