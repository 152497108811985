import { useCallback, useState } from 'react'

export const useTempState = (duration = 3000) => {
  const [state, setState] = useState({ id: 0, value: false })

  return [
    state.value,
    useCallback(() => {
      let value = 0
      setState((s) => {
        value = s.id + 1
        return { id: value, value: true }
      })

      setTimeout(() => {
        setState((s) => {
          if (s.id === value) {
            return { id: s.id, value: false }
          } else {
            return s
          }
        })
      }, duration)
    }, [duration]),
  ] as [boolean, () => void]
}
