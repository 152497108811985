import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  ChangePropertyVisibilityMutationVariables,
  ContextPropertyType,
} from '../gql/graphql.ts'
import { gqlClient } from '../auth'
import { graphql } from '../gql'
import { undoableLoadingToast } from '../utils/toast.tsx'
import { ReactNode } from 'react'
import {
  IconAbc,
  IconBrackets,
  IconCalendar,
  IconGitMerge,
  IconHash,
  IconList,
  IconToggleRight,
  TablerIconsProps,
} from '@tabler/icons-react'

type ContextPropertyTypeConfig = {
  type: ContextPropertyType
  color: string
  name: string
  Icon: (props: TablerIconsProps) => ReactNode
  description?: string
}

export const ContextPropertyTypes: ContextPropertyTypeConfig[] = [
  {
    type: ContextPropertyType.String,
    color: '',
    Icon: IconAbc,
    name: 'String',
    description: 'String primitive',
  },
  {
    type: ContextPropertyType.Select,
    color: '',
    Icon: IconList,
    name: 'Select',
    description: 'A list of possible values',
  },
  {
    type: ContextPropertyType.Number,
    color: '',
    name: 'Number',
    Icon: IconHash,
    description: 'Number primitive',
  },
  {
    type: ContextPropertyType.Boolean,
    color: '',
    name: 'Boolean',
    Icon: IconToggleRight,
    description: 'Boolean primitive',
  },
  {
    type: ContextPropertyType.Date,
    color: '',
    name: 'Date',
    Icon: IconCalendar,
    description: 'ISO date, date-time, or timestamp',
  },
  {
    type: ContextPropertyType.Version,
    color: '',
    name: 'Version',
    Icon: IconGitMerge,
    description: 'SemVer string, or number',
  },
  {
    type: ContextPropertyType.StringArray,
    color: '',
    name: 'Array',
    Icon: IconBrackets,
    description: 'Array of strings',
  },
]

export const ContextPropertyTypeMap = ContextPropertyTypes.reduce(
  (acc, cur) => {
    acc[cur.type] = cur
    return acc
  },
  {} as Record<ContextPropertyType, ContextPropertyTypeConfig>
)

const useChangePropertyVisibilityMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (variables: ChangePropertyVisibilityMutationVariables) =>
      gqlClient.request(
        graphql(`
          mutation changePropertyVisibility($id: ID!, $visible: Boolean!) {
            contextProperties(ids: [$id]) {
              toggleVisibility(visible: $visible) {
                id
                hidden
              }
            }
          }
        `),
        variables
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['context'] })
    },
  })
}

export const useHideProperty = () => {
  const changeVisibility = useChangePropertyVisibilityMutation()

  return (id: string) =>
    undoableLoadingToast({
      id,
      do: {
        promise: () =>
          changeVisibility.mutateAsync({
            id,
            visible: false,
          }),
        loading: 'Hiding property...',
        success: 'Property hidden',
        error: 'Failed to hide property',
      },
      undo: {
        promise: () =>
          changeVisibility.mutateAsync({
            id,
            visible: true,
          }),
        loading: 'Showing property...',
        success: 'Property shown',
        error: 'Failed to show property',
      },
    })
}

export const useShowProperty = () => {
  const changeVisibility = useChangePropertyVisibilityMutation()

  return (id: string) =>
    undoableLoadingToast({
      id,
      undo: {
        promise: () =>
          changeVisibility.mutateAsync({
            id,
            visible: false,
          }),
        loading: 'Hiding property...',
        success: 'Property hidden',
        error: 'Failed to hide property',
      },
      do: {
        promise: () =>
          changeVisibility.mutateAsync({
            id,
            visible: true,
          }),
        loading: 'Showing property...',
        success: 'Property shown',
        error: 'Failed to show property',
      },
    })
}
