import { FC, ReactNode } from 'react'
import style from './QuotesLayout.module.scss'
import blob from './blob.webp'
import { Logo } from '../../component/Logo/Logo.tsx'
import swann from './swan.svg'
import sellsy from './sellsy.svg'
import stoik from './stoik.svg'
import { IconStarFilled } from '@tabler/icons-react'
import brice from './brice-flaceliere.jpg'

export const QuotesLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className={style.layout}>
      <div>{children}</div>
      <div className={style.sidebar}>
        <img src={blob} className={style.blob2} />
        {/*<h1>Get started with feature flags</h1>*/}
        <Logo style={{ alignSelf: 'start' }} height={52} />
        <div />
        <div className={style.quote}>
          <div className={style.starRating}>
            <IconStarFilled />
            <IconStarFilled />
            <IconStarFilled />
            <IconStarFilled />
            <IconStarFilled />
          </div>
          <blockquote>
            <p>
              "We chose Tggl as part of our strategy to improve our release
              process and reliability as we grow. We were using config files and
              database values as a homemade solution, and the switch to Tggl was
              surprisingly swift and their technical support very responsive. It
              has streamlined our release process pretty much instantly and has
              become our go-to tool for pure technical work like smooth
              technology migrations and kill switches. It is now an integral
              part of our workflow."
            </p>
            <footer>
              <img src={brice} />
              <cite>
                <b>Brice Flaceliere</b>
                Staff software engineer @ Sellsy
              </cite>
            </footer>
          </blockquote>
        </div>
        <div>
          <div className={style.trustedBy}>Trusted by</div>
          <div className={style.trustedByLogos}>
            <img height={40} src={swann} alt="Swann" />
            <img height={40} src={sellsy} alt="Sellsy" />
            <img height={40} src={stoik} alt="Stoik" />
          </div>
        </div>
      </div>
    </div>
  )
}
