import { useForm } from 'react-hook-form'
import { Button } from '../components/component/Button/Button'
import { InputWrapper } from '../components/input/InputWrapper/InputWrapper'
import { PasswordInput } from '../components/input/PasswordInput/PasswordInput'
import { TextInput } from '../components/input/TextInput/TextInput'
import { CenteredVerticalLayout } from '../components/layout/CenteredVerticalLayout/CenteredVerticalLayout'
import { graphql } from '../gql'
import { useFormSubmitMutation } from '../hooks/useFormSubmitMutation'
import { gqlClient, setToken } from '../auth'
import { AnonymousOnly } from '../components/layout/Auth/AnonymousOnly'
import { Link, RouteObject } from 'react-router-dom'
import { Logo } from '../components/component/Logo/Logo.tsx'
import style from './Login.module.scss'
import { useState } from 'react'
import checkYourInbox from '../assets/empty-state/check-your-inbox.svg'
import { SendEmailButton } from '../components/component/SendEmailButton/SendEmailButton.tsx'
import { verifyEmailRequestQuery } from '../queries/auth.ts'
import { Modal } from '../components/component/Modal/Modal.tsx'
import { PinInput } from '../components/input/PinInput/PinInput.tsx'
import { Toolbar } from '../components/component/Toolbar/Toolbar.tsx'
import { useGoogleLogin } from '@react-oauth/google'
import { toast } from 'react-hot-toast'
import google from '../assets/logo/google.svg'

const Page = () => {
  const [emailNorVerified, setEmailNorVerified] = useState(false)
  const [needTotp, setNeedTotp] = useState(false)
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    watch,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      totpToken: '',
    },
  })

  const submit = useFormSubmitMutation({
    mutation: graphql(`
      mutation login($email: String!, $password: String!, $totpToken: String) {
        login(email: $email, password: $password, totpToken: $totpToken)
      }
    `),
    control,
    onSuccess: ({ login }) => setToken(login),
    onError: {
      EMAIL_NOT_VERIFIED: () => setEmailNorVerified(true),
      MISSING_TOTP_TOKEN: () => setNeedTotp(true),
    },
  })
  const login = useGoogleLogin({
    onSuccess: ({ access_token }) =>
      toast.promise(
        gqlClient
          .request(
            graphql(`
              mutation googleLogin($token: String!) {
                googleLogin(token: $token)
              }
            `),
            { token: access_token ?? '' }
          )
          .then(({ googleLogin }) => setToken(googleLogin)),
        {
          loading: 'Logging in using Google',
          success: 'Logged in',
          error: (error) => {
            if (
              error.response?.errors?.[0]?.extensions?.code ===
              'MISSING_TOTP_TOKEN'
            ) {
              return '2FA is enabled, please use your password to login'
            }

            return 'Could not login using Google'
          },
        }
      ),
    onError: () => toast.error('Could not sign in with Google'),
  })

  const email = watch('email')

  if (emailNorVerified) {
    return (
      <CenteredVerticalLayout center>
        <img src={checkYourInbox} />
        <h1>Verify your email</h1>
        <p>
          You must verify your email <b>{email}</b> in order to login. If you
          did not receive an email with the verification link, click the button
          bellow.
        </p>
        <SendEmailButton
          mutation={verifyEmailRequestQuery}
          variables={{ email }}
        >
          Re-send email
        </SendEmailButton>
      </CenteredVerticalLayout>
    )
  }

  return (
    <CenteredVerticalLayout>
      {needTotp && (
        <Modal>
          <form onSubmit={handleSubmit(submit)}>
            <h2 style={{ marginBottom: 24 }}>Two-factor authentication</h2>
            <InputWrapper
              component={PinInput}
              control={control}
              name="totpToken"
              label="Security code"
              tooltip="From your authenticator app"
              rules={{ required: true }}
              length={6}
            />
            <Toolbar alignRight>
              <Button color="primary" type="submit" loading={isSubmitting}>
                Login
              </Button>
              <Button onClick={() => setNeedTotp(false)}>Cancel</Button>
            </Toolbar>
          </form>
        </Modal>
      )}
      <div className={style.logo}>
        <Logo />
      </div>
      <Button onClick={() => login()}>
        <img src={google} alt="Google logo" height={18} />
        Google
      </Button>
      <div className="text-separator">or</div>
      <form onSubmit={handleSubmit(submit)}>
        <InputWrapper
          component={TextInput}
          control={control}
          name="email"
          label="Email"
          type="email"
          rules={{ required: true }}
          autoComplete="username"
          autoFocus
        />
        <InputWrapper
          component={PasswordInput}
          control={control}
          name="password"
          label="Password"
          rules={{ required: true }}
          autoComplete="current-password"
        />
        <Button color="primary" type="submit" loading={isSubmitting}>
          Login
        </Button>
        <Link
          to={`/forgot-password${email ? `?email=${email}` : ''}`}
          className={style.forgotPassword}
        >
          Forgot password?
        </Link>
        <div className="text-separator">Don't have an account?</div>
        <Button
          to={`/sign-up${email ? `?email=${email}` : ''}`}
          color="success"
          variant="light"
        >
          Sign up for free
        </Button>
      </form>
    </CenteredVerticalLayout>
  )
}

export const loginRoute: RouteObject = {
  path: '/login',
  element: (
    <AnonymousOnly>
      <Page />
    </AnonymousOnly>
  ),
}
