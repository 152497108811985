import { RouteObject, useParams } from 'react-router-dom'
import { Sections } from '../../../../components/component/Sections/Sections.tsx'
import { ActionsList } from '../../../../components/component/ActionsList/ActionsList.tsx'
import { Button } from '../../../../components/component/Button/Button.tsx'
import { useForm } from 'react-hook-form'
import { FormSubmitButtons } from '../../../../components/component/FormSubmitButtons/FormSubmitButtons.tsx'
import { InputWrapper } from '../../../../components/input/InputWrapper/InputWrapper.tsx'
import { TextInput } from '../../../../components/input/TextInput/TextInput.tsx'
import {
  IconPlayerPlay,
  IconPlus,
  IconTrash,
  IconWorld,
  IconX,
} from '@tabler/icons-react'
import {
  AddRowsComponentProps,
  CellProps,
  DataSheetGrid,
  keyColumn,
  textColumn,
} from 'react-datasheet-grid'
import 'react-datasheet-grid/dist/style.css'
import { IconButton } from '../../../../components/component/IconButton/IconButton.tsx'
import { useFormDefaultValue } from '../../../../hooks/useFormDefaultValue.ts'
import { graphql } from '../../../../gql'
import { useFormSubmitMutation } from '../../../../hooks/useFormSubmitMutation.ts'
import {
  useDeleteWebhook,
  useTestRunWebhook,
} from '../../../../queries/webhooks.tsx'

const Page = () => {
  const { webhookId } = useParams()

  const {
    control,
    reset,
    formState: { isDirty, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      url: '',
      headers: [{ name: '', value: '' }],
    },
  })

  useFormDefaultValue({
    control,
    queryKey: ['webhook', webhookId, 'settings default value'],
    request: graphql(`
      query WebhookSettingsDefaultValue($id: ID!) {
        webhook(id: $id) {
          id
          url
          headers
        }
      }
    `),
    variables: { id: webhookId as string },
    mapDefaultValues: (data) => ({
      url: data.webhook?.url ?? '',
      headers: Object.entries(
        (data.webhook?.headers ?? {}) as Record<string, string>
      ).map(([name, value]) => ({ name, value })),
    }),
  })

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation UpdateWebhookSettings($id: ID!, $url: String!, $headers: JSON!) {
        webhook(id: $id) {
          update(url: $url, headers: $headers) {
            id
          }
        }
      }
    `),
    mapVariables: (data) => ({
      id: webhookId as string,
      url: data.url,
      headers: data.headers.reduce((acc, cur) => {
        if (cur.name) {
          acc[cur.name] = cur.value
        }
        return acc
      }, {} as Record<string, string>),
    }),
  })
  const deleteWebhook = useDeleteWebhook()
  const testRunWebhook = useTestRunWebhook()

  return (
    <Sections>
      <Sections.Section title="Payload">
        <form onSubmit={handleSubmit(submit)}>
          <InputWrapper
            control={control}
            name="url"
            component={TextInput}
            label="Url"
            prefix={<IconWorld size={16} />}
            rules={{ required: true }}
          />
          <InputWrapper
            control={control}
            name="headers"
            component={DataSheetGrid}
            // @ts-ignore
            columns={[
              { ...keyColumn('name', textColumn), title: 'Header' },
              { ...keyColumn('value', textColumn), title: 'Value' },
            ]}
            gutterColumn={false}
            disableContextMenu
            disableExpandSelection
            stickyRightColumn={{
              component: ({ deleteRow }: CellProps) => {
                return <IconButton icon={IconX} onClick={deleteRow} />
              },
            }}
            autoAddRow
            rowHeight={36}
            createRow={() => ({ name: '', value: '' })}
            addRowsComponent={({ addRows }: AddRowsComponentProps) => (
              <div style={{ marginTop: 10 }}>
                <Button
                  variant="light"
                  onClick={() => addRows(1)}
                  inline
                  icon={IconPlus}
                >
                  Header
                </Button>
              </div>
            )}
          />
          <FormSubmitButtons
            isDirty={isDirty}
            isSubmitting={isSubmitting}
            reset={reset}
            labelSubmit="Save"
          />
        </form>
      </Sections.Section>
      <hr />
      <Sections.Section title="Danger zone">
        <ActionsList>
          <ActionsList.Action
            title="Test run"
            subTitle="Manually trigger this webhook as if someone just edited a flag."
            action={
              <Button
                onClick={() => testRunWebhook(webhookId as string)}
                icon={IconPlayerPlay}
              >
                Test run
              </Button>
            }
          />
          <ActionsList.Action
            title="Delete webhook"
            subTitle="Permanently delete this webhook and all of its data."
            action={
              <Button
                color="error"
                onClick={() => deleteWebhook(webhookId as string)}
                icon={IconTrash}
              >
                Delete
              </Button>
            }
          />
        </ActionsList>
      </Sections.Section>
    </Sections>
  )
}

export const settingsRoute: RouteObject = {
  path: 'settings',
  element: <Page />,
}
