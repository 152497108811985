import { FC, ReactNode } from 'react'
import './Meter.scss'
import { FormattedNumber } from 'react-intl'
import cx from 'classnames'

export type MeterProps = {
  title?: ReactNode
  startAt?: number
  current?: number
  limit?: number | null
  hideCounter?: boolean
}

export const Meter: FC<MeterProps> = ({
  current = 0,
  limit,
  startAt = 0,
  title,
  hideCounter = false,
}) => {
  return (
    <div className="meter">
      <div className="meter-title">
        <span>{title}</span>
        {!hideCounter && (
          <span>
            <FormattedNumber value={current} /> /{' '}
            {typeof limit === 'number' ? (
              <FormattedNumber value={limit} />
            ) : (
              '∞'
            )}
          </span>
        )}
      </div>
      <div
        className={cx('meter-bar', typeof limit !== 'number' && 'unlimited')}
      >
        <div
          style={{
            width:
              typeof limit === 'number'
                ? `${
                    Math.min(1, (current - startAt) / (limit - startAt)) * 100
                  }%`
                : typeof current === 'number'
                ? '100%'
                : 0,
          }}
        />
      </div>
    </div>
  )
}
