import {
  Control,
  FieldArrayPath,
  FieldValues,
  Path,
  useFieldArray,
  UseFieldArrayReturn,
  useWatch,
} from 'react-hook-form'
import { DragControls, motion, Reorder, useDragControls } from 'framer-motion'
import {
  IconCopy,
  IconGripVertical,
  IconHelpCircleFilled,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react'
import { Dropdown } from '../../component/DropdownMenu/DropdownMenu.tsx'
import { MoreActions } from '../../component/MoreActions/MoreActions.tsx'
import { InputWrapper } from '../InputWrapper/InputWrapper.tsx'
import { VariationInput } from '../VariationInput/VariationInput.tsx'
import { Button } from '../../component/Button/Button.tsx'
import { FC, forwardRef, ReactNode, RefAttributes, useMemo } from 'react'
import { Rule, RulesInput } from '../RulesInput/RulesInput.tsx'
import { Tooltip } from '../../component/Tooltip/Tooltip.tsx'
import { TextInput } from '../TextInput/TextInput.tsx'
import { getMovedItem } from '../../../utils/reorder.ts'
import { useCurrentOrgSafe } from '../../../hooks/useCurrentOrgSafe.ts'
import { Operator } from '../../../gql/graphql.ts'

const variants = {
  duplicate: { opacity: 0, scale: 0.8, y: '-100%' },
  visible: { opacity: 1, scale: 1, y: 0 },
  new: { opacity: 0, scale: 0.8 },
}

export type Condition = {
  id: string | null
  name: string | null
  initialVariant: keyof typeof variants | false
  variationId: string | null
  rules: Rule[]
}

export type ConditionsInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>
> = {
  control: Control<TFieldValues>
  name: TFieldArrayName
  flagId: string
  defaultVariationId?: string | null
}

const transition = {
  type: 'spring',
  stiffness: 700,
  damping: 30,
  mass: 0.8,
}

const Item: FC<{
  render: (controls: DragControls) => ReactNode
}> = ({ render }) => {
  const controls = useDragControls()

  return render(controls)
}

export const checkImpactsProduction = ({
  value,
  rules,
  contextPropertyId,
}: {
  contextPropertyId?: string
  value?: string
  rules: Condition['rules']
}) => {
  const explicitlySelectOtherEnvs = rules.some((rule) => {
    return (
      rule.contextPropertyId === contextPropertyId &&
      (rule.operator === Operator.StrEquals ||
        rule.operator === Operator.SelectEquals) &&
      (rule.params.values?.length ?? 0) > 0 &&
      !rule.params.values.includes(value)
    )
  })

  const explicitlySelectEnv = rules.some((rule) => {
    return (
      rule.contextPropertyId === contextPropertyId &&
      (rule.operator === Operator.StrEquals ||
        rule.operator === Operator.SelectEquals) &&
      rule.params.values?.includes(value)
    )
  })

  const explicitlyRejectEnv = rules.some((rule) => {
    return (
      rule.contextPropertyId === contextPropertyId &&
      (rule.operator === Operator.StrNotEquals ||
        rule.operator === Operator.SelectNotEquals) &&
      rule.params.values?.includes(value)
    )
  })

  const explicitlyRejectOtherEnvs = rules.some((rule) => {
    return (
      rule.contextPropertyId === contextPropertyId &&
      (rule.operator === Operator.StrNotEquals ||
        rule.operator === Operator.SelectNotEquals) &&
      (rule.params.values?.length ?? 0) > 0 &&
      !rule.params.values.includes(value)
    )
  })

  return {
    explicitlySelectOtherEnvs,
    explicitlySelectEnv,
    explicitlyRejectEnv,
    explicitlyRejectOtherEnvs,
    impactsProduction: !explicitlySelectOtherEnvs && !explicitlyRejectEnv,
  }
}

const ProductionBadge: FC<{
  control: Control<any>
  name: string
}> = ({ name, control }) => {
  const { project } = useCurrentOrgSafe()
  const rules = useWatch({
    control,
    //@ts-ignore
    name,
  }) as Condition['rules']

  const { impactsProduction, explicitlySelectEnv, explicitlyRejectOtherEnvs } =
    checkImpactsProduction({
      value: project.environmentContextProperty?.value,
      rules,
      contextPropertyId: project.environmentContextProperty?.contextProperty.id,
    })

  if (!impactsProduction) {
    return null
  }

  return (
    <div className="production-badge">
      <Tooltip>
        <Tooltip.Trigger component="div" children="Production" />
        <Tooltip.Content>
          {explicitlySelectEnv
            ? 'This condition specifically targets the production environment, modifying it will impact production.'
            : explicitlyRejectOtherEnvs
            ? 'This condition explicitly excludes non-production environments, modifying it will impact production.'
            : 'This condition targets all environments, modifying it will impact production.'}
        </Tooltip.Content>
      </Tooltip>
    </div>
  )
}

export const ConditionsInput = forwardRef<unknown, ConditionsInputProps>(
  <
    TFieldValues extends FieldValues = FieldValues,
    TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>
  >(
    {
      control,
      name,
      flagId,
      defaultVariationId = null,
    }: ConditionsInputProps<TFieldValues, TFieldArrayName>,
    ref: unknown
  ) => {
    const { project } = useCurrentOrgSafe()
    const conditions = useWatch({
      control,
      //@ts-ignore
      name,
    }) as Condition[]

    const lastConditionIndexToTargetProduction = useMemo(() => {
      return conditions.findIndex((condition) => {
        if (condition.rules.length !== 1) {
          return false
        }

        const { explicitlySelectEnv, explicitlyRejectOtherEnvs } =
          checkImpactsProduction({
            value: project.environmentContextProperty?.value,
            rules: condition.rules,
            contextPropertyId:
              project.environmentContextProperty?.contextProperty.id,
          })

        return explicitlySelectEnv || explicitlyRejectOtherEnvs
      })
    }, [
      conditions,
      project.environmentContextProperty?.contextProperty.id,
      project.environmentContextProperty?.value,
    ])

    const { fields, append, remove, insert, move } = useFieldArray({
      control,
      name,
    }) as unknown as UseFieldArrayReturn<
      { conditions: Condition[] },
      'conditions'
    >

    return (
      <Reorder.Group
        axis="y"
        values={fields}
        className="conditions-list"
        onReorder={(newValue) => {
          const moved = getMovedItem(fields as any, newValue as any)

          if (moved) {
            move(moved.from, moved.to)
          }
        }}
        as="div"
      >
        {fields.map((condition, index) => (
          <Item
            key={condition.id}
            render={(controls) => (
              <Reorder.Item
                key={condition.id}
                value={condition}
                layout="position"
                initial={condition.initialVariant}
                style={{ originY: 0 }}
                animate="visible"
                transition={transition}
                className="condition"
                variants={variants}
                dragListener={false}
                dragControls={controls}
                as="div"
              >
                {(lastConditionIndexToTargetProduction === -1 ||
                  lastConditionIndexToTargetProduction >= index) && (
                  <ProductionBadge
                    control={control}
                    name={`${name}.${index}.rules`}
                  />
                )}
                <header>
                  <div
                    className="handler"
                    onPointerDown={(e) => {
                      controls.start(e)
                      e.preventDefault()
                    }}
                  >
                    <IconGripVertical size={18} />
                  </div>
                  <InputWrapper
                    name={`${name}.${index}.name` as Path<TFieldValues>}
                    // @ts-ignore
                    component={TextInput}
                    control={control}
                    style={{ flex: 1 }}
                    transparent
                    placeholder={`Condition #${index + 1}`}
                  />
                  <Dropdown component={MoreActions}>
                    <Dropdown.Item
                      label="Duplicate"
                      onClick={() =>
                        insert(
                          index + 1,
                          JSON.parse(
                            JSON.stringify({
                              ...condition,
                              rules: condition.rules.map((rule) => ({
                                ...rule,
                                id: null,
                              })),
                              id: null,
                              initialVariant: 'duplicate',
                            })
                          )
                        )
                      }
                      icon={IconCopy}
                    />
                    <Dropdown.Item
                      label="Delete"
                      danger
                      onClick={() => {
                        // @ts-ignore
                        document.activeElement?.blur()
                        remove(index)
                      }}
                      icon={IconTrash}
                    />
                  </Dropdown>
                </header>
                <main>
                  <div className="statement">if</div>
                  <RulesInput
                    control={control}
                    name={
                      `${name}.${index}.rules` as FieldArrayPath<TFieldValues>
                    }
                  />
                  <div className="statement">then</div>
                  <InputWrapper
                    component={VariationInput as any}
                    control={control}
                    name={`${name}.${index}.variationId` as Path<TFieldValues>}
                    // @ts-ignore
                    flagId={flagId}
                    rules={{ required: true }}
                  />
                </main>
              </Reorder.Item>
            )}
          />
        ))}
        <motion.div
          layout
          transition={transition}
          whileHover={{ scale: 1.02 }}
          className="condition template"
        >
          <main>
            <Button
              inline
              icon={IconPlus}
              onClick={() =>
                append({
                  id: null,
                  name: null,
                  initialVariant: 'new',
                  variationId: defaultVariationId,
                  rules: [
                    {
                      id: null,
                      params: {},
                      operator: null,
                      contextPropertyId: null,
                    },
                  ],
                })
              }
            >
              Add condition
            </Button>
          </main>
        </motion.div>
        <motion.div layout transition={transition} className="condition">
          {lastConditionIndexToTargetProduction === -1 && (
            <div className="production-badge fallback">
              <Tooltip>
                <Tooltip.Trigger component="div" children="Prod." />
                <Tooltip.Content>
                  Changing the fallback variation will impact production
                </Tooltip.Content>
              </Tooltip>
            </div>
          )}
          <header>
            <span style={{ padding: '0 0.5rem 0 1rem' }}>
              {fields.length === 0 ? 'For all users' : 'Fallback'}
            </span>
            <Tooltip>
              <Tooltip.Trigger component={IconHelpCircleFilled} size={18} />
              {fields.length === 0 ? (
                <Tooltip.Content>
                  Return this variation for all users, you can add conditions
                  above if you want a more granular control
                </Tooltip.Content>
              ) : (
                <Tooltip.Content>
                  If none of the above conditions are met, fallback to this
                  variation
                </Tooltip.Content>
              )}
            </Tooltip>
          </header>
          <main>
            <div className="statement">
              {fields.length === 0 ? 'return' : 'else'}
            </div>
            <InputWrapper
              component={VariationInput as any}
              control={control}
              name={`defaultVariationId` as Path<TFieldValues>}
              // @ts-ignore
              flagId={flagId}
            />
          </main>
        </motion.div>
      </Reorder.Group>
    )
  }
) as <
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>
>(
  props: ConditionsInputProps<TFieldValues, TFieldArrayName> &
    RefAttributes<unknown>
) => ReactNode
