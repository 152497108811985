import style from './Navbar.module.scss'
import { FC, ReactNode } from 'react'
import { TablerIconsProps } from '@tabler/icons-react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import cx from 'classnames'

export const Navbar: FC<{ children: ReactNode }> & {
  Link: typeof NavbarLink
  Body: typeof Body
} = ({ children }) => {
  return <nav className={style.navbar}>{children}</nav>
}

const NavbarLink = ({
  children,
  icon: Icon,
  href,
  ...props
}: Omit<NavLinkProps, 'children' | 'style'> & {
  children: ReactNode
  href?: string
  icon: (props: TablerIconsProps) => ReactNode
}) => {
  if (href) {
    return (
      <a href={href} {...props} className={style['menu-item']}>
        <Icon size={18} stroke={1.75} />
        <span>{children}</span>
      </a>
    )
  }

  return (
    <NavLink
      {...props}
      className={({ isActive }) =>
        cx(style['menu-item'], isActive && style.active)
      }
    >
      <Icon size={18} stroke={1.75} />
      <span>{children}</span>
    </NavLink>
  )
}

const Body: FC<{ children: ReactNode }> = ({ children }) => {
  return <main className={style.content}>{children}</main>
}

Navbar.Link = NavbarLink
Navbar.Body = Body
