import { useQueryClient } from '@tanstack/react-query'
import { confirmDelete } from '../components/component/Modal/Modal.tsx'
import { fetchUser, gqlClient } from '../auth'
import { graphql } from '../gql'

export const useDeleteProject = () => {
  const queryClient = useQueryClient()

  return (id: string) =>
    confirmDelete({
      unit: 'projects',
      onDelete: () =>
        gqlClient
          .request(
            graphql(`
              mutation DeleteProject($id: ID!) {
                project(id: $id) {
                  delete
                }
              }
            `),
            { id }
          )
          .then(() => {
            queryClient.invalidateQueries({ queryKey: ['project', id] })
            queryClient.invalidateQueries({ queryKey: ['projects'] })
          })
          .then(fetchUser),
    })
}
