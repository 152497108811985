import {
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
} from 'react'
import './TextInput.scss'
import cx from 'classnames'
import { IconSearch, IconX } from '@tabler/icons-react'
import { useMergeRefs } from '@floating-ui/react'

export type RawTextInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'prefix'
> & {
  prefix?: ReactNode
  suffix?: ReactNode
  transparent?: boolean
}

export type TextInputProps = Pick<
  RawTextInputProps,
  | 'placeholder'
  | 'autoFocus'
  | 'className'
  | 'type'
  | 'autoComplete'
  | 'disabled'
  | 'prefix'
  | 'suffix'
  | 'transparent'
  | 'onKeyDown'
> & {
  value: string
  onChange: (value: string) => void
  search?: boolean
}

export const RawTextInput = forwardRef<HTMLInputElement, RawTextInputProps>(
  ({ prefix, suffix, className, transparent, ...props }, ref) => {
    return (
      <div
        className={cx(
          'input-container',
          className,
          transparent && 'transparent'
        )}
      >
        <label className="input">
          {prefix}
          <input size={1} {...props} ref={ref} />
          {suffix}
        </label>
      </div>
    )
  }
)

export type FormattedTextInputProps<T> = Omit<
  RawTextInputProps,
  'value' | 'onChange'
> & {
  value: T
  onChange: (value: T) => void
  alignRight?: boolean
  // When true, data is updated as the user types, otherwise it is only updated on blur. Default to true
  continuousUpdates?: boolean
  // Parse what the user types
  parseUserInput?: (value: string) => T
  // Format the value of the input when it is blurred
  formatBlurredInput?: (value: T) => string
  // Format the value of the input when it gets focused
  formatInputOnFocus?: (value: T) => string
}

export const FormattedTextInput = forwardRef<
  HTMLInputElement,
  FormattedTextInputProps<string>
>(
  (
    {
      value,
      onChange,
      alignRight = false,
      formatInputOnFocus = (value) => String(value ?? ''),
      formatBlurredInput = (value) => String(value ?? ''),
      parseUserInput = (value) => value.trim(),
      continuousUpdates = false,
      onFocus,
      onBlur,
      ...props
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const mergedRef = useMergeRefs([ref, inputRef])

    useEffect(() => {
      if (!inputRef.current || inputRef.current === document.activeElement)
        return

      inputRef.current.value = formatBlurredInput(value)
    }, [value])

    return (
      <RawTextInput
        // We use an uncontrolled component for better performance
        defaultValue={formatBlurredInput(value)}
        onChange={(e) => {
          // Only update the row's value as the user types if continuousUpdates is true
          if (continuousUpdates) {
            onChange(parseUserInput(e.target.value))
          }
        }}
        onFocus={(e) => {
          e.target.value = formatInputOnFocus(value)
          onFocus?.(e)
        }}
        onBlur={(e) => {
          if (!continuousUpdates) {
            const newValue = parseUserInput(e.target.value)
            onChange(newValue)
            e.target.value = formatBlurredInput(newValue)
          } else {
            e.target.value = formatBlurredInput(value)
          }
          onBlur?.(e)
        }}
        className={cx(alignRight && 'align-right')}
        {...props}
        ref={mergedRef}
      />
    )
  }
) as <T>(props: FormattedTextInputProps<T>) => ReactNode

export const FloatInput = forwardRef<
  HTMLInputElement,
  Pick<
    FormattedTextInputProps<number | null>,
    | 'value'
    | 'onChange'
    | 'prefix'
    | 'suffix'
    | 'placeholder'
    | 'continuousUpdates'
  >
>((props, ref) => {
  return (
    <FormattedTextInput
      {...props}
      alignRight
      parseUserInput={(value) => {
        const number = parseFloat(value)
        return !isNaN(number) ? number : null
      }}
      formatBlurredInput={(value) =>
        typeof value === 'number' ? String(value) : ''
      }
      // @ts-ignore
      ref={ref}
    />
  )
})

const clamp = (number: number, min?: number, max?: number) => {
  return Math.min(
    Math.max(number, min ?? number),
    max ?? Math.max(number, min ?? number)
  )
}

export const IntInput = forwardRef<
  HTMLInputElement,
  Pick<
    FormattedTextInputProps<number | null>,
    | 'value'
    | 'onChange'
    | 'prefix'
    | 'suffix'
    | 'placeholder'
    | 'continuousUpdates'
  > & { min?: number; max?: number }
>(({ min, max, ...props }, ref) => {
  return (
    <FormattedTextInput
      {...props}
      alignRight
      parseUserInput={(value) => {
        const number = parseInt(value)
        return !isNaN(number) ? clamp(number, min, max) : null
      }}
      formatBlurredInput={(value) =>
        typeof value === 'number' ? String(value) : ''
      }
      // @ts-ignore
      ref={ref}
    />
  )
})

export const VersionInput = forwardRef<
  HTMLInputElement,
  Pick<
    FormattedTextInputProps<string | null>,
    'value' | 'onChange' | 'prefix' | 'suffix'
  >
>((props, ref) => {
  return (
    <FormattedTextInput
      {...props}
      parseUserInput={(value) => {
        return (
          value
            .replace(/[^0-9.]+/g, '')
            .split('.')
            .filter(Boolean)
            .join('.') || null
        )
      }}
      // @ts-ignore
      ref={ref}
    />
  )
})

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      value,
      onChange,
      search,
      className,
      prefix,
      suffix,
      placeholder,
      ...props
    },
    ref
  ) => {
    return (
      <RawTextInput
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...props}
        prefix={search ? <IconSearch size={16} /> : prefix}
        suffix={
          search ? (
            <IconX
              size={16}
              className="clear-button"
              onClick={() => onChange('')}
            />
          ) : (
            suffix
          )
        }
        placeholder={search ? placeholder ?? 'Search...' : placeholder}
        className={cx(search && 'search', className)}
        ref={ref}
      />
    )
  }
)
