import { useEffect, useState } from 'react'

export class Singleton<T> {
  private _counter = 0
  private _callbacks = new Map<number, (value: T) => void>()

  constructor(private _value: T) {}

  value() {
    return this._value
  }

  setValue(value: T) {
    if (JSON.stringify(this._value) === JSON.stringify(value)) {
      return
    }

    this._value = value

    for (const callback of this._callbacks.values()) {
      callback(value)
    }
  }

  onChange(callback: (value: T) => void) {
    const id = this._counter++
    this._callbacks.set(id, callback)
    return () => {
      this._callbacks.delete(id)
    }
  }

  hook() {
    return () => {
      const [value, setValue] = useState(this._value)

      useEffect(() => this.onChange(setValue), [])

      return value
    }
  }
}
