import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  offset,
  Placement,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTypeahead,
} from '@floating-ui/react'
import {
  createContext,
  Dispatch,
  FC,
  forwardRef,
  ForwardRefExoticComponent,
  HTMLProps,
  PropsWithoutRef,
  ReactNode,
  RefAttributes,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import './DropdownMenu.scss'
import { IconChevronRight, TablerIconsProps } from '@tabler/icons-react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { Tooltip } from '../Tooltip/Tooltip.tsx'

const MenuContext = createContext<{
  getItemProps: (userProps?: HTMLProps<HTMLElement>) => Record<string, unknown>
  activeIndex: number | null
  setActiveIndex: Dispatch<SetStateAction<number | null>>
  setHasFocusInside: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
}>({
  getItemProps: () => ({}),
  activeIndex: null,
  setActiveIndex: () => null,
  setHasFocusInside: () => null,
  isOpen: false,
})

interface MenuProps {
  label?: string
  icon?: (props: TablerIconsProps) => ReactNode
  nested?: boolean
  children?: ReactNode
  component?: FC<any> | keyof JSX.IntrinsicElements
  minWidth?: number
  placement?: Placement
  onOpenChange?: (open: boolean) => void
  mainAxisOffset?: number
  alignmentAxisOffset?: number
  className?: string
}

const MenuComponent = forwardRef<HTMLButtonElement, MenuProps>(
  (
    {
      children,
      label,
      icon: Icon = () => null,
      component: Component = 'button',
      minWidth,
      placement,
      onOpenChange,
      mainAxisOffset,
      alignmentAxisOffset,
      className,
      ...props
    },
    forwardedRef
  ) => {
    const [isOpen, setIsOpen] = useState(false)
    const [hasFocusInside, setHasFocusInside] = useState(false)
    const [activeIndex, setActiveIndex] = useState<number | null>(null)

    const elementsRef = useRef<Array<HTMLButtonElement | null>>([])
    const labelsRef = useRef<Array<string | null>>([])
    const parent = useContext(MenuContext)

    const tree = useFloatingTree()
    const nodeId = useFloatingNodeId()
    const parentId = useFloatingParentNodeId()
    const item = useListItem()

    const isNested = parentId != null

    const { floatingStyles, refs, context } = useFloating<HTMLButtonElement>({
      nodeId,
      open: isOpen,
      onOpenChange: (o) => {
        setIsOpen(o)
        onOpenChange?.(o)
      },
      placement: placement ?? (isNested ? 'right-start' : 'bottom-start'),
      middleware: [
        offset({
          mainAxis: mainAxisOffset ?? (isNested ? 3 : 6),
          alignmentAxis: alignmentAxisOffset ?? (isNested ? -3 : 0),
        }),
        flip({ padding: 10 }),
        shift(),
      ],
      whileElementsMounted: autoUpdate,
    })

    const hover = useHover(context, {
      enabled: isNested,
      delay: { open: 75 },
      handleClose: safePolygon({ blockPointerEvents: true }),
    })
    const click = useClick(context, {
      event: 'mousedown',
      toggle: !isNested,
      ignoreMouse: isNested,
    })
    const role = useRole(context, { role: 'menu' })
    const dismiss = useDismiss(context, { bubbles: true })
    const listNavigation = useListNavigation(context, {
      listRef: elementsRef,
      activeIndex,
      nested: isNested,
      onNavigate: setActiveIndex,
    })
    const typeahead = useTypeahead(context, {
      listRef: labelsRef,
      onMatch: isOpen ? setActiveIndex : undefined,
      activeIndex,
    })

    const { getReferenceProps, getFloatingProps, getItemProps } =
      useInteractions([hover, click, role, dismiss, listNavigation, typeahead])

    useEffect(() => {
      if (!tree) return

      function handleTreeClick() {
        setIsOpen(false)
      }

      function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
        if (event.nodeId !== nodeId && event.parentId === parentId) {
          setIsOpen(false)
        }
      }

      tree.events.on('click', handleTreeClick)
      tree.events.on('menuopen', onSubMenuOpen)

      return () => {
        tree.events.off('click', handleTreeClick)
        tree.events.off('menuopen', onSubMenuOpen)
      }
    }, [tree, nodeId, parentId])

    useEffect(() => {
      if (isOpen && tree) {
        tree.events.emit('menuopen', { parentId, nodeId })
      }
    }, [tree, isOpen, nodeId, parentId])

    return (
      <FloatingNode id={nodeId}>
        <Component
          ref={useMergeRefs([refs.setReference, item.ref, forwardedRef])}
          tabIndex={
            !isNested ? undefined : parent.activeIndex === item.index ? 0 : -1
          }
          role={isNested ? 'menuitem' : undefined}
          data-open={isOpen ? '' : undefined}
          data-nested={isNested ? '' : undefined}
          data-focus-inside={hasFocusInside ? '' : undefined}
          className={isNested ? 'dropdown-item' : undefined}
          {...getReferenceProps(
            parent.getItemProps({
              ...props,
              onFocus() {
                setHasFocusInside(false)
                parent.setHasFocusInside(true)
              },
            })
          )}
        >
          <Icon size={16} stroke={1.8} />
          <span>{label}</span>
          {isNested && <IconChevronRight size={16} stroke={2.5} />}
        </Component>
        <MenuContext.Provider
          value={{
            activeIndex,
            setActiveIndex,
            getItemProps,
            setHasFocusInside,
            isOpen,
          }}
        >
          <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
            {isOpen && (
              <FloatingPortal>
                <FloatingFocusManager
                  context={context}
                  modal={false}
                  initialFocus={isNested ? -1 : 0}
                  returnFocus={!isNested}
                >
                  <div
                    ref={refs.setFloating}
                    className={cx('dropdown-menu', className)}
                    style={{ minWidth, ...floatingStyles }}
                    {...getFloatingProps()}
                  >
                    {children}
                  </div>
                </FloatingFocusManager>
              </FloatingPortal>
            )}
          </FloatingList>
        </MenuContext.Provider>
      </FloatingNode>
    )
  }
)

export interface MenuItemProps {
  label: string
  disabled?: boolean
  selected?: boolean
  onClick?: () => void
  icon?: (props: TablerIconsProps) => ReactNode
  to?: string
  danger?: boolean
  primary?: boolean
  tooltip?: ReactNode
}

const DropdownItem = forwardRef<HTMLButtonElement, MenuItemProps>(
  (
    {
      label,
      disabled,
      icon: Icon = () => null,
      selected,
      to,
      danger,
      primary,
      tooltip,
      ...props
    },
    forwardedRef
  ) => {
    const menu = useContext(MenuContext)
    const item = useListItem({ label: disabled ? null : label })
    const tree = useFloatingTree()
    const isActive = item.index === menu.activeIndex
    const ref = useMergeRefs([item.ref, forwardedRef])

    const allProps = {
      ...props,
      ref,
      type: to ? undefined : 'button',
      role: 'menuitem',
      className: cx(
        'dropdown-item',
        selected === true && 'selected',
        selected === false && 'unselected',
        danger && 'danger',
        primary && 'primary',
        disabled && 'disabled'
      ),
      tabIndex: isActive ? 0 : -1,
      'aria-disabled': disabled ? 'true' : undefined,
      ...menu.getItemProps({
        onClick: disabled
          ? undefined
          : () => {
              props.onClick?.()
              tree?.events.emit('click')
            },
        onFocus() {
          menu.setHasFocusInside(true)
        },
      }),
      children: (
        <>
          <Icon size={16} stroke={1.8} />
          <span>{label}</span>
        </>
      ),
      to,
    }

    if (tooltip) {
      return (
        <Tooltip placement="right" showArrow={false}>
          <Tooltip.Trigger component={to ? Link : 'button'} {...allProps} />
          <Tooltip.Content>{tooltip}</Tooltip.Content>
        </Tooltip>
      )
    }

    if (to) {
      // @ts-ignore
      return <Link {...allProps} />
    }

    // @ts-ignore
    return <button {...allProps} />
  }
)

const DropdownGroup: FC<{
  children: ReactNode
  label: string
  noSticky?: boolean
}> = ({ children, label, noSticky }) => {
  return (
    <div className={cx('dropdown-group', noSticky && 'no-sticky')}>
      <div>{label}</div>
      {children}
    </div>
  )
}

// @ts-ignore
export const Dropdown: ForwardRefExoticComponent<
  PropsWithoutRef<MenuProps> & RefAttributes<HTMLButtonElement>
> & { Item: typeof DropdownItem; Group: typeof DropdownGroup } = forwardRef<
  HTMLButtonElement,
  MenuProps
>((props, ref) => {
  const parentId = useFloatingParentNodeId()

  if (parentId === null) {
    return (
      <FloatingTree>
        <MenuComponent {...props} ref={ref} />
      </FloatingTree>
    )
  }

  return <MenuComponent {...props} ref={ref} />
})

Dropdown.Item = DropdownItem
Dropdown.Group = DropdownGroup
