import { forwardRef } from 'react'
import { SelectInput } from '../SelectInput/SelectInput.tsx'
import ct from 'countries-and-timezones'

const timezones = Object.values(ct.getAllTimezones())
  .sort((a, b) => a.utcOffset - b.utcOffset)
  .reduce((acc, timezone) => {
    const names = timezone.name.split('/')

    acc[names[0]] ??= []
    acc[names[0]].push({
      value: timezone.name,
      label: names[names.length - 1].replace(/_/g, ' '),
    })

    return acc
  }, {} as Record<string, { label: string; value: string }[]>)

delete timezones.Etc
delete timezones.Factory
delete timezones.HST
delete timezones.CST6CDT
delete timezones.PST8PDT
delete timezones.MST7MDT

const options: {
  label: string
  options: { label: string; value: string }[]
}[] = Object.entries(timezones)
  .map(([label, options]) => ({ label, options }))
  .sort((a, b) => a.label.localeCompare(b.label))

export const TimezoneInput = forwardRef<
  unknown,
  {
    value: string | null
    onChange: (value: string | null) => void
  }
>(({ value, onChange }, ref) => {
  return (
    <SelectInput
      value={value}
      ref={ref}
      onChange={onChange}
      autoWidth
      placeholder="Timezone"
      options={options}
    />
  )
})
