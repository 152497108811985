import { TextInput, TextInputProps } from '../TextInput/TextInput.tsx'
import { forwardRef } from 'react'

export type PasswordInputProps = Omit<TextInputProps, 'type'>

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  (props, ref) => {
    return <TextInput type="password" {...props} ref={ref} />
  }
)
