import { RouteObject } from 'react-router-dom'
import { useCurrentOrgSafe } from '../../../../hooks/useCurrentOrgSafe.ts'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useFormSubmitMutation } from '../../../../hooks/useFormSubmitMutation.ts'
import { graphql } from '../../../../gql'
import { fetchUser } from '../../../../auth'
import { InputWrapper } from '../../../../components/input/InputWrapper/InputWrapper.tsx'
import { ChangeRequestRequirement } from '../../../../gql/graphql.ts'
import {
  MultiSelectInput,
  SelectInput,
} from '../../../../components/input/SelectInput/SelectInput.tsx'
import { FormSubmitButtons } from '../../../../components/component/FormSubmitButtons/FormSubmitButtons.tsx'
import { Sections } from '../../../../components/component/Sections/Sections.tsx'
import { BigRadioInput } from '../../../../components/input/BigRadioInput/BigRadioInput.tsx'
import { Card } from '../../../../components/component/Card/Card.tsx'
import { Button } from '../../../../components/component/Button/Button.tsx'
import { IconLockOpen, IconPhone } from '@tabler/icons-react'
import { openModal } from '../../../../components/component/Modal/Modal.tsx'
import { Toolbar } from '../../../../components/component/Toolbar/Toolbar.tsx'

const Settings = () => {
  const { project, organization } = useCurrentOrgSafe()
  const [initialProjectId, setInitialProjectId] = useState(project.id)

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting },
    watch,
  } = useForm({
    defaultValues: {
      approvalRoles: project.changeRequestRoleApprovals.map(({ id }) => id),
      minApprovals: project.changeRequestRoleApprovals.length
        ? ('roles' as const)
        : project.changeRequestMinApprovals,
      require: project.requireChangeRequest,
    },
  })

  const minApprovals = watch('minApprovals')

  useEffect(() => {
    if (initialProjectId !== project.id) {
      reset({
        minApprovals: project.changeRequestMinApprovals,
        require: project.requireChangeRequest,
      })
      setInitialProjectId(project.id)
    }
  }, [
    initialProjectId,
    project.changeRequestMinApprovals,
    project.id,
    project.requireChangeRequest,
    reset,
  ])

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation setChangeRequestRequirements(
        $projectId: ID!
        $minApprovals: Int!
        $require: ChangeRequestRequirement!
        $roleIds: [String!]!
      ) {
        project(id: $projectId) {
          setChangeRequestRequirements(
            minApprovals: $minApprovals
            require: $require
            roleIds: $roleIds
          ) {
            id
          }
        }
      }
    `),
    mapVariables: (values) => ({
      projectId: project.id,
      minApprovals: values.minApprovals === 'roles' ? 0 : values.minApprovals,
      require: values.require,
      roleIds: values.minApprovals === 'roles' ? values.approvalRoles : [],
    }),
    onSuccess: async () => {
      await fetchUser()
    },
    onError: {
      PLAN_LIMIT: () =>
        openModal({
          title: 'Want to enforce a custom approval process?',
          content: (
            <>
              <p>
                Custom approval process is an enterprise feature. It allows you
                to require users to go through the review process, you can then
                choose to enable it for all changes or only changes that impact
                production.
              </p>
              <p>
                The advance role management allows you to define specific roles
                for each user, even allowing some users to bypass the process
                under certain conditions.{' '}
                <a
                  href="https://tggl.io/help/concepts/reviews#enforcing-reviews"
                  target="_blank"
                >
                  Learn more about custom approval process
                </a>
                .
              </p>
              <Toolbar alignRight>
                <Button
                  icon={IconPhone}
                  color="primary"
                  autoFocus
                  to="https://tidycal.com/nicolaskeller/30-min"
                  target="_blank"
                >
                  Book a meeting with sales
                </Button>
              </Toolbar>
            </>
          ),
          size: 'm',
        }),
    },
  })

  return (
    <Sections>
      <Sections.Section
        title="Flag reviews"
        subTitle="Enforce some rules regarding flag reviews"
      >
        {!organization.canMakeReviews && (
          <Card color="primary" style={{ marginBottom: 32 }}>
            <h2>Want to improve collaboration?</h2>
            <p>
              Reviews allow your team to request changes to a flag without
              applying them immediately. You can then enforce specific rules
              regarding the approval process.
            </p>
            <p style={{ marginBottom: 20 }}>
              If you want to know more about reviews, check out our{' '}
              <a href="https://tggl.io/help/concepts/reviews" target="_blank">
                help center
              </a>
              .
            </p>
            <Button
              icon={IconLockOpen}
              color="primary"
              to="/settings/billing"
              inline
            >
              Upgrade plan
            </Button>
          </Card>
        )}
        <form
          onSubmit={handleSubmit(submit)}
          style={{
            maxWidth: 600,
            opacity:
              organization.canMakeReviews || organization.canEnforceReview
                ? 1
                : 0.5,
            pointerEvents:
              organization.canMakeReviews || organization.canEnforceReview
                ? 'all'
                : 'none',
          }}
        >
          <InputWrapper
            //@ts-ignore
            component={SelectInput}
            control={control}
            name="minApprovals"
            label="Minimum approvals"
            rules={{ required: true }}
            tooltip="The minimum number of approvals required for a review to be applied"
            options={[
              { label: 'No approvals required', value: 0 },
              { label: '1 approval', value: 1 },
              { label: '2 approvals', value: 2 },
              { label: '3 approvals', value: 3 },
              { label: 'Approvals from specific roles', value: 'roles' },
            ]}
          />
          {minApprovals === 'roles' && (
            <InputWrapper
              //@ts-ignore
              component={MultiSelectInput}
              control={control}
              name="approvalRoles"
              label="Require one approval from each of these roles"
              rules={{ required: true }}
              style={{ marginBottom: 32 }}
              closeMenuOnSelect={false}
              options={organization.roles.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
            />
          )}
          <InputWrapper
            //@ts-ignore
            component={BigRadioInput}
            control={control}
            name="require"
            label="Require reviews"
            rules={{ required: true }}
            tooltip="Decides when a review is required and when a change can be immediately applied"
            options={[
              {
                label: 'Not required',
                value: ChangeRequestRequirement.Never,
                description:
                  'Changes can be applied immediately. Users can still choose to create a review first, but it is not enforced.',
              },
              {
                label: 'Required for production',
                value: ChangeRequestRequirement.Production,
                description:
                  'Changes can be applied immediately, unless it affects the production environment, then a review is required.',
              },
              {
                label: 'Always required',
                value: ChangeRequestRequirement.Always,
                description:
                  'Changes can never be applied immediately. A review is always required.',
              },
            ]}
          />
          <FormSubmitButtons
            isDirty={isDirty}
            isSubmitting={isSubmitting}
            reset={reset}
            labelSubmit="Update"
          />
        </form>
      </Sections.Section>
    </Sections>
  )
}

export const projectSettingsRoute: RouteObject = {
  path: 'settings',
  element: <Settings />,
}
