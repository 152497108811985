import {
  RouteObject,
  unstable_usePrompt as usePrompt,
  useNavigate,
  useParams,
} from 'react-router-dom'
import '../../../../../components/input/ConditionsInput/ConditionsInput.scss'
import { Control, DeepRequired, useForm } from 'react-hook-form'
import {
  ChangeRequestRequirement,
  ManageFlagsRole,
  Operator,
  ReviewsRole,
} from '../../../../../gql/graphql.ts'
import {
  checkImpactsProduction,
  ConditionsInput,
} from '../../../../../components/input/ConditionsInput/ConditionsInput.tsx'
import { useId, useMemo, useState } from 'react'
import { useFormDefaultValue } from '../../../../../hooks/useFormDefaultValue.ts'
import { graphql } from '../../../../../gql'
import { Toolbar } from '../../../../../components/component/Toolbar/Toolbar.tsx'
import { Button } from '../../../../../components/component/Button/Button.tsx'
import style from './Conditions.module.scss'
import { AnimatePresence, motion } from 'framer-motion'
import { useFormSubmitMutation } from '../../../../../hooks/useFormSubmitMutation.ts'
import { toast } from 'react-hot-toast'
import {
  IconAlertTriangle,
  IconCheck,
  IconEyeglass,
  IconX,
} from '@tabler/icons-react'
import { useCurrentOrgSafe } from '../../../../../hooks/useCurrentOrgSafe.ts'
import { Tooltip } from '../../../../../components/component/Tooltip/Tooltip.tsx'
import { useQueryClient } from '@tanstack/react-query'
import { Modal } from '../../../../../components/component/Modal/Modal.tsx'
import { IconButton } from '../../../../../components/component/IconButton/IconButton.tsx'
import { InputWrapper } from '../../../../../components/input/InputWrapper/InputWrapper.tsx'
import { LongTextInput } from '../../../../../components/input/LongTextInput/LongTextInput.tsx'

let counter = 0

const Page = () => {
  const { flagId } = useParams()
  const id = useId()
  const { organization, project } = useCurrentOrgSafe()

  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitting, errors, defaultValues },
  } = useForm({
    defaultValues: {
      active: false,
      defaultVariationId: null as string | null,
      message: '',
      conditions: [
        {
          id: id + String(counter++),
          name: null as string | null,
          variationId: null as string | null,
          rules: [
            {
              id: id + String(counter++),
              operator: null as Operator | null,
              params: {} as Record<string, any>,
              contextPropertyId: null as string | null,
            },
          ],
        },
      ],
    },
  })

  const mapVariables = (
    data: typeof control extends Control<infer D> ? D : never
  ) => ({
    id: flagId as string,
    message: data.message,
    data: {
      active: data.active,
      defaultVariationId: data.defaultVariationId ?? '',
      conditions: data.conditions.map((condition) => ({
        id: condition.id,
        variationId: condition.variationId ?? '',
        name: condition.name ?? '',
        rules: condition.rules.map((rule) => {
          const { range, ...params } = rule.params

          return {
            id: rule.id,
            operator: rule.operator,
            params: {
              ...params,
              rangeStart: range?.start,
              rangeEnd: range?.end,
            },
            contextPropertyId: rule.contextPropertyId,
          }
        }),
      })),
    },
  })

  const queryClient = useQueryClient()

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation updateFlagConditions($id: ID!, $data: UpdateFlagConditions!) {
        flag(id: $id) {
          updateConditions(data: $data) {
            id
          }
        }
      }
    `),
    mapVariables,
    onSuccess: () => {
      toast.success('Conditions updated')
      queryClient.invalidateQueries({
        queryKey: ['flag', flagId],
      })
    },
  })

  const navigate = useNavigate()

  const saveChangeRequest = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation updateFlagChangeRequestConditions(
        $id: ID!
        $data: UpdateFlagConditions!
        $message: String
      ) {
        flag(id: $id) {
          createChangeRequest(
            conditions: $data
            information: null
            variations: null
            message: $message
          ) {
            id
          }
        }
      }
    `),
    mapVariables,
    onSuccess: (data) => {
      toast.success('Review created')
      queryClient.invalidateQueries({
        queryKey: ['flag', flagId],
      })
      setTimeout(
        () =>
          navigate(
            `/project/${project.slug}/feature-flags/${flagId}/reviews/${data.flag?.createChangeRequest.id}`
          ),
        100
      )
    },
  })

  const [showMessageModal, setShowMessageModal] = useState(false)

  usePrompt({
    when: isDirty,
    message: 'You have unsaved changes. Are you sure you want to leave?',
  })

  const { ready, data: flagData } = useFormDefaultValue({
    control,
    queryKey: ['flag', flagId, 'default condition values'],
    request: graphql(`
      query flagDetails($id: ID!) {
        flag(id: $id) {
          id
          active
          defaultVariation {
            id
          }
          variations {
            id
            active
          }
          sharedWithMe
          conditions {
            id
            name
            rules {
              id
              contextProperty {
                id
              }
              operator
              params
            }
            variation {
              id
            }
          }
        }
      }
    `),
    variables: { id: flagId as string },
    mapDefaultValues: (data) => ({
      active: data.flag?.active ?? false,
      defaultVariationId: data.flag?.defaultVariation.id ?? null,
      message: '',
      conditions:
        data.flag?.conditions.map((condition) => ({
          id: condition.id,
          name: condition.name,
          variationId: condition.variation.id,
          rules: condition.rules.map((rule) => {
            const { rangeStart, rangeEnd, ...params } = rule.params

            if (rangeStart !== undefined || rangeEnd !== undefined) {
              params.range = {
                start: rangeStart,
                end: rangeEnd,
              }
            }

            return {
              id: rule.id,
              operator: rule.operator,
              params,
              contextPropertyId: rule.contextProperty.id,
            }
          }),
        })) ?? [],
    }),
  })

  const lastConditionIndexToTargetProduction = useMemo(() => {
    const defValues = defaultValues as DeepRequired<typeof defaultValues>

    return defValues!.conditions.findIndex((condition) => {
      if (condition.rules.length !== 1) {
        return false
      }

      const { explicitlySelectEnv, explicitlyRejectOtherEnvs } =
        checkImpactsProduction({
          value: project.environmentContextProperty?.value,
          rules: condition.rules,
          contextPropertyId:
            project.environmentContextProperty?.contextProperty.id,
        })

      return explicitlySelectEnv || explicitlyRejectOtherEnvs
    })
  }, [
    defaultValues,
    project.environmentContextProperty?.contextProperty.id,
    project.environmentContextProperty?.value,
  ])

  const existingConditionsThatImpactProduction = useMemo(() => {
    const defValues = defaultValues as DeepRequired<typeof defaultValues>

    return (
      defValues!.conditions.filter((c, index) => {
        if (
          lastConditionIndexToTargetProduction !== -1 &&
          index > lastConditionIndexToTargetProduction
        ) {
          return false
        }

        const { impactsProduction } = checkImpactsProduction({
          value: project.environmentContextProperty?.value,
          rules: c.rules,
          contextPropertyId:
            project.environmentContextProperty?.contextProperty.id,
        })

        return impactsProduction
      }) ?? []
    )
  }, [project, defaultValues, lastConditionIndexToTargetProduction])

  const defaultVariationId = watch('defaultVariationId')
  const conditions = watch('conditions')

  const productionChanges = (() => {
    if (
      defaultVariationId !== defaultValues!.defaultVariationId &&
      lastConditionIndexToTargetProduction === -1
    ) {
      return true
    }

    if (
      existingConditionsThatImpactProduction.some((c) => {
        const condition = conditions.find((c2) => c2.id === c.id)

        if (!condition) {
          return true
        }

        if (condition.variationId !== c.variationId) {
          return true
        }

        if (condition.rules.length !== c.rules.length) {
          return true
        }

        return condition.rules.some((r, index) => {
          const rule = c.rules[index]

          if (r.operator !== rule.operator) {
            return true
          }

          if (r.contextPropertyId !== rule.contextPropertyId) {
            return true
          }

          if (JSON.stringify(r.params) !== JSON.stringify(rule.params)) {
            return true
          }
        })
      })
    ) {
      return true
    }

    const lastConditionIndexToTargetProductionNow = conditions.findIndex(
      (condition) => {
        if (condition.rules.length !== 1) {
          return false
        }

        const { explicitlySelectEnv, explicitlyRejectOtherEnvs } =
          checkImpactsProduction({
            value: project.environmentContextProperty?.value,
            rules: condition.rules,
            contextPropertyId:
              project.environmentContextProperty?.contextProperty.id,
          })

        return explicitlySelectEnv || explicitlyRejectOtherEnvs
      }
    )

    return conditions
      .filter((c, index) => {
        if (
          lastConditionIndexToTargetProductionNow !== -1 &&
          index > lastConditionIndexToTargetProductionNow
        ) {
          return false
        }

        const { impactsProduction } = checkImpactsProduction({
          value: project.environmentContextProperty?.value,
          rules: c.rules,
          contextPropertyId:
            project.environmentContextProperty?.contextProperty.id,
        })

        return impactsProduction
      })
      .some(
        (c) =>
          !existingConditionsThatImpactProduction.some((c2) => c2.id === c.id)
      )
  })()

  if (!ready.current) {
    return <section></section>
  }

  return (
    <section>
      <form
        onSubmit={handleSubmit(
          organization.myAggregatedRole.manageFlags !== ManageFlagsRole.None
            ? submit
            : () => null
        )}
      >
        <ConditionsInput
          control={control}
          name="conditions"
          flagId={flagId as string}
          defaultVariationId={
            flagData?.flag?.variations.find(({ active }) => active)?.id
          }
        />

        <AnimatePresence>
          {showMessageModal && (
            <Modal
              key="message-modal"
              onClose={() => {
                setShowMessageModal(false)
              }}
              size="m"
            >
              <Toolbar>
                <h2>Request review</h2>
                <IconButton
                  icon={IconX}
                  onClick={() => {
                    setShowMessageModal(false)
                  }}
                />
              </Toolbar>
              <InputWrapper
                name="message"
                component={LongTextInput}
                placeholder="Describe the changes you are requesting..."
                minLines={3}
                control={control}
                helper={
                  Object.keys(errors).length > 0 && (
                    <p>
                      <IconAlertTriangle
                        size={16}
                        style={{ verticalAlign: 'bottom' }}
                      />{' '}
                      Your changes are invalid
                    </p>
                  )
                }
              />

              <Toolbar alignRight>
                <Button
                  onClick={handleSubmit(saveChangeRequest)}
                  color="success"
                  loading={isSubmitting}
                  icon={IconEyeglass}
                  autoFocus
                >
                  Request review
                </Button>
                <Button
                  onClick={() => {
                    setShowMessageModal(false)
                  }}
                  variant="light"
                >
                  Cancel
                </Button>
              </Toolbar>
            </Modal>
          )}
        </AnimatePresence>
        <motion.div
          className={style.toolbar}
          initial={{ y: '100%' }}
          animate={{ y: isDirty ? 0 : '100%' }}
        >
          <Toolbar>
            {organization.myAggregatedRole.manageFlags ===
              ManageFlagsRole.All ||
            (organization.myAggregatedRole.manageFlags ===
              ManageFlagsRole.Shared &&
              flagData?.flag?.sharedWithMe) ? (
              <>
                {(project.requireChangeRequest !==
                  ChangeRequestRequirement.Always ||
                  organization.myAggregatedRole.reviews ===
                    ReviewsRole.Bypass ||
                  !organization.canMakeReviews) && (
                  <Tooltip>
                    <Tooltip.Trigger
                      component="div"
                      children={
                        <Button
                          color="primary"
                          loading={isSubmitting}
                          disabled={
                            organization.myAggregatedRole.reviews !==
                              ReviewsRole.Bypass &&
                            organization.canMakeReviews &&
                            project.requireChangeRequest ===
                              ChangeRequestRequirement.Production &&
                            productionChanges
                          }
                          type="submit"
                          icon={IconCheck}
                        >
                          Save changes
                        </Button>
                      }
                    />
                    <Tooltip.Content>
                      {organization.myAggregatedRole.reviews !==
                        ReviewsRole.Bypass &&
                      organization.canMakeReviews &&
                      project.requireChangeRequest ===
                        ChangeRequestRequirement.Production &&
                      productionChanges ? (
                        <>
                          Your changes will impact production, you have to
                          request a review
                        </>
                      ) : (
                        'Apply changes immediately'
                      )}
                    </Tooltip.Content>
                  </Tooltip>
                )}
                {organization.canMakeReviews && (
                  <Tooltip>
                    <Tooltip.Trigger
                      component={Button}
                      color="success"
                      variant={
                        project.requireChangeRequest !==
                        ChangeRequestRequirement.Always
                          ? 'light'
                          : 'filled'
                      }
                      loading={isSubmitting}
                      icon={IconEyeglass}
                      children="Request review"
                      onClick={() => setShowMessageModal(true)}
                    />
                    <Tooltip.Content>
                      Someone else will need to approve these changes before
                      applying them
                    </Tooltip.Content>
                  </Tooltip>
                )}
              </>
            ) : (
              <>
                <IconAlertTriangle
                  size={16}
                  style={{ color: 'var(--color-error)' }}
                />{' '}
                You are only a viewer, all changes will be lost
              </>
            )}
          </Toolbar>
        </motion.div>
      </form>
    </section>
  )
}

export const conditionsRoute: RouteObject = {
  path: 'conditions',
  element: <Page />,
}
