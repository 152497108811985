import { RouteObject } from 'react-router-dom'
import { TokenStatus, useQueryParamToken } from '../hooks/useQueryParamToken.ts'
import { useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import { gqlClient } from '../auth'
import { graphql } from '../gql'
import { useSilentLoader } from '../hooks/useSilentLoader.tsx'
import { CenteredVerticalLayout } from '../components/layout/CenteredVerticalLayout/CenteredVerticalLayout.tsx'
import { EmptyStateImage } from '../components/layout/EmptyStateImage/EmptyStateImage.tsx'
import somethingWentWrong from '../assets/empty-state/something-went-wrong.svg'
import awesome from '../assets/empty-state/awesome.svg'
import { IconArrowLeft } from '@tabler/icons-react'

const Page = () => {
  const token = useQueryParamToken()
  const { mutate, isPending, isError } = useMutation({
    mutationFn: (t: string) =>
      gqlClient.request(
        graphql(`
          mutation unsubscribe($token: String!) {
            optOutEmails(token: $token) {
              email
            }
          }
        `),
        { token: t }
      ),
  })

  useEffect(() => {
    if (token.status === TokenStatus.OK) {
      mutate(token.token)
    }
  }, [token.status, token.token, mutate])

  const silentLoader = useSilentLoader(isPending)

  if (silentLoader) {
    return null
  }

  if (isPending) {
    return (
      <CenteredVerticalLayout>
        <CenteredVerticalLayout.Loader />
      </CenteredVerticalLayout>
    )
  }

  if (isError) {
    return (
      <EmptyStateImage
        imageSrc={somethingWentWrong}
        title="Could not unsubscribe :/"
        text="Unfortunately we could not unsubscribe you from our emails, we appologize for that. Please contact us so we can make things right."
        cta="Back"
        ctaIcon={IconArrowLeft}
        ctaTo="/login"
      />
    )
  }

  return (
    <EmptyStateImage
      imageSrc={awesome}
      title="Successfuly unsubscribed!"
      text="We totally understand that you might not be interested, sorry for bothering you!"
      cta="Go to the app"
      ctaTo="/login"
    />
  )
}

export const unsubscribeRoute: RouteObject = {
  path: '/unsubscribe',
  element: <Page />,
}
