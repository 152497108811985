import { RouteObject, useNavigate } from 'react-router-dom'
import {
  IconBraces,
  IconCode,
  IconCopy,
  IconCreditCard,
  IconEyeglass,
  IconKey,
  IconPlus,
  IconSettings,
  IconToggleRight,
  IconTrash,
  IconUserCheck,
  IconUsers,
  IconWebhook,
} from '@tabler/icons-react'
import {
  ArrayTextInput,
  MultiSelectInput,
  SelectInput,
  SelectOption,
} from '../../../../components/input/SelectInput/SelectInput.tsx'
import { InputWrapper } from '../../../../components/input/InputWrapper/InputWrapper.tsx'
import { Control, DeepRequired, useFieldArray, useForm } from 'react-hook-form'
import { useFormSubmitMutation } from '../../../../hooks/useFormSubmitMutation.ts'
import { graphql } from '../../../../gql'
import { toast } from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchUser, gqlClient } from '../../../../auth'
import { ColumnDef } from '@tanstack/react-table'
import {
  ApiKeysRole,
  BillingRole,
  ContextRole,
  ManageFlagsRole,
  OrgMembersQuery,
  ProjectsRole,
  ReviewsRole,
  TeamRole,
  TechnicalExpertiseRole,
  ViewFlagsRole,
  WebhooksRole,
} from '../../../../gql/graphql.ts'
import './../../../../components/component/Table/Table.scss'
import { ReactNode, useMemo, useState } from 'react'
import { Table } from '../../../../components/component/Table/Table.tsx'
import { ShortDate } from '../../../../components/component/ShortDate/ShortDate.tsx'
import { Badge } from '../../../../components/component/Badge/Badge.tsx'
import { useCurrentOrgSafe } from '../../../../hooks/useCurrentOrgSafe.ts'
import { Dropdown } from '../../../../components/component/DropdownMenu/DropdownMenu.tsx'
import { FormSubmitButtons } from '../../../../components/component/FormSubmitButtons/FormSubmitButtons.tsx'
import {
  confirm,
  confirmDelete,
  openModal,
} from '../../../../components/component/Modal/Modal.tsx'
import { Button } from '../../../../components/component/Button/Button.tsx'
import { useFormDefaultValue } from '../../../../hooks/useFormDefaultValue.ts'
import style from './Team.module.scss'
import { TextInput } from '../../../../components/input/TextInput/TextInput.tsx'
import { MoreActions } from '../../../../components/component/MoreActions/MoreActions.tsx'
import upgradePlan from '../../../../assets/empty-state/add-product.svg'
import { Toolbar } from '../../../../components/component/Toolbar/Toolbar.tsx'

export const InviteMembers = ({
  defaultEmails = [],
}: {
  defaultEmails?: string[]
}) => {
  const { organization } = useCurrentOrgSafe()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      emails: defaultEmails,
      roleIds: [] as string[],
    },
  })

  const emails = watch('emails')

  const queryClient = useQueryClient()

  const navigate = useNavigate()

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation inviteMembers(
        $id: ID!
        $emails: [String!]!
        $roleIds: [String!]!
      ) {
        organization(id: $id) {
          inviteMembers(emails: $emails, roleIds: $roleIds)
        }
      }
    `),
    mapVariables: (data) => ({
      id: organization.id,
      emails: data.emails,
      roleIds: data.roleIds,
    }),
    onSuccess: (_, data) => {
      toast.success(`${data.emails.length} members invited!`)
      queryClient.invalidateQueries({
        queryKey: ['members'],
      })

      if (
        !organization.allowSelfSignup &&
        organization.domain &&
        data.emails.some((email) => email.endsWith(`@${organization.domain}`))
      ) {
        confirm({
          title: 'Quick tip',
          cancelText: 'No, thanks',
          confirmText: 'Go to settings',
          description: (
            <>
              <p>
                You can save time by letting anyone with an email that ends with
                @{organization.domain} join.
              </p>
              <p>
                You will never be charged extra: if you don't have any seat left
                we will notify you that someone wants to join.
              </p>
            </>
          ),
        }).then(() => {
          navigate('/settings/organization')
        })
      }
    },
    onError: {
      UNAUTHENTICATED: () =>
        toast.error('You cannot manage roles for this organization'),
      PLAN_LIMIT: () => toast.error(`Plan limit exceeded, please upgrade`),
    },
  })

  return (
    <form onSubmit={handleSubmit(submit)}>
      <InputWrapper
        control={control}
        name="emails"
        component={ArrayTextInput}
        label="Emails"
        placeholder="paul@acme.com, alice@acme.com..."
        rules={{ validate: (v) => v.length > 0 }}
      />
      <InputWrapper
        control={control}
        name="roleIds"
        label="Roles"
        // @ts-ignore
        component={MultiSelectInput}
        rules={{ validate: (v) => v.length > 0 }}
        options={organization.roles.map((role) => ({
          label: role.name,
          value: role.id,
        }))}
      />
      <FormSubmitButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        reset={reset}
        labelSubmit={emails.length > 1 ? `Invite (${emails.length})` : 'Invite'}
      />
    </form>
  )
}

const SetMembersRoles = ({ emails }: { emails: string[] }) => {
  const { organization } = useCurrentOrgSafe()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
  } = useForm({
    defaultValues: {
      roleIds: [] as string[],
    },
  })

  const queryClient = useQueryClient()

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation setMembersRoles(
        $id: ID!
        $emails: [String!]!
        $roleIds: [String!]!
      ) {
        organization(id: $id) {
          setMembersRoles(emails: $emails, roleIds: $roleIds)
        }
      }
    `),
    mapVariables: (data) => ({
      id: organization.id,
      emails,
      roleIds: data.roleIds,
    }),
    onSuccess: () => {
      toast.success(`Roles updated`)
      queryClient.invalidateQueries({
        queryKey: ['members'],
      })
      fetchUser()
    },
    onError: {
      UNAUTHENTICATED: () =>
        toast.error('You cannot manage roles for this organization'),
      PLAN_LIMIT: () => toast.error(`Plan limit exceeded, please upgrade`),
    },
  })

  return (
    <form onSubmit={handleSubmit(submit)}>
      <InputWrapper
        control={control}
        name="roleIds"
        label="Roles"
        // @ts-ignore
        component={MultiSelectInput}
        rules={{ validate: (v) => v.length > 0 }}
        options={organization.roles.map((role) => ({
          label: role.name,
          value: role.id,
        }))}
      />
      <FormSubmitButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        reset={reset}
        labelSubmit="Update roles"
      />
    </form>
  )
}

const roleRows: {
  icon: (props: { size?: string | number }) => ReactNode
  title: string
  name: string
  options: SelectOption<any>[]
}[] = [
  {
    title: 'Team',
    name: 'team',
    icon: IconUsers,
    options: [
      {
        label: 'View members',
        value: TeamRole.See,
        tooltip:
          "Can see who is in the organization but cannot invite new members or update existing members' roles",
      },
      {
        label: 'Manage',
        value: TeamRole.Manage,
        tooltip:
          'Can invite new members and change existing members roles and its own roles',
      },
    ],
  },
  {
    title: 'Billing',
    name: 'billing',
    icon: IconCreditCard,
    options: [
      {
        label: 'No',
        value: BillingRole.Hidden,
        tooltip: 'All billing related information is hidden',
      },
      {
        label: 'Manage',
        value: BillingRole.Manage,
        tooltip: 'Can manage all billing related information',
      },
    ],
  },
  // {
  //   title: 'Projects',
  //   name: 'projects',
  //   icon: IconCategory2,
  //   options: [
  //     {
  //       label: 'View shared',
  //       value: ProjectsRole.SeeShared,
  //       tooltip: 'Can only see projects explicitly shared with that role',
  //     },
  //     {
  //       label: 'View all',
  //       value: ProjectsRole.SeeAll,
  //       tooltip:
  //         'Can see all projects of the organization, but not create or edit them',
  //     },
  //     {
  //       label: 'Manage',
  //       value: ProjectsRole.Manage,
  //       tooltip: 'Can create, see, and edit all projects in the organization',
  //     },
  //   ],
  // },
  {
    title: 'Context',
    name: 'context',
    icon: IconBraces,
    options: [
      {
        label: 'No',
        value: ContextRole.Hidden,
        tooltip:
          'The context menu is hidden from the app, usually better for non-technical people',
      },
      {
        label: 'View',
        value: ContextRole.See,
        tooltip:
          'Can see the context menu, but cannot create or edit any property',
      },
      {
        label: 'Manage',
        value: ContextRole.Manage,
        tooltip: 'Can manage context properties of all projects it can see',
      },
    ],
  },
  {
    title: 'View flags',
    name: 'viewFlags',
    icon: IconToggleRight,
    options: [
      {
        label: 'View shared',
        value: ViewFlagsRole.Shared,
        tooltip:
          'Can only see feature flags shared with everyone or explicitly shared with $$',
      },
      {
        label: 'View all',
        value: ViewFlagsRole.All,
        tooltip: 'Can see all feature flags',
      },
    ],
  },
  {
    title: 'Manage flags',
    name: 'manageFlags',
    icon: IconToggleRight,
    options: [
      {
        label: 'No',
        value: ManageFlagsRole.None,
        tooltip: 'Cannot manage flags',
      },
      {
        label: 'Manage shared',
        value: ManageFlagsRole.Shared,
        tooltip:
          'Can only manage flags shared with everyone or explicitly shared with $$',
      },
      {
        label: 'Manage all',
        value: ManageFlagsRole.All,
        tooltip: 'Can manage all flags',
      },
    ],
  },
  {
    title: 'Tech expertise',
    name: 'technicalExpertise',
    icon: IconCode,
    options: [
      {
        label: 'Non-technical',
        value: TechnicalExpertiseRole.NonTechnical,
        tooltip: 'Helps non-technical users with simplified interfaces',
      },
      {
        label: 'Technical',
        value: TechnicalExpertiseRole.Technical,
        tooltip: 'Lets technical users see and manage technical aspects',
      },
    ],
  },
  {
    title: 'Reviews',
    name: 'reviews',
    icon: IconEyeglass,
    options: [
      {
        label: 'Create',
        value: ReviewsRole.Create,
        tooltip:
          'Can create reviews but will not be able to approve, reject, or apply them',
      },
      {
        label: 'Approve',
        value: ReviewsRole.Approve,
        tooltip:
          'Can approve or reject reviews but will not be able to apply them',
      },
      {
        label: 'Apply',
        value: ReviewsRole.Apply,
        tooltip: 'Can apply or close reviews',
      },
      {
        label: 'Bypass',
        value: ReviewsRole.Bypass,
        tooltip: 'Can bypass the review process',
      },
    ],
  },
  {
    title: 'API keys',
    name: 'apiKeys',
    icon: IconKey,
    options: [
      {
        label: 'No',
        value: ApiKeysRole.Hidden,
        tooltip:
          'The API keys menu is hidden from the app, usually better for non-technical people',
      },
      {
        label: 'View',
        value: ApiKeysRole.See,
        tooltip: 'Can see client and server API keys',
      },
    ],
  },
  {
    title: 'Webhooks',
    name: 'webhooks',
    icon: IconWebhook,
    options: [
      {
        label: 'No',
        value: WebhooksRole.Hidden,
        tooltip:
          'The webhooks menu is hidden from the app, usually better for non-technical people',
      },
      {
        label: 'View',
        value: WebhooksRole.See,
        tooltip:
          'Can see all webhooks and run history, but cannot update them or create new ones',
      },
      {
        label: 'Manage',
        value: WebhooksRole.Manage,
        tooltip: 'Can create, update, and delete webhooks',
      },
    ],
  },
]

const RoleRow = ({
  fields,
  title,
  name,
  options,
  control,
  icon: Icon,
}: {
  fields: { id: string; name: string }[]
  icon: (props: { size?: string | number; stroke?: number }) => ReactNode
  title: string
  name: string
  options: SelectOption<any>[]
  control: Control<any>
}) => {
  return (
    <div className={style.row}>
      <div>
        <h3>
          <Icon size={16} stroke={1.5} /> {title}
        </h3>
      </div>
      {fields.map((field, index) => (
        <div key={field.id}>
          <InputWrapper
            control={control}
            name={`roles.${index}.${name}`}
            component={SelectInput}
            options={options.map((option) => ({
              ...option,
              tooltip:
                typeof option.tooltip === 'string'
                  ? option.tooltip.replace('$$', field.name)
                  : option.tooltip,
            }))}
          />
        </div>
      ))}
    </div>
  )
}

const ManageRoles = () => {
  const { organization } = useCurrentOrgSafe()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      roles: organization.roles.map((role) => ({
        id: role.id as string | null,
        name: role.name,
        team: TeamRole.See,
        billing: BillingRole.Hidden,
        projects: ProjectsRole.SeeShared,
        context: ContextRole.Hidden,
        apiKeys: ApiKeysRole.Hidden,
        webhooks: WebhooksRole.Hidden,
        reviews: ReviewsRole.Create,
        manageFlags: ManageFlagsRole.Shared,
        viewFlags: ViewFlagsRole.Shared,
        technicalExpertise: TechnicalExpertiseRole.NonTechnical,
      })),
    },
  })

  useFormDefaultValue({
    control,
    queryKey: ['organization', organization.id, 'roles'],
    request: graphql(`
      query orgRoles($id: ID!) {
        organization(id: $id) {
          roles {
            id
            name
            team
            billing
            projects
            context
            apiKeys
            webhooks
            reviews
            manageFlags
            viewFlags
            technicalExpertise
          }
        }
      }
    `),
    mapDefaultValues: (data) => ({
      roles: data.organization?.roles ?? [],
    }),
    variables: {
      id: organization.id,
    },
  })

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation updateRoles($id: ID!, $roles: [RoleInformation!]!) {
        organization(id: $id) {
          setRoles(roles: $roles) {
            id
          }
        }
      }
    `),
    mapVariables: (data) => ({
      id: organization.id,
      roles: data.roles,
    }),
    onSuccess: () => {
      fetchUser()
      toast.success('Roles updated')
    },
    onError: {
      AT_LEAST_ONE_ROLE: () =>
        toast.error('You cannot delete the last role of a member'),
      AT_LEAST_ONE_ADMIN: () =>
        toast.error('You must have at least one admin who can manage the team'),
      AT_LEAST_ONE_ROLE_FOR_FLAG: () =>
        toast.error('You cannot delete the last role of a flag'),
    },
  })

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'roles',
  })

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className={style.container}>
        <div className={style.row}>
          <div />
          {fields.map((field, index) => (
            <div key={field.id}>
              <InputWrapper
                component={TextInput}
                control={control}
                name={`roles.${index}.name`}
                label="Role"
                suffix={
                  <Dropdown component={MoreActions} placement="bottom-end">
                    <Dropdown.Item
                      label="Duplicate"
                      icon={IconCopy}
                      onClick={() =>
                        insert(index + 1, { ...getValues(`roles.${index}`) })
                      }
                    />
                    <Dropdown.Item
                      label="Delete"
                      icon={IconTrash}
                      danger
                      disabled={fields.length === 1}
                      tooltip={
                        fields.length === 1
                          ? 'You cannot delete last role'
                          : null
                      }
                      onClick={() => remove(index)}
                    />
                  </Dropdown>
                }
              />
            </div>
          ))}
          <div>
            <Button
              color="primary"
              icon={IconPlus}
              onClick={() =>
                append({
                  id: null,
                  name: '',
                  team: TeamRole.See,
                  billing: BillingRole.Hidden,
                  projects: ProjectsRole.SeeShared,
                  context: ContextRole.Hidden,
                  apiKeys: ApiKeysRole.Hidden,
                  webhooks: WebhooksRole.Hidden,
                  reviews: ReviewsRole.Create,
                  manageFlags: ManageFlagsRole.Shared,
                  viewFlags: ViewFlagsRole.Shared,
                  technicalExpertise: TechnicalExpertiseRole.NonTechnical,
                })
              }
            >
              Add
            </Button>
          </div>
        </div>
        <div className={style.separator} />
        {roleRows.map((row) => (
          <RoleRow key={row.title} {...row} control={control} fields={fields} />
        ))}
      </div>
      <FormSubmitButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        reset={reset}
        labelSubmit="Save"
      />
    </form>
  )
}

type Row = DeepRequired<OrgMembersQuery>['organization']['members'][0]

const Members = () => {
  const { organization } = useCurrentOrgSafe()

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['members'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query orgMembers($id: ID!) {
            organization(id: $id) {
              id
              membersLimit {
                left
              }
              members {
                ... on OrganizationMember {
                  id
                  lastConnexion
                  user {
                    id
                    email
                    name
                  }
                  roles {
                    id
                    name
                  }
                }
                ... on Invitation {
                  id
                  email
                  invitedBy {
                    id
                    name
                  }
                  invitedAt
                  roles {
                    id
                    name
                  }
                }
              }
            }
          }
        `),
        {
          id: organization.id,
        }
      ),
  })

  const columns = useMemo<ColumnDef<Row>[]>(
    () => [
      {
        id: 'name',
        accessorFn: (row) =>
          'user' in row ? `${row.user.name ?? ''}${row.user.email}` : row.email,
        header: 'Name',
        cell: ({ row }) => {
          if ('user' in row.original) {
            return (
              <div>
                <div>
                  <em>{row.original.user.name}</em>
                </div>
                <small>{row.original.user.email}</small>
              </div>
            )
          }
          return (
            <>
              <div>{row.original.email}</div>
            </>
          )
        },
        sortingFn: 'alphanumeric',
      },
      {
        id: 'roles',
        accessorFn: (row) => row.roles,
        enableSorting: false,
        cell: ({ row }) => (
          <Badge.List>
            {row.original.roles.map((role) => (
              <Badge key={role.id}>{role.name}</Badge>
            ))}
          </Badge.List>
        ),
        header: 'Roles',
      },
      {
        id: 'lastConnexion',
        accessorFn: (row) => ('user' in row ? row.lastConnexion : undefined),
        sortUndefined: -1,
        cell: ({ getValue }) => {
          const value = getValue()
          return value ? (
            <ShortDate value={value as string} ago />
          ) : (
            <Badge>Pending invitation</Badge>
          )
        },
        header: 'Last connexion',
      },
    ],
    []
  )

  const queryClient = useQueryClient()

  const deleteMembers = useMutation({
    mutationFn: (emails: string[]) =>
      gqlClient.request(
        graphql(`
          mutation deleteMember($id: ID!, $emails: [String!]!) {
            organization(id: $id) {
              deleteMembers(emails: $emails)
            }
          }
        `),
        {
          id: organization.id,
          emails,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['members'] })
    },
  })

  const [rowSelection, setRowSelection] = useState(
    {} as Record<string, boolean>
  )

  return (
    <>
      <Table<Row>
        data={(data?.organization?.members as Row[]) ?? []}
        rowSelection={
          organization.myAggregatedRole.team === TeamRole.Manage
            ? rowSelection
            : undefined
        }
        loading={isLoading}
        fetching={isFetching}
        onRowSelectionChange={setRowSelection}
        columns={columns}
        defaultSorting={[{ id: 'lastConnexion', desc: true }]}
        unit="members"
        onCreate={
          organization.myAggregatedRole.team === TeamRole.Manage
            ? data?.organization?.membersLimit.left === 0
              ? () =>
                  openModal({
                    title: null,
                    content: (
                      <>
                        <img className="illustration" src={upgradePlan} />
                        <h2 style={{ textAlign: 'center' }}>
                          No more seats available
                        </h2>
                        <p style={{ textAlign: 'center' }}>
                          You used all the available seats that you currently
                          have. You need to add more seats or upgrade plan to
                          invite new members.
                        </p>
                        <Toolbar alignRight>
                          <Button
                            to="/settings/billing"
                            color="primary"
                            icon={IconSettings}
                          >
                            Manage plan
                          </Button>
                        </Toolbar>
                      </>
                    ),
                  })
              : () =>
                  openModal({
                    title: 'Invite members',
                    content: <InviteMembers />,
                  })
            : undefined
        }
        noSelectionToolbar={
          organization.myAggregatedRole.team === TeamRole.Manage ? (
            <Button
              icon={IconUsers}
              onClick={() =>
                openModal({
                  title: 'Manage roles',
                  content: <ManageRoles />,
                  size: 'l',
                })
              }
            >
              Manage roles
            </Button>
          ) : undefined
        }
        createButtonText="Invite members"
        selectionToolbar={
          <>
            <Button
              icon={IconUserCheck}
              onClick={() =>
                openModal({
                  title: 'Edit roles',
                  content: (
                    <SetMembersRoles
                      emails={
                        data?.organization?.members
                          .filter((member) => rowSelection[member.id])
                          .map((member) =>
                            'user' in member ? member.user.email : member.email
                          ) ?? []
                      }
                    />
                  ),
                })
              }
            >
              Edit roles
            </Button>
            <Button
              color="error"
              onClick={() =>
                confirmDelete({
                  unit: 'members',
                  values: data?.organization?.members
                    .filter((row) => rowSelection[row.id])
                    .map((row) => ('user' in row ? row.user.email : row.email)),
                  onDelete: (emails) => deleteMembers.mutateAsync(emails),
                  onDeleted: () => setRowSelection({}),
                })
              }
              icon={IconTrash}
            >
              Delete
            </Button>
          </>
        }
        filters={{
          status: {
            filter: (row, value) => 'user' in row === value,
            name: 'Invitation status',
            options: [
              {
                label: 'Pending',
                value: false,
                tooltip: 'Only show pending invitations',
              },
              {
                label: 'Accepted',
                value: true,
                tooltip: 'Only show active members',
              },
            ],
          },
        }}
        moreActions={
          organization.myAggregatedRole.team === TeamRole.Manage
            ? ({ row }) => (
                <>
                  <Dropdown.Item
                    label="Edit roles"
                    icon={IconUserCheck}
                    onClick={() =>
                      openModal({
                        title: 'Edit roles',
                        content: (
                          <SetMembersRoles
                            emails={[
                              'user' in row.original
                                ? row.original.user.email
                                : row.original.email,
                            ]}
                          />
                        ),
                      })
                    }
                  />
                  <Dropdown.Item
                    label="Delete"
                    icon={IconTrash}
                    danger
                    onClick={() =>
                      confirmDelete({
                        unit:
                          'user' in row.original ? 'members' : 'invitations',
                        values: [
                          'user' in row.original
                            ? row.original.user.email
                            : row.original.email,
                        ],
                        onDelete: (emails) => deleteMembers.mutateAsync(emails),
                        onDeleted: () => setRowSelection({}),
                      })
                    }
                  />
                </>
              )
            : undefined
        }
      />
    </>
  )
}

export const teamRoute: RouteObject = {
  path: 'team',
  element: (
    <>
      <Members />
    </>
  ),
}
