import { Navigate, RouteObject, useSearchParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gqlClient } from '../../../../auth'
import { graphql } from '../../../../gql'
import { useCurrentOrgSafe } from '../../../../hooks/useCurrentOrgSafe.ts'
import { useEffect } from 'react'
import { CenteredVerticalLayout } from '../../../../components/layout/CenteredVerticalLayout/CenteredVerticalLayout.tsx'
import { toast } from 'react-hot-toast'

let lastRun = null as number | null

const Page = () => {
  const [params] = useSearchParams()
  const { organization } = useCurrentOrgSafe()

  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      gqlClient.request(
        graphql(`
          mutation enableSlackIntegration($id: ID!, $code: String!) {
            organization(id: $id) {
              enableSlackIntegration(code: $code)
            }
          }
        `),
        { id: organization.id, code: params.get('code') ?? '' }
      ),
    onError: (error: any) => {
      if (
        error.response.errors?.[0]?.extensions?.code === 'SLACK_ERROR_PROCESS'
      ) {
        toast.error(
          'Could not enable Slack integration, the process was not completed properly. Please try again.',
          { duration: 5000 }
        )
      } else if (
        error.response.errors?.[0]?.extensions?.code === 'SLACK_ERROR_INTERNAL'
      ) {
        toast.error(
          'Could not enable Slack integration due to some internal errors. Try again or contact us.',
          { duration: 5000 }
        )
      } else if (
        error.response.errors?.[0]?.extensions?.code === 'SLACK_ERROR_SCOPE'
      ) {
        toast.error(
          'It looks like you may have tempered with the scopes. Please try again.',
          { duration: 5000 }
        )
      } else {
        toast.error('Could not enable Slack integration. Please try again.', {
          duration: 5000,
        })
      }
    },
    onSuccess: () => {
      toast.success('Slack integration enabled!')

      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ['org', organization.id, 'integrations'],
        })
      }, 1000)
    },
  })

  useEffect(() => {
    if (!lastRun || Date.now() - lastRun > 3000) {
      lastRun = Date.now()
      mutate()
    }
  }, [])

  if (isPending) {
    return (
      <CenteredVerticalLayout>
        <CenteredVerticalLayout.Loader />
      </CenteredVerticalLayout>
    )
  }

  return <Navigate to="/settings/integrations" />
}

export const slackRedirectRoute: RouteObject = {
  path: 'slack-redirect',
  element: <Page />,
}
