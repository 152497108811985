import { FC, ReactNode } from 'react'
import style from './CenteredVerticalLayout.module.scss'
import { IconLoader2 } from '@tabler/icons-react'
import cx from 'classnames'

export const CenteredVerticalLayout: FC<{
  children: ReactNode
  center?: boolean
  size?: 's' | 'm' | 'l'
}> & {
  Loader: typeof Loader
} = ({ children, center, size = 's' }) => {
  return (
    <div className={cx(style.container, center && style.center, style[size])}>
      <main>{children}</main>
    </div>
  )
}

const Loader = () => {
  return (
    <IconLoader2
      className={cx(style.loader, 'spinner')}
      size={50}
      stroke={1.5}
    />
  )
}

CenteredVerticalLayout.Loader = Loader
