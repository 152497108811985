import { FC, ReactNode } from 'react'
import { TablerIconsProps } from '@tabler/icons-react'
import { Button } from '../../component/Button/Button.tsx'
import './EmptyStateImage.scss'
import emptyImage from '../../../assets/empty-state/empty-box.svg'

export const EmptyStateImage: FC<{
  imageSrc?: string
  title?: ReactNode
  text?: ReactNode
  cta?: string | boolean
  ctaTo?: string
  ctaOnClick?: () => void
  ctaIcon?: (props: TablerIconsProps) => ReactNode
}> = ({
  imageSrc = emptyImage,
  title,
  cta,
  ctaTo,
  ctaOnClick,
  text,
  ctaIcon,
}) => {
  return (
    <div className="empty-state">
      <main>
        <img src={imageSrc} />
        <aside>
          {title && <h1>{title}</h1>}
          {typeof text === 'string' ? <p>{text}</p> : text}
          {cta && (
            <Button
              color="primary"
              inline
              onClick={ctaOnClick}
              to={ctaTo}
              icon={ctaIcon}
            >
              {cta}
            </Button>
          )}
        </aside>
      </main>
    </div>
  )
}
