import { ReactNode } from 'react'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { Button } from '../Button/Button.tsx'
import { IconCheck, IconSend } from '@tabler/icons-react'
import { useMutation } from '@tanstack/react-query'
import { gqlClient } from '../../../auth'
import { toast } from 'react-hot-toast'

export const SendEmailButton = <TResponse, TVariables>({
  children,
  mutation,
  variables,
}: {
  children: ReactNode
  mutation: TypedDocumentNode<TResponse, TVariables>
  variables: TVariables
}) => {
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: () => gqlClient.request(mutation, variables as any),
    onError: () => toast.error('Something went wrong'),
  })

  if (isSuccess) {
    return (
      <span>
        <IconCheck size={16} /> Email sent!
      </span>
    )
  }

  return (
    <Button
      color="primary"
      icon={IconSend}
      loading={isPending}
      onClick={() => mutate()}
    >
      {children}
    </Button>
  )
}
