import { forwardRef } from 'react'
import { Operator } from '../../../gql/graphql.ts'
import {
  ArrayTextInput,
  AutoCompleteArrayTextInput,
  MultiSelectInput,
} from '../SelectInput/SelectInput.tsx'
import { Control } from 'react-hook-form'
import { InputWrapper } from '../InputWrapper/InputWrapper.tsx'
import { FloatInput, TextInput, VersionInput } from '../TextInput/TextInput.tsx'
import { PercentageRangeInput } from '../PercentageRangeInput/PercentageRangeInput.tsx'
import { TimezoneInput } from '../TimezoneInput/TimezoneInput.tsx'
import { DateInput } from '../DateInput/DateInput.tsx'
import { IconSlash } from '@tabler/icons-react'
import { gqlClient } from '../../../auth'
import { graphql } from '../../../gql'
import { useFlag } from 'react-tggl-client'

export const ParamsInput = forwardRef<
  unknown,
  {
    control: Control<any>
    name: string
    operator: Operator | null
    contextPropertyId: string | null
    meta?: any
  }
>(({ control, name, operator, meta, contextPropertyId }, ref) => {
  const { active } = useFlag('autoCompleteValuesInConditions')
  return (
    <>
      {[
        !active && Operator.StrEquals,
        !active && Operator.StrNotEquals,
        Operator.StrStartsWith,
        Operator.StrNotStartsWith,
        Operator.StrEndsWith,
        Operator.StrNotEndsWith,
        Operator.StrContains,
        Operator.StrNotContains,
        Operator.ArrOverlap,
        Operator.ArrNotOverlap,
      ].includes(operator as Operator) && (
        <InputWrapper
          name={`${name}.values`}
          control={control}
          component={ArrayTextInput}
          style={{ flex: '1 1 300px' }}
          placeholder="Values..."
        />
      )}
      {active &&
        [Operator.StrEquals, Operator.StrNotEquals].includes(
          operator as Operator
        ) && [
          <InputWrapper
            key={contextPropertyId}
            name={`${name}.values`}
            control={control}
            component={AutoCompleteArrayTextInput}
            getOptions={async (input: string) => {
              if (!contextPropertyId) {
                return []
              }

              const result = await gqlClient.request(
                graphql(`
                  query contextPropertyValues($id: ID!, $query: String!) {
                    contextProperty(id: $id) {
                      id
                      values(query: $query) {
                        value
                        label
                      }
                    }
                  }
                `),
                {
                  id: contextPropertyId,
                  query: input,
                }
              )

              return (
                result.contextProperty?.values.map((option) => ({
                  value: option.value,
                  label: option.label ?? option.value,
                })) ?? []
              )
            }}
            getLabels={async (values: any[]) => {
              if (!contextPropertyId) {
                return values.map(() => null)
              }

              const result = await gqlClient.request(
                graphql(`
                  query contextPropertyLabels($id: ID!, $values: [String!]!) {
                    contextProperty(id: $id) {
                      id
                      labels(values: $values)
                    }
                  }
                `),
                {
                  id: contextPropertyId,
                  values,
                }
              )

              return result.contextProperty?.labels ?? values.map(() => null)
            }}
            style={{ flex: '1 1 300px' }}
            placeholder="Values..."
          />,
        ]}
      {[
        Operator.NumEquals,
        Operator.NumNotEquals,
        Operator.NumGt,
        Operator.NumLt,
        Operator.NumGte,
        Operator.NumLte,
      ].includes(operator as Operator) && (
        <InputWrapper
          name={`${name}.number`}
          control={control}
          component={FloatInput}
          style={{ flex: '0 1 120px' }}
        />
      )}
      {[Operator.SelectEquals, Operator.SelectNotEquals].includes(
        operator as Operator
      ) && (
        <InputWrapper
          name={`${name}.values`}
          control={control}
          component={MultiSelectInput}
          style={{ flex: '1 1 300px' }}
          options={
            meta?.options?.map(({ value }: any) => ({
              value,
              label: value,
            })) ?? []
          }
        />
      )}
      {[Operator.SemverIs, Operator.SemverLte, Operator.SemverGte].includes(
        operator as Operator
      ) && (
        <InputWrapper
          name={`${name}.version`}
          control={control}
          component={VersionInput}
          style={{ flex: '0 1 120px' }}
        />
      )}
      {[Operator.StrRegexp, Operator.StrNotRegexp].includes(
        operator as Operator
      ) && (
        <InputWrapper
          name={`${name}.regexp`}
          control={control}
          component={TextInput}
          prefix={<IconSlash size={14} />}
          suffix={<IconSlash size={14} />}
          className="monospace"
          style={{ flex: '1 1 120px' }}
        />
      )}
      {[Operator.Percentage].includes(operator as Operator) && (
        <InputWrapper
          name={`${name}.range`}
          control={control}
          component={PercentageRangeInput}
          defaultValue={{ start: 0, end: 0.2 }}
          style={{ flex: '1 1 300px' }}
        />
      )}
      {[Operator.DateIs].includes(operator as Operator) && (
        <>
          <InputWrapper
            name={`${name}.date`}
            control={control}
            component={DateInput}
            style={{ flex: '0 1 160px' }}
          />
        </>
      )}
      {[Operator.DateAfter, Operator.DateBefore].includes(
        operator as Operator
      ) && (
        <>
          <InputWrapper
            name={`${name}.datetime`}
            control={control}
            component={DateInput}
            time
            style={{ flex: '0 1 200px' }}
          />
        </>
      )}
      {[Operator.DateIs, Operator.DateAfter, Operator.DateBefore].includes(
        operator as Operator
      ) && (
        <>
          <InputWrapper
            name={`${name}.timezone`}
            control={control}
            component={TimezoneInput}
            defaultValue={Intl.DateTimeFormat().resolvedOptions().timeZone}
            style={{ flex: '1 1 200px' }}
          />
        </>
      )}
    </>
  )
})
