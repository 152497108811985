import { AppLayout } from '../../components/layout/AppLayout/AppLayout.tsx'
import { AuthenticatedOnly } from '../../components/layout/Auth/AuthenticatedOnly'
import { Navigate, RouteObject } from 'react-router-dom'
import { settingsRoute } from './settings/Settings.tsx'
import { projectRoute } from './project/Project.tsx'
import { webhooksRoute } from './project/webhooks/Webhooks.tsx'
import { useCurrentOrgSafe } from '../../hooks/useCurrentOrgSafe.ts'
import { importFlagsRoute } from './import/ImportFlags.tsx'

const GoToOverview = () => {
  const { project } = useCurrentOrgSafe()
  return <Navigate to={`/project/${project.slug}`} />
}

export const appRoute: RouteObject = {
  path: '/',
  element: (
    <AuthenticatedOnly>
      <AppLayout />
    </AuthenticatedOnly>
  ),
  children: [
    settingsRoute,
    projectRoute,
    webhooksRoute,
    importFlagsRoute,
    { index: true, element: <GoToOverview /> },
  ],
}
