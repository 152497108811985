import { forwardRef, useEffect, useRef } from 'react'
import { useMergeRefs } from '@floating-ui/react'

const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string
) => {
  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.height = '0px'
      textAreaRef.style.height = textAreaRef.scrollHeight + 'px'
    }
  }, [textAreaRef, value])
}

export const LongTextInput = forwardRef<
  HTMLTextAreaElement,
  {
    value: string
    onChange: (value: string) => void
    placeholder?: string
    minLines?: number
  }
>(({ value, onChange, minLines = 2, ...props }, ref) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  useAutosizeTextArea(textAreaRef.current, value)
  const mergedRef = useMergeRefs([textAreaRef, ref])

  return (
    <div className="input-container">
      <label className="input">
        <textarea
          ref={mergedRef}
          {...props}
          onChange={(e) => onChange(e.target.value)}
          value={value ?? ''}
          style={{ minHeight: minLines * 17 + 24 }}
        />
      </label>
    </div>
  )
})
