import { forwardRef } from 'react'
import './SwitchInput.scss'
import cx from 'classnames'

export type SwitchInputProps = {
  value: boolean
  onChange: (value: boolean) => void
}

export const SwitchInput = forwardRef<HTMLInputElement, SwitchInputProps>(
  ({ value, onChange, ...rest }, ref) => {
    return (
      <label className={cx('switch', { checked: value })}>
        <input
          {...rest}
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          type="checkbox"
          ref={ref}
        />
        <div />
      </label>
    )
  }
)
