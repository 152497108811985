import { CSSProperties, FC, ReactNode } from 'react'
import './Sections.scss'

export const Sections: FC<{
  children?: ReactNode
  titleColumnWidthFr?: number
}> & {
  Section: typeof Section
} = ({ children, titleColumnWidthFr }) => {
  return (
    <div
      className="sections"
      style={
        titleColumnWidthFr
          ? ({
              ['--titles-column-width']: `${titleColumnWidthFr}fr`,
            } as CSSProperties)
          : undefined
      }
    >
      {children}
    </div>
  )
}

const Section: FC<{
  title: ReactNode
  subTitle?: ReactNode
  children?: ReactNode
}> = ({ title, subTitle, children }) => {
  return (
    <>
      <aside>
        <h2>{title}</h2>
        {subTitle}
      </aside>
      <article>{children}</article>
    </>
  )
}
Sections.Section = Section
