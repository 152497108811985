import { CSSProperties, forwardRef, useRef, useState } from 'react'
import './PinInput.scss'
import cx from 'classnames'
import { useMergeRefs } from '@floating-ui/react'

type PinInputProps = {
  value: string
  onChange: (value: string) => void
  length: number
  group?: number
  autoFocus?: boolean
}

export const PinInput = forwardRef<HTMLInputElement, PinInputProps>(
  ({ value, onChange, length, group = 3, autoFocus }, ref) => {
    const inputRef = useRef<HTMLInputElement>()
    const mergedRef = useMergeRefs([ref, inputRef])
    const [selection, setSelection] = useState<null | {
      start: number
      end: number
    }>(null)

    return (
      <div
        className="pin-container"
        style={{ ['--length']: length } as CSSProperties}
      >
        <input
          value={value}
          onChange={(e) =>
            onChange(e.target.value.replace(/[^0-9]+/g, '').slice(0, length))
          }
          autoFocus={autoFocus}
          ref={mergedRef}
          onBlur={() => setSelection(null)}
          onSelect={(e) =>
            setSelection({
              start: e.currentTarget.selectionStart as number,
              end: e.currentTarget.selectionEnd as number,
            })
          }
        />
        {new Array(length).fill(0).map((_, index) => (
          <div
            key={index}
            className={cx(
              'char',
              index % group === group - 1 &&
                index !== length - 1 &&
                'separator-after',
              index === selection?.start && 'active',
              index === length - 1 &&
                selection?.start === length &&
                'active-right',
              index >= (selection?.start ?? Infinity) &&
                index < (selection?.end ?? 0) &&
                'selected'
            )}
            onClick={() => {
              inputRef.current!.focus()
              inputRef.current!.selectionStart = Math.min(index, value.length)
              inputRef.current!.selectionEnd = Math.min(index + 1, value.length)
              setSelection({
                start: Math.min(index, value.length),
                end: Math.min(index + 1, value.length),
              })
            }}
          >
            {value.slice(index, index + 1)}
          </div>
        ))}
      </div>
    )
  }
)
