import { FC, ReactNode } from 'react'
import { useToken, useUser } from '../../../auth'
import { CenteredVerticalLayout } from '../CenteredVerticalLayout/CenteredVerticalLayout'
import { Navigate } from 'react-router-dom'

export const AuthenticatedOnly: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const user = useUser()
  const token = useToken()

  if (!user && !token) {
    return <Navigate to="/login" />
  }

  if (!user || !token) {
    return (
      <CenteredVerticalLayout>
        <CenteredVerticalLayout.Loader />
      </CenteredVerticalLayout>
    )
  }

  return children
}
