import { forwardRef } from 'react'
import { SelectInput } from '../SelectInput/SelectInput.tsx'
import { useQuery } from '@tanstack/react-query'
import { gqlClient } from '../../../auth'
import { graphql } from '../../../gql'
import { useCurrentOrgSafe } from '../../../hooks/useCurrentOrgSafe.ts'
import { ContextRole } from '../../../gql/graphql.ts'
import { openModal } from '../../component/Modal/Modal.tsx'
import { PropertyForm } from '../../../pages/app/project/context/Context.tsx'

export const useProjectContextProperties = () => {
  const { project } = useCurrentOrgSafe()

  return useQuery({
    queryKey: ['context', project.id, 'input'],
    staleTime: 60000,
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query projectContextProperties($id: ID!) {
            project(id: $id) {
              id
              contextProperties {
                id
                name
                description
                type
                hidden
                meta
              }
            }
          }
        `),
        {
          id: project.id,
        }
      ),
  })
}

export const ContextPropertyInput = forwardRef<
  unknown,
  {
    value: string | null
    onChange: (value: string | null) => void
    disabled?: boolean
  }
>(({ value, onChange, disabled }, ref) => {
  const { data } = useProjectContextProperties()
  const { organization } = useCurrentOrgSafe()

  return (
    <SelectInput
      value={value}
      onChange={onChange}
      autoWidth
      ref={ref}
      placeholder="Property"
      disabled={disabled}
      options={
        data?.project?.contextProperties
          .filter(({ hidden }) => !hidden)
          .map((property) => ({
            value: property.id,
            label: property.name,
            tooltip: property.description,
          }))
          .sort((a, b) => (a.label > b.label ? 1 : -1)) ?? []
      }
      allOptions={data?.project?.contextProperties.map((property) => ({
        value: property.id,
        label: property.name,
      }))}
      onCreateOption={
        organization.myAggregatedRole.context === ContextRole.Manage
          ? (value) => {
              return new Promise<string | null>((resolve) => {
                openModal({
                  title: 'New Context Property',
                  content: (
                    <PropertyForm defaultName={value} onCreated={resolve} />
                  ),
                })
              })
            }
          : undefined
      }
      moreActions={
        organization.myAggregatedRole.context === ContextRole.Manage
          ? (option) =>
              openModal({
                title: 'Edit Context Property',
                content: <PropertyForm id={option.value as string} />,
              })
          : undefined
      }
    />
  )
})
