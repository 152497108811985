import { CSSProperties, forwardRef, ReactNode } from 'react'
import './Button.scss'
import cx from 'classnames'
import { IconLoader2, TablerIconsProps } from '@tabler/icons-react'
import { Link } from 'react-router-dom'

export type ButtonProps = {
  children?: ReactNode
  color?: 'primary' | 'default' | 'error' | 'success'
  variant?: 'filled' | 'light'
  icon?: (props: TablerIconsProps) => ReactNode
  disabled?: boolean
  loading?: boolean
  inline?: boolean
  type?: 'button' | 'submit'
  onClick?: () => void
  autoFocus?: boolean
  to?: string
  target?: '_blank'
  style?: CSSProperties
}

export const Button = forwardRef<HTMLElement, ButtonProps>(
  (
    {
      children,
      color = 'default',
      icon: Icon = () => null,
      disabled = false,
      loading = false,
      type = 'button',
      inline = false,
      variant = 'filled',
      onClick,
      to,
      ...props
    },
    ref
  ) => {
    const Component = to ? Link : 'button'

    return (
      <div className="btn-container">
        <Component
          // @ts-ignore
          ref={ref}
          className={cx(
            'btn',
            variant,
            color === 'primary' && 'primary',
            color === 'error' && 'error',
            color === 'success' && 'success',
            inline && 'inline'
          )}
          to={to as string}
          disabled={disabled || loading}
          type={to ? undefined : type}
          onClick={() => onClick?.()}
          {...props}
        >
          {!loading && <Icon size={14} stroke={2} />}
          {loading && <IconLoader2 size={14} stroke={2} className="spinner" />}
          {children}
        </Component>
      </div>
    )
  }
)
