import { Navbar } from '../../component/Navbar/Navbar.tsx'
import {
  IconAlertTriangleFilled,
  IconBell,
  IconBraces,
  IconCheck,
  IconChevronDown,
  IconGift,
  IconHelpCircleFilled,
  IconHome,
  IconKey,
  IconLogout,
  IconPlus,
  IconRefresh,
  IconSettings,
  IconToggleRight,
  IconUser,
  IconUsersPlus,
  IconWebhook,
} from '@tabler/icons-react'
import { Link, Navigate, Outlet } from 'react-router-dom'
import { useCurrentOrgSafe } from '../../../hooks/useCurrentOrgSafe.ts'
import './AppLayout.scss'
import { forwardRef, useEffect } from 'react'
import { Dropdown } from '../../component/DropdownMenu/DropdownMenu.tsx'
import { fetchUser, setCurrentOrg, useCurrentOrg, useUser } from '../../../auth'
import { openModal } from '../../component/Modal/Modal.tsx'
import { useForm } from 'react-hook-form'
import { FormSubmitButtons } from '../../component/FormSubmitButtons/FormSubmitButtons.tsx'
import { InputWrapper } from '../../input/InputWrapper/InputWrapper.tsx'
import { TextInput } from '../../input/TextInput/TextInput.tsx'
import { LongTextInput } from '../../input/LongTextInput/LongTextInput.tsx'
import Case from 'case'
import { useFormSubmitMutation } from '../../../hooks/useFormSubmitMutation.ts'
import { graphql } from '../../../gql'
import { toast } from 'react-hot-toast'
import defaultCompanyLogo from '../../../assets/logo/default-company-logo.svg'
import {
  ApiKeysRole,
  ContextRole,
  OrganizationStatus,
  WebhooksRole,
} from '../../../gql/graphql.ts'
import style from '../../component/Navbar/Navbar.module.scss'
import { Meter } from '../../component/Meter/Meter.tsx'
import { FormattedMessage } from 'react-intl'
import { Notifications } from './Notifications.tsx'
import { ErrorBoundary } from 'react-error-boundary'
import errorImage from '../../../assets/empty-state/something-went-wrong.svg'
import { EmptyStateImage } from '../EmptyStateImage/EmptyStateImage.tsx'

declare const Headway: {
  accountId: string
  hide: () => void
  init: (opts: Record<string, any>) => void
  show: () => void
  toggle: () => void
}

const headway = typeof Headway !== 'undefined' ? Headway : null

const CurrentProject = forwardRef<HTMLDivElement>((props, ref) => {
  const { organization, project } = useCurrentOrgSafe()
  const user = useUser()

  return (
    <div {...props} className="current-project" ref={ref}>
      <img
        className="avatar"
        src={
          organization.domain
            ? `https://img.logo.dev/${organization.domain}?token=pk_PMblKfzbSO6DrsIAU8SfpA`
            : defaultCompanyLogo
        }
        alt="logo"
      />
      {(user?.unreadNotificationCount ?? 0) > 0 && (
        <div className="notifications-container">
          <div className="notifications-badge">
            {(user?.unreadNotificationCount ?? 0) > 999
              ? '999+'
              : (user?.unreadNotificationCount ?? 0) > 1
              ? user?.unreadNotificationCount
              : null}
          </div>
        </div>
      )}
      <div className="title">Project</div>
      <div className="project">{project.name}</div>
      <IconChevronDown size={18} />
    </div>
  )
})

export const NewProjectForm = () => {
  const { organization } = useCurrentOrgSafe()
  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting, touchedFields },
    reset,
    setValue,
    clearErrors,
    watch,
  } = useForm({
    defaultValues: {
      description: '',
      slug: '',
      name: '',
    },
  })

  const name = watch('name')

  useEffect(() => {
    if (!touchedFields.slug) {
      setValue('slug', Case.camel(name))
      clearErrors('slug')
    }
  }, [clearErrors, name, setValue, touchedFields.slug])

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation createProject($id: ID!, $data: CreateProjectData!) {
        organization(id: $id) {
          createProject(data: $data) {
            id
            name
            slug
          }
        }
      }
    `),
    mapVariables: (data) => ({
      id: organization.id,
      data,
    }),
    onSuccess: (data) => {
      toast.success('Project created')
      fetchUser().then(() => {
        setCurrentOrg(
          organization.id,
          data.organization?.createProject.id ?? ''
        )
      })
    },
    onError: {
      PLAN_LIMIT: () => toast.error('You have reached the plan limit'),
    },
  })

  return (
    <form onSubmit={handleSubmit(submit)}>
      <InputWrapper
        label="Name"
        name="name"
        component={TextInput}
        control={control}
        rules={{ required: true }}
        autoFocus
      />
      <InputWrapper
        label="Key"
        name="slug"
        tooltip="The key is used inside the code to uniquely identify projects."
        component={TextInput}
        control={control}
        rules={{ required: true }}
        className="monospace"
      />
      <InputWrapper
        label="Description"
        name="description"
        component={LongTextInput}
        control={control}
      />
      <FormSubmitButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        reset={reset}
        labelSubmit="Create project"
      />
    </form>
  )
}

export const AppLayout = () => {
  const { organization, project } = useCurrentOrg()
  const user = useUser()

  useEffect(() => {
    headway?.init({
      selector: '#what-s-new-badge',
      account: 'JAWo9x',
    })
  }, [])

  if (!organization || !project) {
    return <Navigate to="/onboarding" />
  }

  return (
    <>
      <Navbar>
        <Dropdown component={CurrentProject} minWidth={260}>
          {(user?.organizations.length ?? 0) > 1 && (
            <Dropdown.Group label="Organizations" noSticky>
              {user?.organizations.map((o) => (
                <Dropdown.Item
                  key={o.id}
                  label={o.name}
                  selected={o.id === organization.id}
                  icon={IconCheck}
                  onClick={() => setCurrentOrg(o.id, o.projects[0].id)}
                />
              ))}
            </Dropdown.Group>
          )}
          <Dropdown.Group label="Projects" noSticky>
            {organization.projects.map((p) => (
              <Dropdown.Item
                key={p.id}
                label={p.name}
                selected={p.id === project.id}
                icon={IconCheck}
                onClick={() => setCurrentOrg(organization.id, p.id)}
              />
            ))}
          </Dropdown.Group>
          <hr />
          <Dropdown.Item
            label="New project"
            icon={IconPlus}
            onClick={() =>
              openModal({
                title: 'New project',
                content: <NewProjectForm />,
              })
            }
          />
          <hr />
          <Dropdown label="Notifications" icon={IconBell}>
            <Notifications />
          </Dropdown>
          <Dropdown.Item
            label="Profile"
            to="/settings/profile"
            icon={IconUser}
          />
          <Dropdown.Item label="Logout" to="/logout" danger icon={IconLogout} />
        </Dropdown>
        <Navbar.Link to={`/project/${project.slug}`} end icon={IconHome}>
          Overview
        </Navbar.Link>
        <Navbar.Link
          to={`/project/${project.slug}/feature-flags`}
          icon={IconToggleRight}
        >
          Feature flags
        </Navbar.Link>
        {organization.myAggregatedRole.context !== ContextRole.Hidden && (
          <Navbar.Link
            to={`/project/${project.slug}/context`}
            icon={IconBraces}
          >
            Context
          </Navbar.Link>
        )}
        {organization.myAggregatedRole.apiKeys === ApiKeysRole.See && (
          <Navbar.Link to={`/project/${project.slug}/api-keys`} icon={IconKey}>
            API keys
          </Navbar.Link>
        )}
        {organization.myAggregatedRole.webhooks !== WebhooksRole.Hidden && (
          <Navbar.Link to={`/webhooks`} icon={IconWebhook}>
            Webhooks
          </Navbar.Link>
        )}
        <hr />
        <Navbar.Link icon={IconUsersPlus} to="settings/team">
          Manage team
        </Navbar.Link>
        <Navbar.Link
          to="/"
          icon={IconHelpCircleFilled}
          href="https://tggl.io/developers"
          target="_blank"
        >
          Help and docs
        </Navbar.Link>
        <div
          className={style['menu-item']}
          onClick={() => setTimeout(headway?.show ?? (() => null), 10)}
        >
          <IconGift size={18} stroke={1.75} />
          <div id="what-s-new-badge" />
          <span>What's new</span>
        </div>
        <Navbar.Link icon={IconSettings} to="/settings/organization">
          Settings
        </Navbar.Link>
        {organization.status === OrganizationStatus.Trial &&
          !organization.hideTrial && (
            <Link to="/settings/billing" className="trial-card">
              <div className="trial-badge">Upgrade</div>
              <div className="trial-title">Trial</div>
              <Meter
                title={
                  <>
                    {Math.round(
                      ((organization.trialEnd ?? Date.now()) - Date.now()) /
                        (24 * 3600 * 1000)
                    )}{' '}
                    days left
                  </>
                }
                startAt={organization.createdAt}
                current={Date.now()}
                limit={organization.trialEnd}
                hideCounter
              />
            </Link>
          )}
        {organization.status === OrganizationStatus.TrialGracePeriod && (
          <Link to="/settings/billing" className="trial-card">
            <div className="trial-title">
              <IconAlertTriangleFilled size={16} />
              Trial ended
            </div>
            <p>
              <FormattedMessage
                id="trial-grace-period"
                values={{
                  daysLeft: Math.round(
                    ((organization.trialGracePeriodEndsAt ?? Date.now()) -
                      Date.now()) /
                      (24 * 3600 * 1000)
                  ),
                }}
              />
            </p>
            <div className="trial-cta">Choose plan</div>
          </Link>
        )}
        {organization.status === OrganizationStatus.TrialEnded && (
          <Link to="/settings/billing" className="trial-card">
            <div className="trial-title">
              <IconAlertTriangleFilled size={16} />
              Trial ended
            </div>
            <p>All your flags are de-activated until you choose a plan</p>
            <div className="trial-cta">Choose plan</div>
          </Link>
        )}
      </Navbar>
      <Navbar.Body>
        <ErrorBoundary
          fallback={
            <EmptyStateImage
              imageSrc={errorImage}
              title="Something went wrong"
              text="Something unexpected happened. Try reloading the page and if the problem persists, please contact support."
              cta="Refresh"
              ctaIcon={IconRefresh}
              ctaOnClick={() => window.location.reload()}
            />
          }
        >
          <Outlet />
        </ErrorBoundary>
      </Navbar.Body>
    </>
  )
}
